import React, { useEffect, useState } from 'react';

import { Divider, Stack, Typography } from '@mui/material';

import { DeleteIconCV } from '../../../../../assets/constants';
import {
  updateAwardsInfo,
  updateEducationInfo,
} from '../updateTemplateDataApi';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { deleteCvAward } from '../deleteTemplateDataApi';
import EditableTypography from '../../../../../common/EditableTypography';
import {useCvBuilderContext} from "../../../../../context/CvBuilderContext/useCvBuilderContext";

export default function Awards({ classes, templateData }) {
  // const [awards, setAwards] = useState([]);
  const {
    formatDate,
    addNewAward,
    awards,
    setAwards,
    updateAwardField,
    handleDeleteAward,
  } = useCvBuilderContext();

  // useEffect(() => {
  //   setAwards(templateData?.awards);
  // }, [templateData || []]);

  // const addNewAward = () => {
  //   setAwards([{ id: Date.now() }, ...awards]);
  // };

  // const handleDelete = (id) => {
  //   const newAwards = awards?.filter((award) => award?.id !== id);
  //   deleteCvAward(id);
  //   setAwards(newAwards);
  // };

  // const replaceAwardId = (awardId, newId) => {
  //   const newAwards = [...awards];
  //   const index = newAwards.findIndex((obj) => obj.id == awardId);
  //   newAwards[index].id = newId;
  //   setAwards(newAwards);
  // };
  //
  // const updateAwardField = (params) => {
  //   const { name, text, id } = params;
  //   updateAwardsInfo(name, text, id).then((resp) => {
  //     if (resp?.code === '11') {
  //       const awardId = resp?.data?.awardId;
  //       replaceAwardId(id, awardId);
  //     }
  //   });
  // };

  return (
    <>
      <Stack
        py={1}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Divider
          classes={{ root: classes.divider__root }}
          sx={{ width: 'calc(100% - 120px) !important' }}
          textAlign='left'
        >
          <Typography variant='title18Bold' sx={{ fontWeight: '600' }}>
            Awards
          </Typography>
        </Divider>
        <Typography
          sx={{
            cursor: 'pointer',
            minWidth: '110px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
          onClick={addNewAward}
        >
          <AddCircleOutlineOutlinedIcon
            style={{ marginRight: '4px', height: '20px', width: '20px' }}
          />{' '}
          Add New
        </Typography>
      </Stack>
      {awards?.map((award) => (
        <Stack
          position='relative'
          direction='row'
          key={award?.id}
          alignItems='flex-start'
          justifyContent='space-between'
          sx={{
            '&:hover': {
              '& .del__icon': {
                display: 'flex',
              },
            },
          }}
        >
          <Stack direction='row' alignItems='flex-start'>
            <Stack
              direction='row'
              alignItems='center'
              sx={{ color: '#3e6af2' }}
            >
                <EditableTypography
                padding={1}
                onBlur={(e) => {
                  updateAwardField({
                    name: 'start_date',
                    text: e.target.innerText,
                    id: award?.id,
                  });
                }}
                variant='title17Bold'
                fontWeight='400'
              >
                {award?.start_date ?? '2019'}
                </EditableTypography>
              -{' '}
                <EditableTypography
                padding={1}
                onBlur={(e) => {
                  updateAwardField({
                    name: 'end_date',
                    text: e.target.innerText,
                    id: award?.id,
                  });
                }}
                variant='title17Bold'
                fontWeight='400'
              >
                {award?.end_date ?? 'Present'}
                </EditableTypography>
            </Stack>
            <Stack>
                <EditableTypography
                padding={1}
                onBlur={(e) => {
                  updateAwardField({
                    name: 'institute',
                    text: e.target.innerText,
                    id: award?.id,
                  });
                }}
                variant='title12Bold'
                fontWeight='400'
              >
                {award?.institute ? award?.institute : 'institute'}
                </EditableTypography>
                <EditableTypography
                padding={1}
                onBlur={(e) => {
                  updateAwardField({
                    name: 'award_name',
                    text: e.target.innerText,
                    id: award?.id,
                  });
                }}
                variant='title18Bold'
                fontWeight='700'
              >
                {award?.award_name ? award?.award_name : 'award title'}
                </EditableTypography>
                <EditableTypography
                padding={1}
                onBlur={(e) => {
                  updateAwardField({
                    name: 'award_description',
                    text: e.target.innerText,
                    id: award?.id,
                  });
                }}
                variant='title14Bold'
                dangerouslySetInnerHTML={{ __html: award?.award_description }}
                fontWeight='400'
                whiteSpace='pre-wrap'
                ></EditableTypography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              fontWeight: '400',
              minWidth: '130px',
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
              <EditableTypography
              padding={1}
              onBlur={(e) => {
                updateAwardField({
                  name: 'award_city',
                  text: e.target.innerText,
                  id: award?.id,
                });
              }}
              variant='title14Bold'
              fontWeight='400'
            >
              {award?.award_city ? award?.award_city : 'award city here'}
              </EditableTypography>
          </Stack>
          <Stack
            className='del__icon'
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '0',
              top: '2rem',
              background: '#fff',
              borderRadius: '6px',
              boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.14)',
              height: '40px',
              width: '40px',
              display: 'none',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 10,
            }}
          >
            <DeleteIconCV
              onClick={() => {
                handleDeleteAward(award?.id);
              }}
            />
          </Stack>
        </Stack>
      ))}
    </>
  );
}
