import React from 'react';

import Chart from 'react-apexcharts';
import { Stack } from '@mui/material';

export default function ChartGraph(props) {
  const {application} = props;

  const series = [
    {
      name: 'High - 2013',
      data: [3, application?.totHighMatch, 13],
    },
    {
      name: 'Median - 2015',
      data: [7, application?.totMedMatch, 3],
    },
    {
      name: 'Low - 2013',
      data: [17, application?.totLowMatch, 25],
    },
  ];
  const options = {
    legend: {
      enabled: false,
    },
    chart: {
      height: 350,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ['#0aa06a', '#1045ff', '#ec7376'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1,
      curve: 'smooth',
    },
    title: {
      //   text: 'Average High & Low Temperature',
      align: 'left',
    },
    sparkling: {
      enabled: true,
    },
    grid: {
      show: true,
      borderColor: '#e4e4e4',
      strokeDashArray: 0,
      position: 'back',
      yaxis: {},
      row: {
        colors: [], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 0,
    },
    xaxis: {
      labels: {
        show: false,
      },
      categories: [],
      title: {
        show: false,
      },
    },
    yaxis: {
      title: {
        // text: 'Temperature',
      },
      labels: {
        show: false,
      },
      //   min: 20,
    },
  };

  return (
    <Stack>
      <Chart options={options} series={series} type='line' height={190} />
    </Stack>
  );
}
