import React, { useState } from 'react';

import AccordionMain from '../AccordionMain';
import TextField from '../../../../../../common/Textfield';

export default function PersonalInformation({
  expandedAccordion,
  handleAccordionChange,
}) {
  const [fieldErrors, setFieldErrors] = useState('');
  const [fieldVals, setFieldVals] = useState({
    full_name: '',
    jobTitle: '',
    email_address: '',
    mobile_number: '',
    address: '',
    link: '',
  });

  const handleChanges = (e) => {
    var { name, value } = e.target;
    var value = (value = value?.trimStart());
    setFieldVals({
      ...fieldVals,
      [name]: value,
    });
    setFieldErrors('');
  };
  return (
    <AccordionMain
      title='Personal Information'
      name='personalInfo'
      expandedState={expandedAccordion === 'personalInfo'}
      onChange={handleAccordionChange}
    >
      <TextField
        autoFocus
        margin='dense'
        tableInput
        variant='standard'
        helperText={fieldErrors}
        value={fieldVals?.full_name}
        placeholder='Enter Full Name'
        onChange={(e) => handleChanges(e)}
      />
      <TextField
        autoFocus
        margin='dense'
        tableInput
        variant='standard'
        helperText={fieldErrors}
        value={fieldVals?.jobTitle}
        placeholder='Enter Job Title'
        onChange={(e) => handleChanges(e)}
      />
      <TextField
        autoFocus
        margin='dense'
        tableInput
        variant='standard'
        helperText={fieldErrors}
        value={fieldVals?.email_address}
        placeholder='Enter Email Address'
        onChange={(e) => handleChanges(e)}
      />
      <TextField
        autoFocus
        margin='dense'
        tableInput
        variant='standard'
        helperText={fieldErrors}
        value={fieldVals?.mobile_number}
        placeholder='Enter Mobile Number'
        onChange={(e) => handleChanges(e)}
      />
      <TextField
        autoFocus
        margin='dense'
        tableInput
        variant='standard'
        helperText={fieldErrors}
        value={fieldVals?.address}
        placeholder='Enter Address'
        onChange={(e) => handleChanges(e)}
      />
      <TextField
        autoFocus
        margin='dense'
        tableInput
        variant='standard'
        helperText={fieldErrors}
        value={fieldVals?.link}
        placeholder='Enter Website'
        onChange={(e) => handleChanges(e)}
      />
    </AccordionMain>
  );
}
