import React, { useEffect, useState } from 'react';

import { makeStyles, useTheme } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Divider, Drawer, Stack, useMediaQuery } from '@mui/material';

import Style from './style';
import TemplateLoader from '../../../TemplateLoader';
import { EditIconTable } from '../../../../../assets/constants';
import AvatarMenu from '../../../../../common/AvatarMenu';
import ActionButtons from '../../Templates/ActionButtons';
import EditTemplate from '../EditTemplate';

const Styles = makeStyles((theme) => Style(theme));

export default function PreviewPageTemp({ toggle, setToggle }) {
  const classes = Styles();
  const theme = useTheme();

  const [editToggle, setEditToggle] = useState(false);
  // useEffect(() => {
  //   console.log(job);
  // }, [toggle]);

  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));
  const handleClose = () => {
    // navigate(`/job/app/${id}`);
    setToggle(false);
  };

  const EditDialog = editToggle && (
    <EditTemplate toggle={editToggle} setToggle={setEditToggle} />
  );

  return (
    <Drawer
      anchor='left'
      open={toggle}
      onClose={() => {
        handleClose();
      }}
      className={classes.drawer__job__details}
      sx={{
        '--Drawer-transitionDuration': toggle ? '1s' : '0.2s',
        '& .MuiDrawer-paper': {
          overflowY: 'auto',
        },
      }}
    >
      <Stack width='100vw'>
        <Stack
          p={3}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            '& .edit__icon': {
              height: '18px',
              width: '18px',
              '& path': {
                fill: '#010103',
              },
            },
          }}
        >
          <ArrowBackIcon
            className={classes.close__icon}
            onClick={handleClose}
          />
          <Stack direction='row' alignItems='center' spacing={2}>
            <EditIconTable
              className='edit__icon'
              onClick={() => setEditToggle(true)}
            />
            <AvatarMenu />
          </Stack>
        </Stack>
        <Divider />
        <Stack pt={3.5} pb={2} px={3}>
          <Stack className={classes.template__view}>
            <TemplateLoader />
          </Stack>
          <Stack
            sx={{
              '& > .MuiStack-root': {
                px: 0,
              },
            }}
          >
            <ActionButtons
              smMediaquery={smMediaquery}
              mediaquery1380={mediaquery1380}
            />
          </Stack>
        </Stack>
        {EditDialog}
      </Stack>
    </Drawer>
  );
}
