import { useState } from 'react';
import { headers, usefetch } from '../helpers/fetch';
import { getLocalStorage, setLocalStorage } from '../helpers/common';

export const useGetRemainingCredits = () => {
  const [error, setError] = useState(null);
  const [isLoadingCredits, setIsLoadingCredits] = useState(null);
  const [response, setResponse] = useState(null);
  const [credits, setCredits] = useState([]);
  const [hasCredits, setHasCredits] = useState(false);
  let doesHasCredit = false;
  const getRemainingCredits = async (params) => {
    setIsLoadingCredits(true);
    const { prodCode } = params || {};
    const data = { prodCode: prodCode };

    if (localStorage.getItem('token')) {
      const resp = await usefetch(
        'packages/remainingCredits',
        'POST',
        headers(),
        data,
      );

      if (resp?.code === '11') {
        setCredits((prev) => resp?.data?.credits);
        if (resp?.data?.credits) {
          setLocalStorage('credits', resp?.data?.credits);
        }
        if (prodCode && resp?.data?.has_credits == 'Y') {
          doesHasCredit = true;
        }
      }

      if (!resp.ok) {
        setError(resp.error);
      }

      if (resp.ok) {
        setResponse((prevData) => [...prevData, resp]);
      }

      setIsLoadingCredits(false);
      return { resp, doesHasCredit };
    }
    return doesHasCredit;
  };

  const checkCredits = (prodCode) => {
    let doesHasCredit = false;
    let localCredits = getLocalStorage('credits');
    let creditCheck = [];
    if (localCredits && localCredits != 'null' && localCredits.length > 0) {
      creditCheck = localCredits;
    } else {
      creditCheck = credits;
    }

    for (let i = 0; i < creditCheck.length; i++) {
      if (
        creditCheck[i]?.details?.code === prodCode &&
        Number(creditCheck[i]?.totCredits) > 0
      ) {
        doesHasCredit = Number(creditCheck[i]?.totCredits);
      }
    }
    return doesHasCredit;
  };

  const checkPerJobCredit = (prodCode, jid) => {
    let localCredits = getLocalStorage('credits');
    let creditCheck = [];
    if (localCredits && localCredits != 'null' && localCredits.length > 0) {
      creditCheck = localCredits;
    } else {
      creditCheck = credits;
    }

    const productDetail = creditCheck?.find(
      (item) => item.details?.code === prodCode,
    );

    const jobCounts = productDetail?.jCredit?.find((job) => job.jid === jid);
    if (
      jobCounts &&
      Number(jobCounts?.per_job_count) >= Number(jobCounts?.remaining_credits)
    ) {
      if (Number(productDetail?.totCredits) > 0) {
        return Number(productDetail?.totCredits);
      }
    } else {
      return (
        Math.abs(
          Number(jobCounts?.per_job_count) -
            Number(jobCounts?.remaining_credits),
        ) + Number(productDetail?.totCredits)
      );
    }
    return false;
  };

  return {
    getRemainingCredits,
    response,
    credits,
    isLoadingCredits,
    hasCredits,
    error,
    checkCredits,
    checkPerJobCredit,
  };
};
