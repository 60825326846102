import React from 'react';
import { Stack } from '@mui/material';

import Awards from './Awards';
import Summary from './Summary';
import Language from './Language';
import Education from './Education';
import Employment from './Employment';
import PersonalInformation from './PersonalInformation';
import Skills from './Skills';

export default function Template2Stack({ publicView }) {
  return (
    <Stack sx={{ position: 'relative' }}>
      {/* Overlay Public layer */}
      {publicView && (
        <Stack
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1, // Make sure the overlay is above everything else
            backgroundColor: 'transparent', // No background color, just an invisible layer
          }}
        />
      )}
      <Stack direction='row' px={6} sx={{ fontFamily: '"Lora", serif' }}>
        <Stack sx={{ width: '40%' }}>
          <PersonalInformation />
          <Education publicView={publicView} />
          <Awards publicView={publicView} />
          <Language publicView={publicView} />
        </Stack>
        <Stack pl={8.75} sx={{ width: '60%' }}>
          <Summary />
          <Skills publicView={publicView} />
          <Employment publicView={publicView} />
        </Stack>
      </Stack>
    </Stack>
  );
}
