import React, { useState, useRef, useEffect, useContext } from "react";
import { headers, usefetch } from "../../../helpers/fetch";
import { setLocalStorage } from "../../../helpers/common";
import { useGetRemainingCredits } from "../../../services/useGetRemainingCredits";
import { useGetPackages } from "../../../services/useGetPackages";
import { useAuthContext } from "../../../Auth/useAuthContext";
import { useNavigate } from "react-router-dom";

export const useCart = () => {
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [cartAmount, setCartAmount] = useState(0); // total amount amount
  const [amountWOT, setAmountWOT] = useState(0); // amount without
  const [salesTaxAmount, setSalesTaxAmount] = useState(0);
  const [cartCount, setCartCount] = useState(null);
  const [payNowPopup, setPayNowPopup] = useState(false);
  const [PaymentData, setPaymentData] = useState(null);
  const [OrderId, setOrderId] = useState(null);
  const [OrderPayment, setOrderAmount] = useState(null);
  const [creditToggle, setCreditToggle] = useState(false);
  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);
  const [accountDrawerOpen, setAccountDrawerOpen] = useState(false);
  const [seekerFlow, setSeekerFlow] = useState(false);
  const [paymentToggle, setPaymentToggle] = useState(false);
  const [paymentRes, setPaymentRes] = useState(null);
  const [seekerPackageAmount, setSeekerPackageAmount] = useState('');


  const {
    getRemainingCredits,
    checkCredits,
    checkPerJobCredit,
    credits,
    hasCredits,
  } = useGetRemainingCredits();
  const { user } = useAuthContext();
  const {
    getPackages,
    packages,
    topUps,
    salesTaxPercent,
    setSalesTaxPercent,
    isLoadingPackages,
    setIsLoadingPackages,
  } = useGetPackages();

  const handleAddToCart = (obj, type = "plus") => {
    const cartArr = [...cart];
    const existing_package = cartArr?.find((item) => item.id === obj.id);
    const amount = 0;

    if (type === "minus") {
      if (existing_package.quantity != 1) {
        existing_package.quantity = Number(existing_package.quantity) - 1;
      }
    } else {
      if (existing_package) {
        existing_package.quantity = Number(existing_package.quantity) + 1;
      } else {
        cartArr.push({ ...obj, quantity: 1 });
      }
    }
    const amountWOTT = cartArr.reduce(
      (amount, pack) => amount + Number(pack.price) * Number(pack.quantity),
      0
    );
    setAmountWOT(amountWOTT);
    const salesTaxA = Math.ceil((salesTaxPercent / 100) * amountWOTT);
    setSalesTaxAmount(salesTaxA);
    const totalAmount = Math.ceil(Number(amountWOTT) + Number(salesTaxA));

    setCartAmount(totalAmount);
    setCartCount(cartArr?.length);
    setCart(() => cartArr);
    saveOrder(cartArr, totalAmount, amountWOTT, salesTaxA, "");
  };

  const packageExist = (package_id) => {
    if (cart != "undefined" && cart?.length > 0) {
      return cart?.find((item) => item.id === package_id) !== undefined;
    }
  };

  const handleDeleteCart = (obj) => {
    const cartArr = [...cart];
    const updatedCart = cartArr.filter((item) => item.id !== obj.id);
    const amount = 0;

    const amountWOTT = updatedCart.reduce(
      (amount, pack) => amount + pack.price * pack.quantity,
      0
    );
    setAmountWOT(amountWOTT);
    const salesTaxA = Math.ceil((salesTaxPercent / 100) * amountWOTT);
    setSalesTaxAmount(() => salesTaxA);
    const totalAmount = Math.ceil(Number(amountWOTT) + Number(salesTaxA));
    setCartAmount((prev) => totalAmount);
    setCart(updatedCart);
    saveOrder(updatedCart, totalAmount, amountWOTT, salesTaxA, obj.id);
    setCartCount(updatedCart?.length);
  };
  useEffect(() => {
      if(user?.userType !== 'seeker'){
        if( cartDrawerOpen || cart?.length < 1) {
          getCart();
        }
      }
      if(cartDrawerOpen) {
         getRemainingCredits();
      }
  }, [cartDrawerOpen]);

  useEffect(() => {
    if(user?.userType === 'seeker'){
      seekerPackage();
    }
  }, [seekerPackageAmount]);

  const emptyCart = () => {
    setOrderId("");
    setOrderAmount("");
    setCartAmount("");
    setCart("");
    setCartCount("");
    setLocalStorage("OrderId", "");
    setLocalStorage("OrderAmount", "");
  };

  const getCart = async () => {
    if (localStorage.getItem("token")) {
      const resp = await usefetch("packages/cart", "POST", headers(), {});
      if (resp?.code === "11") {
        if (resp?.data?.packages != undefined) {
          setCart(resp?.data?.packages);
          setCartCount(resp?.data?.packages.length);
          setOrderId(resp?.data?.orderId);
          setCartAmount(Number(resp?.data?.amount));
          setLocalStorage("OrderId", resp?.data?.orderId);
          setLocalStorage("OrderAmount", Number(resp?.data?.amount));
          setSalesTaxPercent((prev) => resp?.data?.sales_tax_percent);
          setAmountWOT((prev) => resp?.data?.price);
          setSalesTaxAmount((prev) => resp?.data?.sales_tax_amount);
        }
      }
    }
  };

  const seekerOrder = async () => {
    const resp = await usefetch("packages/seekerOrder", "POST", headers());
    if (resp?.code == "11") {
      setPaymentData(resp?.data);
      setOrderId(() => resp?.data?.orderId);
      setCartAmount(() => resp?.data?.amount);
      setOrderAmount(() => resp?.data?.amount);
      setLocalStorage("OrderId", resp?.data?.orderId);
      setLocalStorage("OrderAmount", resp?.data?.amount);
      setPayNowPopup(true);
      setSeekerFlow(true);
    }
    return resp;
  };
  const seekerPackage = async () => {
    const resp = await usefetch("packages/seekerPackage", "POST", headers());
    if (resp?.code == "11") {
      setSeekerPackageAmount(() => resp?.data?.amount);
    }
    return resp;
  };
  const payNow = async (orderid = "", cart_amount = "") => {
    const data = {
      orderId: orderid ? orderid : OrderId,
      cartAmount: cart_amount ? cart_amount : cartAmount,
    };
    setIsLoadingPackages(true);
    const resp = await usefetch("packages/payOrder", "POST", headers(), data);
    if (resp?.code == "11") {
      if (resp?.data?.token == "undefined") {
        console.log(resp.data);
        setOrderId(resp?.data?.orderId);
      } else {
        setPaymentData(resp?.data);
        setOrderId(resp?.data?.orderId);
        setCartAmount(resp?.data?.amount);
        setOrderAmount(resp?.data?.amount);
        setLocalStorage("OrderId", OrderId);
        setLocalStorage("OrderAmount", cartAmount);
        setPayNowPopup(true);
      }
    }
    setIsLoadingPackages(false);
  };
  const saveOrder = async (
    cartArr,
    totalAmount,
    amountWOTT,
    salesTaxA,
    delItem = ""
  ) => {
    console.log(cartArr);
    const fCart = cartArr.map((item) => {
      return {
        package_id: item.id,
        quantity: item.quantity,
        OrderItemId: item?.OrderItemId,
      };
    });
    const data = {
      cart: fCart,
      cartAmount: totalAmount,
      salesTaxPercent: salesTaxPercent,
      price: amountWOTT,
      salesTaxAmount: salesTaxA,
      delItem: delItem,
      orderId: OrderId,
    };
    if (user && user?.token) {
      const resp = await usefetch(
        "packages/saveOrder",
        "POST",
        headers(),
        data
      );
      if (resp?.code === "11") {
        console.log(resp.data);
        if (resp?.data?.packages != undefined) {
          setOrderId(resp?.data?.orderId);
          setOrderAmount(resp?.data?.amount);
          setCart(resp?.data?.packages);
          setLocalStorage("OrderId", resp?.data?.orderId);
          setLocalStorage("OrderAmount", resp?.data?.amount);
        } else {
        }
      }
    } else {
      navigate("/login");
    }
  };

  const saveFreeOrder = async (package_id) => {
    const data = {
      package_id: package_id,
    };

    const resp = await usefetch(
      "packages/saveFreeOrder",
      "POST",
      headers(),
      data
    );
    if (resp?.code === "11") {
      console.log(resp.data);
      if (resp?.data?.orderId != undefined) {
        setPaymentRes({
          code: "11",
          message: "You have just unlocked Rozeegpt Free Edition.",
          heading: "Congratulations!",
        });
        setPaymentToggle(true);
        getPackages();
      }
    }
  };

  return {
    packageExist,
    cartCount,
    cart,
    setCart,
    cartAmount,
    payNowPopup,
    PaymentData,
    OrderPayment,
    OrderId,
    credits,
    hasCredits,
    creditToggle,
    cartDrawerOpen,
    getPackages,
    packages,
    topUps,
    isLoadingPackages,
    paymentToggle,
    setPaymentToggle,
    paymentRes,
    setPaymentRes,
    setCartDrawerOpen,
    setCreditToggle,
    checkCredits,
    handleAddToCart,
    handleDeleteCart,
    payNow,
    setPayNowPopup,
    getRemainingCredits,
    saveFreeOrder,
    accountDrawerOpen,
    setAccountDrawerOpen,
    setCartCount,
    setOrderAmount,
    setCartAmount,
    setOrderId,
    salesTaxPercent,
    salesTaxAmount,
    amountWOT,
    emptyCart,
    getCart,
    checkPerJobCredit,
    seekerOrder,
    seekerFlow,
    seekerPackage,
    seekerPackageAmount
  };
};
