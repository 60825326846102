import React, { useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Dialog, Stack, Typography, useMediaQuery } from '@mui/material';

import Style from './style';
import Button from '../../../common/Button';
import { CheckedBlackIcon } from '../../../assets/constants';
import { CareerCoachInterviewContext } from '../VideoInterview/CareerCoachInterviewContext';

const Styles = makeStyles((theme) => Style(theme));

export default function SuccessPopup({ toggle, setToggle, resp = {} }) {
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const { paymentSuccessToggle, setPaymenttSuccessToggle, paymentRes } = useContext(
    CareerCoachInterviewContext,
  );

  return (
    <Dialog
      open={paymentSuccessToggle}
      onClose={setPaymenttSuccessToggle}
      className={classes.main__dialog__success__custom}
    >
      <Stack justifyContent='flex-end' direction='row'>
        <CloseRoundedIcon
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setPaymenttSuccessToggle(false)
            window.location.reload();
          }}
        />
      </Stack>
      <Stack
        p={2}
        pt={0}
        height='100%'
        justifyContent='center'
        alignItems='center'
        color='primary.main'
      >
        <CheckedBlackIcon
          style={{
            width: '70px',
            height: '70px',
          }}
        />
        <Typography pt={3.8} sx={{ fontWeight: '600' }} variant={'title20Bold'}>
          {paymentRes?.heading}
        </Typography>
        <Typography
          variant='title14Bold'
          sx={{ textAlign: 'center', fontWeight: '400' }}
          pt={1.2}
          pb={3}
        >
          {paymentRes?.message}
        </Typography>
        <Button
          variant='contained'
          color='primary'
          title='Ok'
          height='48px'
          width='100%'
          fontSize='13px'
          borderRadius='15px'
          onClick={() => {
            setPaymenttSuccessToggle(false);
            navigate('/career-coach/dashboard');
            window.location.reload();
          }}
        />
      </Stack>
    </Dialog>
  );
}
