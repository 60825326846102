import React, { useState } from 'react';

import clsx from 'clsx';
import { useTheme } from '@mui/styles';
import {
  Stack,
  MenuItem,
  TextField,
  Autocomplete,
  useMediaQuery,
} from '@mui/material';

import JobType from './JobType';
import Button from '../../../common/Button';
import Textfield from '../../../common/Textfield';
import { JOB_TYPE } from '../../../helpers/constants';


export default function City({
  cities,
  classes,
  loading,
  keyEnter,
  fieldVals,
  loadingCities,
  handleChanges,
  TypograhyMain,
  selectedCities,
  saveFieldValue,
  handleCityClick,
  handleCityChange,
  getOptionDisabled,
  SpecificEndAdornment,
  selectedJobType,
   setSelectedJobType
}) {
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  const handleChange = (event) => {
    const { name } = event.target;
    setSelectedJobType(name);
  };

  return (
    <>
      <TypograhyMain titleText='Which city is this <br /> position based in?' />
      {true ? (
        <Stack className='field__stack' position='relative'>
          <Stack
            className={clsx(
              classes.subordinates__count,
              classes.cities__stack,
              selectedCities?.length >= 8 && classes.cities__field__none,
            )}
            sx={{
              '& .MuiInputBase-root': {
                padding: '0 !important',
                paddingLeft: '24px !important',
                '& input': {
                  padding: '0 !important',
                },
              },
            }}
          >
            <Autocomplete
              multiple
              autoHighlight={true}
              id='tags-standard'
              options={loadingCities ? [{ city: 'Loading' }] : cities}
              loading={loadingCities}
              loadingText='Loading'
              getOptionLabel={(option) => option?.city}
              getOptionDisabled={getOptionDisabled}
              onChange={handleCityChange}
              // onInputChange={handleCityInputChange}
              // limitTags={3}
              disableClearable
              popupIcon={false}
              renderOption={(props, option) => (
                <li {...props}>{loadingCities ? 'Loading' : option?.city}</li>
              )}
              // disabled={loading || selectedCities?.length >= 8}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  name='city_id'
                  autoFocus
                  onEnter={keyEnter}
                  onChange={(e) => {
                    handleChanges(e);
                  }}
                  disabled={loading}
                  value={fieldVals?.city_id}
                  placeholder={selectedCities?.length >= 1 ? '' : 'Enter City'}
                  // InputProps={{
                  //   endAdornment: (
                  //     <SpecificEndAdornment
                  //       nameSpecific='city_id'
                  //       valueSpecific={fieldVals?.city_id}
                  //     />
                  //   ),
                  // }}
                />
              )}
            />
          </Stack>
          <Stack
            direction={smMediaquery ? 'column' : 'row'}
            pt={2}
            spacing={smMediaquery ? 3 : 0}
            alignItems='flex-start'
            justifyContent='space-between'
          >
            <Stack>
             <JobType
                handleChange={handleChange}
                selectedJobType={selectedJobType}
                setSelectedJobType={setSelectedJobType}
              />
            </Stack>
            <Button
              variant='contained'
              color='primary'
              title='Continue'
              height='59px'
              width='150px'
              borderRadius='15px'
              disabled={
                selectedCities?.length < 1 && selectedJobType !== JOB_TYPE.REMOTE
              }
              onClick={() => {
                saveFieldValue('city_id', selectedCities);
              }}
            />
          </Stack>
        </Stack>
      ) : (
        <Stack className='field__stack' position='relative'>
          <Textfield
            placeholder='Enter City'
            margin='dense'
            variant='outlined'
            name='city_id'
            autoFocus
            onEnter={keyEnter}
            value={fieldVals?.city_id}
            onChange={(e) => {
              handleChanges(e);
            }}
            disabled={loading}
            endAdornment={
              <SpecificEndAdornment
                nameSpecific='city_id'
                valueSpecific={fieldVals?.city_id}
              />
            }
          />
          {fieldVals?.city_id && cities?.length ? (
            <Stack
              position='absolute'
              sx={{
                width: '100%',
                top: smMediaquery
                  ? '6.5rem'
                  : mediaquery1380
                  ? '7.5rem'
                  : '9.5rem',
                background: '#fff',
                boxShadow: '0px 0px 36px 0px rgba(0,0,0,0.10)',
                borderRadius: '10px',
                overflow: 'hidden',
                padding: '8px',
              }}
            >
              {cities
                .slice(0, mediaquery1380 ? 3 : 5)
                .map(function (item, index) {
                  return (
                    <MenuItem
                      sx={{ py: 1, borderRadius: '6px' }}
                      name='selectedCity'
                      onClick={(e) => handleCityClick(index)}
                    >
                      {item.city}
                    </MenuItem>
                  );
                })}
            </Stack>
          ) : null}
        </Stack>
      )}
    </>
  );
}
