import {useState} from "react";
import {headers, defaultHeaders, usefetch} from "../helpers/fetch";

export const useLogin = () => {
    const [error, setError] = useState(null);
    const [isLoggingIn, setIsLoggingIn] = useState(null);
    const [response, setResponse] = useState(null);

    const login = async (params) => {
        const {email, password} = params;
        setIsLoggingIn(true);

        const response = await usefetch('user/login', 'post', defaultHeaders(), {
            email: email,
            password: password
        });

        if (!response.ok) {
            setError(response.error);
        }

        if (response.ok) {
            setResponse((prevData) => [...prevData, response]);
        }

        setIsLoggingIn(false);
        return response;
    };

    return {login, response, isLoggingIn, error};
};