const Style = (theme) => ({
  content__height: {
    height: 'calc(100vh)',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column !important',
    },
  },
  tab__details: {
    width: 'calc(100% - 325px)',
    backgroundColor: 'var(--color-white)',
    padding: '0 0 0 1rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '0 0 0 0',
    },
  },
  content__bar: {
    width: 'calc(100% - 325px)',
    height: '100%',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100%)',
      minHeight: 'calc(100vh - 172px)',
      height: 'max-content',
      overflowY: 'unset',
    },
  },
  cv__side: {
    width: '325px',
    boxShadow: '-10px 0px 28px 0px rgba(0,0,0,0.08)',
  },
});
export default Style;
