import React, { useContext, useState } from 'react';

import {
  Stack,
  Checkbox,
  FormGroup,
  Typography,
  useMediaQuery,
  FormControlLabel,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

import { useLocation } from 'react-router-dom';

import Textfield from '../../../common/Textfield';
import { CheckedBlackIcon } from '../../../assets/constants';
import { CartContext } from '../../Packages/Cart/CartContext';
import Button from '../../../common/Button';

export default function CustomizationSection(props) {
  const { setPremiumDialog } = props;
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));
  const location = useLocation();
  const [fieldVals, setFieldVals] = useState({
    description: '',
    link: '',
  });
  const [showTextField, setShowTextField] = useState(false); // State to control the visibility of the Textfield
  const [customizeCheck, setCustomizeCheck] = useState(null);
  const { seekerOrder, payNow, checkCredits } = useContext(CartContext);

  const handleCheckboxChange = (event) => {
    setShowTextField(event.target.checked); // Update the state based on the checkbox value
  };

  const handleChanges = (e) => {
    var { name, value } = e.target;
    var value = (value = value?.trimStart());
    setFieldVals({
      ...fieldVals,
      [name]: value,
    });
  };
  return (
    <Stack>
      <Stack
        pb={2.3}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography
          variant={smMediaquery ? 'title16Bold' : 'title18Bold'}
          sx={{ fontWeight: smMediaquery ? '500' : '600' }}
        >
          Customize your CV for any job
        </Typography>
        {!checkCredits('CCV') && (
          <Typography
            variant='title12Bold'
            sx={{ color: '#ec7376', fontWeight: '600', cursor: 'pointer' }}
            onClick={() => {
              seekerOrder().then((resp) => {
                payNow(resp?.data?.orderId, resp?.data?.amount);
              });
            }}
          >
            TRY IT
          </Typography>
        )}
      </Stack>
      <Stack
        sx={{
          '& .MuiFormControlLabel-root': {
            marginBottom: mediaquery1380 ? '14px' : '20px',
            marginLeft: '0 !important',
            marginRight: '0 !important',
            '& .MuiCheckbox-root': {
              height: mediaquery1380 ? '17px' : '24px',
              width: mediaquery1380 ? '17px' : '24px',
              padding: '0 !important',
            },
            '& .MuiTypography-root': {
              fontSize: '14px',
              fontWeight: smMediaquery ? '400' : '500',
              paddingLeft: '0.7rem',
            },
          },
          '& .MuiInputBase-root': {
            borderRadius: '15px !important',

            '& textarea, & input': {
              fontSize: '14px',
              fontWeight: '400',
            },
          },
        }}
      >
        <FormGroup>
          {sessionStorage.getItem('jid') ? (
            <FormControlLabel
              control={
                <Checkbox
                  icon={
                    <CircleOutlinedIcon
                      sx={{
                        color: '#010103',
                        height: mediaquery1380 ? '17px' : '24px',
                        width: mediaquery1380 ? '17px' : '24px',
                      }}
                    />
                  }
                  checkedIcon={<CheckedBlackIcon />}
                  checked={customizeCheck === 'job'}
                  value={'job'}
                  onChange={() => {
                    setCustomizeCheck('job');
                  }}
                />
              }
              label='Customize for this job'
            />
          ) : (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={
                      <CircleOutlinedIcon
                        sx={{
                          color: '#010103',
                          height: mediaquery1380 ? '17px' : '24px',
                          width: mediaquery1380 ? '17px' : '24px',
                        }}
                      />
                    }
                    checked={customizeCheck === 'linkJob'}
                    onChange={() => {
                      setCustomizeCheck('linkJob');
                    }}
                    checkedIcon={<CheckedBlackIcon />}
                    value={'linkJob'}
                  />
                }
                label='Customize for job at link'
              />
              {customizeCheck === 'linkJob' && (
                <Stack
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '55px',
                      '& input': {
                        paddingLeft: '8px',
                      },
                    },
                  }}
                >
                  <Textfield
                    placeholder='https://linkedin.com/job/software-engineer'
                    autoFocus
                    variant='outlined'
                    name='link'
                    value={fieldVals?.link}
                    onChange={(e) => {
                      handleChanges(e);
                    }}
                  />
                </Stack>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    icon={
                      <CircleOutlinedIcon
                        sx={{
                          color: '#010103',
                          height: mediaquery1380 ? '17px' : '24px',
                          width: mediaquery1380 ? '17px' : '24px',
                        }}
                      />
                    }
                    checked={customizeCheck === 'jd'}
                    value={'jd'}
                    onChange={() => {
                      setCustomizeCheck('jd');
                    }}
                    checkedIcon={<CheckedBlackIcon />}
                  />
                }
                label='Customize for job description below'
                onChange={handleCheckboxChange}
              />
            </>
          )}
        </FormGroup>
        {customizeCheck === 'jd' && (
          <Textfield
            placeholder='Enter Description'
            margin='dense'
            shrink
            autoFocus
            multiline={true}
            maxRows={3}
            variant='outlined'
            name='description'
            value={fieldVals?.description}
            onChange={(e) => {
              handleChanges(e);
            }}

            // onEnter={keyEnter}
            // helperText={fieldErrors}
            // disabled={loading}
          />
        )}
      </Stack>
    </Stack>
  );
}
