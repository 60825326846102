import React from 'react';
import { Autocomplete, Box, Stack, TextField } from '@mui/material';

import Textfield from '../../../common/Textfield';

export default function ManageEmployes({
  refMain,
  loading,
  fieldErrors,
  classes,
  TypograhyMain,
  fieldVals,
  setFieldVals,
  keyEnter,
  setFieldErrors,
  SpecificEndAdornment,
}) {
  const yesNoOption = [
    { code: 'yes', label: 'Yes', phone: '1' },
    {
      code: 'no',
      label: 'No',
      phone: '0',
    },
  ];

  const handleChanges = (e) => {
    let { name, value } = e.target;
    value = value?.trimStart();
    const lowerValue = value.toLowerCase();

    // Allow only "Yes" or "No", or empty string
    if (name === 'manage_employees') {
      if (e.nativeEvent.inputType === 'deleteContentBackward') {
        // Allow deletion of characters
        value = value;
      } else if (lowerValue === 'y' || lowerValue === 'yes') {
        value = 'Yes';
      } else if (lowerValue === 'n' || lowerValue === 'no') {
        value = 'No';
      } else {
        // If input is invalid, reset to previous valid value
        value = fieldVals[name] || '';
      }
    }

    setFieldVals({
      ...fieldVals,
      [name]: value,
    });

    setFieldErrors('');
  };

  return (
    <>
      <TypograhyMain titleText='Does this position <br /> manage other <br /> employees?' />

      {false ? (
        <Stack className='field__stack' position='relative'>
          <Stack className={classes.subordinates__count}>
            <Autocomplete
              id='country-select-demo'
              sx={{ width: '100%' }}
              options={yesNoOption}
              autoHighlight
              autoComplete='off'
              value={
                fieldVals?.manage_employees === 'no'
                  ? 'No'
                  : fieldVals?.manage_employees === 'yes'
                  ? 'Yes'
                  : null
              }
              onChange={(event, newValue) => {
                setFieldVals({
                  ...fieldVals,
                  manage_employees: newValue ? newValue.code : '',
                });
              }}
              // getOptionLabel={(option) => option.label}
              renderOption={(props, option) => (
                <Box
                  component='li'
                  sx={{
                    '& > img': { mr: 2, flexShrink: 0 },
                  }}
                  {...props}
                >
                  {option.label}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  placeholder='Yes or No'
                  {...params}
                  autoComplete='off'
                  autoFocus
                  value={fieldVals?.manage_employees === 'yes' ? 'Yes' : 'No'}
                  name='manage_employees'
                  onKeyDown={keyEnter}
                  onChange={(e) => handleChanges(e)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <SpecificEndAdornment
                        nameSpecific='manage_employees'
                        valueSpecific={fieldVals?.manage_employees}
                      />
                    ),
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </Stack>
        </Stack>
      ) : (
        <Stack
          sx={{
            '& .MuiInputBase-root': {
              '& input': {
                '&::placeholder': {
                  textTransform: 'none !important',
                },
              },
            },
          }}
          className='field__stack'
        >
          <Textfield
            placeholder='Yes or No'
            margin='dense'
            variant='outlined'
            name='manage_employees'
            inputRef={refMain}
            autoFocus
            textCapitalize
            onEnter={keyEnter}
            value={fieldVals?.manage_employees}
            onChange={(e) => {
              handleChanges(e);
            }}
            maxLength='100'
            helperText={fieldErrors}
            disabled={loading}
            endAdornment={
              <SpecificEndAdornment
                nameSpecific='manage_employees'
                valueSpecific={fieldVals?.manage_employees}
              />
            }
          />
        </Stack>
      )}
    </>
  );
}
