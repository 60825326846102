const Style = (theme) => ({
  subordinates__count: {
    border: 'none',
    '& fieldset': {
      border: 'none',
    },
    '& .label__main': {
      fontSize: '30px',
    },
    '& .MuiFormControl-root': {
      marginTop: '8px',
      marginBottom: '4px',
    },
    '& .MuiInputBase-root': {
      background: '#fff',
      borderRadius: '20px',
      height: '92px',
      padding: '0 !important',
      paddingRight: '14px !important',
      border: '1px solid #010103',
      '& input': {
        fontSize: '30px',
        fontWeight: '500',
        padding: '.2rem 1.5rem !important',
        [theme.breakpoints.down('1380')]: {
          fontSize: '22px',
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '16px',
        },
      },
    },
    [theme.breakpoints.down('1380')]: {
      '& .label__main': {
        fontSize: '22px',
      },
      '& .MuiInputBase-root': {
        height: '74px',
        '& input': {
          fontSize: '22px',
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      '& .label__main': {
        fontSize: '16px',
      },
      '& .MuiInputBase-root': {
        height: '60px',
        '& input': {
          fontSize: '16px',
        },
      },
    },
  },
  cities__stack: {
    '& .MuiInputBase-root': {
      paddingTop: '20px !important',
      paddingBottom: '20px !important',
      paddingLeft: '14px !important',
      height: 'max-content !important',
      minHeight: '74px !important',
      '& input': {
        padding: '0 8px  !important',
      },
      '& .MuiChip-root': {
        background: '#f3f3f3',
        borderRadius: '7px',
        height: '37px',
        '& .MuiChip-label': {
          fontSize: '14px',
          fontWeight: '500',
          color: '#010103',
        },
      },
    },
  },
  cities__field__none: {
    '& .MuiInputBase-root': {
      '& input': {
        display: 'none',
      },
    },
  },
});
export default Style;
