import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Stack, Tabs, Tab } from '@mui/material';

import Style from './style';
import PurchaseHistory from './PurchaseHistory';
import RemainingCredits from './RemainingCredits';

const Styles = makeStyles((theme) => Style(theme));

export default function CreditsHistoryTab({ isFromMenubarDrawer }) {
  const classes = Styles();
  const [value, setTabValue] = useState(
    isFromMenubarDrawer ? 'history' : 'credits',
  );
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Stack py={4}>
      <Tabs
        className={classes.tabs__main}
        value={value}
        onChange={handleTabChange}
        classes={{
          indicator: classes.indicator,
        }}
      >
        <Tab label='Remaining Credits' value='credits'></Tab>
        <Tab label='Purchase History' value='history'></Tab>
      </Tabs>
      <Stack mt={4} sx={{ height: 'calc(100vh - 398px)', overflowY: 'auto' }}>
        {value === 'credits' ? <RemainingCredits /> : <PurchaseHistory />}
      </Stack>
    </Stack>
  );
}
