import {headers, usefetch} from "../../../../helpers/fetch";

export const deleteCvExperience = async (experienceId, cvId) => {
    const resp = await usefetch(
        'cv/deleteCvExperience',
        'post',
        headers(),
        {
            cvId: cvId,
            experienceId: experienceId,
        },
    );
}

export const deleteCvEducation = async (educationId, cvId) => {
    const resp = await usefetch(
        'cv/deleteCvEducation',
        'post',
        headers(),
        {
            cvId: cvId,
            educationId: educationId,
        },
    );
}

export const deleteCvLanguage = async (languageId, cvId) => {
    const resp = await usefetch(
        'cv/deleteCvLanguage',
        'post',
        headers(),
        {
            cvId: cvId,
            languageId: languageId,
        },
    );
}

export const deleteCvAward = async (awardId, cvId) => {
    const resp = await usefetch(
        'cv/deleteCvAward',
        'post',
        headers(),
        {
            cvId: cvId,
            awardId: awardId,
        },
    );
}

export const deleteCvSkill = async (skillId, cvId) => {
    const resp = await usefetch(
        'cv/deleteCvSkill',
        'post',
        headers(),
        {
            cvId: cvId,
            skillId: skillId,
        },
    );
}