import {useState} from "react";
import {defaultHeaders, headers, usefetch} from "../helpers/fetch";

export const useLogPayment = () => {
    const [error, setError] = useState(null);
    const [isLoggingPayment, setIsLoggingPayment] = useState(null);
    const [response, setResponse] = useState(null);
    const [payments, setPayments] = useState([]);

    const logPayment = async (params) => {
        setIsLoggingPayment(true);

        const response = await usefetch('career/logPayment', 'post', defaultHeaders(), params);

        if (!response.ok) {
            setError(response.error);
        }

        if (response.ok) {
            setResponse((prevData) => [...prevData, response]);
        }

        setIsLoggingPayment(false);
        setPayments(payments => response?.data?.payments);
        return response;
    };

    return { logPayment, response, payments, setPayments, isLoggingPayment, error };
};