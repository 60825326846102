import React from 'react';

import { useTheme } from '@mui/styles';
import { Stack, Typography, useMediaQuery } from '@mui/material';

import {
  LogoBlack,
  LogoWhite,
  SuccessTestAttempt,
} from '../../../assets/constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function SuccessSubmit(props) {
  const { videoInterview } = props;
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Stack
      p={3}
      height='100vh'
      width='100vw'
      sx={{
        background: videoInterview ? '#010103' : '#fff',
        overflowY: 'auto',
      }}
    >
      {videoInterview ? (
        <LogoWhite
          style={{
            width: smMediaquery ? '175px' : '202px',
            height: smMediaquery ? '25px' : '31px',
          }}
        />
      ) : (
        <LogoBlack
          style={{
            width: smMediaquery ? '175px' : '202px',
            height: smMediaquery ? '25px' : '31px',
          }}
        />
      )}
      <Stack
        my={4}
        py={smMediaquery ? 6 : 7}
        px={smMediaquery ? 3.5 : 10}
        alignItems='center'
        justifyContent={smMediaquery ? 'center' : 'center'}
        color='primary.main'
        sx={{
          height: 'calc(100% - 100px)',
          background: videoInterview ? '#010103' : '#fff',
          borderRadius: '0',
          '& li': {
            fontSize: '16px',
            fontWeight: '400',
            color: '#2B2B2B',
            paddingBottom: '0.6rem',
          },
        }}
      >
        {videoInterview ? (
          <CheckCircleIcon
            sx={{
              color: '#fff',
              width: smMediaquery ? '100px' : '140px',
              height: smMediaquery ? '100px' : '140px',
            }}
          />
        ) : (
          <SuccessTestAttempt
            style={{
              width: smMediaquery ? '100px' : '140px',
              height: smMediaquery ? '100px' : '140px',
            }}
          />
        )}

        <Typography
          pt={3}
          variant={smMediaquery ? 'title24Bold' : 'title30Bold'}
          sx={{ fontWeight: '700', color: videoInterview ? '#fff' : '#010103' }}
        >
          {videoInterview ? 'Congratulations' : 'Thank You!'}
        </Typography>
        <Typography
          pt={1.5}
          pb={2}
          variant={smMediaquery ? 'title16Bold' : 'title18Bold'}
          sx={{
            fontWeight: '400',
            lineHeight: smMediaquery ? '25px' : '34px',
            textAlign: 'center',
            color: videoInterview ? '#fff' : '#010103',
            '& br': {
              display: smMediaquery ? 'none' : 'block',
            },
          }}
        >
          {videoInterview ? (
            <span>
              Your responses have been successfully recorded. <br /> Thank you
              for your time and effort.
            </span>
          ) : (
            "You've successfully completed the assessment test."
          )}
          &nbsp;
        </Typography>
      </Stack>
    </Stack>
  );
}
