const Style = (theme) => ({
  qualify__drawer: {
    display: 'flex',
    alignItems: 'flex-end',
    '& .MuiDrawer-paper': {
      width: '100% !important',
      height: 'max-content',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px 10px 0 0',
      boxShadow: 'none',
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  checkbox__label: {
    padding: '0 !important',
    borderRadius: '6px',
    marginBottom: '21px',
    '& label': {
      margin: '0',
    },
    '& .MuiCheckbox-root': {
      padding: '0 !important',
    },
    '& .MuiFormControlLabel-root': {
      alignItems: 'flex-start',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px',
      fontWeight: '500',
      color: '#010103',
      paddingTop: '0px',
      paddingRight: '17px',
      paddingLeft: '9px',
    },
  },
});
export default Style;
