import React from 'react';

import { format } from 'date-fns';
import { StyleSheet, Text, View, Image } from '@react-pdf/renderer';
import CallTemplateIcon from '../../../../assets/images/call-template-icon.png';
import EmailTemplateIcon from '../../../../assets/images/email-template-icon.png';
import LocationTemplateIcon from '../../../../assets/images/location-template-icon.png';
import WebsiteTemplateIcon from '../../../../assets/images/website-template-icon.png';

const styles = StyleSheet.create({
  company__name: {
    fontSize: 18,
    fontWeight: '600',
    paddingBottom: 5,
    color: '#010103',
  },
  job__title: {
    fontFamily: 'Outfit',
    fontSize: 12,
    fontWeight: '400',
    color: '#010103',
    paddingBottom: 14,
  },
  info__text: {
    fontFamily: 'Outfit',
    fontSize: 10,
    fontWeight: '400',
    color: '#010103',
    paddingLeft: 5,
    width: 90 + '%',
  },
  width__info: {
    width: 50 + '%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  info__img: {
    width: '18px',
    height: '18px',
    borderRadius: '100px',
  },
  info__stack: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 7,
  },
});

export default function Information({ cvInfo }) {
  return (
    <>
      <Text style={styles.company__name}>{cvInfo?.name}</Text>
      <Text style={styles.job__title}>{cvInfo?.tagline}</Text>

      <View style={styles.info__stack}>
        <View style={styles.width__info}>
          <Image src={EmailTemplateIcon} style={styles.info__img} />

          <Text style={styles.info__text}>{cvInfo?.email}</Text>
        </View>
        <View style={styles.width__info}>
          <Image src={CallTemplateIcon} style={styles.info__img} />

          <Text style={styles.info__text}>{cvInfo?.mobile}</Text>
        </View>
      </View>
      <View style={styles.info__stack}>
        <View style={styles.width__info}>
          <Image src={LocationTemplateIcon} style={styles.info__img} />

          <Text style={styles.info__text}>{cvInfo?.address}</Text>
        </View>
        <View style={styles.width__info}>
          <Image src={WebsiteTemplateIcon} style={styles.info__img} />

          <Text style={styles.info__text}>{cvInfo?.link}</Text>
        </View>
      </View>
    </>
  );
}
