import {
    CircularProgress, Dialog, Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    TableHead,
    Box,
    Stack,
    Tab,
    Pagination, Tabs
} from "@mui/material";

import Textfield from "../../common/Textfield";
import React, {useEffect, useState} from "react";
import Button from "../../common/Button";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {defaultHeaders, usefetch} from "../../helpers/fetch";

const createData = (prompt, promptType, model) => {
    return {prompt, promptType, model};
}

export default function Prompts() {
    const [loading, setLoading] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [activePrompts, setActivePrompts] = useState([]);
    const [history, setHistory] = useState([]);
    const [value, setTabValue] = useState('openai');
    const [model, setModel] = useState('openai');

    let rows = [];

    history.map(function (k, index) {
        rows.push(
            createData(k.user_prompt, k.prompt_type, k.model)
        );
    });

    window.onerror = e => {
       let elem = document.getElementById('webpack-dev-server-client-overlay-div');
       let elem1 = document.getElementById('webpack-dev-server-client-overlay');
       if(elem1) {
        elem1.setAttribute('style', 'display: none');
       }
    };


    useEffect(function () {
        getAllPrompts();
    }, []);

    const [fieldVals, setFieldVals] = useState({
        skillsPrompt: '',
        jobDescriptionPrompt: '',
        cvParserPrompt: '',
        cvMatchPrompt: '',
        cvChatPrompt: '',
        skillsPromptSystem: '',
        jobDescriptionPromptSystem: '',
        cvParserPromptSystem: '',
        cvMatchPromptSystem: '',
        cvChatPromptSystem: '',
    });

    const handleTabChange = (event, newValue) => {
        setModel(newValue);
        setTabValue(newValue);
        getAllPrompts(newValue);
    }

    const handleSave = (prompt, systemPrompt, promptType) => {
        usefetch('prompts/addprompt', 'post', defaultHeaders(), {
            prompt: prompt,
            systemPrompt: systemPrompt,
            promptType: promptType,
            model: model,
        });
    }

    const handleCVChatSave = (e) => {
        usefetch('prompts/addprompt', 'post', defaultHeaders(), {
            prompt: fieldVals.cvParserPrompt,
            promptType: 'chat_cv',
            model: model,
        });
    }

    const activatePrompt = (promptType) => {
        usefetch('prompts/setdefault', 'post', defaultHeaders(), {
            promptType: promptType,
            model: model,
        }).then(function () {
            getAllPrompts();
        });
    }

    const getAllPrompts = (mymodel) => {
        setFieldVals({
            skillsPrompt: '',
            jobDescriptionPrompt: '',
            cvParserPrompt: '',
            cvMatchPrompt: '',
            cvChatPrompt: '',
            skillsPromptSystem: '',
            jobDescriptionPromptSystem: '',
            cvParserPromptSystem: '',
            cvMatchPromptSystem: '',
            cvChatPromptSystem: '',
        });
        usefetch('prompts/getallprompts', 'post', defaultHeaders(), {
            model: mymodel !== undefined ? mymodel : model,
        }).then(function (resp) {
            setActivePrompts(resp?.data);
            if(resp?.data.length === 0) {
                    setFieldVals({
                        skillsPrompt: '',
                        jobDescriptionPrompt: '',
                        cvParserPrompt: '',
                        cvMatchPrompt: '',
                        cvChatPrompt: '',
                        skillsPromptSystem: '',
                        jobDescriptionPromptSystem: '',
                        cvParserPromptSystem: '',
                        cvMatchPromptSystem: '',
                        cvChatPromptSystem: '',
                    });
            } else if(resp?.data.length > 0) {
                let tempFields = {};
                resp?.data?.map(function (prompt, index) {
                    switch (prompt.prompt_type) {
                        case 'skills':
                            tempFields = {
                                ...tempFields,
                                skillsPrompt: prompt.user_prompt,
                                skillsPromptSystem: prompt.system_prompt
                            };
                            break;
                        case 'job_desc':
                            tempFields = {
                                ...tempFields,
                                jobDescriptionPrompt: prompt.user_prompt,
                                jobDescriptionPromptSystem: prompt.system_prompt
                            };
                            break;
                        case 'parse_cv':
                            tempFields = {
                                ...tempFields,
                                cvParserPrompt: prompt.user_prompt,
                                cvParserPromptSystem: prompt.system_prompt
                            };
                            break;
                        case 'match_cv':
                            tempFields = {
                                ...tempFields,
                                cvMatchPrompt: prompt.user_prompt,
                                cvMatchPromptSystem: prompt.system_prompt
                            };
                            break;
                        case 'chat_cv':
                        tempFields = {
                            ...tempFields,
                                cvChatPrompt: prompt.user_prompt,
                                cvChatPromptSystem: prompt.system_prompt
                        };
                            break;
                    }
                });
                setFieldVals(tempFields);
            }
        });
    }

    const getPromptsHistory = (promptType) => {
        usefetch('prompts/gethistory', 'post', defaultHeaders(), {
            model: model,
            promptType: promptType
        }).then(function (resp) {
            setHistory(resp?.data);
        });
    }

    const handleHistory = (promptType) => {
        setShowHistory(true);
        getPromptsHistory(promptType);
    }

    const handleChange = (e) => {
        var { name, value } = e.target;
        setFieldVals({
            ...fieldVals,
            [name]: value,
        });

    }

    const handleUse = (e, prompt, promptType, model) => {
        if(promptType === 'skills') {
            setFieldVals({
                ...fieldVals,
                skillsPrompt: prompt,
            });
        }
        if(promptType === 'job_desc') {
            setFieldVals({
                ...fieldVals,
                jobDescriptionPrompt: prompt,
            });
        }

        if(promptType === 'parse_cv') {
            setFieldVals({
                ...fieldVals,
                cvParserPrompt: prompt,
            });
        }

        if(promptType === 'match_cv') {
            setFieldVals({
                ...fieldVals,
                cvMatchPrompt: prompt,
            });
        }

        if(promptType === 'chat_cv') {
            setFieldVals({
                ...fieldVals,
                cvChatPrompt: prompt,
            });
        }

    }

    const handleActiveStatus = (promptType) => {
       return activePrompts.some(k => k.prompt_type === promptType && k.status === 'active') ? 'Activated' : 'Activate'
    }

    return (
        <Grid>
            <Dialog
                open={showHistory}
                maxWidth={{minWidth: 650}}
            >
                <Stack justifyContent='flex-end' direction='row' pt={2} pb={2} pr={2}>
                    <CloseRoundedIcon
                        style={{ cursor: 'pointer', color: '#000' }}
                        onClick={() => {
                            setShowHistory(false);
                        }}
                    />
                </Stack>

                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="prompts">
                        <TableHead>
                            <TableCell>Prompt</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => {
                                return ( <TableRow>
                                    <TableCell>{row.prompt}</TableCell>
                                    <TableCell align="right">
                                        <Button
                                            title='Use'
                                            color='secondary'
                                            width='50%'
                                            height='40px'
                                            variant='contained'
                                            borderRadius='22px'
                                            onClick={(e) => handleUse(e,  row.prompt, row.promptType, row.model)}
                                        />
                                    </TableCell>
                                </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Dialog>

            <Box sx={{width: '100%', typography: 'body1'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={handleTabChange}>
                            <Tab label="openai" value='openai'></Tab>
                            <Tab label="bard" value='bard'></Tab>
                        </Tabs>
                    </Box>
            </Box>

            <Stack direction="row" alignItems="center" spacing={5}>

                <Textfield
                    placeholder='Please Enter a Prompt For Skills.'
                    margin='dense'
                    shrink
                    multiline={true}
                    maxRows={3}
                    label='Skills Prompt'
                    variant='outlined'
                    value={fieldVals?.skillsPrompt}
                    name='skillsPrompt'
                    autoFocus
                    onChange={(e) => handleChange(e)}
                    endAdornment={
                        loading && (
                            <Stack
                                sx={{ height: '30px', width: '30px', marginRight: '0.5rem' }}
                            >
                                <CircularProgress
                                    style={{
                                        height: '30px',
                                        width: '30px',
                                        color: '#010103',
                                        boxShadow: 'inset 0 0 1px 2px #e8e8e8',
                                        backgroundColor: 'transparent',
                                        borderRadius: '50%',
                                    }}
                                />
                            </Stack>
                        )
                    }
                />

                {value === 'openai' && (<>
                    <Textfield
                        placeholder='Please Enter System Prompt For Skills.'
                        margin='dense'
                        shrink
                        multiline={true}
                        maxRows={3}
                        label='Skills Prompt (System)'
                        variant='outlined'
                        value={fieldVals?.skillsPromptSystem}
                        name='skillsPromptSystem'
                        autoFocus
                        onChange={(e) => handleChange(e)}
                        endAdornment={
                            loading && (
                                <Stack
                                    sx={{ height: '30px', width: '30px', marginRight: '0.5rem' }}
                                >
                                    <CircularProgress
                                        style={{
                                            height: '30px',
                                            width: '30px',
                                            color: '#010103',
                                            boxShadow: 'inset 0 0 1px 2px #e8e8e8',
                                            backgroundColor: 'transparent',
                                            borderRadius: '50%',
                                        }}
                                    />
                                </Stack>
                            )
                        }
                    />
                </>)}

                <Stack direction="column" spacing={2}>
                    <Button
                        title='Save'
                        color='secondary'
                        width='84%'
                        height='40px'
                        variant='contained'
                        borderRadius='22px'
                        onClick={(e) => handleSave(fieldVals?.skillsPrompt, fieldVals?.skillsPromptSystem,'skills')}
                    />
                    <Button
                        title='History'
                        color='secondary'
                        width='84%'
                        height='40px'
                        variant='contained'
                        borderRadius='22px'
                        onClick={(e) => handleHistory('skills')}
                    />
                </Stack>



                <Stack direction="column" spacing={2}>
                    <Button
                        title={handleActiveStatus('skills')}
                        color='secondary'
                        width='84%'
                        height='40px'
                        disabled={handleActiveStatus('skills') === 'Activated'}
                        variant='contained'
                        borderRadius='22px'
                        onClick={(e) => activatePrompt('skills')}
                    />
                </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={5}>

                <Textfield
                    placeholder='Please Enter a Prompt For Job Description.'
                    margin='dense'
                    shrink
                    multiline={true}
                    maxRows={3}
                    label='Job Description Prompt'
                    variant='outlined'
                    value={fieldVals?.jobDescriptionPrompt}
                    name='jobDescriptionPrompt'
                    autoFocus
                    onChange={(e) => handleChange(e)}
                    endAdornment={
                        loading && (
                            <Stack
                                sx={{ height: '30px', width: '30px', marginRight: '0.5rem' }}
                            >
                                <CircularProgress
                                    style={{
                                        height: '30px',
                                        width: '30px',
                                        color: '#010103',
                                        boxShadow: 'inset 0 0 1px 2px #e8e8e8',
                                        backgroundColor: 'transparent',
                                        borderRadius: '50%',
                                    }}
                                />
                            </Stack>
                        )
                    }
                />

                {value === 'openai' && (<Textfield
                    placeholder='Please Enter a Prompt For Job Description.'
                    margin='dense'
                    shrink
                    multiline={true}
                    maxRows={3}
                    label='Job Description Prompt'
                    variant='outlined'
                    value={fieldVals?.jobDescriptionPromptSystem}
                    name='jobDescriptionPromptSystem'
                    autoFocus
                    onChange={(e) => handleChange(e)}
                    endAdornment={
                        loading && (
                            <Stack
                                sx={{height: '30px', width: '30px', marginRight: '0.5rem'}}
                            >
                                <CircularProgress
                                    style={{
                                        height: '30px',
                                        width: '30px',
                                        color: '#010103',
                                        boxShadow: 'inset 0 0 1px 2px #e8e8e8',
                                        backgroundColor: 'transparent',
                                        borderRadius: '50%',
                                    }}
                                />
                            </Stack>
                        )
                    }
                />)}
                <Stack direction="column" spacing={2}>
                    <Button
                        title='Save'
                        color='secondary'
                        width='84%'
                        height='40px'
                        variant='contained'
                        borderRadius='22px'
                        onClick={(e) => handleSave(fieldVals?.jobDescriptionPrompt, fieldVals?.jobDescriptionPromptSystem, 'job_desc')}
                    />
                    <Button
                        title='History'
                        color='secondary'
                        width='84%'
                        height='40px'
                        variant='contained'
                        borderRadius='22px'
                        onClick={(e) => handleHistory('job_desc')}
                    />
                </Stack>

                <Stack direction="column" spacing={2}>
                    <Button
                        title={handleActiveStatus('job_desc')}
                        color='secondary'
                        width='84%'
                        height='40px'
                        disabled={handleActiveStatus('job_desc') === 'Activated'}
                        variant='contained'
                        borderRadius='22px'
                        onClick={(e) => activatePrompt('job_desc')}
                    />
                </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={5}>
                <Textfield
                    placeholder='Please Enter a Prompt For Cv parsing.'
                    margin='dense'
                    shrink
                    multiline={true}
                    maxRows={3}
                    label='CV Parsing Prompt'
                    variant='outlined'
                    value={fieldVals?.cvParserPrompt}
                    name='cvParserPrompt'
                    autoFocus
                    onChange={(e) => handleChange(e)}
                    endAdornment={
                        loading && (
                            <Stack
                                sx={{ height: '30px', width: '30px', marginRight: '0.5rem' }}
                            >
                                <CircularProgress
                                    style={{
                                        height: '30px',
                                        width: '30px',
                                        color: '#010103',
                                        boxShadow: 'inset 0 0 1px 2px #e8e8e8',
                                        backgroundColor: 'transparent',
                                        borderRadius: '50%',
                                    }}
                                />
                            </Stack>
                        )
                    }
                />
                {value === 'openai' && (
                    <Textfield
                        placeholder='Please Enter a Prompt For Cv parsing.'
                        margin='dense'
                        shrink
                        multiline={true}
                        maxRows={3}
                        label='CV Parsing Prompt'
                        variant='outlined'
                        value={fieldVals?.cvParserPromptSystem}
                        name='cvParserPromptSystem'
                        autoFocus
                        onChange={(e) => handleChange(e)}
                        endAdornment={
                            loading && (
                                <Stack
                                    sx={{ height: '30px', width: '30px', marginRight: '0.5rem' }}
                                >
                                    <CircularProgress
                                        style={{
                                            height: '30px',
                                            width: '30px',
                                            color: '#010103',
                                            boxShadow: 'inset 0 0 1px 2px #e8e8e8',
                                            backgroundColor: 'transparent',
                                            borderRadius: '50%',
                                        }}
                                    />
                                </Stack>
                            )
                        }
                    />
                )}
                <Stack direction="column" spacing={2}>
                    <Button
                        title='Save'
                        color='secondary'
                        width='84%'
                        height='40px'
                        variant='contained'
                        borderRadius='22px'
                        onClick={(e) => handleSave(fieldVals?.cvParserPrompt, fieldVals?.cvParserPromptSystem, 'parse_cv')}
                    />
                    <Button
                        title='History'
                        color='secondary'
                        width='84%'
                        height='40px'
                        variant='contained'
                        borderRadius='22px'
                        onClick={(e) => handleHistory('parse_cv')}
                    />
                </Stack>

                <Stack direction="column" spacing={2}>
                    <Button
                        // title='Activate'
                        title={handleActiveStatus('parse_cv')}
                        color='secondary'
                        width='84%'
                        height='40px'
                        disabled={handleActiveStatus('parse_cv') === 'Activated'}
                        variant='contained'
                        borderRadius='22px'
                        onClick={(e) => activatePrompt('parse_cv')}
                    />
                </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={5}>
                <Textfield
                    placeholder='Please Enter a Prompt For Cv matching.'
                    margin='dense'
                    shrink
                    multiline={true}
                    maxRows={3}
                    label='CV Matching Prompt'
                    variant='outlined'
                    value={fieldVals?.cvMatchPrompt}
                    name='cvMatchPrompt'
                    autoFocus
                    onChange={(e) => handleChange(e)}
                    endAdornment={
                        loading && (
                            <Stack
                                sx={{ height: '30px', width: '30px', marginRight: '0.5rem' }}
                            >
                                <CircularProgress
                                    style={{
                                        height: '30px',
                                        width: '30px',
                                        color: '#010103',
                                        boxShadow: 'inset 0 0 1px 2px #e8e8e8',
                                        backgroundColor: 'transparent',
                                        borderRadius: '50%',
                                    }}
                                />
                            </Stack>
                        )
                    }
                />
                {value === 'openai' && (
                    <Textfield
                        placeholder='Please Enter a Prompt For Cv matching.'
                        margin='dense'
                        shrink
                        multiline={true}
                        maxRows={3}
                        label='CV Matching Prompt'
                        variant='outlined'
                        value={fieldVals?.cvMatchPromptSystem}
                        name='cvMatchPromptSystem'
                        autoFocus
                        onChange={(e) => handleChange(e)}
                        endAdornment={
                            loading && (
                                <Stack
                                    sx={{ height: '30px', width: '30px', marginRight: '0.5rem' }}
                                >
                                    <CircularProgress
                                        style={{
                                            height: '30px',
                                            width: '30px',
                                            color: '#010103',
                                            boxShadow: 'inset 0 0 1px 2px #e8e8e8',
                                            backgroundColor: 'transparent',
                                            borderRadius: '50%',
                                        }}
                                    />
                                </Stack>
                            )
                        }
                    />
                )}
                <Stack direction="column" spacing={2}>
                    <Button
                        title='Save'
                        color='secondary'
                        width='84%'
                        height='40px'
                        variant='contained'
                        borderRadius='22px'
                        onClick={(e) => handleSave(fieldVals?.cvMatchPrompt, fieldVals?.cvMatchPromptSystem, 'match_cv')}
                    />
                    <Button
                        title='History'
                        color='secondary'
                        width='84%'
                        height='40px'
                        variant='contained'
                        borderRadius='22px'
                        onClick={(e) => handleHistory('match_cv')}
                    />
                </Stack>

                <Stack direction="column" spacing={2}>
                    <Button
                        // title='Activate'
                        title={handleActiveStatus('match_cv')}
                        color='secondary'
                        width='84%'
                        height='40px'
                        disabled={handleActiveStatus('match_cv') === 'Activated'}
                        variant='contained'
                        borderRadius='22px'
                        onClick={(e) => activatePrompt('match_cv')}
                    />
                </Stack>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={5}>
                <Textfield
                    placeholder='Please Enter a Prompt For Cv chatting.'
                    margin='dense'
                    shrink
                    multiline={true}
                    maxRows={3}
                    label='CV Chatting Prompt'
                    variant='outlined'
                    value={fieldVals?.cvChatPrompt}
                    name='cvChatPrompt'
                    autoFocus
                    onChange={(e) => handleChange(e)}
                    endAdornment={
                        loading && (
                            <Stack
                                sx={{ height: '30px', width: '30px', marginRight: '0.5rem' }}
                            >
                                <CircularProgress
                                    style={{
                                        height: '30px',
                                        width: '30px',
                                        color: '#010103',
                                        boxShadow: 'inset 0 0 1px 2px #e8e8e8',
                                        backgroundColor: 'transparent',
                                        borderRadius: '50%',
                                    }}
                                />
                            </Stack>
                        )
                    }
                />
                {value === 'openai' && (
                    <Textfield
                        placeholder='Please Enter a Prompt For Cv chatting.'
                        margin='dense'
                        shrink
                        multiline={true}
                        maxRows={3}
                        label='CV Chatting Prompt'
                        variant='outlined'
                        value={fieldVals?.cvChatPrompt}
                        name='cvChatPrompt'
                        autoFocus
                        onChange={(e) => handleChange(e)}
                        endAdornment={
                            loading && (
                                <Stack
                                    sx={{ height: '30px', width: '30px', marginRight: '0.5rem' }}
                                >
                                    <CircularProgress
                                        style={{
                                            height: '30px',
                                            width: '30px',
                                            color: '#010103',
                                            boxShadow: 'inset 0 0 1px 2px #e8e8e8',
                                            backgroundColor: 'transparent',
                                            borderRadius: '50%',
                                        }}
                                    />
                                </Stack>
                            )
                        }
                    />
                )}
                <Stack direction="column" spacing={2}>
                    <Button
                        title='Save'
                        color='secondary'
                        width='84%'
                        height='40px'
                        variant='contained'
                        borderRadius='22px'
                        onClick={(e) => handleSave(fieldVals?.cvMatchPrompt, fieldVals?.cvMatchPromptSystem, 'chat_cv')}
                    />
                    <Button
                        title='History'
                        color='secondary'
                        width='84%'
                        height='40px'
                        variant='contained'
                        borderRadius='22px'
                        onClick={(e) => handleHistory('chat_cv')}
                    />
                </Stack>

                <Stack direction="column" spacing={2}>
                    <Button
                        // title='Activate'
                        title={handleActiveStatus('chat_cv')}
                        color='secondary'
                        width='84%'
                        height='40px'
                        disabled={handleActiveStatus('chat_cv') === 'Activated'}
                        variant='contained'
                        borderRadius='22px'
                        onClick={(e) => activatePrompt('chat_cv')}
                    />
                </Stack>
            </Stack>
        </Grid>
    );
}