import { Stack } from '@mui/material';
import React from 'react';

import Button from '../../../../common/Button';

export default function ActionButtons({
  smMediaquery,
  mediaquery1380,
  showOtpPopup,
  handleCompleteApplication,
}) {
  return (
    <Stack px={smMediaquery ? 2 : 5} pt={smMediaquery ? 3 : 6} spacing={2}>
      {smMediaquery ? (
        <>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Button
              width='47%'
              height={smMediaquery ? '47px' : mediaquery1380 ? '52px' : '59px'}
              color='primary'
              className='w-100'
              fontSize={
                smMediaquery ? '12px' : mediaquery1380 ? '16px' : '18px'
              }
              borderRadius='11px'
              variant='outlined'
              title='Choose CV Template'
              backgroundColor='#fff !important'
              borderStyle='1px solid #010103 !important'
              onClick={handleCompleteApplication}
            />
            <Button
              width='47%'
              height={smMediaquery ? '47px' : mediaquery1380 ? '52px' : '59px'}
              color='primary'
              className='w-100'
              fontSize={
                smMediaquery ? '12px' : mediaquery1380 ? '16px' : '18px'
              }
              borderRadius='11px'
              variant='outlined'
              title='Complete Application'
              backgroundColor='#fff !important'
              borderStyle='1px solid #010103 !important'
              onClick={handleCompleteApplication}
            />
          </Stack>
          {false ? (
            <Button
              width='100%'
              height={smMediaquery ? '47px' : mediaquery1380 ? '52px' : '59px'}
              color='primary'
              className='w-100'
              fontSize={
                smMediaquery ? '12px' : mediaquery1380 ? '16px' : '18px'
              }
              borderRadius='11px'
              variant='contained'
              title='Upgrade to Premium'
              onClick={() => showOtpPopup(true)}
            />
          ) : (
            <Button
              width='100%'
              height={smMediaquery ? '47px' : mediaquery1380 ? '52px' : '59px'}
              color='primary'
              className='w-100'
              fontSize={
                smMediaquery ? '12px' : mediaquery1380 ? '16px' : '18px'
              }
              textTransform='none'
              borderRadius='11px'
              variant='contained'
              title='Customize your CV for any job'
              onClick={() => showOtpPopup(true)}
            />
          )}
        </>
      ) : (
        <>
          <Button
            width='100%'
            height={smMediaquery ? '47px' : mediaquery1380 ? '52px' : '59px'}
            color='primary'
            className='w-100'
            fontSize={smMediaquery ? '12px' : mediaquery1380 ? '16px' : '18px'}
            borderRadius='11px'
            variant='contained'
            title='Upgrade to Premium'
            onClick={() => showOtpPopup(true)}
          />
          <Button
            width='100%'
            height={smMediaquery ? '47px' : mediaquery1380 ? '52px' : '59px'}
            color='primary'
            className='w-100'
            fontSize={smMediaquery ? '12px' : mediaquery1380 ? '16px' : '18px'}
            borderRadius='11px'
            variant='outlined'
            title='Complete Application'
            backgroundColor='#fff !important'
            borderStyle='1px solid #010103 !important'
            onClick={handleCompleteApplication}
          />
        </>
      )}
    </Stack>
  );
}
