import React, { useContext } from 'react';

import { makeStyles } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Drawer, Stack, Typography } from '@mui/material';

import Style from '../Sidebar/style';
import Button from '../../../../common/Button';
import Avatars from '../../../../common/Avatar';
import { AuthContext } from '../../../../Auth/AuthContext';
import PersonalInformationTab from './PersonalInformationTab';
import Pages from './Pages';
import { LogoutIcon, LogoutNewIcon } from '../../../../assets/constants';
import JobMatches from './JobMatches';

const Styles = makeStyles((theme) => Style(theme));

export default function MenubarDrawer({ toggle, setToggle }) {
  const classes = Styles();

  const { user, logout } = useContext(AuthContext);
  const handleSignOut = () => {
    logout();
  };

  return (
    <Drawer
      anchor='right'
      open={toggle}
      //   onClose={() => setToggle(false)}
      className={classes.drawer__menubar}
      sx={{
        '--Drawer-transitionDuration': toggle ? '2.4s' : '0.2s',
        '& .MuiDrawer-paper': {
          overflowY: 'auto',
        },
      }}
    >
      <Stack
        width='100%'
        py={4}
        px={3}
        sx={{
          height: 'calc(100% - 2px)',
          overflowY: 'auto',
        }}
      >
        <Stack
          pb={'39px'}
          sx={{ cursor: 'pointer', width: 'max-content' }}
          onClick={() => {
            setToggle(false);
          }}
        >
          <Typography
            variant='title16Bold'
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: '400',
              width: 'max-content',
            }}
          >
            <ArrowBackIcon
              sx={{ marginRight: 1.2, height: '20px', width: '20px' }}
            />
            Back
          </Typography>
        </Stack>

        {/*Avatar name*/}

        <Stack mb={'26px'} direction='row' alignItems='center'>
          <Avatars userName={user?.full_name} src={user?.image} />
          <Stack pl={2}>
            <Typography
              pb={0.7}
              variant='title16Bold'
              sx={{ fontWeight: '600' }}
            >
              {user?.full_name}
            </Typography>
            <Typography
              variant='title12Bold'
              sx={{ fontWeight: '400', color: '#1A1A1A' }}
            >
              {user?.email}
            </Typography>
          </Stack>
        </Stack>

        {/* Pages */}

        <Pages />

        <Button
          onClick={() => handleSignOut()}
          title='Logout'
          startIcon={<LogoutNewIcon />}
          fontWeight='500'
          borderRadius='50px'
          fontSize='14px'
          variant='contained'
          height='44px'
          width='118px'
          textColor='#fff'
          backgroundColor='#DE1135 !important'
          disableLabelPadding
        />
      </Stack>
    </Drawer>
  );
}
