import React from 'react';

import { Stack, Typography, FormControlLabel, Checkbox } from '@mui/material';

import { CheckboxIcon, CheckboxCheckedIcon } from '../../assets/constants';

export default function TermsConditionCheck({ checkedTerms, setCheckedTerms }) {
  return (
    <Stack direction={'row'} alignItems='center'>
      <FormControlLabel
        sx={{ margin: 0 }}
        control={
          <Checkbox
            icon={<CheckboxIcon />}
            checkedIcon={<CheckboxCheckedIcon />}
            checked={checkedTerms}
          />
        }
        onClick={() => {
          setCheckedTerms(!checkedTerms);
        }}
      />
      <Typography
        variant='title14Bold'
        sx={{
          fontWeight: '400',
          '& a': {
            color: '#010103 !important',
          },
        }}
      >
        I have read the{' '}
        <a target='_blank' rel='noreferrer' href='/terms'>
          terms of use
        </a>{' '}
        &{' '}
        <a target='_blank' rel='noreferrer' href='/privacy-policy'>
          {' '}
          privacy policy
        </a>
      </Typography>
    </Stack>
  );
}
