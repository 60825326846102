import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/styles';
import {
  Stack,
  Typography,
  FormControlLabel,
  useMediaQuery,
  Checkbox,
} from '@mui/material';

import Textfield from '../../../common/Textfield';
import { CheckboxCheckedIcon, CheckboxIcon } from '../../../assets/constants';
import { Link } from 'react-router-dom';
import TermsConditionCheck from '../../TermsConditionCheck';

export default function ReEnterPassword({
  refMain,
  loading,
  fieldErrors,
  classes,
  TypograhyMain,
  fieldVals,
  showPassword,
  keyEnter,
  handleChanges,
  SpecificEndAdornment,
  checkedTerms,
  setCheckedTerms,
  setFieldErrors,
  checkedTermErrorMessage,
}) {
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));
  useEffect(() => {
    if (checkedTerms && fieldErrors === checkedTermErrorMessage) {
      setFieldErrors('');
    }
  }, [checkedTerms]);

  return (
    <>
      <TypograhyMain titleText=' Please re-enter <br />  your password' />
      <Stack className='field__stack'>
        <Textfield
          placeholder='Re-enter password'
          margin='dense'
          inputRef={refMain}
          variant='outlined'
          name='password_two'
          autoFocus
          type={showPassword ? 'text' : 'password'}
          value={fieldVals?.password_two}
          onChange={(e) => {
            handleChanges(e);
          }}
          onEnter={keyEnter}
          disabled={loading}
          helperText={fieldErrors}
          endAdornment={
            <SpecificEndAdornment
              nameSpecific='password_two'
              valueSpecific={fieldVals?.password_two}
            />
          }
        />
      </Stack>
      <TermsConditionCheck
        checkedTerms={checkedTerms}
        setCheckedTerms={setCheckedTerms}
      />
    </>
  );
}
