import {headers, usefetch} from "../../../../helpers/fetch";

export const updateCvInfo = async (fieldName, fieldValue, cvId) => {
   const resp = await usefetch(
        'cv/updateCvInfo',
        'post',
        headers(),
        {
            fieldName: fieldName,
            fieldValue: fieldValue,
            cvId: cvId
        },
    );
}

export const updateExperienceInfo = async (fieldName, fieldValue, experienceId, cvId) => {
    return usefetch(
        'cv/updateCvExperience',
        'post',
        headers(),
        {
            fieldName: fieldName,
            fieldValue: fieldValue,
            cvId: cvId,
            experienceId: experienceId,
        },
    );
}

export const updateEducationInfo = async (fieldName, fieldValue, educationId, cvId) => {
    return usefetch(
        'cv/updateCvEducation',
        'post',
        headers(),
        {
            fieldName: fieldName,
            fieldValue: fieldValue,
            cvId: cvId,
            educationId: educationId,
        },
    );
}

export const updateSkillsInfo = async (fieldName, fieldValue, skillId, cvId) => {
   const resp = await usefetch(
        'cv/updateCvSkill',
        'post',
        headers(),
        {
            fieldName: fieldName,
            fieldValue: fieldValue,
            cvId: cvId,
            skillId: skillId,
        },
    );
}

export const updateAwardsInfo = async (fieldName, fieldValue, awardId, cvId) => {
    return usefetch(
        'cv/updateCvAward',
        'post',
        headers(),
        {
            fieldName: fieldName,
            fieldValue: fieldValue,
            cvId: cvId,
            awardId: awardId,
        },
    );

}

export const updateLanguageInfo = async (fieldName, fieldValue, languageId, cvId) => {
    const resp = await usefetch(
        'cv/updateCvLanguage',
        'post',
        headers(),
        {
            fieldName: fieldName,
            fieldValue: fieldValue,
            cvId: cvId,
            languageId: languageId,
        },
    );
}

export const updateCvTemplate = async (params) => {
    const {templateCode, cvId} = params;
    return usefetch(
        'cv/updateTemplate',
        'post',
        headers(),
        {
            cvId: sessionStorage.getItem('cvId'),
            templateCode: templateCode,
        },
    );
}
