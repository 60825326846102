import React from 'react';

import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export default function TooltipBlack({
  children,
  title,
  bgWhite,
  isFromApplicant,
}) {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      padding: '12px',
      maxHeight: 'max-content',
      maxWidth: isFromApplicant ? '750px' : '350px',
      marginLeft: '2rem',
      borderRadius: '8px',
      color: bgWhite ? '#010103' : '#fff',
      background: bgWhite ? '#fff' : '#010103',
      boxShadow:
        '0px 1px px 0px rgba(0,0,0,0.15), 0px 4px 8px 0px rgba(0,0,0,0.20)',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: bgWhite ? '#fff' : '#010103',
    },
  }));

  return (
    <Stack
      className='tooltip__ellipsis'
      sx={{
        '& .MuiTooltip-popper': {
          backgroundColor: 'red !important',
        },
      }}
    >
      <HtmlTooltip arrow title={title}>
        <span>{children}</span>
      </HtmlTooltip>
    </Stack>
  );
}
