export const convertServerDateFormate = (serverDate) => {
    try {
        let date = new Date(serverDate);
        let options = {
            month: 'short',
            day: '2-digit',
            year: 'numeric'
        };
        return date.toLocaleDateString('en-US', options);
    } catch (e) {
        return '';
    }
}

export const formatDateToLongDateTimeString = (serverDate) => {
    try {
        let date = new Date(serverDate);
        let options = {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };
        return date.toLocaleDateString('en-US', options);
    } catch (e) {
        return '';
    }
}