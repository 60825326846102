import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  Autocomplete,
  Box,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import Style from './style';
import Context from '../../store/Context';
import Button from '../../common/Button';
import Textfield from '../../common/Textfield';
import TextDictionary from '../../common/Dictionary';
import ForgotPassword from '../../pages/Signin/ForgotPassword';
import { defaultHeaders, headers, usefetch } from '../../helpers/fetch';
import { InputIcon, InputIconBlack } from '../../assets/constants';
import {
  emailValidation,
  jobTitleValidation,
  maxBudgetValidation,
  passwordValidation,
  phoneNumberValidation,
  requireStringValidation,
  subordinateValidation,
} from '../../helpers/formValidation';
import ShowHideSalary from './ShowHideSalary';
import Experience from './components/Experience';
import { lastWordLetterCase, letterCase } from '../../helpers/common';
import ManageEmployes from './components/ManageEmployes';
import JobOgTags from '../../pages/SharedJobDetail/JobOgTags';
import { useGenerateTest } from '../../services/useGenerateTest';
import { createCookie, eraseCookie } from '../../helpers/CookieHelper';
import City from './components/City';
import ReEnterPassword from './components/ReEnterPassword';
import { AuthContext } from '../../Auth/AuthContext';
import { JOB_TYPE } from '../../helpers/constants';
import { GoogleSignIn } from '../../common/GoogleSignIn';
import { SITE_AT } from '../../helpers/utils';
import { APP_ENV } from '../../helpers/constants';

const Styles = makeStyles((theme) => Style(theme));

export default function FormText({ fieldName, readOnly }) {
  const refMain = useRef();
  const classes = Styles();
  const theme = useTheme();
  const navigate = useNavigate();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaQuery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  const defaultOtpTime = 30;
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState('');
  const [showCounter, setShowCounter] = useState(false);
  const [otpTime, setOtpTime] = useState(defaultOtpTime);
  const [showPassword, setShowPassword] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);
  const [globalState, globalDispatch] = useContext(Context);
  const [passwordLabel, setPasswordLabel] = useState('Create');
  const [userExists, setUserExists] = useState(false);
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const { generateTest, response, questions, isGeneratingTest } =
    useGenerateTest();
  const [allowLetterCase, setAllowLettercase] = useState(true);
  const [hideSalary, setHideSalary] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const { user, refreshUserData } = useContext(AuthContext);
  const [fieldVals, setFieldVals] = useState({
    jobTitle: '',
    experience: '',
    company_name: '',
    manage_employees: '',
    subordinates_count: '',
    other_requirements: '',
    city_id: '',
    maximum_budget: '',
    full_name: '',
    email_address: '',
    mobile_number: '',
    password_one: '',
    password_two: '',
    otp: '',
  });

  const { flow } = useParams();

  useEffect(() => {
    if (!flow) {
      return;
    }

    if (flow === 'old') {
      eraseCookie('flow');
    } else if (flow === 'new') {
      createCookie('flow', 'new');
    }
  }, []);

  useEffect(() => {
    if (otpTime <= 0) {
      setShowCounter(false);
      return;
    }

    const timeInterval = setInterval(() => {
      setOtpTime((otpTime) => otpTime - 1);
    }, 1000);

    return () => clearInterval(timeInterval);
  }, [otpTime]);

  const handleResendOtp = () => {
    usefetch('otp/resendsignupotp', 'post', defaultHeaders(), {
      email: fieldVals.email,
    }).then(function (resp) {
      setShowCounter(true);
      setOtpTime(defaultOtpTime);
    });
  };

  const PrefilledValues = () => {
    // if (user?.email) {
    //   navigate('/dashbaord');
    // }
    switch (window.location.pathname) {
      case '/signup':
        let jobTitle = sessionStorage.getItem('jobTitle');
        if (jobTitle) {
          setFieldVals({ ...fieldVals, jobTitle: jobTitle });
        }
        break;
      case '/signup/experience':
        let experience = sessionStorage.getItem('experience');

        if (experience) {
          setFieldVals({
            ...fieldVals,
            experience: experience,
          });
        }
        break;
      case '/signup/company_name':
        let company_name = sessionStorage.getItem('company_name');
        if (company_name) {
          setFieldVals({ ...fieldVals, company_name: company_name });
        }
        break;
      case '/signup/manage_employees':
        let manage_employees = sessionStorage.getItem('manage_employees');

        if (manage_employees) {
          setFieldVals({
            ...fieldVals,
            manage_employees: manage_employees?.toLowerCase(),
          });
        }
        break;
      case '/signup/subordinates_count':
        let subordinates_count = sessionStorage.getItem('subordinates_count');

        if (subordinates_count) {
          setFieldVals({
            ...fieldVals,
            subordinates_count: subordinates_count,
          });
        }
        break;
      case '/signup/other_requirements':
        let other_requirements = sessionStorage.getItem('other_requirements');
        if (other_requirements) {
          setFieldVals({
            ...fieldVals,
            other_requirements: other_requirements,
          });
        }
        break;
      case '/signup/city_id':
        let city_id = sessionStorage.getItem('city_id');
        if (city_id) {
          setFieldVals({ ...fieldVals, city_id: city_id });
        }
        break;
      case '/signup/maximum_budget':
        let maximum_budget = sessionStorage.getItem('maximum_budget');
        if (maximum_budget) {
          setFieldVals({ ...fieldVals, maximum_budget: maximum_budget });
        }
        break;
      case '/signup/full_name':
        let full_name = sessionStorage.getItem('full_name');
        if (full_name) {
          setFieldVals({ ...fieldVals, full_name: full_name });
        }
        break;
      case '/signup/email_address':
        let email_address = sessionStorage.getItem('email_address');
        if (email_address) {
          setFieldVals({ ...fieldVals, email_address: email_address });
        }
        break;
      case '/signup/mobile_number':
        let mobile_number = sessionStorage.getItem('mobile_number');
        if (mobile_number) {
          setFieldVals({ ...fieldVals, mobile_number: mobile_number });
        }
        break;
      case '/signup/password_one':
        let password_one = sessionStorage.getItem('password_one');
        if (password_one) {
          setFieldVals({ ...fieldVals, password_one: password_one });
        }
        break;
      case '/signup/password_two':
        let password_two = sessionStorage.getItem('password_two');
        if (password_two) {
          setFieldVals({ ...fieldVals, password_two: password_two });
        }
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    PrefilledValues();
    if (
      window.location.pathname === '/signup' ||
      window.location.pathname === '/signup/full_name' ||
      window.location.pathname === '/signup/maximum_budget' ||
      window.location.pathname === '/signup/manage_employees' ||
      window.location.pathname === '/signup/mobile_number' ||
      window.location.pathname === '/signup/password_one' ||
      window.location.pathname === '/signup/password_two' ||
      window.location.pathname === '/signup/otp' ||
      window.location.pathname === '/signup/email_address'
    ) {
      refMain.current?.focus();
    }
  }, [window.location.pathname]);

  useEffect(function () {
    const navigationEntries = window.performance.getEntriesByType('navigation');
    if (
      navigationEntries.length > 0 &&
      navigationEntries[0].type === 'reload'
    ) {
      // if (window.location.pathname === '/signup') {
      //   removeItems();
      // }
    }

    // usefetch('cities/getcities', 'get', defaultHeaders(), {registerTempClientId: globalState.enc_id , label : name, value : value}).then(function (resp) {
    //   console.log(resp);
    // });
  }, []);

  const handleResetPassword = () => {
    setShowPasswordPopup(true);
  };

  const handleNextField = (name, value) => {
    switch (name) {
      case 'jobTitle':
        navigate('/signup/experience');
        break;
      case 'experience':
        sessionStorage.setItem('experience', value);
        navigate('/signup/company_name');
        break;
      case 'company_name':
        sessionStorage.setItem('company_name', value);
        navigate('/signup/manage_employees');
        break;
      case 'manage_employees':
        sessionStorage.setItem('manage_employees', value?.toLowerCase());
        navigate('/signup/subordinates_count');
        break;
      case 'subordinates_count':
        sessionStorage.setItem('subordinates_count', value);
        navigate('/signup/other_requirements');
        break;
      case 'other_requirements':
        sessionStorage.setItem('other_requirements', value);
        navigate('/signup/city_id');
        break;
      case 'city_id':
        sessionStorage.setItem('city_id', value);
        navigate('/signup/maximum_budget');
        break;
      case 'maximum_budget':
        sessionStorage.setItem('maximum_budget', value);
        navigate('/signup/email_address');
        break;
      case 'email_address':
        sessionStorage.setItem('email_address', value);
        navigate('/signup/full_name');
        break;
      case 'full_name':
        sessionStorage.setItem('full_name', value);
        navigate('/signup/mobile_number');
        break;
      case 'mobile_number':
        sessionStorage.setItem('mobile_number', value);
        navigate('/signup/password_one');
        break;
      case 'password_one':
        sessionStorage.setItem('password_one', value);
        navigate('/signup/password_two');
        break;
      case 'password_two':
        sessionStorage.setItem('password_two', value);
        navigate('/signup/otp');
        break;

      default:
        return;
    }
  };

  function handleCityClick(index) {
    usefetch('registerTemp/savetempvalue', 'post', defaultHeaders(), {
      registerTempClientId: localStorage.getItem('enc_id'),
      label: 'city_id',
      value: cities[index].city,
    }).then(function (resp) {
      handleNextField('city_id', cities[index].city);
    });
  }

  const handleChanges = (e) => {
    var { name, value } = e.target;
    const inputElement = e.target;
    var value = (value = value?.trimStart());
    const currentCursorPosition = inputElement.selectionStart;
    const currentCursorPositionEnd = inputElement.selectionEnd;

    switch (name) {
      case 'jobTitle':
        if (allowLetterCase) {
          value = lastWordLetterCase({ str: value });
        }
        break;
      case 'company_name':
        if (allowLetterCase) {
          value = lastWordLetterCase({ str: value });
        }
        break;
    }
    if (
      name === 'other_requirements' ||
      name === 'jobTitle' ||
      name === 'company_name'
    ) {
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }

    if (name === 'manage_employees') {
      if (value.toLowerCase() === 'n') {
        value = 'No';
      } else if (value.toLowerCase() === 'y') {
        value = 'Yes';
      }
    }
    // Restore the cursor position
    if (name === 'manage_employees') {
      console.log('Here');
    } else {
      setTimeout(() => {
        inputElement.selectionStart = currentCursorPosition;
        inputElement.selectionEnd = currentCursorPosition;
      }, 0);
    }

    setFieldVals({
      ...fieldVals,
      [name]: value,
    });

    setFieldErrors('');

    if (name === 'city_id') {
      setLoadingCities(true);
      usefetch('cities/getcities', 'post', defaultHeaders(), {
        cityName: value,
      }).then(function (resp) {
        if (resp?.code != undefined) {
          setCities(resp.data);
          setLoadingCities(false);
        }
      });
    }
  };

  const saveFieldValue = (name, value) => {
    if (
      fieldErrors ||
      value === '' ||
      (Array.isArray(value) &&
        value.length === 0 &&
        selectedJobType !== JOB_TYPE.REMOTE)
    ) {
      return;
    } else {
      if (name === 'password_two' && !checkedTerms) {
        setFieldErrors('Please agree to terms and conditions to proceed.');
        return;
      }
      employerPostJob(name, value);
    }
  };

  const removeItems = () => {
    localStorage.removeItem('jobTitle');
    sessionStorage.removeItem('jobTitle');
    sessionStorage.removeItem('experience');
    sessionStorage.removeItem('company_name');
    sessionStorage.removeItem('manage_employees');
    sessionStorage.removeItem('subordinates_count');
    sessionStorage.removeItem('other_requirements');
    sessionStorage.removeItem('maximum_budget');
    sessionStorage.removeItem('full_name');
    sessionStorage.removeItem('city_id');
    sessionStorage.removeItem('email_address');
    sessionStorage.removeItem('mobile_number');
    sessionStorage.removeItem('password_one');
    sessionStorage.removeItem('password_two');
  };
  const employerPostJob = (name, value) => {
    switch (name) {
      case 'jobTitle':
        setLoading(true);
        // localStorage.setItem('jobTitle', value);
        usefetch('registerTemp/savetitlevalue', 'post', defaultHeaders(), {
          jobTitle: value,
        }).then(function (resp) {
          if (resp.data !== undefined) {
            globalDispatch({
              enc_id: resp.data.enc_id,
              skills: resp.data.skills,
            });
            // globalDispatch({type:'default', data: {enc_id: resp.data.enc_id, skills: resp.data.skills}});
            setLoading(false);
            localStorage.setItem('enc_id', resp.data.enc_id);
            localStorage.setItem('jobTitle', value);
            navigate('/chooseSkills');
          }
        });
        break;

      // case 'city':
      //   break;
      case 'manage_employees':
        let tempValue = value;
        if (typeof tempValue === 'object') {
          tempValue = value?.code;
        } else {
          tempValue = value?.toLowerCase();
        }
        setFieldVals({
          ...fieldVals,
          manage_employees: tempValue,
        });
        usefetch('registerTemp/savetempvalue', 'post', defaultHeaders(), {
          registerTempClientId: localStorage.getItem('enc_id'),
          label: name,
          value: value,
        }).then(() => {
          if (tempValue === 'yes') {
            sessionStorage.setItem('manage_employees', value);
            navigate('/signup/subordinates_count');
          } else if (tempValue === 'no') {
            sessionStorage.setItem('manage_employees', value);
            navigate('/signup/other_requirements');
          } else {
            return;
          }
        });
        break;
      case 'otp':
        if (value?.length < 4 || value?.length > 6 || !value?.length) {
          setFieldErrors('Enter valid otp');
        } else {
          setLoading(true);
          usefetch('user/verifyOtp', 'post', defaultHeaders(), {
            otp: value,
            email: localStorage.getItem('userEmail'),
          }).then(function (resp) {
            if (resp?.code === '11') {
              localStorage.setItem('jid', resp?.data?.job?.jid);
              // globalDispatch({type:'default', data: {enc_id: resp.data.enc_id, skills: resp.data.skills}});
              globalDispatch({
                token: resp?.data?.sessionId,
              });

              navigate(`/job/app/${localStorage.getItem('jid')}`, {
                state: { showDescription: 'Y' },
              });
              localStorage.setItem('token', resp?.data?.sessionId);
              localStorage.setItem('userName', resp?.data?.userName);
              // navigate(`/jobDetails`, { state: { showDescription: 'Y' } });
              removeItems();
            } else {
              setFieldErrors(resp?.message);
            }
            setLoading(false);
            // setShowSecondField(0);
          });
        }
        break;

      default:
        setLoading(true);
        let shouldHideSalary = undefined;
        if (name === 'maximum_budget') {
          shouldHideSalary = hideSalary ? 'Y' : 'N';
          if (localStorage.getItem('token')) {
            recruiterPostJob(name, value);
            break;
          }
        } else {
          shouldHideSalary = undefined;
        }

        let jobType = undefined;
        if (name === 'city_id') {
          const cityIds = [];
          value?.map((city) => {
            cityIds.push(city?.cityID);
          });
          jobType = selectedJobType;
          value = cityIds;
        }

        usefetch('registerTemp/savetempvalue', 'post', defaultHeaders(), {
          registerTempClientId: localStorage.getItem('enc_id'),
          label: name,
          value: value,
          hideSalary: shouldHideSalary,
          workplace: jobType,
        }).then(function (resp) {
          setPasswordLabel('Create');
          globalDispatch({
            userExists: 'no',
          });
          if (resp?.code === '11') {
            if (name === 'password_two') {
              setLoading(true);
              usefetch('user/signup', 'post', defaultHeaders(), {
                registerTempInfoId: localStorage.getItem(
                  'enc_id',
                  resp.data.enc_id,
                ),
                isGeneric: sessionStorage.getItem('isGeneric'),
              }).then(function (resp) {
                if (resp?.code === '11') {
                  // createCookie('userEmail', resp?.data?.email, 50000);
                  // globalDispatch({
                  //   userEmail: resp?.data?.email,
                  // });
                }
                // sessionStorage.removeItem('ísGeneric');
                // generateTest(resp?.data?.jid);
                setUserExists(false);
                localStorage.setItem('jid', resp?.data?.jid);
                localStorage.setItem('userEmail', resp?.data?.email);
                navigate('/signup/otp');
                setLoading(false);
              });
            } else {
              setLoading(false);
              handleNextField(name, value);
            }
          } else {
            if (resp.code === '03') {
              // user already exists, take password and redirect to applicant page
              navigate('/signup/password_one');
              globalDispatch({
                userExists: 'yes',
              });
              setLoading(false);
              setUserExists(true);
              setPasswordLabel('Enter');
            }
            if (resp.code === '05') {
              // user exists but didn't verified his email
              navigate('/signup/otp');
              setLoading(false);
              setUserExists(true);
              localStorage.setItem('userEmail', resp?.data?.email);
            }
            if (resp.code === '06') {
              // login success
              generateTest(resp?.data?.jid);
              localStorage.setItem('jid', resp?.data?.jid);
              localStorage.setItem('token', resp?.data?.sessionId);
              localStorage.setItem('userName', resp?.data?.userName);
              navigate(`/job/app/${resp?.data?.jid}`, {
                state: { showDescription: 'Y' },
              });
              removeItems();
              // navigate(`/job/app/${resp?.data?.jid}`);
              setLoading(false);
            }
            if (name === 'password_one' && resp.code === '04') {
              setFieldErrors(resp?.message);
              setPasswordLabel('Enter');
              setUserExists(true);
              setLoading(false);
            }
            if (name === 'password_two') {
              setFieldErrors(resp?.message);
              setLoading(false);
            }
            if (resp.code === '00') {
              setLoading(false);
              setFieldErrors(resp?.message);
            }
          }
        });
    }
  };

  const recruiterPostJob = (name, value) => {
    setLoading(true);
    usefetch('registerTemp/savetempvalue', 'post', defaultHeaders(), {
      registerTempClientId: localStorage.getItem('enc_id'),
      label: name,
      value: value,
      hideSalary: hideSalary ? 'Y' : 'N',
    }).then((resp) => {
      usefetch('jobs/recruiterpostjob', 'post', headers(), {
        registerTempInfoId: localStorage.getItem('enc_id'),
      }).then((resp) => {
        refreshUserData().then(() => {
          navigate(`/job/app/${resp?.jid}`, {
            state: { showDescription: 'Y' },
          });
          generateTest(resp?.jid);
          removeItems();
          setLoading(false);
        });
      });
    });
  };
  const checkedTermErrorMessage =
    'Please agree to terms and conditions to proceed.';
  const keyEnter = (e) => {
    let errors = '';
    if (e.key === 'Backspace' || e.key === 'Delete') {
      setAllowLettercase(false);
    }
    if (e.code === 'Space') {
      setAllowLettercase(true);
    }
    if (e.key === 'Enter') {
      const { name, value } = e.target;
      if (name === 'manage_employees') {
        const lowerCaseValue = value?.toLowerCase();
        if (
          !lowerCaseValue ||
          (lowerCaseValue !== 'yes' && lowerCaseValue !== 'no')
        ) {
          errors = 'Please enter "Yes" or "No".';
        }
      }
      if (name === 'jobTitle') {
        errors = jobTitleValidation(e.target.value);
      }
      if (name === 'company_name') {
        errors = jobTitleValidation(e.target.value);
      }
      if (
        name === 'subordinates_count' ||
        name === 'experience' ||
        name === 'other_requirements'
      ) {
        errors = subordinateValidation(e.target.value);
      }
      if (name === 'maximum_budget') {
        errors = maxBudgetValidation(e.target.value);
      }
      if (name === 'full_name') {
        errors = requireStringValidation(e.target.value);
      }
      if (name === 'email_address') {
        errors = emailValidation(e.target.value);
      }
      if (name === 'mobile_number') {
        errors = phoneNumberValidation(e.target.value);
      }
      if (name === 'password_one') {
        errors = passwordValidation(e.target.value);
      }
      if (name === 'password_two') {
        if (!checkedTerms) {
          errors = checkedTermErrorMessage;
        }
        setFieldErrors(null);
      }
      if (name === 'otp') {
        setFieldErrors(null);
      }
      setFieldErrors(errors);
      if (!errors) {
        saveFieldValue(name, value);
      } else {
        setLoading(false);
      }
    }
  };

  const multilinePlaceholder =
    'For Example: \nAtleast 5 years of experience is required. Must have good communication skills. Banking industry experience is preferred. Atleast 16 years of education and a masters degree in business or engineering is requiried.';

  const TypograhyMain = ({ titleText }) => {
    return (
      <Typography
        variant={smMediaquery ? 'title34Bold' : mediaQuery1380 ? 'h3' : 'h2'}
        sx={{
          fontWeight: mediaQuery1380 && '800',
          lineHeight: smMediaquery ? '42px' : mediaQuery1380 ? '50px' : '60px',
          // height: smMediaquery ? '115px' : mediaQuery1380 ? '182px' : '200px',
          height: smMediaquery ? '115px' : 'max-content',
        }}
        pt={smMediaquery ? 0 : 3}
        pb={smMediaquery ? 0 : 2}
        dangerouslySetInnerHTML={{ __html: titleText }}
      />
    );
  };

  const SpecificEndAdornment = ({ nameSpecific, valueSpecific }) => {
    return loading ? (
      <Stack sx={{ height: '30px', width: '30px', marginRight: '0.5rem' }}>
        <CircularProgress
          style={{
            height: '30px',
            width: '30px',
            color: '#010103',
            boxShadow: 'inset 0 0 1px 2px #e8e8e8',
            backgroundColor: 'transparent',
            borderRadius: '50%',
          }}
        />
      </Stack>
    ) : (
      <Stack direction='row'>
        {(nameSpecific === 'password_one' ||
          nameSpecific === 'password_two') && (
          <Stack pr={1}>
            {showPassword ? (
              <VisibilityOffIcon
                style={{ cursor: 'pointer', color: '#010103' }}
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <VisibilityIcon
                style={{ cursor: 'pointer', color: '#010103' }}
                onClick={() => setShowPassword(true)}
              />
            )}
          </Stack>
        )}
        <Stack>
          {(
            nameSpecific === 'subordinates_count' ||
            nameSpecific === 'experience'
              ? valueSpecific?.length > 0
              : valueSpecific?.length > 1 && !fieldErrors
          ) ? (
            <InputIconBlack
              style={{ cursor: 'pointer' }}
              onClick={() => {
                saveFieldValue(nameSpecific, valueSpecific);
              }}
            />
          ) : (
            <InputIcon />
          )}
        </Stack>
      </Stack>
    );
  };

  const [selectedCities, setSelectedCities] = useState([]);

  const handleCityChange = (event, value) => {
    setSelectedCities(value);
  };

  // const handleCityInputChange = (event, value) => {
  //   if (!value) {
  //     // If input is empty, reset the selected cities
  //     setSelectedCities([]);
  //   }
  // };
  useEffect(() => {
    if (window.location.pathname === '/signup/city_id') {
      setLoadingCities(true);
      usefetch('cities/getcities', 'post', defaultHeaders()).then(function (
        resp,
      ) {
        if (resp?.code != undefined) {
          setCities(resp.data);
          setLoadingCities(false);
        }
      });
    }
  }, [window.location.pathname]);

  const [selectedJobType, setSelectedJobType] = useState(JOB_TYPE.ONSITE);

  useEffect(() => {
    // Filter out selected cities from the list of cities
    const filteredCities = cities.filter(
      (city) =>
        !selectedCities.find((selectedCity) => selectedCity.city === city.city),
    );
    setCities(filteredCities);
  }, [selectedCities]);

  const getOptionDisabled = (option) => {
    return (
      selectedCities.length >= 8 ||
      selectedCities.some((selectedCity) => selectedCity?.city === option.city)
    );
  };

  return (
    <Stack
      sx={{
        '& .field__stack': {
          width: smMediaquery ? '100%' : mediaQuery1380 ? '500px' : '600px',
          maxWidth: '100%',
          paddingTop: mediaQuery1380 ? 3.6 : 5.6,
          '& .MuiInputBase-root': {
            background: '#fff',
            height: smMediaquery ? '60px' : mediaQuery1380 ? '74px' : '82px',
            '& input': {
              fontSize: smMediaquery
                ? '16px'
                : mediaQuery1380
                ? '20px'
                : '22px',
            },
            '& .MuiInputAdornment-root .MuiTypography-root': {
              fontSize: smMediaquery
                ? '16px'
                : mediaQuery1380
                ? '20px'
                : '22px',
            },
          },
        },
      }}
    >
      <JobOgTags />
      {(() => {
        switch (fieldName) {
          case 'jobTitle':
            return (
              <>
                <TypograhyMain titleText={TextDictionary.signup._1} />
                <Stack className='field__stack'>
                  <Textfield
                    autoFocus={smMediaquery ? false : true}
                    placeholder='Enter Job Title'
                    margin='dense'
                    variant='outlined'
                    name='jobTitle'
                    inputRef={refMain}
                    // textCapitalize
                    value={fieldVals?.jobTitle}
                    onChange={(e) => {
                      handleChanges(e);
                    }}
                    onEnter={keyEnter}
                    disabled={loading}
                    maxLength={70}
                    endAdornment={
                      <SpecificEndAdornment
                        nameSpecific='jobTitle'
                        valueSpecific={fieldVals?.jobTitle}
                      />
                    }
                  />
                </Stack>
              </>
            );
          case 'experience':
            return (
              <Experience
                fieldErrors={fieldErrors}
                loading={loading}
                refMain={refMain}
                classes={classes}
                TypograhyMain={TypograhyMain}
                fieldVals={fieldVals}
                setFieldVals={setFieldVals}
                keyEnter={keyEnter}
                handleChanges={handleChanges}
                SpecificEndAdornment={SpecificEndAdornment}
              />
            );
          case 'company_name':
            return (
              <>
                <TypograhyMain titleText=' What is the <br /> name of your <br /> company?' />
                <Stack className='field__stack'>
                  <Textfield
                    placeholder='Enter Company Name'
                    margin='dense'
                    variant='outlined'
                    helperText={fieldErrors}
                    name='company_name'
                    autoFocus
                    value={fieldVals?.company_name}
                    onChange={(e) => {
                      handleChanges(e);
                    }}
                    onEnter={keyEnter}
                    disabled={loading}
                    maxLength={50}
                    endAdornment={
                      <SpecificEndAdornment
                        nameSpecific='company_name'
                        valueSpecific={fieldVals?.company_name}
                      />
                    }
                  />
                </Stack>
              </>
            );
          case 'manage_employees':
            return (
              <ManageEmployes
                fieldErrors={fieldErrors}
                loading={loading}
                refMain={refMain}
                classes={classes}
                setFieldErrors={setFieldErrors}
                TypograhyMain={TypograhyMain}
                fieldVals={fieldVals}
                setFieldVals={setFieldVals}
                keyEnter={keyEnter}
                handleChanges={handleChanges}
                SpecificEndAdornment={SpecificEndAdornment}
              />
            );
          case 'subordinates_count':
            return (
              <>
                <TypograhyMain titleText='How many <br /> employees work <br /> under this position?' />
                <Stack className='field__stack'>
                  <Textfield
                    placeholder='20'
                    margin='dense'
                    variant='outlined'
                    name='subordinates_count'
                    autoFocus
                    onEnter={keyEnter}
                    value={fieldVals?.subordinates_count}
                    onChange={(e) => {
                      handleChanges(e);
                    }}
                    inputComponent
                    helperText={fieldErrors}
                    disabled={loading}
                    endAdornment={
                      <SpecificEndAdornment
                        nameSpecific='subordinates_count'
                        valueSpecific={fieldVals?.subordinates_count}
                      />
                    }
                  />
                </Stack>
              </>
            );
          case 'other_requirements':
            return (
              <Stack
                sx={{
                  '& .textfield__global': {
                    '& .MuiInputBase-root': {
                      height: '150px',
                      fontSize: smMediaquery
                        ? '16px'
                        : mediaQuery1380
                        ? '20px'
                        : '22px',

                      padding: '14px 20px',

                      '& textarea': {
                        height: '100% !important',
                      },
                    },
                  },
                }}
              >
                <TypograhyMain titleText='Are there any other <br /> requirements?' />
                <Stack className='field__stack'>
                  <Textfield
                    placeholder={multilinePlaceholder}
                    margin='dense'
                    shrink
                    multiline={true}
                    maxRows={4}
                    variant='outlined'
                    name='other_requirements'
                    value={fieldVals?.other_requirements}
                    onChange={(e) => {
                      handleChanges(e);
                    }}
                    autoFocus
                    // onEnter={keyEnter}
                    helperText={fieldErrors}
                    disabled={loading}
                    // endAdornment={
                    //   <SpecificEndAdornment
                    //     nameSpecific='other_requirements'
                    //     valueSpecific={fieldVals?.other_requirements}
                    //   />
                    // }
                  />
                  <Stack alignItems='flex-end'>
                    <Button
                      variant='contained'
                      color='primary'
                      title='Continue'
                      height='59px'
                      width='150px'
                      borderRadius='15px'
                      onClick={() => {
                        saveFieldValue(
                          'other_requirements',
                          fieldVals?.other_requirements,
                        );
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            );

          case 'city_id':
            return (
              <City
                cities={cities}
                classes={classes}
                loading={loading}
                keyEnter={keyEnter}
                fieldVals={fieldVals}
                loadingCities={loadingCities}
                handleChanges={handleChanges}
                TypograhyMain={TypograhyMain}
                selectedCities={selectedCities}
                saveFieldValue={saveFieldValue}
                handleCityClick={handleCityClick}
                handleCityChange={handleCityChange}
                getOptionDisabled={getOptionDisabled}
                selectedJobType={selectedJobType}
                setSelectedJobType={setSelectedJobType}
                SpecificEndAdornment={SpecificEndAdornment}
              />
            );

          case 'maximum_budget':
            return (
              <>
                <TypograhyMain titleText="What's the <br /> maximum budget <br /> for this position?" />
                <Stack position='relative' className='field__stack'>
                  <Textfield
                    placeholder='/ Month'
                    margin='dense'
                    autoFocus
                    startAdornText
                    inputComponent
                    startAdornment={<InputAdornment>PKR</InputAdornment>}
                    variant='outlined'
                    name='maximum_budget'
                    inputRef={refMain}
                    value={fieldVals?.maximum_budget}
                    onChange={(e) => {
                      handleChanges(e);
                    }}
                    maxLength={'11'}
                    onEnter={keyEnter}
                    disabled={loading}
                    helperText={fieldErrors}
                    endAdornment={
                      <SpecificEndAdornment
                        nameSpecific='maximum_budget'
                        valueSpecific={fieldVals?.maximum_budget}
                      />
                    }
                  />
                  <ShowHideSalary
                    toggle={hideSalary}
                    setToggle={setHideSalary}
                  />
                </Stack>
              </>
            );
          case 'email_address':
            return (
              <>
                <TypograhyMain titleText='What is your <br /> email address?' />
                <Stack className='field__stack'>
                  <Textfield
                    placeholder='Enter Email Address'
                    margin='dense'
                    variant='outlined'
                    name='email_address'
                    inputRef={refMain}
                    autoFocus
                    value={fieldVals?.email_address}
                    onChange={(e) => {
                      handleChanges(e);
                    }}
                    onEnter={keyEnter}
                    disabled={loading}
                    helperText={fieldErrors}
                    endAdornment={
                      <SpecificEndAdornment
                        nameSpecific='email_address'
                        valueSpecific={fieldVals?.email_address}
                      />
                    }
                  />
                  <GoogleSignIn from={'signup'} dark />
                </Stack>
              </>
            );

          case 'full_name':
            return (
              <>
                <TypograhyMain titleText='What is your <br /> full name?' />
                <Stack className='field__stack'>
                  <Textfield
                    placeholder='Enter Full Name'
                    margin='dense'
                    autoFocus
                    variant='outlined'
                    name='full_name'
                    textCapitalize
                    value={fieldVals?.full_name}
                    onChange={(e) => {
                      handleChanges(e);
                    }}
                    inputRef={refMain}
                    onEnter={keyEnter}
                    disabled={loading}
                    maxLength={50}
                    endAdornment={
                      <SpecificEndAdornment
                        nameSpecific='full_name'
                        valueSpecific={fieldVals?.full_name}
                      />
                    }
                  />
                </Stack>
              </>
            );

          case 'mobile_number':
            return (
              <>
                <TypograhyMain titleText='What is your <br /> mobile phone <br /> number?' />
                <Stack className='field__stack'>
                  <Textfield
                    placeholder='Enter mobile number'
                    margin='dense'
                    autoFocus
                    inputRef={refMain}
                    variant='outlined'
                    name='mobile_number'
                    value={fieldVals?.mobile_number}
                    onChange={(e) => {
                      handleChanges(e);
                    }}
                    onEnter={keyEnter}
                    disabled={loading}
                    helperText={fieldErrors}
                    endAdornment={
                      <SpecificEndAdornment
                        nameSpecific='mobile_number'
                        valueSpecific={fieldVals?.mobile_number}
                      />
                    }
                  />
                </Stack>
              </>
            );

          case 'password_one':
            return (
              <>
                <TypograhyMain
                  titleText={`${passwordLabel} <br /> your password`}
                />
                <Stack className='field__stack'>
                  <Textfield
                    placeholder='Enter password'
                    margin='dense'
                    variant='outlined'
                    name='password_one'
                    inputRef={refMain}
                    autoFocus
                    type={showPassword ? 'text' : 'password'}
                    value={fieldVals?.password_one}
                    onChange={(e) => {
                      handleChanges(e);
                    }}
                    onEnter={keyEnter}
                    disabled={loading}
                    helperText={fieldErrors}
                    helperChip={
                      userExists
                        ? 'Your account already exists, please enter password to continue.'
                        : 'Your password should be at least 8 characters long and have a combination of letters and numbers.'
                    }
                    endAdornment={
                      <SpecificEndAdornment
                        nameSpecific='password_one'
                        valueSpecific={fieldVals?.password_one}
                      />
                    }
                  />
                  {globalState?.userExists !== undefined &&
                    globalState?.userExists === 'yes' && (
                      <Typography
                        onClick={handleResetPassword}
                        variant='title14Bold'
                        color='secondary.main'
                        sx={{ width: 'max-content', cursor: 'pointer' }}
                      >
                        Reset Password
                      </Typography>
                    )}
                </Stack>
              </>
            );

          case 'password_two':
            return (
              <ReEnterPassword
                fieldErrors={fieldErrors}
                loading={loading}
                refMain={refMain}
                classes={classes}
                setFieldErrors={setFieldErrors}
                checkedTerms={checkedTerms}
                setCheckedTerms={setCheckedTerms}
                checkedTermErrorMessage={checkedTermErrorMessage}
                TypograhyMain={TypograhyMain}
                fieldVals={fieldVals}
                showPassword={showPassword}
                keyEnter={keyEnter}
                handleChanges={handleChanges}
                SpecificEndAdornment={SpecificEndAdornment}
              />
            );

          case 'otp':
            return (
              <>
                <TypograhyMain titleText=' Enter the OTP <br /> sent to your <br /> email address' />
                <Stack className='field__stack'>
                  <Textfield
                    placeholder='Enter OTP'
                    margin='dense'
                    autoFocus
                    inputRef={refMain}
                    variant='outlined'
                    name='otp'
                    value={fieldVals?.otp}
                    onChange={(e) => {
                      handleChanges(e);
                    }}
                    onEnter={keyEnter}
                    disabled={loading}
                    helperText={fieldErrors}
                    endAdornment={
                      <SpecificEndAdornment
                        nameSpecific='otp'
                        valueSpecific={fieldVals?.otp}
                      />
                    }
                  />
                </Stack>
                <>
                  {showCounter ? (
                    <Typography
                      variant='title14Bold'
                      color='secondary.dark'
                      sx={{ width: 'max-content' }}
                    >
                      Resend OTP in {otpTime}
                    </Typography>
                  ) : (
                    <Typography
                      onClick={handleResendOtp}
                      variant='title14Bold'
                      color='secondary.main'
                      sx={{ width: 'max-content', cursor: 'pointer' }}
                    >
                      Resend OTP
                    </Typography>
                  )}
                </>
              </>
            );

          default:
            return null;
        }
      })()}
      {showPasswordPopup && (
        <ForgotPassword
          email={fieldVals?.email_address}
          smMediaquery={smMediaquery}
          toggle={showPasswordPopup}
          setToggle={setShowPasswordPopup}
        />
      )}
      <Outlet />
    </Stack>
  );
}
