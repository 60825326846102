import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Stack,
  Alert,
  Typography,
  Dialog,
  Grow,
  Snackbar,
  IconButton,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';

import Style from './style';
import Button from '../../common/Button';
import Textfield from '../../common/Textfield';
import { defaultHeaders, usefetch } from '../../helpers/fetch';
import {
  InfoIcon,
  LogoWhite,
  InputIcon,
  DarkInputIcon,
  InputIconBlack,
  WhiteLogoNoText,
  InputIconWhite,
} from '../../assets/constants';
import {
  emailValidation,
  passwordValidation,
} from '../../helpers/formValidation';
import { useSnackbar } from '../../App';
import SnackbarCustom from '../../common/Snackbar';

const Styles = makeStyles((theme) => Style(theme));

export default function ForgotPassword({ email, toggle, setToggle }) {
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  const [fieldVals, setFieldVals] = useState({
    email: email !== undefined ? email : '',
    otp: '',
    passwordOne: '',
    passwordTwo: '',
  });
  const defaultOtpTime = 30;
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState('');
  const [currentField, setCurrentField] = useState(1);
  const [snackMessgae, setSnackMessage] = useState('');
  const [showCounter, setShowCounter] = useState(false);
  const [emailSnackbar, setEmailSnackbar] = useState('');
  const [otpTime, setOtpTime] = useState(defaultOtpTime);
  const [showPassword, setShowPassword] = useState(false);
  const [displayInfoAlert, setDisplayInfoAlert] = useState(false);
  const [displaySnackMessage, setDisplaySnackMessage] = useState(false);
  const { setSnackbarMessage } = useSnackbar();

  useEffect(() => {
    if (otpTime <= 0) {
      setShowCounter(false);
      return;
    }

    const timeInterval = setInterval(() => {
      setOtpTime((otpTime) => otpTime - 1);
    }, 1000);

    return () => clearInterval(timeInterval);
  }, [otpTime]);

  const handleChanges = (e) => {
    var { name, value } = e.target;
    var value = (value = value?.trimStart());
    setFieldVals({
      ...fieldVals,
      [name]: value,
    });
    setFieldErrors('');
    setDisplayInfoAlert(false);
  };

  const handleBackClick = () => {
    if (currentField > 1) {
      if (currentField === 3) {
        setCurrentField(1);
      } else {
        setCurrentField(currentField - 1);
      }
    }
    setFieldErrors('');
  };

  const fieldsValidated = () => {
    let errors = '';

    switch (currentField) {
      case 1:
        errors = emailValidation(fieldVals.email);
        break;
      case 2:
        if (
            fieldVals.otp?.length < 4 ||
            fieldVals.otp?.length > 6 ||
            !fieldVals.otp?.length
        ) {
          errors = 'Enter valid otp';
        } else {
          errors = null;
        }
        break;
      case 3:
        errors = passwordValidation(fieldVals?.passwordOne);
        break;
      case 4:
        errors = passwordValidation(fieldVals?.passwordTwo);
        if (fieldVals?.passwordOne !== fieldVals?.passwordTwo) {
          errors = 'Passwords should be same.';
        }
        break;
      default:
        return;
    }
    setFieldErrors(errors);
    return !errors;
  }

  const keyEnter = (e) => {
    let errors = '';
    if (fieldErrors || e.target.value === '') {
      return;
    }
    switch (currentField) {
      case 1:
        errors = emailValidation(fieldVals.email);
        break;
      case 2:
        if (
          fieldVals.otp?.length < 4 ||
          fieldVals.otp?.length > 6 ||
          !fieldVals.otp?.length
        ) {
          errors = 'Enter valid otp';
        } else {
          errors = null;
        }
        break;
      case 3:
        errors = passwordValidation(e.target.value);
        break;
      case 4:
        errors = passwordValidation(e.target.value);
        if (fieldVals?.passwordOne !== e.target.value) {
          errors = 'Passwords should be same.';
        }
        break;
      default:
        return;
    }
    if (e.key === 'Enter') {
      setFieldErrors(errors);

      if (!errors) {
        handleEnter(e);
      }

      return errors;
    }
  };

  const handleEnter = (e, isButton) => {
    if (e.key === 'Enter' || isButton === 'Y') {
      if (!fieldsValidated()) {
        return;
      }

      switch (currentField) {
        case 1:
          setLoading(true);
          usefetch('user/forgotpassword', 'post', defaultHeaders(), {
            email: fieldVals.email,
          }).then(function (resp) {
            setCurrentField(currentField + 1);
            setOtpTime(defaultOtpTime);
            setShowCounter(true);
            setLoading(false);
            setEmailSnackbar(
              'If your account exists on rozeegpt.ai, we have sent you an otp to verify your email.',
            );
          });
          break;
        case 2:
          setLoading(true);

          usefetch('otp/verifyOtp', 'post', defaultHeaders(), {
            email: fieldVals.email,
            otp: fieldVals.otp,
          }).then(function (resp) {
            if (resp?.code === '11') {
              setDisplayInfoAlert(false);
              setSnackMessage('');
              setCurrentField(currentField + 1);
              setLoading(false);
            } else {
              setDisplayInfoAlert(true);
              setSnackMessage(resp?.message);
              setLoading(false);
            }
          });
          break;
        case 4:
          usefetch('user/updateforgotpassword', 'post', defaultHeaders(), {
            email: fieldVals.email,
            otp: fieldVals.otp,
            password:
              fieldVals.passwordOne === fieldVals.passwordTwo
                ? fieldVals.passwordOne
                : '',
          }).then(function (resp) {
            if (resp?.code === '11') {
              setSnackbarMessage(resp?.message);
              setToggle(false);
            }
          });
          break;
        default:
          setCurrentField(currentField + 1);
      }
    }
  };

  const handleResendOtp = () => {
    usefetch('otp/resendforgototp', 'post', defaultHeaders(), {
      email: fieldVals.email,
    }).then(function (resp) {
      setShowCounter(true);
      setOtpTime(defaultOtpTime);
    });
  };

  const SpecificEndAdornment = ({ nameSpecific, valueSpecific }) => {
    return loading ? (
      <Stack sx={{ height: '30px', width: '30px', marginRight: '0.5rem' }}>
        <CircularProgress
          style={{
            height: '30px',
            width: '30px',
            color: '#010103',
            boxShadow: 'inset 0 0 1px 2px #e8e8e8',
            backgroundColor: 'transparent',
            borderRadius: '50%',
          }}
        />
      </Stack>
    ) : (
      <Stack direction='row'>
        {(nameSpecific === 'passwordOne' || nameSpecific === 'passwordTwo') && (
          <Stack pr={1}>
            {showPassword ? (
              <VisibilityOffIcon
                style={{ cursor: 'pointer', color: '#fff' }}
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <VisibilityIcon
                style={{ cursor: 'pointer', color: '#fff' }}
                onClick={() => setShowPassword(true)}
              />
            )}
          </Stack>
        )}
        <Stack>
          {valueSpecific?.length > 1 && !fieldErrors ? (
            <InputIconWhite
              style={{ cursor: 'pointer' }}
              onClick={(e) => handleEnter(e, 'Y')}
            />
          ) : (
            <DarkInputIcon />
          )}
        </Stack>
      </Stack>
    );
  };

  const handleCloseSnackbar = () => {
    setEmailSnackbar('');
  };

  return (
    <Dialog
      open={toggle}
      onClose={setToggle}
      className={classes.main__dialog__forgotPassword}
    >
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiSnackbarContent-root': {
            background: '#fff',
            color: 'primary.main',
          },
        }}
        TransitionComponent={Grow}
        autoHideDuration={4000}
        open={displaySnackMessage}
        message={snackMessgae}
        onClose={() => setDisplaySnackMessage(false)}
      />
      <Stack
        sx={{ position: 'relative', height: '100%' }}
        className={classes.dialog__content__forgotPassword}
      >
        {smMediaquery ? (
          <Stack zIndex='99' position='absolute' top='3rem' right='1rem'>
            <CloseRoundedIcon
              style={{ cursor: 'pointer', color: '#fff' }}
              onClick={() => setToggle(false)}
            />
          </Stack>
        ) : (
          <Stack justifyContent='flex-end' direction='row' pt={2} pr={2}>
            <CloseRoundedIcon
              style={{ cursor: 'pointer', color: '#fff' }}
              onClick={() => setToggle(false)}
            />
          </Stack>
        )}

        <Stack
          direction='column'
          alignItems={smMediaquery ? 'flex-start' : 'center'}
          justifyContent='center'
          px={smMediaquery ? 3 : 6}
          sx={{
            width: '100%',
            position: 'relative',
            height: 'max-content',
          }}
        >
          {currentField > 1 && (
            <Stack
              pt={smMediaquery ? 7 : 0}
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                left: smMediaquery ? '1rem' : '2rem',
                top: '0rem',
              }}
              onClick={handleBackClick}
            >
              <Typography
                variant='title16Bold'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: '400',
                }}
                color='primary.light'
              >
                <ArrowBackIcon sx={{ marginRight: 1.2 }} />
                Back
              </Typography>
            </Stack>
          )}

          {smMediaquery ? (
            <Stack pt={smMediaquery && 16}>
              <WhiteLogoNoText style={{ height: '100px', width: '100px' }} />
            </Stack>
          ) : (
            <Stack
              sx={{
                '& svg': {
                  '& path': {
                    fill: '#fff',
                  },
                },
              }}
            >
              <LogoWhite style={{ width: '120px' }} />
            </Stack>
          )}
          <Stack
            alignItems={smMediaquery ? 'flex-start' : 'center'}
            pb={0}
            width='100%'
          >
            <Typography
              variant={'title26Bold'}
              color='primary.light'
              pt={2}
              sx={{
                fontWeight: smMediaquery && 700,
                lineHeight: smMediaquery && '50px',
              }}
              pb={smMediaquery ? 2 : 1}
            >
              Forgot Password
            </Typography>
            <Typography
              variant={'title14Bold'}
              sx={{ fontWeight: '400' }}
              color='primary.light'
              pb={smMediaquery ? 5 : 3.7}
            >
              {currentField === 1 && 'Enter your email to reset the password'}
              {currentField === 2 && 'Enter OTP to proceed'}
              {currentField === 3 && 'Enter your password'}
              {currentField === 4 && 'Please re-enter your password'}
            </Typography>

            <Stack
              sx={{
                width: '100%',
                '& .MuiInputBase-root': {
                  background: 'transparent !important',
                  border: '1.1px solid #fff',
                  borderRadius: '11px',
                  height: '64px',
                  '& input': {
                    fontSize: mediaquery1380 ? '16px' : '20px',
                    caretColor: '#ffffff !important',
                    color: '#ffffff !important',
                    '&::placeholder': {
                      color: '#999999',
                      opacity: 0.5,
                    },
                    '&:-webkit-autofill': {
                      '-webkit-box-shadow': '0 0 0 30px #fff inset !important',
                      '-webkit-text-fill-color':
                        'var(--color-disabled-hot) !important',
                    },
                  },
                },
              }}
            >
              {currentField === 1 && (
                <Textfield
                  placeholder='Enter email address'
                  margin='dense'
                  variant='outlined'
                  helperText={fieldErrors}
                  darkModeField
                  name='email'
                  value={fieldVals?.email}
                  onEnter={keyEnter}
                  onChange={(e) => handleChanges(e)}
                  endAdornment={
                    <SpecificEndAdornment
                      nameSpecific='email'
                      valueSpecific={fieldVals?.email}
                    />
                  }
                />
              )}
              {currentField === 2 && (
                <Textfield
                  placeholder='Enter OTP'
                  margin='dense'
                  variant='outlined'
                  helperText={fieldErrors}
                  darkModeField
                  name='otp'
                  value={fieldVals?.otp}
                  onEnter={keyEnter}
                  onChange={(e) => handleChanges(e)}
                  endAdornment={
                    <SpecificEndAdornment
                      nameSpecific='otp'
                      valueSpecific={fieldVals?.otp}
                    />
                  }
                />
              )}

              {currentField === 3 && (
                <Textfield
                  placeholder='Enter password'
                  margin='dense'
                  variant='outlined'
                  helperText={fieldErrors}
                  type={showPassword ? 'text' : 'password'}
                  darkModeField
                  name='passwordOne'
                  value={fieldVals?.passwordOne}
                  onEnter={keyEnter}
                  onChange={(e) => handleChanges(e)}
                  helperChip='Your password should be at least 8 characters long and have a combination of letters and numbers.'
                  endAdornment={
                    <SpecificEndAdornment
                      nameSpecific='passwordOne'
                      valueSpecific={fieldVals?.passwordOne}
                    />
                  }
                />
              )}

              {currentField === 4 && (
                <Textfield
                  placeholder='Re-enter password'
                  margin='dense'
                  variant='outlined'
                  type={showPassword ? 'text' : 'password'}
                  darkModeField
                  name='passwordTwo'
                  helperText={fieldErrors}
                  value={fieldVals?.passwordTwo}
                  onEnter={keyEnter}
                  onChange={(e) => handleChanges(e)}
                  endAdornment={
                    <SpecificEndAdornment
                      nameSpecific='passwordTwo'
                      valueSpecific={fieldVals?.passwordTwo}
                    />
                  }
                />
              )}

              {currentField === 2 && (
                <>
                  {showCounter ? (
                    <Typography
                      variant='title14Bold'
                      pb={1}
                      pl={1.8}
                      color='primary.light'
                      sx={{
                        width: 'max-content',
                        alignSelf: 'end',
                        fontWeight: '500',
                      }}
                    >
                      Resend OTP in {otpTime} seconds
                    </Typography>
                  ) : (
                    <Typography
                      onClick={handleResendOtp}
                      variant='title14Bold'
                      pb={1}
                      pl={1.8}
                      color='primary.light'
                      sx={{
                        width: 'max-content',
                        alignSelf: 'end',
                        cursor: 'pointer',
                        fontWeight: '600',
                      }}
                    >
                      Resend OTP
                    </Typography>
                  )}
                  <Stack
                    sx={{
                      '& .MuiSnackbar-root': {
                        width: '96%',
                      },
                    }}
                  >
                    <SnackbarCustom
                      response={emailSnackbar}
                      handleClose={handleCloseSnackbar}
                      autoHideDuration={5000}
                      verticalPosition='bottom'
                    />
                  </Stack>
                </>
              )}

              {displayInfoAlert && (
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  TransitionComponent={Grow}
                  autoHideDuration={3000}
                  open={snackMessgae?.length > 0}
                  onClose={() => {
                    setSnackMessage('');
                  }}
                >
                  <Alert
                    icon={
                      <IconButton
                        size='small'
                        className={classes.info__icon}
                        sx={{
                          '& svg': {
                            '& path': {
                              fill: '#fff',
                            },
                          },
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    }
                    sx={{
                      height: '39px',
                      background: '#de1135',
                      color: '#fff',
                      fontSize: '14px',
                      fontWeight: '400',
                      alignItems: 'center',
                      borderRadius: '8px',
                    }}
                    severity='info'
                  >
                    {snackMessgae}
                  </Alert>
                </Snackbar>
              )}
              <Stack py={smMediaquery ? 1 : 1} />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          sx={{
            position: smMediaquery ? 'relative' : 'relative',
            width: '100%',
          }}
          pb={smMediaquery && 5}
          alignItems='center'
        >
          {currentField === 1 && (
            <Button
              title='Reset Password'
              color='primary'
              width={smMediaquery ? '90%' : '84%'}
              height='59px'
              disabled={loading}
              backgroundColor={
                loading ? '#D6D6D6 !important' : '#fff !important'
              }
              textColor='#010103 !important'
              variant='contained'
              borderRadius='11px'
              fontWeight='400'
              fontSize={smMediaquery ? '14px' : '16px'}
              onClick={(e) => handleEnter(e, 'Y')}
            />
          )}
          {currentField === 2 && (
            <Button
              title='Verify OTP'
              color='primary'
              width={smMediaquery ? '90%' : '84%'}
              height='59px'
              disabled={loading}
              backgroundColor={
                loading ? '#D6D6D6 !important' : '#fff !important'
              }
              textColor='#010103 !important'
              variant='contained'
              borderRadius='11px'
              fontWeight='400'
              fontSize={smMediaquery ? '14px' : '16px'}
              onClick={(e) => handleEnter(e, 'Y')}
            />
          )}
          {currentField === 3 && (
            <Button
              title='Next'
              color='primary'
              width={smMediaquery ? '90%' : '84%'}
              height='59px'
              disabled={loading}
              backgroundColor={
                loading ? '#D6D6D6 !important' : '#fff !important'
              }
              textColor='#010103 !important'
              variant='contained'
              borderRadius='11px'
              fontWeight='400'
              fontSize={smMediaquery ? '14px' : '16px'}
              onClick={(e) => handleEnter(e, 'Y')}
            />
          )}
          {currentField === 4 && (
            <Button
              title='Save Password'
              color='primary'
              width={smMediaquery ? '90%' : '84%'}
              height='59px'
              disabled={loading}
              backgroundColor={
                loading ? '#D6D6D6 !important' : '#fff !important'
              }
              textColor='#010103 !important'
              variant='contained'
              borderRadius='11px'
              fontWeight='400'
              fontSize={smMediaquery ? '14px' : '16px'}
              onClick={(e) => handleEnter(e, 'Y')}
            />
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
}
