const Style = (theme) => ({
  style__main: {
    '& .MuiInputBase-root': {
      background: '#fff',
      height: '92px',
      border: '0 !important',
      borderRadius: '20px',
      border: '1px solid  #010103',
      '& input': {
        fontSize: '30px',
        lineHeight: '34px',
        fontWeight: '500',
        padding: '.2rem 1.5rem',
        color: 'var(--color-disabled-hot)',
        [theme.breakpoints.down('1380')]: {
          fontSize: '20px',
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '16px',
        },
      },
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: '0',
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: '0',
      },
      '& fieldset': {
        border: 'none !important',
      },
      [theme.breakpoints.down('1380')]: {
        height: '74px',
        borderRadius: '20px',
      },
      [theme.breakpoints.down('md')]: {
        height: '60px',
        borderRadius: '14px',
      },
      '&:before': {
        border: 0,
      },
      '&:after': {
        border: '0.25px solid #010103',
      },
      '& .MuiInputAdornment-root': {
        '& .MuiTypography-root': {
          color: 'var(--color-disabled-hot)',
          fontSize: '30px',
          fontWeight: '500',
          paddingLeft: '.5rem',
          [theme.breakpoints.down('1380')]: {
            fontSize: '20px',
          },
          [theme.breakpoints.down('md')]: {
            fontSize: '16px',
          },
        },
      },
    },
    '& .MuiFormHelperText-root.Mui-error': {
      fontSize: '16px',
      fontWeight: '500',
      color: '#ec7376',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
  },
  table__input: {
    '& .MuiInputBase-root': {
      background: '#fff',
      borderRadius: '16px',
      height: '58px',
      border: '1px solid #010103',
      '& input': {
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: '400',
      },
    },
  },
  style__main__startAdorn: {
    '& .MuiInputBase-root': {
      '& input': {
        paddingLeft: '5.2px',
      },
    },
  },
  custom__height: {
    '& .MuiInputBase-root': {
      height: '40px',
    },
  },
  multiline__input: {
    '& .MuiInputBase-root': {
      borderRadius: '20px',
      height: 'max-content',
      fontSize: '30px',
      lineHeight: '34px',
      fontWeight: '500',
      color: 'var(--color-disabled-hot)',
      alignItems: 'flex-end',
      [theme.breakpoints.down('1380')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      '& svg': {
        marginBottom: '8px',
      },
    },
  },
  darkModeField: {
    '& .MuiInputBase-root': {
      background: '#1a1a1a',
      border: '1px solid  #1a1a1a',
      '& input': {
        color: 'var(--color-white)',
        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 30px #1a1a1a inset',
          '-webkit-text-fill-color': 'var(--color-white)',
          borderRadius: '0',
        },
      },
      '& .MuiInputAdornment-root': {
        '& .MuiTypography-root': {
          color: 'var(--color-white)',
        },
      },
    },
    '& .MuiFormHelperText-root': {
      height: '23.23px',
    },
  },
  error__field: {
    '& .MuiInputBase-root': {
      // border: '1px solid  #ec7376',
    },
  },
  post__formField: {
    '& .MuiInputBase-root': {
      background: '#fff',
      height: '68px',
      '& input': {
        fontSize: '20px',
        lineHeight: '25px',
        fontWeight: '500',
      },
    },
  },
});
export default Style;
