import Loader from "../../../common/Loader";
import {useEffect} from "react";
import {deleteAllCookies} from "../../../helpers/CookieHelper";
import { useNavigate } from 'react-router-dom';
import {isEmpty} from "../../../helpers/common";

export const Logout = () => {
    const navigate = useNavigate();
    const logout = () => {
       const careerCoachInterview = localStorage.getItem('careerCoachInterview')
        localStorage.clear();
        sessionStorage.clear();
        deleteAllCookies();
        if(isEmpty(careerCoachInterview)) {
            navigate('/lgoin');
        } else {
            navigate('/career-coach');
        }
    };

    useEffect(() => {
        logout();
    }, []);

    return (
        <>
           <Loader />
        </>
    )
}