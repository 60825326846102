import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Stack, Typography } from '@mui/material';

import Style from './style';
import ShareStack from './ShareStack';
import Button from '../../../../common/Button';
import Loader from '../../../../common/Loader';
import VideoPlayerDrawer from '../../EvaluationDialog/VideoPlayerDrawer';
import { useInterviewContest } from '../../../../services/career-coach/useInterviewContest';
import { CareerCoachInterviewContext } from '../../VideoInterview/CareerCoachInterviewContext';
import { useGetInterviewPermalink } from '../../../../services/career-coach/useGetInterviewPermalink';

const Styles = makeStyles((theme) => Style(theme));

export default function Submission() {
  const classes = Styles();
  const navigate = useNavigate();

  const { getInterviewPermalink } = useGetInterviewPermalink();

  const [data, setData] = useState({});
  const [toggleVideo, setToggleVideo] = useState(false);
  const { addToInterviewContest, isAddingToContest } = useInterviewContest();
  const { displayName } = useContext(CareerCoachInterviewContext);

  const handleContinue = () => {
    // const cachedAnsId = localStorage.getItem('answerId');
    // addToInterviewContest({
    //   contest: 'Y',
    //   answerId: cachedAnsId,
    //   displayName: displayName ? 'Y' : 'N',
    // }).then((resp) => {
    //
    //   // navigate('/career-coach/voting/');
    // });

    const url = sessionStorage.getItem('answerPermalink');
    window.location.href = url;
  };

  useEffect(() => {
    const cachedAnsId = localStorage.getItem('answerId');
    getInterviewPermalink({ id: cachedAnsId }).then((resp) => {
      if (resp.code == '11') {
        setData(resp?.data);
        sessionStorage.setItem('answerPermalink', resp?.data?.permalink);
      }
    });
  }, []);

  return isAddingToContest ? (
    <Loader textWithLoader text1={'Loading...'} />
  ) : (
    <>
      <Stack pb={23} pt={3} alignItems='center' px={2}>
        <Stack
          mb={'45px'}
          alignItems='flex-start'
          sx={{ width: '100%', cursor: 'pointer' }}
        >
          <Typography
            onClick={() => navigate('/career-coach/evaluation')}
            variant='title14Bold'
            sx={{
              width: 'max-content',
              display: 'flex',
              alignItems: 'center',
              fontWeight: '600',
            }}
          >
            <ChevronLeftIcon sx={{ marginRight: 0.5, marginLeft: '-7px' }} />
            Back
          </Typography>
        </Stack>

        <Stack
          alignItems='center'
          sx={{
            '& img': {
              borderRadius: '11px',
              objectFit: 'cover',
              background: '#c3c3c3',
            },
          }}
        >
          <img
            onClick={() => {
              setToggleVideo(true);
            }}
            src={data?.thumbUrl}
            width='148px'
            height='148px'
          />
        </Stack>

        <Typography
          pt={3}
          pb={'28px'}
          variant='title21Bold'
          sx={{ fontWeight: '500', lineHeight: '22px', textAlign: 'center' }}
        >
          <span style={{ color: '#0aa06a', fontWeight: '700' }}>
            {' '}
            Congrats You're In!
          </span>{' '}
          <br />
          Ab competition jeetne kay liye apna zabardast interview doston aur
          colleagues ke saath{' '}
          <span style={{ color: '#0aa06a', fontWeight: '700' }}>
            {' '}
            share karein
          </span>{' '}
          taake wo aapke liye vote kar sakein
        </Typography>
        <ShareStack />

        <Stack
          p={2}
          sx={{
            background: '#fff',
            position: 'fixed',
            bottom: '0',
            left: '0',
            width: '100%',
            // boxShadow: '0px -5px 24px 0px rgba(0, 0, 0, 0.30)',
          }}
        >
          <Button
            color='primary'
            title='Continue'
            // disabled={!termsCheck}
            variant='contained'
            onClick={handleContinue}
            width='100%'
            borderRadius='11px'
            fontSize='14px'
            height='56px'
            fontWeight='400'
            backgroundColor={'#010103 !important'}
            textColor='#fff !important'
          />
        </Stack>
      </Stack>
      <VideoPlayerDrawer
        url={data?.videoUrl}
        toggle={toggleVideo}
        setToggle={setToggleVideo}
      />
    </>
  );
}
