import React, { useEffect, useState } from 'react';

import { Stack, ClickAwayListener } from '@mui/material';

import QuickActions from '../QuickActions';
import TextDictionary from '../../../../../common/Dictionary';
import EditableTypography from '../../../../../common/EditableTypography';

export default function PersonalInformation({
  setFieldVals,
  fieldVals,
  classes,
  templateData,
  handleTextChange,
}) {
  const [showQuickActions, setShowQuickAction] = useState(false);

  useEffect(() => {
    if (templateData) {
      setFieldVals({
        name: templateData.name || '',
        tagline: templateData.tagline || 'Job title here',
        email: templateData.email || 'youremail@example.com',
        mobile: templateData.mobile || '030000000',
        address: templateData.address || 'Lahore, Pakistan',
        link: templateData.link || 'example.com',
        summary: templateData.summary ?? '',
      });
    }
  }, [templateData]);

  return (
    <>
      <EditableTypography
        padding={1}
        variant='title28Bold'
        fontWeight='600'
        width='max-content'
        placeholder={
          TextDictionary.templates.templateplaceholders.personalInformation._1
        }
        onBlur={(e) => handleTextChange(e, 'name')}
      >
        {fieldVals?.name}
      </EditableTypography>

      <EditableTypography
        padding={1}
        width='max-content'
        variant='title16Bold'
        fontWeight='500'
        placeholder={
          TextDictionary.templates.templateplaceholders.personalInformation._2
        }
        onBlur={(e) => handleTextChange(e, 'tagline')}
      >
        {fieldVals?.tagline}
      </EditableTypography>
      <Stack direction='row'>
        <EditableTypography
          padding={1}
          width='max-content'
          variant='title14Bold'
          fontWeight='400'
          minWidth='50%'
          color='rgba(33,33,33,.6)'
          placeholder={
            TextDictionary.templates.templateplaceholders.personalInformation._3
          }
          onBlur={(e) => handleTextChange(e, 'email')}
        >
          {fieldVals?.email}
        </EditableTypography>
        <EditableTypography
          padding={1}
          width='max-content'
          variant='title14Bold'
          fontWeight='400'
          minWidth='200px'
          color='rgba(33,33,33,.6)'
          placeholder={
            TextDictionary.templates.templateplaceholders.personalInformation._4
          }
          onBlur={(e) => handleTextChange(e, 'mobile')}
        >
          {fieldVals?.mobile}
        </EditableTypography>
      </Stack>
      <Stack direction='row'>
        <EditableTypography
          padding={1}
          width='max-content'
          variant='title14Bold'
          fontWeight='400'
          minWidth='50%'
          color='rgba(33,33,33,.6)'
          placeholder={
            TextDictionary.templates.templateplaceholders.personalInformation._5
          }
          onBlur={(e) => handleTextChange(e, 'address')}
        >
          {fieldVals?.address}
        </EditableTypography>
        <ClickAwayListener onClickAway={() => setShowQuickAction(false)}>
          <Stack width='50%' position='relative'>
            <EditableTypography
              padding={1}
              width='max-content'
              variant='title14Bold'
              fontWeight='400'
              minWidth='100%'
              color='rgba(33,33,33,.6)'
              placeholder={TextDictionary.templates.templateplaceholders._6}
              onFocus={() => {
                setShowQuickAction('link');
              }}
              onBlur={(e) => {
                handleTextChange(e, 'link');
              }}
            >
              {fieldVals?.link}
            </EditableTypography>
            {showQuickActions === 'link' && (
              <QuickActions top='-4rem' deleteable />
            )}
          </Stack>
        </ClickAwayListener>
      </Stack>
    </>
  );
}
