const Style = (theme) => ({
  main__container: {
    height: 'calc(100vh - 44px)',
  },
  drawer__job__details: {
    width: '100vw',
  },
  template__view: {
    height: 'calc(100vh - 182px)',
    width: '100%',
    overflowY: 'auto',
    '& > .MuiStack-root': {
      transform: 'scale(0.244)',
      transformOrigin: 'top left',
      height: '34rem',
      padding: '0 !important',
      pointerEvents: 'none !important',
      minWidth: '603px',
    },
  },
  accordion: {
    margin: '0 !important',
    boxShadow: 'none !important',
    '&:before': {
      display: 'none !important',
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid #e4e4e4',
    },
  },
  accordion__summary: {
    flexDirection: 'row',
    padding: '0 !important',
    height: '20px !important',
  },
  divided__width: {
    width: 'calc(100% - 20px)',
  },
  table__header: {
    padding: '1rem',
    background: '#f4f4f4',
  },
  heading: {
    fontSize: '18px',
    fontWeight: '600',
  },
  content: {
    fontSize: '16px',
    fontWeight: '600',
  },
  accordion__details: {
    paddingTop: '0 !important',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',

    '& .accordion__detail__inner': {
      padding: '1rem',
      background: '#fff',
      boxShadow: '0px 0px 14px 0px rgba(0,0,0,0.15)',
    },
  },
});
export default Style;
