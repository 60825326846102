const Style = (theme) => ({
  profile__dialog: {
    
    display:'flex',
    alignItems:'flex-end',
    '& .MuiDrawer-paper': {
      width: '100% !important',
      height: '90vh',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px 10px 0 0',
      boxShadow:'none',
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  close__icon: {
    height: '18px',
    width: '18px',
    marginRight: '.5rem',
    cursor: 'pointer',
  },
});
export default Style;
