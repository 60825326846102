import React, { useState } from 'react';
import { Stack } from '@mui/material';

import TestScreen from './TestScreen';
import SuccessSubmit from './SuccessSubmit';

export default function TestStarted() {
  const [testCompleted, setTestCompleted] = useState(false);
  return (
    <Stack>
      {!testCompleted ? (
        <TestScreen
          testCompleted={testCompleted}
          setTestCompleted={setTestCompleted}
        />
      ) : (
        <SuccessSubmit setTestCompleted={setTestCompleted} />
      )}
    </Stack>
  );
}
