import {useState} from "react";
import {headers, usefetch} from "../helpers/fetch";

export const useGenerateTest = () => {
    const [error, setError] = useState(null);
    const [isGeneratingTest, setIsGeneratingTest] = useState(null);
    const [response, setResponse] = useState(null);
    const [questions, setQuestions] = useState([]);

    const generateTest = async (jid) => {
        setIsGeneratingTest(true);
        const data = {
            jid: jid
        };

        const resp = await usefetch('testBuilder/generatetest', 'POST', headers(), data);

        if (resp?.code === '11') {
            if (resp?.data?.mcqs?.length > 0) {
                setQuestions(prev => [...resp?.data?.mcqs]);
            }
        }

        if (!resp.ok) {
            setError(resp.error);
        }

        if (resp.ok) {
            setResponse((prevData) => [...prevData, resp]);
        }

        setIsGeneratingTest(false);
        return resp;
    };

    return { generateTest, response, questions, isGeneratingTest, error };
};