import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';

export default function SelectOption({ options, onClick, onEnter }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const handleClick = (option, e) => {
    if (disabled) return;
    setSelectedOption(option);
    onClick(option);
    onEnter(e, 'Y', option);
    setDisabled(true);
  };

  return (
    <Stack my={2} width='100%'>
      <Stack
        pl={6}
        width='100%'
        direction='row'
        alignItems='center'
        flexWrap='wrap'
      >
        {options.map((k, i) => {
          const isSelected = k === selectedOption;
          return (
            <Stack
              key={k}
              mr={1.2}
              mb={1.2}
              alignItems='center'
              justifyContent='center'
              px={1.2}
              sx={{
                border: isSelected ? '1px solid #eaeaea' : '1px solid #010103',
                borderRadius: '44px',
                minWidth: '90px',
                height: '36px',
                cursor: disabled ? 'not-allowed' : 'pointer',
                backgroundColor: isSelected ? '#eaeaea' : 'transparent',
                pointerEvents: disabled ? 'none' : 'auto',
              }}
              onClick={(e) => handleClick(k, e)}
            >
              <Typography
                variant='title12Bold'
                sx={{ fontWeight: '600', textTransform: 'none' }}
              >
                {k}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}
