import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/styles';
import { Stack, Typography, Skeleton, useMediaQuery } from '@mui/material';

import Button from '../../../common/Button';
import { NoDataCartIcon } from '../../../assets/constants';
import { CartContext } from '../../Packages/Cart/CartContext';
import { useGetRemainingCredits } from '../../../services/useGetRemainingCredits';

export default function RemainingCredits() {
  const navigate = useNavigate();
  const theme = useTheme();
  const mediaQuery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  const creditOut = 2;
  const {
    getRemainingCredits,
    credits,
    hasCredit,
    hasCredits,
    isLoadingCredits,
  } = useGetRemainingCredits();
  const { setAccountDrawerOpen } = useContext(CartContext);

  useEffect(() => {
    getRemainingCredits();
  }, []);
  return (
    <Stack>
      <Typography pb={2} variant='title18Bold' sx={{ fontWeight: '600' }}>
        Remaining Credits
      </Typography>
      <Stack
        sx={{
          border: credits?.length < 1 ? 'none' : '1px solid #d3d3d3',
          borderRadius: '10px',
        }}
      >
        {isLoadingCredits
          ? [...Array(5)].map((k, i) => {
              return (
                <Stack
                  key={i++}
                  py={1}
                  px={2}
                  width='100%'
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{
                    '&:not(:last-child)': {
                      borderBottom: '1px solid #e4e4e4',
                    },
                  }}
                >
                  <Skeleton animation='wave' height={30} width='80%' />
                  <Skeleton
                    animation='wave'
                    height={40}
                    width={40}
                    variant='circular'
                  />
                </Stack>
              );
            })
          : credits.map((product, i) => {
              return (
                <Stack
                  key={product?.product_id}
                  py={1}
                  px={2}
                  width='100%'
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{
                    '&:not(:last-child)': {
                      borderBottom: '1px solid #e4e4e4',
                    },
                  }}
                >
                  <Typography variant='title16Bold' sx={{ fontWeight: '400' }}>
                    {product?.details?.name}
                  </Typography>
                  <Stack
                    sx={{
                      height: '42px',
                      width: '42px',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '16px',
                      fontWeight: '500',
                      justifyContent: 'center',
                      color:
                        creditOut > product?.totCredits ? '#de1135' : '#010103',
                      background:
                        creditOut > product?.totCredits ? '#fff' : '#f3f3f3',
                      border:
                        creditOut > product?.totCredits
                          ? '1px solid #de1135'
                          : '1px solid #f3f3f3',
                      borderRadius: '50px',
                    }}
                  >
                    {product?.totCredits}
                  </Stack>
                </Stack>
              );
            })}

        {credits?.length < 1 && !isLoadingCredits && (
          <Stack
            spacing={5}
            height='100%'
            alignItems='center'
            justifyContent='center'
          >
            <NoDataCartIcon />

            <Button
              onClick={() => {
                setAccountDrawerOpen(false);
                navigate('/packages');
              }}
              width='228px'
              height='57px'
              variant='outlined'
              backgroundColor='#fff !important'
              fontSize='16px'
              borderRadius={mediaQuery1380 ? '8px' : '11px'}
              borderStyle='1px solid #010103 !important'
              title='Buy Credits'
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
