import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/styles';
import CircleIcon from '@mui/icons-material/Circle';
import {
  Typography,
  Stack,
  Divider,
  Skeleton,
  useMediaQuery,
} from '@mui/material';

import Button from '../../../common/Button';
import { formatDate } from '../../../helpers/common';
import { PACKAGE__TYPE } from '../../../helpers/constants';
import { CartContext } from '../../Packages/Cart/CartContext';
import { CrownPremium, NoDataCartIcon } from '../../../assets/constants';
import { useGetOrderHistory } from '../../../services/useGetOrderHistory';

export default function PurchaseHistory({ i }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const mediaQuery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  const { setAccountDrawerOpen } = useContext(CartContext);

  const [showDetails, setShowDetails] = useState(false);

  const handleExpand = (k) => {
    showDetails === k ? setShowDetails(false) : setShowDetails(k);
  };
  const { getOrderHistory, orderHistory, isLoadingHistory } =
    useGetOrderHistory();
  const type = PACKAGE__TYPE;

  useEffect(() => {
    getOrderHistory();
  }, []);

  return (
    <Stack>
      <Typography pb={2} variant='title18Bold' sx={{ fontWeight: '600' }}>
        Purchase History
      </Typography>
      {isLoadingHistory
        ? [...Array(3)].map((k, i) => {
            return (
              <Stack
                mb={2}
                p={2.2}
                sx={{
                  backgroundColor: '#f3f3f3',
                  borderRadius: '10px',
                  border: '1px solid #f3f3f3',
                }}
              >
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Stack direction='row' alignItems='center'>
                    <Stack
                      sx={{
                        color: '#010103',
                      }}
                    >
                      <Skeleton animation='wave' height={25} width={90} />
                      <Typography
                        py={0.6}
                        variant='title16Bold'
                        sx={{ fontWeight: '400' }}
                      >
                        <Skeleton animation='wave' height={20} width={110} />
                      </Typography>
                      <Skeleton animation='wave' height={20} width={140} />
                    </Stack>
                  </Stack>
                  <Typography
                    sx={{
                      width: 'max-content',
                    }}
                  >
                    {' '}
                    <Skeleton animation='wave' height={20} width={50} />{' '}
                  </Typography>
                </Stack>
              </Stack>
            );
          })
        : orderHistory?.map((order, i) => {
            return (
              <>
                {order?.items?.map((item, j) => (
                  <Stack
                    mb={2}
                    p={2.2}
                    sx={{
                      backgroundColor:
                        PACKAGE__TYPE.PREMIUM === item.pack.is_premium
                          ? 'rgba(232, 176,41, 0.20)'
                          : '#f3f3f3',
                      borderRadius: '10px',
                      border:
                        PACKAGE__TYPE.PREMIUM === item.pack.is_premium
                          ? '1px solid #e8b029'
                          : '1px solid #f3f3f3',
                    }}
                  >
                    <>
                      {' '}
                      <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                      >
                        <Stack direction='row' alignItems='center'>
                          {PACKAGE__TYPE.PREMIUM === item.pack.is_premium && (
                            <CrownPremium style={{ marginRight: '1.2rem' }} />
                          )}
                          <Stack
                            sx={{
                              color:
                                PACKAGE__TYPE.PREMIUM === item.pack.is_premium
                                  ? '#9c7007'
                                  : '#010103',
                            }}
                          >
                            <Typography
                              variant='title16Bold'
                              sx={{ fontWeight: '700' }}
                            >
                              {item?.pack?.name}
                            </Typography>
                            <Typography
                              py={0.6}
                              variant='title16Bold'
                              sx={{ fontWeight: '400' }}
                            >
                              {item?.pack.is_free === 'Y' ? (
                                <Typography
                                  variant='title12Bold'
                                  sx={{ fontWeight: '400' }}
                                >
                                  {item?.pack?.duration}
                                </Typography>
                              ) : (
                                <>
                                  Rs.{' '}
                                  {Number(item?.pack?.price).toLocaleString(
                                    'en-US',
                                  )}{' '}
                                  <Typography
                                    variant='title12Bold'
                                    sx={{ fontWeight: '400' }}
                                  >
                                    /{item?.pack?.duration}
                                  </Typography>
                                </>
                              )}
                            </Typography>
                            <Typography
                              variant='title12Bold'
                              sx={{ fontWeight: '400' }}
                            >
                              Purchase Date:{' '}
                              {formatDate(order?.created_at)}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Typography
                          onClick={() => {
                            handleExpand(item?.pack.id);
                          }}
                          variant='title14Bold'
                          sx={{
                            fontWeight: '500',
                            width: 'max-content',
                            cursor: 'pointer',
                            color:
                              showDetails === item?.pack.id
                                ? '#de1135'
                                : '#010103',
                          }}
                        >
                          {showDetails === item?.pack.id ? 'Close' : 'Details'}
                        </Typography>
                      </Stack>
                      {showDetails === item?.pack.id && (
                        <Stack>
                          <Divider sx={{ my: 2, backgroundColor: '#c2c2c2' }} />
                          {item?.pack?.products.map((prod, p) => {
                            return (
                              <Stack
                                pb={1}
                                key={p}
                                direction='row'
                                alignItems='center'
                              >
                                <CircleIcon
                                  sx={{ height: '9px', width: '9px' }}
                                />
                                <Typography
                                  pl={1.2}
                                  variant='title14Bold'
                                  sx={{ fontWeigth: '400' }}
                                >
                                  {prod?.quantity}{' '}
                                  {prod?.name
                                    ? prod?.name
                                    : prod?.details?.name}
                                </Typography>
                              </Stack>
                            );
                          })}
                        </Stack>
                      )}
                    </>
                  </Stack>
                ))}
              </>
            );
          })}

      {orderHistory?.length < 1 && !isLoadingHistory && (
        <Stack
          spacing={5}
          height='100%'
          alignItems='center'
          justifyContent='center'
        >
          <NoDataCartIcon />

          <Button
            onClick={() => {
              setAccountDrawerOpen(false);
              navigate('/packages');
            }}
            width='228px'
            height='57px'
            variant='outlined'
            backgroundColor='#fff !important'
            fontSize='16px'
            borderRadius={mediaQuery1380 ? '8px' : '11px'}
            borderStyle='1px solid #010103 !important'
            title='Buy Credits'
          />
        </Stack>
      )}
    </Stack>
  );
}
