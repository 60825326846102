import React, { useState } from "react";
import { useTheme } from "@mui/styles";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../Auth/useAuth";
import { GoogleIcon } from "../../assets/constants";
import { defaultHeaders, usefetch } from "../../helpers/fetch";
import Loader from "../Loader";

const clientId =
  "46776265335-76kifiort1c0k063o6d7datqh35l43qt.apps.googleusercontent.com"; // From Google Developer Console

export const GoogleSignIn = (props) => {
  const { dark, from } = props;

  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down("md"));
  const mediaQuery1380 = useMediaQuery(theme.breakpoints.down("1380"));
  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const { loginWithGoogle, setUser } = useAuth();
  const handleLoginSuccess = (response) => {
    setIsLoggingIn(true);
    if (from === "signup") {
      usefetch("user/signup", "post", defaultHeaders(), {
        registerTempInfoId: localStorage.getItem("enc_id"),
        isGeneric: sessionStorage.getItem("isGeneric"),
        gtoken: response.credential,
      }).then(function (resp) {
        localStorage.setItem("jid", resp?.data?.jid);
        localStorage.setItem("userEmail", resp?.data?.email);
        if (resp?.code === "11") {
          setUser(resp?.data?.userInfo);
          localStorage.setItem("token", resp?.data?.userInfo?.token);
          navigate(`/job/app/${resp?.data?.jid}/description`);
        }
        setIsLoggingIn(false);
      });
    } else {
      loginWithGoogle({ gtoken: response.credential }).then((res) => {
        setIsLoggingIn(false);
      });
    }
  };

  return isLoggingIn ? (
    <Stack
      sx={{ position: "fixed", inset: "0", height: "100vh", width: "100vw" }}
    >
      <Loader />
    </Stack>
  ) : (
    <Stack
      // sx={{
      //   position: 'relative',
      //   '& iframe': {
      //     height: '56px !important',
      //     width: '100% !important',
      //     transform: smMediaquery
      //       ? 'scale(2.3, 1.3)'
      //       : mediaQuery1380
      //       ? 'sscale(2.3, 1.3)'
      //       : 'scale(2.75, 1.3)',
      //     transformOrigin: 'top left',
      //   },
      // }}
      sx={{
        position: "relative",
        "& iframe": {
          height: "56px !important",
          width: "100% !important",
          transform: smMediaquery
            ? "scale(2.8, 1.4)" // Adjust scale factor
            : mediaQuery1380
            ? "scale(2.4, 1.4)"
            : "scale(2.87, 1.4)", // Ensure these values fit your layout
          marginLeft: smMediaquery
          ? "-27px" // Adjust scale factor
          : mediaQuery1380
          ? "-30px"
          : "-30px", // Ensure these values fit your layout
          transformOrigin: "top left",
        },
      }}
    >
      
      <Stack
        sx={{
          overflow:'hidden',
          position: "absolute",
          inset: "0 !important",
          margin: "0 !important",
          width: "100%",
          zIndex: "9",
          opacity: "0",
        }} 
      >
        <GoogleOAuthProvider clientId={clientId}>
          {/* <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={() => console.log("Login Failed")}
            theme="filled_blue" // or "outline" or "filled_black"
            size="large" // or "medium", "small"
            text="continue_with" // or "signin", "signup"
            shape="rectangular" // or "pill", "square", "circle"
            width="100% !important"
            height="56px"
          /> */}
          <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={() => console.log("Login Failed")}
            theme="filled_blue" // or "outline" or "filled_black"
            size="large"
            text="continue_with" // "signin", "signup"
            shape="rectangular" // or "pill", "square", "circle"
            width="100%" // Apply consistent width
            sx={{
              width: "100% !important", // Ensure full width
              height: "56px !important", // Apply fixed height
              display: "block", // Prevent shrinking
            }}
          />
        </GoogleOAuthProvider>
      </Stack>
      <Stack
        sx={{
          position: "relative",
          width: "100%",
          height: "56px",
          background: dark ? "#010103 !important" : "#fff !important",
          borderRadius: "11px",
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="title14Bold"
          sx={{ color: dark ? "#fff" : "#010103" }}
        >
          Continue with Google&nbsp;&nbsp;&nbsp; <GoogleIcon />
        </Typography>
      </Stack>
    </Stack>
  );
};
