import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Requireuth({ children }) {
  // const { id } = useParams();
  const userToken = localStorage.getItem('token');
  const navigate = useNavigate();
  useEffect(() => {
    if (userToken) {
      // navigate(`/job/app/${id}`);
      // navigate(`/dashboard`);
      // navigate(`/jobDetails`);
    } else {
      navigate('/');
      return;
    }
  }, []);

  return children;
}
export default Requireuth;

export function GuestRoute({ children }) {
  // const { id } = useParams();
  const userToken = localStorage.getItem('token');
  const jid = localStorage.getItem('jid');
  // const navigate = useNavigate();
  useEffect(() => {
    if (userToken && jid) {
      // navigate(`/job/app/${jid}`);
      // navigate(`/jobDetails`);
    }
  }, []);

  return children;
}
