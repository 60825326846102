import Template1 from '../Seeker/CreateResume/Templates/Template1';
import Template1New from '../Seeker/CreateResume/Templates/Template1New';
import Template2 from './CreateResume/Templates/Template2';

const TemplateLoader = ({ publicView, templateId, templateData, getCv }) => {
  switch (templateId) {
    case 'k2n5h2nj3':
      return (
        <Template1New
          publicView={publicView}
          templateData={templateData}
          getCv={getCv}
        />
      );
      break;
    case 'k32343sds':
      return (
        <Template2
          publicView={publicView}
          templateData={templateData}
          getCv={getCv}
        />
      );
      break;
    default:
      return <Template1New templateData={templateData} getCv={getCv} />;
      break;
  }
};

export default TemplateLoader;
