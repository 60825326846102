import React, { useEffect, useState } from 'react';

import { makeStyles, useTheme } from '@mui/styles';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';

import Style from './style';
import ShareDropdown from './ShareDropdown';
import CVViewDropdown from './CVViewDropdown';
import MoreHorizDropdown from './MoreHorizDropdown';
import { headers, usefetch } from '../../../helpers/fetch';
import Template1 from '../CreateResume/Templates/Template1';
import TemplateImg from '../../../assets/images/template-1.png';
import TemplateImg2 from '../../../assets/images/template-2.png';
import { formatDate } from '../../../helpers/common';

const Styles = makeStyles((theme) => Style(theme));

export default function MyCvs({ page, getJob, job, toggle, setToggle }) {
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  //   More Horiz State
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreHorizDropdown, setMoreHorizDropdown] = useState(false);
  const [cvs, setCvs] = useState([]);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserCvs = async () => {
    const resp = await usefetch('cv/getUserCVs', 'post', headers(), {});
    setCvs(resp?.data?.cvs);
  };

  useEffect(() => {
    if (window.location.pathname === "/dashboard/my-cvs") {
      getUserCvs();
    }
   
  }, []);

  return (
    <Stack>
      <Typography
        pb={1.5}
        variant={mediaquery1380 ? 'title24Bold' : 'title34Bold'}
        sx={{ fontWeight: '700' }}
      >
        My CVs
      </Typography>
      <Typography
        pb={2}
        variant={mediaquery1380 ? 'title14Bold' : 'title18Bold'}
        sx={{ fontWeight: '400' }}
      >
        You have the cvs below created through RozeeGpt.
      </Typography>

      <Stack pt={3} direction='row' flexWrap='wrap'>
        {cvs?.map((k, i) => {
          // console.log(k);
          return (
            <Stack key={i} mb={4} mr={2} position='relative'>
              <Stack position='relative'>
                <Stack className={classes.template__view}>
                  <img
                    src={
                      k?.templateCode === 'k2n5h2nj3'
                        ? TemplateImg
                        : TemplateImg2
                    }
                    alt=''
                  />
                </Stack>
                <Stack
                  position='absolute'
                  top='0'
                  left='0'
                  sx={{
                    height: '100%',
                    width: '100%',
                    '&:hover': {
                      '& .title__hover': {
                        opacity: 1,
                      },
                    },
                  }}
                >
                  <Stack
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget);
                      setMoreHorizDropdown(i);
                    }}
                    className={classes.more__horiz__icon}
                  >
                    <MoreHorizRoundedIcon sx={{ width: '15px' }} />
                  </Stack>
                  {moreHorizDropdown === i && (
                    <MoreHorizDropdown
                      i={i}
                      open={open}
                      classes={classes}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      cv={k}
                    />
                  )}
                  <Stack
                    className='title__hover'
                    sx={{
                      opacity: 0,
                      background:
                        'linear-gradient(180deg, rgba(0,0,0,0.06) 0%, rgba(0,0,0,0.06) 100% )',
                      borderRadius: '11px',
                      backdropFilter: 'blur(7.5px)',
                      padding: '10px',
                    }}
                    position='absolute'
                    bottom='6.5px'
                    left='6.5px'
                    width='91.5%'
                  >
                    <Typography
                      pb={0.8}
                      variant='title12Bold'
                      sx={{ fontWeight: '500' }}
                    >
                      {k?.title}
                    </Typography>
                    <Typography
                      variant='title10Bold'
                      sx={{ fontWeight: '400' }}
                    >
                      {formatDate(k?.createdAt)}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                mt={1.6}
                spacing={1.2}
                direction='row'
                alignItems='center'
                justifyContent='center'
              >
                <ShareDropdown cv={k} classes={classes} i={i} />
                <CVViewDropdown cv={k} classes={classes} i={i} />
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}
