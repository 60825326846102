import React from 'react';

import EmailIcon from '@mui/icons-material/Email';
import { Stack, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';

import EditableTypography from '../../../../../common/EditableTypography';
import { useCvBuilderContext } from '../../../../../context/CvBuilderContext/useCvBuilderContext';

export default function PersonalInformation() {
  const { fieldVals, handleTextChange } = useCvBuilderContext();

  return (
    <Stack>
      <EditableTypography
        variant='title36Bold'
        placeholder={'Your name here'}
        sx={{ lineHeight: '38px' }}
        onBlur={(e, isChanged) => {
          if (isChanged) {
            handleTextChange(e, 'name');
          }
        }}
      >
        {fieldVals?.name}
      </EditableTypography>

      <EditableTypography
        my={2}
        contentEditable
        variant='title16Bold'
        placeholder={'Your tagline here'}
        sx={{ fontWeight: '400' }}
        onBlur={(e, isChanged) => {
          if (isChanged) {
            handleTextChange(e, 'tagline');
          }
        }}
      >
        {fieldVals?.tagline}
      </EditableTypography>
      <Stack spacing={1.2}>
        <Stack spacing={1.2} direction='row' alignItems='center'>
          <EmailIcon />
          <EditableTypography
            contentEditable
            variant='title14Bold'
            placeholder={'example@gmail.com'}
            sx={{ fontWeight: '400' }}
            onBlur={(e, isChanged) => {
              if (isChanged) {
                handleTextChange(e, 'email');
              }
            }}
          >
            {fieldVals?.email}
          </EditableTypography>
        </Stack>

        <Stack spacing={1.2} direction='row' alignItems='center'>
          <CallOutlinedIcon />
          <EditableTypography
            contentEditable
            variant='title14Bold'
            placeholder={'03000000000'}
            sx={{ fontWeight: '400' }}
            onBlur={(e, isChanged) => {
              if (isChanged) {
                handleTextChange(e, 'mobile');
              }
            }}
          >
            {fieldVals?.mobile}
          </EditableTypography>
        </Stack>

        <Stack spacing={1.2} direction='row' alignItems='center'>
          <HomeOutlinedIcon />
          <EditableTypography
            variant='title14Bold'
            sx={{ fontWeight: '400' }}
            placeholder={'Your address here'}
            onBlur={(e, isChanged) => {
              if (isChanged) {
                handleTextChange(e, 'address');
              }
            }}
          >
            {fieldVals?.address}
          </EditableTypography>
        </Stack>

        <Stack spacing={1.2} direction='row' alignItems='center'>
          <LanguageIcon />
          <EditableTypography
            contentEditable
            variant='title14Bold'
            sx={{ fontWeight: '400' }}
            onBlur={(e, isChanged) => {
              if (isChanged) {
                handleTextChange(e, 'link');
              }
            }}
            placeholder={'Your portfolio link here'}
          >
            {fieldVals?.link}
          </EditableTypography>
        </Stack>
      </Stack>
    </Stack>
  );
}
