const Style = (theme) => ({
  outlineEditable: {
    outlineOffset: '8px',
    borderRadius: '0px',
    '&:hover': {
      outline: '2px solid #999999',
    },
    '&:focus': {
      outline: '2px solid #256ef4',
    },
  },
  editor__stack: {
    '& .tox-tinymce': {
      borderRadius: '0',
      border: '2px solid #256ef4',
    },
    '& .tox-editor-header': {
      position: 'absolute',
      top: '-4rem',
    },
  },

});
export default Style;
