import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#fff',
      main: '#010103',
      dark: '#010103',
      contrastText: '#fff',
      success: '#31c274',
      heading: '#96A3CC',
      pale: '#2B2B2B',
      medium: '#67739C',
      lightMain: '#f9f9f9',
      lightContrast: '#aaaaaa',
    },
    primaryButton: {
      main: 'rgba(105, 33, 196, .1)',
      contrastText: '#6921c4',
    },
    secondary: {
      main: '#1045ff',
    },
    secondaryLight: {
      main: 'rgba(16,69,255,0.04)',
    },
    blueLight: {
      light: '#fff',
      main: '#3682ff',
      contrastText: '#fff',
    },
    success: {
      main: '#31c274',
      medium: '#0AA06A',
    },
    danger: {
      main: '#ec7376',
    },
    error: {
      main: '#ff3636',
    },
    disabled: {
      main: '#A4A8AC',
      light: '#6D7D8B',
      hot: '#545B7D',
      black: '#000',
    },
    mode: 'light',
  },
  typography: {
    fontFamily: "'Outfit', sans-serif !important",
    h1: {
      fontSize: '90px',
      fontWeight: '700',
      lineHeight: '48px',
    },
    h2: {
      fontSize: '60px',
      fontWeight: '900',
      lineHeight: '66px',
    },
    h3: {
      fontSize: '50px',
      fontWeight: '700',
      lineHeight: '66px',
    },
    h4: {
      fontSize: '44px',
      fontWeight: '600',
      lineHeight: '66px',
    },
    h5: {
      fontSize: '26px',
      fontWeight: '600',
      lineHeight: '66px',
    },
    mainInput: {
      fontSize: '30px',
      fontWeight: '500',
      lineHeight: 'normal',
    },
    title70Bold: {
      fontSize: '70px',
      fontWeight: '800',
      lineHeight: 'normal',
    },
    title58Bold: {
      fontSize: '58px',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    title54Bold: {
      fontSize: '54px',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    title48Bold: {
      fontSize: '48px',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    title36Bold: {
      fontSize: '36px',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    title34Bold: {
      fontSize: '34px',
      fontWeight: '600',
      lineHeight: 'normal',
    },
    title30Bold: {
      fontSize: '30px',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    title28Bold: {
      fontSize: '28px',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    title26Bold: {
      fontSize: '26px',
      fontWeight: '600',
      lineHeight: 'normal',
    },
    title25Bold: {
      fontSize: '25px',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    title24Bold: {
      fontSize: '24px',
      fontWeight: '600',
      lineHeight: 'normal',
    },
    title22Bold: {
      fontSize: '22px',
      fontWeight: '600',
      lineHeight: 'normal',
    },
    title20Bold: {
      fontSize: '20px',
      fontWeight: '400',
      lineHeight: 'normal',
    },
    title18Bold: {
      fontSize: '18px',
      fontWeight: '500',
      lineHeight: 'normal',
    },
    title17Bold: {
      fontSize: '17px',
      fontWeight: '500',
      lineHeight: 'normal',
    },
    title16Bold: {
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: 'normal',
    },
    title15Bold: {
      fontSize: '15px',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    title14Bold: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: 'normal',
    },
    title13Bold: {
      fontSize: '13px',
      fontWeight: '500',
      lineHeight: 'normal',
    },
    title12Bold: {
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: 'normal',
    },
    title11Bold: {
      fontSize: '11px',
      fontWeight: '500',
      lineHeight: 'normal',
    },
    title10Bold: {
      fontSize: '10px',
      fontWeight: '500',
      lineHeight: 'normal',
    },

    bannerHeading: {
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '20px',
    },
    mainLabel: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '19px',
    },
    helperText: {
      fontSize: '.75rem',
      fontWeight: '400',
      lineHeight: '1.66',
      letterSpacing: '0.03333em',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      400: 400,
      1380: 1380,
      1400: 1400,
      1450: 1450,
      1520: 1520,
      1920: 1920,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 30px #fff inset',
            WebkitTextFillColor: 'var(--color-disabled-hot)',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiPopover-paper.MuiMenu-paper': {
            boxShadow: '0px 2px 24px 0px rgba(0,0,0,0.15)',
            padding: '8px',
            borderRadius: '8px',
            zIndex: '1900',
            '& .MuiList-padding': {
              padding: '0',
            },
            '& .MuiMenuItem-root': {
              borderRadis: '6px',
              height: '40px',
              '&:hover': {
                background: '#f7f7f7',
              },
            },
          },
        },
      },
    },
  },
});

export default theme;
