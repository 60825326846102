import React from 'react';

import { makeStyles, useTheme } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Dialog, Stack, Typography, useMediaQuery } from '@mui/material';

import Style from './style';
import Button from '../../../common/Button';

const Styles = makeStyles((theme) => Style(theme));

export default function VerificationSuccess({
  toggle,
  setToggle,
  handleUpdate,
  setOtpToggle,
  setPasswordToggle,
}) {
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  const handleClose = () => {
    setToggle(false);
    setPasswordToggle(false);
    setOtpToggle(false);
    handleUpdate();
  };

  return (
    <Dialog
      open={toggle}
      onClose={setToggle}
      className={classes.main__dialog__success}
    >
      <Stack
        justifyContent='flex-end'
        direction='row'
        position='absolute'
        right='.5rem'
        top='0.5rem'
      >
        <CloseRoundedIcon style={{ cursor: 'pointer' }} onClick={handleClose} />
      </Stack>
      <Stack
        p={2}
        pt={4}
        height='100%'
        justifyContent='center'
        alignItems='center'
        color='primary.main'
      >
        <CheckCircleIcon
          style={{
            width: '54px',
            height: '54px',
          }}
        />
        <Typography pt={1.7} sx={{ fontWeight: '600' }} variant='title20Bold'>
          Success!
        </Typography>
        <Typography
          variant='title14Bold'
          sx={{ textAlign: 'center', fontWeight: '400' }}
          pt={1.2}
          pb={3}
          px={3}
        >
          Your password has been created successfully
        </Typography>
        <Button
          color={'primary'}
          title='Continue'
          variant='contained'
          width='100%'
          height='48px'
          borderRadius='11px'
          fontSize={mediaquery1380 ? '14px' : '16px'}
          fontWeight='500'
          onClick={() => {
            handleUpdate();
            setToggle(false);
            setPasswordToggle(false);
            setOtpToggle(false);
          }}
        />
      </Stack>
    </Dialog>
  );
}
