import React, { useState } from 'react';

import AccordionMain from '../AccordionMain';
import TextField from '../../../../../../common/Textfield';

export default function Summary({ expandedAccordion, handleAccordionChange }) {
  const [fieldErrors, setFieldErrors] = useState('');
  const [fieldVals, setFieldVals] = useState({
    summary: '',
  });

  const handleChanges = (e) => {
    var { name, value } = e.target;
    var value = (value = value?.trimStart());
    setFieldVals({
      ...fieldVals,
      [name]: value,
    });
    setFieldErrors('');
  };
  return (
    <AccordionMain
      title='Summary'
      name='summary'
      expandedState={expandedAccordion === 'summary'}
      onChange={handleAccordionChange}
    >
      <TextField
        autoFocus
        margin='dense'
        tableInput
        variant='standard'
        multiline
        rows={1}
        maxRows={3}
        helperText={fieldErrors}
        value={fieldVals?.summary}
        placeholder='Enter Summary'
        onChange={(e) => handleChanges(e)}
      />
    </AccordionMain>
  );
}
