import { useReducer } from "react";

const reducer = (state, action) => {
	switch (action.type) {
		case "UPDATE":
			var newState = {
				...state,
				...action.data
			};
			window.gState = newState;
			localStorage.setItem('APPLICATION', JSON.stringify(newState));
			return newState;
		case "UPDATE_NO_STORAGE":
			var newState = {
				...state,
				...action.data
			};
			window.gState = newState;
			return newState;
		default: {
			return state;
		}
	}
};

export const emptyState = {
	token: '',
	localStorage: false,
	loader: false,
	locale: 'en',
	verificationModal: false,
	otp_screen: false,
	cart:[]
};
const useGlobalState = () => {
	const [globalState, siteDispatch] = useReducer(reducer, emptyState);
	const globalDispatch = (data) => {
		return siteDispatch({ type: 'UPDATE', data: data });
	}
	const globalSiteDispatch = (data) => {
		return siteDispatch({ type: 'UPDATE_NO_STORAGE', data: data });
	}
	return [globalState, globalDispatch, globalSiteDispatch];
};

export default useGlobalState;