import React, { useContext, useRef, useState } from 'react';

import TextField from '@mui/material/TextField';
import { makeStyles, useTheme } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import {
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  Stack,
  Drawer,
  Typography,
  useMediaQuery,
} from '@mui/material';

import Style from './style';
import SuccessPopup from './SuccessPopup';
import Button from '../../../common/Button';
import { UpgradeCrown } from '../../../assets/constants';
import { headers, usefetch } from '../../../helpers/fetch';
import PaymentBank from '../../../assets/images/payment-bank.png';
import PaymentCard from '../../../assets/images/payment-card-new.png';
import PaymentWallet from '../../../assets/images/payment-wallet.png';
import PaymentPaypro from '../../../assets/images/payment-paypro.png';
import { CareerCoachInterviewContext } from '../VideoInterview/CareerCoachInterviewContext';

const Styles = makeStyles((theme) => Style(theme));

export default function PaymentPopup({}) {
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));

  const [checked, setChecked] = useState(null);
  const [successPopup, setSuccessPopup] = useState(false);

  const { upgradePopup, setUpgradePopup, PaymentData, OrderPayment, guruFlow } =
    useContext(CareerCoachInterviewContext);

  const formRef = useRef(null);

  const handleSubmit = () => {
    formRef.current.submit();
  };

  const PaymentForm = () => (
    <div>
      <form
        ref={formRef}
        id='PayFast_payment_form'
        action={PaymentData?.postURL}
        method='POST'
      >
        <TextField
          name='CURRENCY_CODE'
          value={PaymentData?.curr}
          style={{ display: 'none' }}
        />
        <TextField
          name='MERCHANT_ID'
          value={PaymentData?.merchantId}
          style={{ display: 'none' }}
        />
        <TextField
          name='TOKEN'
          value={PaymentData?.token}
          style={{ display: 'none' }}
        />
        <TextField
          name='SUCCESS_URL'
          value={PaymentData?.successURL}
          style={{ display: 'none' }}
        />
        <TextField
          name='FAILURE_URL'
          value={PaymentData?.failureURL}
          style={{ display: 'none' }}
        />
        <TextField
          name='CHECKOUT_URL'
          value='https://typedwebhook.'
          style={{ display: 'none' }}
        />
        <TextField
          name='CUSTOMER_EMAIL_ADDRESS'
          value={PaymentData?.customer?.email}
          style={{ display: 'none' }}
        />
        <TextField
          name='CUSTOMER_MOBILE_NO'
          value={PaymentData?.customer?.mobile}
          style={{ display: 'none' }}
        />
        <TextField
          name='TXNAMT'
          value={PaymentData?.trans?.amount}
          style={{ display: 'none' }}
        />
        <TextField
          name='BASKET_ID'
          value={PaymentData?.trans?.basketId}
          style={{ display: 'none' }}
        />
        <TextField
          name='ORDER_DATE'
          value={PaymentData?.date}
          style={{ display: 'none' }}
        />
        <TextField
          name='SIGNATURE'
          value={PaymentData?.randString}
          style={{ display: 'none' }}
        />
        <TextField
          name='VERSION'
          value={PaymentData?.version}
          style={{ display: 'none' }}
        />
        <TextField
          name='TXNDESC'
          value={PaymentData?.trans?.description}
          style={{ display: 'none' }}
        />
        <TextField name='PROCCODE' value='00' style={{ display: 'none' }} />
        <TextField
          name='TRAN_TYPE'
          value='ECOMM_PURCHASE'
          style={{ display: 'none' }}
        />
        <TextField name='STORE_ID' value='' style={{ display: 'none' }} />
        <TextField
          name='RECURRING_TXN'
          value='false'
          style={{ display: 'none' }}
        />
      </form>
    </div>
  );

  function handleCheckBox(index) {
    setChecked(index);
  }

  const SuccessPopupMain = successPopup && (
    <SuccessPopup toggle={successPopup} setToggle={setSuccessPopup} />
  );

  const Content = () => {
    return (
      <>
        <Stack
          justifyContent='flex-end'
          direction='row'
          position='absolute'
          right='.5rem'
          top='0.5rem'
        >
          <CloseRoundedIcon
            style={{ cursor: 'pointer' }}
            onClick={() => setUpgradePopup(false)}
          />
        </Stack>
        <Stack
          p={3}
          px={smMediaquery ? 1 : 3}
          pb={smMediaquery ? 12 : 3}
          direction={smMediaquery ? 'column' : 'row'}
          height='100%'
          justifyContent='space-between'
          alignItems='center'
          color='primary.main'
        >
          <Stack height='max-content' width={smMediaquery ? '100%' : '54%'}>
            {guruFlow ? (
              <>
                {smMediaquery ? null : (
                  <Typography
                    py={2}
                    variant='title16Bold'
                    sx={{
                      display: 'flex',
                      fontWeight: '400',
                      alignItems: 'center',
                    }}
                  >
                    <UpgradeCrown style={{ marginRight: '.5rem' }} />
                    Upgrade To Premium
                  </Typography>
                )}
                <Typography
                  pt={1.2}
                  variant={smMediaquery ? 'title20Bold' : 'title28Bold'}
                  pb={smMediaquery ? 1 : 2}
                  sx={{
                    fontWeight: '600',
                    textAlign: smMediaquery ? 'center' : 'left',
                  }}
                >
                  Try RozeeGPT Premium
                </Typography>
                <Typography
                  pb={smMediaquery ? 2 : 4}
                  variant={smMediaquery ? 'title14Bold' : 'title16Bold'}
                  sx={{
                    fontWeight: '300',
                    lineHeight: '24px',
                    textAlign: smMediaquery ? 'center' : 'left',
                  }}
                >
                  RozeeGPT Premium Membership Rs.{' '}
                  {Number(OrderPayment).toLocaleString('en-US')}
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  py={2}
                  variant='title16Bold'
                  sx={{
                    display: 'flex',
                    fontWeight: '400',
                    alignItems: 'center',
                  }}
                >
                  <UpgradeCrown style={{ marginRight: '.5rem' }} />
                  Elevate your hiring process
                </Typography>
                <Typography
                  pt={1.2}
                  variant={smMediaquery ? 'title20Bold' : 'title28Bold'}
                  pb={smMediaquery ? 1 : 2}
                  sx={{
                    fontWeight: '600',
                    textAlign: smMediaquery ? 'center' : 'left',
                  }}
                >
                  Buy Hiring Tools
                </Typography>
                <Typography
                  pb={smMediaquery ? 2 : 4}
                  variant={smMediaquery ? 'title14Bold' : 'title16Bold'}
                  sx={{
                    fontWeight: '300',
                    lineHeight: '24px',
                    textAlign: smMediaquery ? 'center' : 'left',
                  }}
                >
                  Pay now and start hiring Effortlessly!
                </Typography>
              </>
            )}
            <Stack
              p={smMediaquery ? 3 : 0}
              className={classes.btn__main__payment}
            >
              <Button
                variant='contained'
                backgroundColor='#010103 !important'
                textColor='#fff !important'
                borderRadius='11px'
                fontSize={smMediaquery ? '14px' : '16px'}
                height={smMediaquery ? '56px' : '52px'}
                width={smMediaquery ? '100%' : '273px'}
                onClick={() => handleSubmit()}
                title={smMediaquery ? 'Continue' : 'Pay'}
              />
            </Stack>
            <PaymentForm />
          </Stack>
          <Stack
            pt={smMediaquery ? 2.3 : 0}
            pb={smMediaquery ? 2 : 0}
            sx={{
              borderTop: smMediaquery ? '1px solid #d3d3d3' : null,
              borderBottom: smMediaquery ? '1px solid #d3d3d3' : null,
            }}
            width={smMediaquery ? '100%' : '43%'}
          >
            <Typography
              variant='title16Bold'
              sx={{ fontWeight: '500' }}
              pb={2.4}
            >
              Payment Method
            </Typography>

            <Stack
              p={smMediaquery ? 0 : 2}
              sx={{
                border: smMediaquery ? null : '1px solid #d3d3d3',
                borderRadius: '15px',

                '& .form__group': {
                  '& .MuiFormControlLabel-root': {
                    margin: '0 !important',
                  },
                },
              }}
            >
              <Stack
                pb={2}
                width='100%'
                direction='row'
                alignItems='center'
                sx={{
                  cursor: 'pointer',
                  '& .logo': {
                    height: '31px',
                    marginRight: '0.3rem',
                  },
                  borderBottom: '1px solid #d3d3d3',
                }}
                onClick={(e) => handleCheckBox(1)}
              >
                <FormGroup className='form__group'>
                  <FormControlLabel
                    label=''
                    control={
                      <Checkbox
                        sx={{ padding: '.35rem' }}
                        onChange={(e) => handleCheckBox(1)}
                        icon={
                          <RadioButtonUncheckedIcon sx={{ color: '#d3d3d3' }} />
                        }
                        checkedIcon={
                          <RadioButtonCheckedIcon sx={{ color: '#0aa06a' }} />
                        }
                        checked={checked === 1}
                      />
                    }
                  />
                </FormGroup>
                <Typography
                  sx={{ width: 'calc(100% - 25px)' }}
                  variant='title14Bold'
                >
                  Card Payment
                </Typography>
                <img src={PaymentCard} alt='card' srcset='' className='logo' />
              </Stack>
              <Stack
                py={1.8}
                width='100%'
                direction='row'
                alignItems='center'
                sx={{
                  cursor: 'pointer',
                  '& .logo': {
                    height: '28px',
                    width: '32px',
                    filter: 'grayscale(1)',
                    marginRight: '0.9rem',
                  },
                  borderBottom: '1px solid #d3d3d3',
                }}
                onClick={(e) => handleCheckBox(2)}
              >
                <FormGroup className='form__group'>
                  <FormControlLabel
                    label=''
                    control={
                      <Checkbox
                        sx={{ padding: '.35rem' }}
                        onChange={(e) => handleCheckBox(2)}
                        icon={
                          <RadioButtonUncheckedIcon sx={{ color: '#d3d3d3' }} />
                        }
                        checkedIcon={
                          <RadioButtonCheckedIcon sx={{ color: '#0aa06a' }} />
                        }
                        checked={checked === 2}
                      />
                    }
                  />
                </FormGroup>
                <Typography sx={{ width: 'calc(100% - 25px)' }}>
                  Bank Account
                </Typography>
                <img
                  src={PaymentBank}
                  alt='card'
                  srcset=''
                  className='logo'
                  height='35px !important'
                />
              </Stack>
              <Stack
                py={1.8}
                width='100%'
                direction='row'
                alignItems='center'
                sx={{
                  cursor: 'pointer',
                  '& .logo': {
                    height: '27px',
                    width: '54px',
                  },
                  borderBottom: '1px solid #d3d3d3',
                }}
                onClick={(e) => handleCheckBox(3)}
              >
                <FormGroup className='form__group'>
                  <FormControlLabel
                    label=''
                    control={
                      <Checkbox
                        sx={{ padding: '.35rem' }}
                        onChange={(e) => handleCheckBox(3)}
                        icon={
                          <RadioButtonUncheckedIcon sx={{ color: '#d3d3d3' }} />
                        }
                        checkedIcon={
                          <RadioButtonCheckedIcon sx={{ color: '#0aa06a' }} />
                        }
                        checked={checked === 3}
                      />
                    }
                  />
                </FormGroup>
                <Typography sx={{ width: 'calc(100% - 25px)' }}>
                  Wallets and Others
                </Typography>
                <img
                  src={PaymentWallet}
                  alt='card'
                  srcset=''
                  className='logo'
                />
              </Stack>

              <Stack
                pt={1.8}
                width='100%'
                direction='row'
                alignItems='center'
                sx={{
                  cursor: 'pointer',
                  '& .logo': {
                    height: '38px',
                    width: '54px',
                    marginRight: '0.5rem',
                  },
                }}
                onClick={(e) => handleCheckBox(4)}
              >
                <FormGroup className='form__group'>
                  <FormControlLabel
                    label=''
                    control={
                      <Checkbox
                        sx={{ padding: '.35rem' }}
                        onChange={(e) => handleCheckBox(4)}
                        icon={
                          <RadioButtonUncheckedIcon sx={{ color: '#d3d3d3' }} />
                        }
                        checkedIcon={
                          <RadioButtonCheckedIcon sx={{ color: '#0aa06a' }} />
                        }
                        checked={checked === 4}
                      />
                    }
                  />
                </FormGroup>
                <Typography sx={{ width: 'calc(100% - 17px)' }}>
                  Raast
                </Typography>
                <img
                  src={PaymentPaypro}
                  alt='card'
                  srcset=''
                  className='logo'
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </>
    );
  };

  return (
    <>
      {smMediaquery ? (
        <Drawer
          anchor='bottom'
          open={upgradePopup}
          onClose={() => setUpgradePopup(false)}
          className={classes.upgrade__premium__sm}
          sx={{
            '--Drawer-transitionDuration': upgradePopup ? '1s' : '0.1s',
          }}
        >
          <Content />
        </Drawer>
      ) : (
        <Dialog
          open={upgradePopup}
          onClose={setUpgradePopup}
          className={classes.upgrade__premium}
        >
          <Content />
        </Dialog>
      )}
      {SuccessPopupMain}
    </>
  );
}
