import { useState } from 'react';
import { defaultHeaders, usefetch } from '../helpers/fetch';

export const useCities = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [response, setResponse] = useState(null);
  const [cities, setCities] = useState([]);

  const getCities = async (cityName) => {
    setIsLoading(true);
    const response = await usefetch(
      'cities/getcities',
      'post',
      defaultHeaders(),
      {
        cityName: cityName,
      },
    );

    if (!response.ok) {
      setError(response.error);
    }

    if (response.ok) {
      setResponse((prevData) => [...prevData, response]);
    }

    setIsLoading(false);
    setCities(response?.data);
    return response;
  };

  return { getCities, response, cities, isLoading, error };
};
