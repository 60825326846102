import React, { useState, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import TireRepairIcon from '@mui/icons-material/TireRepair';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Dialog, Stack, Typography, useMediaQuery } from '@mui/material';

import Style from './style';
import Button from '../../../common/Button';
import UpdatePassword from './UpdatePassword';
import Textfield from '../../../common/Textfield';
import VerificationSuccess from './VerificationSuccess';
import { defaultHeaders, headers, usefetch } from '../../../helpers/fetch';
import {
  InputIcon,
  InputIconBlack,
  VerifyEmailIcon,
} from '../../../assets/constants';
import { useAuthContext } from '../../../Auth/useAuthContext';

const Styles = makeStyles((theme) => Style(theme));

export default function OTPVerification({
  toggle,
  setToggle,
  email,
  handleUpdate,
}) {
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  const [fieldErrors, setFieldErrors] = useState('');
  const [isOtpVerified, setIsOtpVerified] = useState([]);
  const [successPopup, setSuccessPopup] = useState(false);
  const [passwordPopup, setPasswordPopup] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [fieldVals, setFieldVals] = useState({
    otp: '',
  });

  const { user, refreshUserData } = useAuthContext();

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setFieldVals({
      ...fieldVals,
      [name]: value,
    });
    setFieldErrors('');
  };

  const verifyOtp = (e, isButton) => {
    if (fieldErrors || e?.target?.value === '') {
      return;
    } else {
      if (e.key === 'Enter' || isButton === 'Y') {
        if (
          fieldVals?.otp?.length < 4 ||
          fieldVals?.otp?.length > 6 ||
          !fieldVals?.otp?.length
        ) {
          setFieldErrors('Enter valid otp');
        } else {
          usefetch('user/verifySeekerOtp', 'post', defaultHeaders(), {
            otp: fieldVals?.otp,
            email: email,
          }).then(function (resp) {
            if (resp?.code === '11') {
              setPasswordPopup(true);
              refreshUserData();
            } else {
              setFieldErrors(resp?.message);
            }
          });
        }
      }
    }
  };

  const sendOtp = () => {
    return usefetch('user/sendOtp', 'post', defaultHeaders(), {
      email: email,
    }).then(function (resp) {
      return resp;
    });
  };

  // useEffect(() => {
  //   if (toggle) {
  //     sendOtp();
  //   }
  // }, []);

  const handleResendOtp = () => {
    sendOtp();
    // () => setSuccessPopup(true)
  };

  const SuccessPopup = successPopup && (
    <VerificationSuccess
      toggle={successPopup}
      setToggle={setSuccessPopup}
      handleUpdate={handleUpdate}
    />
  );

  const UpdatePasswordPopup = (
    <UpdatePassword
      toggle={passwordPopup}
      setToggle={setPasswordPopup}
      setOtpToggle={setToggle}
      email={email}
      otp={fieldVals?.otp}
      handleUpdate={handleUpdate}
    />
  );

  return (
    <Dialog
      open={toggle}
      onClose={setToggle}
      className={classes.main__dialog__verification}
    >
      <Stack
        justifyContent='flex-end'
        direction='row'
        position='absolute'
        right='.5rem'
        top='0.5rem'
      >
        <CloseRoundedIcon
          style={{ cursor: 'pointer' }}
          onClick={() => setToggle(false)}
        />
      </Stack>
      <Stack
        p={1.8}
        px={2.5}
        width='100%'
        height='100%'
        justifyContent='center'
        alignItems='center'
        color='primary.main'
      >
        <VerifyEmailIcon />
        <Typography variant='title20Bold' sx={{ fontWeight: '600' }} pt={1.7}>
          Verify Email
        </Typography>
        <Typography
          variant='title14Bold'
          sx={{ textAlign: 'center', fontWeight: '400', lineHeight: '22px' }}
          pt={1.2}
          pb={0.5}
        >
          We’ve sent an OTP to the email address extracted from your uploaded
          CV. Please enter the OTP to verify your email address.
        </Typography>

        <Typography
          pb={2.5}
          varinat='title14Bold'
          sx={{
            fontWeight: '600',
            width: '100%',
            wordWrap: 'break-word',
            textAlign: 'center',
          }}
        >
          {email}
        </Typography>

        <Stack
          width='100%'
          sx={{
            '& .label__main': {
              fontSize: '18px',
            },
            '& .textfield__global': {
              width: '100%',
              '& .MuiInputBase-root': {
                height: '44px',
                border: 'none',
                borderRadius: '11px',
                '& input': {
                  fontSize: '12px',
                  padding: '.2rem 1rem',
                },
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #d3d3d3 !important', // Default border color
                },
                '&:hover fieldset': {
                  border: '1px solid #010103 !important', // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid #010103 !important', // Border color on focus
                },
              },
              '& .MuiFormHelperText-root': {
                fontSize: '12px !important',
                fontWeight: '400 !important',
              },
            },
          }}
        >
          <Textfield
            placeholder='Enter OTP'
            margin='dense'
            autoFocus
            variant='outlined'
            name='otp'
            value={fieldVals?.otp}
            onChange={(e) => {
              handleChanges(e);
            }}
            onEnter={verifyOtp}
            //   disabled={loading}
            helperText={fieldErrors}
            endAdornment={
              fieldVals?.otp?.length > 3 ? (
                <InputIconBlack
                  style={{ cursor: 'pointer' }}
                  nameSpecific='otp'
                  valueSpecific={fieldVals?.otp}
                  onClick={(e) => verifyOtp(e, 'Y')}
                />
              ) : (
                <InputIcon />
              )
            }
          />
          <Button
            className='w-100'
            color={'primary'}
            title='Confirm'
            variant='contained'
            width='100%'
            height='48px'
            borderRadius='11px'
            fontSize={mediaquery1380 ? '14px' : '16px'}
            fontWeight='500'
            disabled={fieldVals?.otp?.length < 4}
            onClick={(e) => {
              verifyOtp(e, 'Y');
            }}
          />
        </Stack>
        <Typography
          mt={3}
          variant='title14Bold'
          sx={{ display: 'flex', fontWeight: '300' }}
        >
          Didn't Recieve the OTP?&nbsp;
          <Typography
            variant='title14Bold'
            sx={{
              textDecoration: 'underline',
              fontWeight: '300',
              cursor: 'pointer',
            }}
            onClick={handleResendOtp}
          >
            Resend OTP
          </Typography>
        </Typography>
      </Stack>

      {UpdatePasswordPopup}
      {SuccessPopup}
    </Dialog>
  );
}
