import React from 'react';

import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  data: {
    marginTop: 0,
    marginBottom: 5,
    borderTop: '1px solid #acacac',
    borderBottom: '1px solid #acacac',
  },
  heading: {
    fontSize: 13,
    fontWeight: '600',
    color: '#010103',
    paddingBottom: 8,
    paddingTop: 10,
  },
  para: {
    fontSize: 10,
    fontWeight: '300',
    color: '#010103',
    paddingBottom: 7,
    lineHeight: 1.5,
  },
  skills__container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: 100 + '%',
    paddingBottom: 20,
  },
});

export default function Skills({ cvInfo }) {
  return (
    cvInfo?.skills?.length > 0 && (
      <View style={styles.data}>
        <Text style={[styles.heading, { paddingTop: 25, paddingBottom: 15 }]}>
          Skills:
        </Text>
        <View style={styles.skills__container}>
          {cvInfo?.skills?.map((k, i) => {
            return (
              <View style={[{ width: 47 + '%', paddingBottom: 5 }]}>
                <Text style={styles.para}>{k?.skill}</Text>
              </View>
            );
          })}
        </View>
      </View>
    )
  );
}
