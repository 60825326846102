import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import {
  Divider,
  IconButton,
  Stack,
  Typography,
  TextField,
} from '@mui/material';

import EditableTypography from '../../../../../common/EditableTypography';
import { useCvBuilderContext } from '../../../../../context/CvBuilderContext/useCvBuilderContext';

export default function Language({ publicView }) {
  const {
    language,
    languages,
    handleDeleteLanguage,
    handleUpdateCvLang,
    keyEnterLanguage,
    handleChangeLangauge,
    addNewLangState,
    setAddNewLangState,
  } = useCvBuilderContext();

  return (
    <Stack pt={3}>
      <Divider
        sx={{ backgroundColor: '#acacac', mb: 4, borderColor: 'none' }}
      />
      <Stack
        py={1}
        spacing={1.2}
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
      >
        <Typography variant='title18Bold' sx={{ fontWeight: '600' }}>
          Language
        </Typography>
        <Typography
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => setAddNewLangState(true)}
        >
          <AddCircleOutlineOutlinedIcon
            style={{
              marginRight: '4px',
              height: '20px',
              width: '20px',
              display: publicView && 'none',
            }}
          />{' '}
        </Typography>
      </Stack>
      {addNewLangState && (
        <Stack alignItems='center' direction='row'>
          <IconButton
            sx={{ p: 0 }}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            size='small'
          >
            <AddIcon sx={{ fontSize: '15px' }} />
          </IconButton>
          <Stack
            sx={{
              '& .MuiInputBase-root': {
                '& input': {
                  padding: '0.35rem .5rem',
                  fontSize: '14px',
                  fontWeight: '400',
                },
              },
            }}
          >
            <TextField
              value={language}
              onChange={handleChangeLangauge}
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              autoFocus={addNewLangState}
              onBlur={() => setAddNewLangState(false)}
              onKeyDown={keyEnterLanguage}
              placeholder='Add new language'
            />
          </Stack>
        </Stack>
      )}
      <Stack pb={2} width='100%' direction='row' flexWrap='wrap'>
        {languages?.map((k, i) => {
          return (
            <Stack
              key={k + i}
              pb={1.5}
              width='50%'
              alignItems='center'
              direction='row'
              sx={{
                '& span': {
                  minWidth: 'unset !important',
                },
                '&:hover': {
                  '& .cross__icon': {
                    opacity: 1,
                  },
                },
              }}
            >
              {/* <span>&nbsp; • &nbsp;</span> */}

              <EditableTypography
                px={1}
                py={0.6}
                key={k?.id}
                variant='title14Bold'
                minWidth='100%'
                fontWeight='400'
                color='#212121'
                onBlur={(e) => {
                  handleUpdateCvLang({
                    content: e.target.innerText,
                    langId: k?.id,
                  });
                }}
              >
                {k?.language}
              </EditableTypography>
              <IconButton
                sx={{ p: 0, opacity: 0 }}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                size='small'
                className='cross__icon'
                onClick={() => handleDeleteLanguage(k?.id)}
              >
                <CloseIcon sx={{ fontSize: '15px' }} />
              </IconButton>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}
