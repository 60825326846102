import {useState} from "react";
import {headers, defaultHeaders, usefetch} from "../helpers/fetch";

export const useClearVideoInterview = () => {
    const [error, setError] = useState(null);
    const [isClearingInterview, setIsClearingInterview] = useState(null);
    const [response, setResponse] = useState(null);
  
    const clearVideoInterview = async (params) => {
        setIsClearingInterview(true);
        const data = {
            testCode: params?.testCode,
        };

        const resp = await usefetch('videoInterview/clearVidInt', 'POST', defaultHeaders(), data);

        if (resp?.code === '11') {
            
        }

        if (!resp.ok) {
            setError(resp.error);
        }

        if (resp.ok) {
            setResponse((prevData) => [...prevData, resp]);
        }

        setIsClearingInterview(false);
        return resp;
    };

    return { clearVideoInterview, response, isClearingInterview, error };
};