import {useState} from "react";
import {headers, usefetch} from "../../../helpers/fetch";
import {HTTP_METHOD} from "../../../helpers/constants";

export const useGetTypes = () => {
    const [error, setError] = useState(null);
    const [isLoadingTypes, setIsLoadingTypes] = useState(null);
    const [interviewTypes, setInterviewTypes] = useState([]);
    const [creditsUsed, setCreditsUsed] = useState(null);
    const [remainingCredits, setRemainingCredits] = useState(null);

    const endpoint = 'career/getTypes';

    const getInterviewsTypes = async (data) => {
        setIsLoadingTypes(true);

        const response = await usefetch(endpoint, HTTP_METHOD.POST, headers(), data);

        if (!response.ok) {
            setError(response.error);
        }

        setIsLoadingTypes(false);
        setInterviewTypes(response?.data?.types);
        setCreditsUsed(parseInt (response?.data?.credit_used));
        setRemainingCredits(parseInt (response?.data?.credits));
        return response;
    };

    return {  getInterviewsTypes,  interviewTypes,creditsUsed, isLoadingTypes, error,remainingCredits };
};