import { CartContext } from "./CartContext";
import {useCart} from "./useCart";

export const CartProvider = (props) => {
    const {
        children
    } = props;
    const values =useCart();
  return (
    <CartContext.Provider value={values}>
      {children}
    </CartContext.Provider>
  );
};
