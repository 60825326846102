import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { useContext } from 'react';
import { AuthContext } from '../../Auth/AuthContext';
import {createCookie, readCookie} from "../../helpers/CookieHelper";

function stringAvatar(name) {
  const words = name?.split(' ');
  const firstLetter = words?.length > 0 ? words[0][0]?.toUpperCase() : '';
  const secondLetter = words?.length > 1 ? words[1][0]?.toUpperCase() : '';
  const text = `${firstLetter}${secondLetter}`;

  return {
    sx: {
      border: '1px solid #010303',
      bgcolor: 'primary.light',
      height: '54px',
      width: '54px',
      color: 'primary.main',
      fontSize: '24px',
      fontWeight: '600',
    },
    children: text !== 'undefined' ? text : '',
  };
}

export default function Avatars({ userName, src, onClick }) {
 

    return <Avatar onClick={onClick} src={src} {...stringAvatar(userName ?? '')} />;
}
