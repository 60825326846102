import { color } from '@uiw/react-codemirror';

const Style = (theme) => ({
  stepper__stack: {
    '& .MuiStep-root': {
      '& .MuiStepLabel-iconContainer.Mui-active': {
        '& svg': {
          color: '#0aa06a !important',
          '& text': {
            fill: '#fff',
          },
        },
      },
      '& .MuiStepLabel-iconContainer.Mui-disabled': {
        display: 'none',
        '& svg': {
          color: 'rgba(172,172,172,0.4) !important',
          [theme.breakpoints.down('md')]: {
            color: '#fff !important',
          },
          '& text': {
            fill: '#fff',
            [theme.breakpoints.down('md')]: {
              fill: 'rgb(175,162,155)',
            },
          },
        },
      },
      '& .MuiStepLabel-iconContainer.Mui-completed': {
        [theme.breakpoints.down('md')]: {
          // display: 'none',
        },
      },
      '& .MuiStepLabel-labelContainer': {
        '& .MuiStepLabel-label.Mui-active': {
          '& p': {
            color: '#0aa06a',
            fontWeight: '700',
            [theme.breakpoints.down('md')]: {
              color: '#fff',
              fontWeight: '400',
            },
          },
        },
        '& .MuiStepLabel-label.Mui-disabled': {
          display: 'none',
          '& p': {
            color: '#acacac',
            [theme.breakpoints.down('md')]: {
              color: '#fff',
            },
          },
        },
        '& .MuiStepLabel-label.Mui-completed': {
          [theme.breakpoints.down('md')]: {
            // display: 'none',
          },
        },
        '& .MuiStepLabel-label': {
          paddingLeft: '.5rem',
          '& p': {
            fontSize: '18px',
            paddingTop: '2px',
            [theme.breakpoints.down('md')]: {
              fontSize: '14px',
              fontWeight: '400',
            },
          },
        },
      },

      '& .MuiStepLabel-iconContainer': {
        '& svg': {
          height: '30px',
          width: '30px',
          '& text': {
            fontSize: '12px',
            fontWeight: '400',
          },
        },
      },
      '& .MuiStepLabel-root': {
        paddingTop: '0',
        paddingBottom: '0',
        alignItems: 'flex-start',
        [theme.breakpoints.down('md')]: {
          alignItems: 'center',
        },
      },
      [theme.breakpoints.down('md')]: {
        // background:
        //   'linear-gradient(90deg, rgba(217,217,217,0.60) 0%, rgba(115,115,115,0.60) 100% )',
        // backdropFilter: 'blur(112px)',
        // borderRadius: '20px',
        // padding: '17px 20px',
        // '&:has(.Mui-disabled)': {
        //   background:
        //     'linear-gradient(90deg, rgba(10,160,106,0.18)) 0%, rgba(10, 160, 106, 0.18) 100%',
        //   boxShadow: '0px 4px 24px 0px rgba(0,0,0,0.15)',
        // },
      },
    },
    '& .MuiStepConnector-root': {
      marginLeft: '15px',
      opacity: '0 !important',
      '& .MuiStepConnector-line': {
        borderLeftWidth: '2px',
        minHeight: '45px',
      },
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    '& .MuiStepConnector-root.Mui-disabled': {
      '& .MuiStepConnector-line': {
        borderLeftColor: 'rgba(255,255,255, 0.2)',
      },
    },
    '& .MuiStepConnector-root.Mui-completed': {
      '& .MuiStepConnector-line': {
        borderLeftColor: 'rgba(255,255,255, 1)',
      },
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  acive__step__bg__mob: {
    backdropFilter: 'blur(112px)',
    borderRadius: '20px',
    padding: '9px',
    background:
      'linear-gradient(90deg, rgba(10, 160, 106, 0.18) 0%, rgba(10, 160, 106, 0.18) 100%) !important',
    boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.15) !important',
    border: '2px solid #0aa06a !important',
    '& .MuiStepLabel-iconContainer': {
      display: 'none',
    },
  },
  main__dialog__instructions: {
    '& .MuiDialog-paper': {
      width: '380px !important',
      height: '220px',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px',

      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        margin: '10px',
        height: '600px',
        background: 'transparent',
      },
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.9)',
      backdropFilter: 'blur(4px)',
    },
  },
  submit__stack: {
    [theme.breakpoints.down('md')]: {
      background: '#fff',
      position: 'fixed',
      width: '100%',
      left: 0,
      bottom: 0,
    },
  },
  rereord__btn: {
    fontSize: '14px',
    fontWeight: '400',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '256px',
    height: '56px',
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderRadius: '11px',
    color: '#010103',
    textTransform: 'none',
    border: '1px solid #010103',
    [theme.breakpoints.down('md')]: {
      width: '47%',
    },
  },
  rerecord__btn: {
    backgroundColor: '#fff',
    color: '#010103',
  },
  submit__btn: {
    backgroundColor: '#010103',
    color: '#fff',
  },
  permission__dialog: {
    '& .MuiDialog-paper': {
      width: '365px !important',
      height: 'max-content',
      maxHeight: '85vh',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px',
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        margin: '10px',
      },
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  ios__permission__dialog: {
    display: 'flex',
    alignItems: 'flex-end',
    '& .MuiDrawer-paper': {
      width: '100% !important',
      height: 'max-content',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '20px 20px 0 0',
      boxShadow: 'none',
      backgroundColor: '#fff',
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
});
export default Style;
