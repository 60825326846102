const Style = (theme) => ({
  sidebar: {
    width: '480px',
    height: '100vh',
    boxShadow: '0px 0px 74px 0px rgba(0,0,0,0.15)',
    [theme.breakpoints.down('1380')]: {
      width: '412px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  templates: {
    width: 'calc(100% - 480px)',
    [theme.breakpoints.down('1380')]: {
      width: 'calc(100% - 412px)',
    },
  },
  tab__panel: {
    height: '100vh',
    overflowY: 'auto',
  },
});
export default Style;
