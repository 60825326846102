import React, { useState } from 'react';

import { Checkbox, FormControlLabel, Stack } from '@mui/material';

import { CheckboxCheckedIcon, CheckboxIcon } from '../../../assets/constants';

import { JOB_TYPE, JOB_TYPES_LABELS } from '../../../helpers/constants';

const jobTypes = [
  {
    label: JOB_TYPES_LABELS[JOB_TYPE.ONSITE],
    name: JOB_TYPE.ONSITE,
  },
  {
    label: JOB_TYPES_LABELS[JOB_TYPE.HYBRID],
    name: JOB_TYPE.HYBRID,
  },
  {
    label: JOB_TYPES_LABELS[JOB_TYPE.REMOTE],
    name: JOB_TYPE.REMOTE,
  },
];

export default function JobType({
  selectedJobType,
  setSelectedJobType,
  handleChange,
}) {
  return (
    <Stack direction='row'>
      {jobTypes?.map((jobType, i) => {
        return (
          <FormControlLabel
            sx={{ margin: 0, mr: 2 }}
            label={jobType?.label}
            control={
              <Checkbox
                icon={<CheckboxIcon />}
                checkedIcon={<CheckboxCheckedIcon />}
                checked={selectedJobType === jobType?.name}
                onChange={handleChange}
                name={jobType?.name}
              />
            }
          />
        );
      })}
    </Stack>
  );
}
