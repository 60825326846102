const Style = (theme) => ({
  indicator: {
    display: 'none',
  },

  tabs__bar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: '0',
    zIndex: '1',
    position: 'relative',
    borderRadius: '0',
    height: '150px',
    '& .MuiTabScrollButton-root': {
      color: '#fff',
      borderBottom: '0',
      opacity: '1 !important',
    },
    '& .MuiTabScrollButton-root.Mui-disabled': {
      color: 'rgba(109, 125, 139, .3)',
    },
    '& .MuiTabs-scroller': {
      '& .MuiTabs-flexContainer': {
        justifyContent: 'flex-start',
        height: '100%',
        '& .MuiTab-root': {
          margin: '0',
          opacity: '1',
          width: '33.33%',
          minWidth: '33.33%',
          maxWidth: '33.33%',
          fontSize: '18px',
          minHeight: '60px',
          borderBottom: '0',
          borderRadius: '10px',
          padding: '.5rem',
          alignItems: 'center',
          justifyContent: 'flex-start',
          textTransform: 'capitalize',
          color: '#010103',
          border: '1px solid rgb(255, 255, 255) !important',
          [theme.breakpoints.down('1380')]: {
            minHeight: '50px',
            fontSize: '16px',
            padding: 0,
          },
          // [theme.breakpoints.down('md')]: {
          //   width: 'max-content',
          //   minWidth: 'max-content',
          //   maxWidth: 'max-content',
          //   fontSize: '14px',
          //   minHeight: '40px',
          //   padding: '.5rem .9rem',
          // },
          '& > .MuiStack-root': {
            transform: 'scale(0.244)',
            transformOrigin: 'left',
            height: '34rem',
            padding: '0 !important',
            pointerEvents: 'none',
            minWidth: '485px',
          },
        },
        '& .Mui-selected': {
          zIndex: 1,
          border: '1px solid rgb(16,69,255) !important',
          // [theme.breakpoints.down('md')]: {
          //   backgroundColor: '#2b2b2b !important',
          // },
        },
        // [theme.breakpoints.down('md')]: {
        //   flexDirection: 'row',
        // },
      },
    },
    // [theme.breakpoints.down('1380')]: {
    //   height: 'calc(100% - 410px)',
    // },
    // [theme.breakpoints.down('md')]: {
    //   height: 'max-content',
    // },
  },
});
export default Style;
