import {useState} from "react";
import {headers, usefetch} from "../helpers/fetch";

export const useShareCv = () => {
    const [error, setError] = useState(null);
    const [isSharingCv, setIsSharingCv] = useState(null);
    const [response, setResponse] = useState(null);
    const shareCv = async (params) => {
        setIsSharingCv(true);

        const {cvId, email} = params;

        const response = await usefetch('cv/shareCv', 'post', headers(), {
            cvId: cvId,
            email: email
        });

        if (!response.ok) {
            setError(response.error);
        }

        if (response.ok) {
            setResponse((prevData) => [...prevData, response]);
        }

        setIsSharingCv(false);
        return response;
    };

    return { shareCv, response, isSharingCv, error };
};