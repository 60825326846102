import React, { useEffect, useRef, useState } from 'react';

import {
  Menu,
  Stack,
  Divider,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { BlobProvider } from '@react-pdf/renderer';

import {
  EditCVIcon,
  EditIconTable,
  DownloadCVIcon,
  CustomizeCVIcon,
  ChooseTemplateIcon,
} from '../../../assets/constants';
import { formatDate } from '../../../helpers/common';
import { headers, usefetch } from '../../../helpers/fetch';
import Template1Pdf from '../../../pages/PDFs/Templates/Template1/index';
import { updateLanguageInfo } from '../CreateResume/Templates/updateTemplateDataApi';

export default function MoreHorizDropdown({
  i,
  cv,
  open,

  classes,
  anchorEl,
  handleClose,
}) {
  const refMain = useRef();

  const [cvInfo, setCvInfo] = useState([]);
  const [educations, setEducations] = useState([]);
  const [editable, setEditable] = useState(false);
  const [nameEditable, setNameEditable] = useState(cv?.title);
  const navigate = useNavigate();

  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  const handleUpdateTitle = async (e) => {
    var cvTitle = e.target.value;

    return await usefetch('cv/updateCvName', 'post', headers(), {
      cvId: cv?.id,
      cvTitle: cvTitle,
    });
    // 'cvTitle',
    //     e.target.innerText,
    //     cv?.id
  };

  const handleCvEdit = () => {
    navigate(`/cv-edit?cvId=${cv?.encId}`, {
      state: { applyJob: 'N' },
    });
  };

  console.log(cv);

  const getCv = () => {
    if (!cv.encId) {
      return;
    }
    usefetch('cv/getCvInfo', 'post', headers(), {
      cvId: cv.encId,
    }).then(function (resp) {
      setCvInfo(resp?.data?.cvInfo);
      console.log(resp?.data?.cvInfo);
    });
  };

  useEffect(() => {
    getCv();
  }, []);

  return (
    <Menu
      id={`basic-menu${i}`}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      menulistprop={{
        'aria-labelledby': `basic-button${i}`,
      }}
    >
      <Stack p={1} width='255px'>
        <Stack width='100%' direction='row' alignItems='center'>
          <Stack
            width='100%'
            className={classes.name__editable}
            variant='title14Bold'
            direction='row'
          >
            <TextField
              className='text__main'
              variant='standard'
              onChange={(e) => setNameEditable(e.target.value)}
              value={nameEditable}
              autoFocus={editable}
              inputRef={refMain}
              InputProps={{ disableUnderline: true }}
              onBlur={(e) => {
                handleUpdateTitle(e);
              }}
            />

            <EditIconTable
              className='edit__icon'
              onClick={() => refMain.current.focus()}
              style={{
                marginLeft: '0.5rem',
              }}
            />
          </Stack>
        </Stack>
        <Typography
          pt={0.5}
          pb={1.9}
          variant='title12Bold'
          sx={{ fontWeight: '400' }}
        >
          {cv?.updatedAt ? formatDate(cv?.updatedAt) : ''}
        </Typography>

        <Divider sx={{ borderColor: '#e4e4e4', mb: 1.2 }} />
        <MenuItem
          sx={{
            padding: '6px 12px',
            borderRadius: '10px',
          }}
          onClick={() => {
            handleCvEdit();
          }}
        >
          <ChooseTemplateIcon />{' '}
          <Typography pl={1.2} variant='title14Bold' sx={{ fontWeight: '400' }}>
            Choose Template
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{
            padding: '6px 12px',
            borderRadius: '10px',
          }}
          onClick={() => {
            handleCvEdit();
          }}
        >
          <EditCVIcon />
          <Typography pl={1.2} variant='title14Bold' sx={{ fontWeight: '400' }}>
            Edit
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{
            padding: '6px 12px',
            borderRadius: '10px',
          }}
          onClick={() => {
            handleCvEdit();
          }}
        >
          <CustomizeCVIcon />
          <Typography pl={1.2} variant='title14Bold' sx={{ fontWeight: '400' }}>
            Customize
          </Typography>
        </MenuItem>
        <BlobProvider document={<Template1Pdf cvInfo={cvInfo} />}>
          {({ url, ...rest }) => {
            return (
              <MenuItem
                sx={{
                  padding: '6px 12px',
                  borderRadius: '10px',
                }}
              >
                <Stack
                  width='100%'
                  direction='row'
                  alignItems='center'
                  justifyContent='flex-start'
                  sx={{
                    '& a': {
                      textDecoration: 'none !important',
                      color: '#010103 !important',
                    },
                  }}
                >
                  <DownloadCVIcon style={{ width: '19px', height: '19px' }} />

                  <a
                    style={{
                      width: 'calc(100% - 20px)',
                      height: '100%',
                      fontSize: '14px',
                      fontWeight: '400',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      paddingLeft: '9.6px',
                    }}
                    href={url}
                    rel='noreferrer'
                    target='_blank'
                  >
                    Download{' '}
                  </a>
                </Stack>
              </MenuItem>
            );
          }}
        </BlobProvider>
      </Stack>
    </Menu>
  );
}
