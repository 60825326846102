import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Divider,
  IconButton,
  Stack,
  Typography,
  TextField,
} from '@mui/material';

import { updateSkillsInfo } from '../updateTemplateDataApi';
import { useTemplateContext } from '../../../../../context/TemplateContext/useTemplateContext';
import { useCvBuilderContext } from '../../../../../context/CvBuilderContext/useCvBuilderContext';
import EditableTypography from '../../../../../common/EditableTypography';

export default function Skills({ publicView }) {
  const {
    skill,
    handleUpdateCvSkill,
    skills,
    handleDeleteSkill,
    keyEnterSkill,
    handleChangeSkill,
  } = useCvBuilderContext();

  return (
    <Stack py={3}>
      <Stack
        py={1}
        spacing={1.2}
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
      >
        <Typography variant='title18Bold' sx={{ fontWeight: '600' }}>
          Skills
        </Typography>
      </Stack>
      <Divider sx={{ backgroundColor: '#d3d3d3' }} my={2} />
      {skills?.map((k, i) => {
        return (
          <Stack alignItems='center' direction='row' key={k?.id}>
            <span>&nbsp; • &nbsp;</span>
            <EditableTypography
              px={1}
              py={0.6}
              width='max-content'
              key={k + i}
              variant='title14Bold'
              sx={{ fontWeight: '400', color: '#212121' }}
              onBlur={(e) =>
                handleUpdateCvSkill({
                  content: e.target.innerText,
                  skillId: k?.id,
                })
              }
            >
              {k?.skill}
            </EditableTypography>
            <IconButton
              sx={{ p: 0 }}
              disableRipple
              disableFocusRipple
              disableTouchRipple
              size='small'
            >
              <CloseIcon
                onClick={() => handleDeleteSkill(k?.id)}
                sx={{ fontSize: '15px', display: publicView && 'none' }}
              />
            </IconButton>
          </Stack>
        );
      })}
      <Stack
        alignItems='center'
        direction='row'
        sx={{
          display: publicView && 'none',
        }}
      >
        <IconButton
          sx={{ p: 0 }}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          size='small'
        >
          <AddIcon sx={{ fontSize: '15px' }} />
        </IconButton>
        <Stack
          sx={{
            '& .MuiInputBase-root': {
              '& input': {
                padding: '0.35rem .5rem',
                fontSize: '14px',
                fontWeight: '400',
              },
            },
          }}
        >
          <TextField
            value={skill}
            onChange={handleChangeSkill}
            variant='standard'
            InputProps={{
              disableUnderline: true,
            }}
            onKeyDown={keyEnterSkill}
            placeholder='Add new skill'
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
