const Style = (theme) => ({
  main__container: {
    height: 'calc(100vh - 44px)',
  },
  drawer__job__details: {
    width: '100vw',
  },
  template__view: {
    border: '2px solid #d6d6d6',
    borderRadius: '12px',
    height: 'calc(100vh - 275px)',
    width: '100%',
    overflow: 'hidden',
    pointerEvents: 'none !important',
    '& > .MuiStack-root': {
      transform: 'scale(0.244)',
      transformOrigin: 'top left',
      height: '34rem',
      padding: '0 !important',
      pointerEvents: 'none !important',
      minWidth: '603px',
    },
  },
});
export default Style;
