import React, { useState, useContext, useRef } from 'react';

import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';

import Style from './style';
import MenubarDrawer from '../MenubarDrawer';
import Button from '../../../../common/Button';
import Avatars from '../../../../common/Avatar';
import TooltipBlack from '../../../../common/Tooltip';
import { AuthContext } from '../../../../Auth/AuthContext';
import { LogoWhite, LogoutIcon } from '../../../../assets/constants';

const Styles = makeStyles((theme) => Style(theme));

export default function Sidebar({
  allTabs,
  activeTab,
  a11yProps,
  handleChange,
  userInfo,
  handleChatFocus,
}) {
  const classes = Styles();
  const theme = useTheme();
  const navigate = useNavigate();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaQuery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  const [showDropdown, setShowDropdown] = useState(false);
  const [menubarDrawer, showMenubarDrawer] = useState(false);

  const fileRef = useRef(null);
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate('/signup');
  };
  const { user, updateProfileImage } = useContext(AuthContext);

  return (
    <div className={classes.side__bar}>
      <Stack
        pb={smMediaquery ? 5 : 0}
        width='100%'
        direction={smMediaquery ? 'row' : 'column'}
        justifyContent={smMediaquery ? 'space-between' : 'flex-start'}
        alignItems={smMediaquery ? 'center' : 'flex-start'}
      >
        <LogoWhite
          style={{
            height: smMediaquery ? '22px' : mediaQuery1380 ? '25px' : '30px',
            width: smMediaquery ? '140px' : mediaQuery1380 ? '180px' : '200px',
          }}
        />
        <Stack
          width={smMediaquery ? 'max-content' : '100%'}
          alignItems='center'
          sx={{ color: 'primary.light' }}
          pb={smMediaquery ? 0 : 5}
        >
          <Stack
            width='100%'
            alignItems='center'
            justifyContent='space-between'
            sx={{ flexDirection: { xs: 'row', md: 'column' } }}
          >
            <Stack
              position='relative'
              onClick={() => {
                smMediaquery && setShowDropdown(!showDropdown);
              }}
              sx={{
                '& .MuiAvatar-root': {
                  height: smMediaquery ? '44px !important' : '90px !important',
                  width: smMediaquery ? '44px !important' : '90px !important',
                  fontSize: smMediaquery
                    ? '18px !important'
                    : '25px !important',
                },
              }}
            >
              {smMediaquery ? (
                <Stack
                  color='#010103 !important'
                  sx={{
                    '& svg': {
                      color: '#fff',
                    },
                  }}
                  onClick={() => showMenubarDrawer(true)}
                >
                  <Avatars userName={user?.full_name} src={user?.image} />
                </Stack>
              ) : (
                <>
                  <input
                    type='file'
                    accept={'.jpg,.jpeg,.png'}
                    hidden
                    ref={fileRef}
                    name='file'
                    onChange={(e) => {
                      updateProfileImage(e.target.files[0]);
                    }}
                  />
                  <Avatars
                    onClick={() => {
                      fileRef.current.click();
                    }}
                    userName={user?.full_name}
                    src={user?.image}
                  />
                </>
              )}
            </Stack>
          </Stack>
          <Stack
            width='100% '
            alignItems='center'
            sx={{
              display: { xs: 'none', md: 'flex' },
              '& .tooltip__ellipsis': {
                width: '100%',
              },
            }}
          >
            <Typography
              variant='title20Bold'
              sx={{ fontWeight: '600' }}
              pt={2}
              pb={0.7}
            >
              {userInfo?.full_name}
            </Typography>

            <TooltipBlack arrow bgWhite title={userInfo?.email}>
              <Typography
                pb={3}
                variant='title16Bold'
                sx={{
                  textAlign: 'center',
                  fontWeight: '500',
                  width: 'calc(100% - 10px)',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                color='primary.light'
              >
                {userInfo?.email}
              </Typography>
            </TooltipBlack>

            <Button
              title='Create Customized CV'
              variant='contained'
              borderStyle='1px solid #fff !important'
              backgroundColor='#010103 !important'
              height={mediaQuery1380 ? '45px' : '52px'}
              width={'100%'}
              fontSize={mediaQuery1380 ? '14px' : '16px'}
              borderRadius='10px'
              onClick={() => handleChatFocus()}
            />
          </Stack>
        </Stack>
      </Stack>
      <Tabs
        orientation='vertical'
        // variant='scrollable'
        className={clsx(classes.tabs__bar)}
        value={activeTab}
        onChange={handleChange}
        aria-label='basic tabs example'
        classes={{
          indicator: classes.indicator,
        }}
      >
        {allTabs?.map(
          (tab, key) =>
            tab.title && (
              <Tab
                label={tab.title}
                key={tab.title}
                icon={tab.icon}
                iconPosition='start'
                {...a11yProps(key++)}
              />
            ),
        )}
      </Tabs>
      <Stack
        sx={{ display: { xs: 'none', md: 'flex' } }}
        direction='row'
        className={classes.logout__tab}
        onClick={() => handleLogout()}
      >
        <LogoutIcon style={{ marginRight: '14px' }} />
        <Typography variant='title14Bold' sx={{ fontWeight: '400' }}>
          Logout
        </Typography>
      </Stack>
      <MenubarDrawer toggle={menubarDrawer} setToggle={showMenubarDrawer} />
    </div>
  );
}
