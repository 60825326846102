import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Divider,
  IconButton,
  Stack,
  Typography,
  TextField,
} from '@mui/material';

import EditableTypography from '../../../../../common/EditableTypography';
import { useCvBuilderContext } from '../../../../../context/CvBuilderContext/useCvBuilderContext';

export default function Language({ publicView }) {
  const {
    language,
    languages,
    handleDeleteLanguage,
    handleUpdateCvLang,
    keyEnterLanguage,
    handleChangeLangauge,
  } = useCvBuilderContext();

  return (
    <Stack py={3}>
      <Stack
        py={1}
        spacing={1.2}
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
      >
        <Typography variant='title18Bold' sx={{ fontWeight: '600' }}>
          Language
        </Typography>
      </Stack>
      <Divider sx={{ backgroundColor: '#d3d3d3' }} my={2} />
      {languages?.map((k, i) => {
        return (
          <Stack alignItems='center' key={k?.id} direction='row'>
            <span>&nbsp; • &nbsp;</span>
            <EditableTypography
              paddingX={1}
              paddingY={0.6}
              width='max-content'
              variant='title14Bold'
              fontWeight='400'
              color='#212121'
              onBlur={(e) =>
                handleUpdateCvLang({
                  content: e.target.innerText,
                  langId: k?.id,
                })
              }
            >
              {k?.language}
            </EditableTypography>
            <IconButton
              sx={{ p: 0 }}
              disableRipple
              disableFocusRipple
              disableTouchRipple
              size='small'
              onClick={() => handleDeleteLanguage(k?.id)}
            >
              <CloseIcon
                sx={{ fontSize: '15px', display: publicView && 'none' }}
              />
            </IconButton>
          </Stack>
        );
      })}
      <Stack
        alignItems='center'
        direction='row'
        sx={{ display: publicView && 'none' }}
      >
        <IconButton
          sx={{ p: 0 }}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          size='small'
        >
          <AddIcon sx={{ fontSize: '15px' }} />
        </IconButton>
        <Stack
          sx={{
            '& .MuiInputBase-root': {
              '& input': {
                padding: '0.35rem .5rem',
                fontSize: '14px',
                fontWeight: '400',
              },
            },
          }}
        >
          <TextField
            value={language}
            onChange={handleChangeLangauge}
            variant='standard'
            InputProps={{
              disableUnderline: true,
            }}
            onKeyDown={keyEnterLanguage}
            placeholder='Add new language'
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
