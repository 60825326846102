import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { makeStyles, useTheme } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Drawer,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

import Style from './style';
import Button from '../../../common/Button';
import CreditsHisotryTab from './CreditsHistoryTab';
import { CartContext } from '../../Packages/Cart/CartContext';
import {
  AccountOverviewIcon,
  AccountOverviewIconDark,
} from '../../../assets/constants';

const Styles = makeStyles((theme) => Style(theme));

export default function AccountOverviewDrawer({
  darktheme,
  isFromMenu,
  isFromMenubarDrawer,
}) {
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaQuery1380 = useMediaQuery(theme.breakpoints.down('1380'));
  const navigate = useNavigate();

  const { accountDrawerOpen, setAccountDrawerOpen } = useContext(CartContext);

  const handleClose = () => {
    setAccountDrawerOpen(false);
  };

  return (
    <>
      <Stack>
        {isFromMenubarDrawer ? null : isFromMenu ? (
          <Typography
            onClick={() => {
              setAccountDrawerOpen(true);
            }}
            variant='title16Bold'
            sx={{ fontWeight: '400' }}
          >
            Account Overview
          </Typography>
        ) : (
          <Button
            onClick={() => {
              setAccountDrawerOpen(true);
            }}
            title='Account Overview'
            endIcon={
              darktheme ? (
                <AccountOverviewIconDark />
              ) : (
                <AccountOverviewIconDark />
              )
            }
            fontWeight='500'
            borderRadius={smMediaquery ? '8px' : '11px'}
            fontSize={smMediaquery ? '12px' : '14px'}
            variant='outlined'
            height={mediaQuery1380 ? '40px' : '50px'}
            textColor={darktheme ? '#fff' : '#010103 '}
            backgroundColor={'transparent !important'}
            borderStyle={
              darktheme
                ? '2px solid #ffffffb0 !important'
                : '1px solid #010103 !important'
            }
            disableLabelPadding
          />
        )}
      </Stack>
      <Drawer
        anchor='right'
        open={accountDrawerOpen}
        onClose={handleClose}
        className={classes.drawer__main}
        sx={{
          '--Drawer-transitionDuration': accountDrawerOpen ? '1s' : '0.2s',
          '& .MuiDrawer-paper': {
            overflowY: 'auto',
          },
        }}
      >
        <Stack px={3} py={2.5} width='100%'>
          <Stack sx={{ cursor: 'pointer' }} onClick={handleClose}>
            <Typography
              variant='title16Bold'
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: '400',
              }}
            >
              <ArrowBackIcon sx={{ marginRight: 1.2 }} />
              Back
            </Typography>
          </Stack>
          <Typography
            pt={4}
            pb={1}
            variant={'title24Bold'}
            sx={{ fontWeight: '700' }}
            color='primary.main'
          >
            Account Overview
          </Typography>
          <Typography
            variant={'title14Bold'}
            sx={{ fontWeight: '400' }}
            color='primary.main'
            pb={2}
          >
            Check your remaining credits and view your purchase history here.
          </Typography>
          <Button
            variant='contained'
            backgroundColor='#010103 !important'
            textColor='#fff !important'
            borderRadius='11px'
            fontSize='16px'
            height='57px'
            onClick={() => {
              setAccountDrawerOpen(false);
              navigate('/packages');
            }}
            title='Buy Credits'
          />

          <CreditsHisotryTab isFromMenubarDrawer={isFromMenubarDrawer} />
        </Stack>
      </Drawer>
    </>
  );
}
