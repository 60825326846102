import React from 'react';

import { makeStyles } from '@mui/styles';
import { Skeleton, Stack, TableCell, TableRow } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  table__first__column: {
    minWidth: '300px',
  },
  table__column: {
    width: 'calc(100% - 180px / 5)',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% / 4)',
    },
  },
  last__table__column: {
    minWidth: '180px',
  },
  table__body__row: {
    borderBottom: '1px solid #e4e4e4',
    paddingTop: '30px',
    paddingBottom: '30px',
  },
}));
const ListingLoader = ({ count, rowsCount, lengthRow, hideLastDot }) => {
  const classes = useStyles();
  const lengthRows = lengthRow
    ? lengthRow
    : count?.filter((_, i) => i !== count.length - 1);
  const mapCount = rowsCount ? rowsCount : [...Array(5)];
  return (
    <>
      {mapCount.map((k, i) => {
        return (
          <Stack
            direction='row'
            alignItems='center'
            className={classes.table__body__row}
            px={5}
            key={i++}
          >
            {lengthRows?.map((m, index) => {
              return (
                <Stack className={classes.table__column} key={index++}>
                  <Skeleton animation='wave' height={20} width='80%' />
                </Stack>
              );
            })}
            {hideLastDot ? null : (
              <Stack direction='row' className={classes.last__table__column}>
                <Skeleton
                  animation='wave'
                  variant='circle'
                  height={20}
                  width={20}
                />
              </Stack>
            )}
          </Stack>
        );
      })}
    </>
  );
};

export default ListingLoader;
