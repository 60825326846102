import {useState} from "react";
import {headers, usefetch} from "../helpers/fetch";

export const useGetPayments = () => {
    const [error, setError] = useState(null);
    const [isPaymentsLoading, setIsPaymentsLoading] = useState(null);
    const [response, setResponse] = useState(null);
    const [payments, setPayments] = useState([]);

    const getPayments = async () => {
        setIsPaymentsLoading(true);

        const response = await usefetch('career/getPayments', 'post', headers(), {});

        if (!response.ok) {
            setError(response.error);
        }

        if (response.ok) {
            setResponse((prevData) => [...prevData, response]);
        }

        setIsPaymentsLoading(false);
        setPayments(payments => response?.data?.payments);
        return response;
    };

    return { getPayments, response, payments, setPayments, isPaymentsLoading, error };
};