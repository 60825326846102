
import {useAuth} from "./useAuth";
import {AuthContext} from "./AuthContext";

export const AuthProvider = (props) => {
    const {
        children,
    } = props;


    let values = useAuth();

    // useEffect(() => {
    //     values?.getCodingDictionary();
    //     values?.getCodingQuestions({jid: jid}).then((resp) => {
    //         values?.setInviteAll(resp?.data?.inviteAll === "Y");
    //     });
    // }, []);

    return (
        <AuthContext.Provider value={values}>
            {children}
        </AuthContext.Provider>
    );
}