import React from 'react';

import { makeStyles } from '@mui/styles';
import { Drawer } from '@mui/material';

import Style from './style';
import Content from './content';

const Styles = makeStyles((theme) => Style(theme));

export default function SelectQuestionsDialog({ toggle, setToggle }) {
  const classes = Styles();

  return (
    <>
      <Drawer
        anchor='bottom'
        open={toggle}
        className={classes.select__question__dialog}
        sx={{
          '--Drawer-transitionDuration': toggle ? '1s' : '0.1s',
        }}
      >
        <Content setToggle={setToggle} />
      </Drawer>
    </>
  );
}
