import { useState, useRef, useEffect, useCallback } from "react";
import { useOptimalMediaFormat } from "./useOptimalMediaFormat";
import { getLocalStorage, isEmpty, setLocalStorage } from "../common";

// Define status messages as constants
const StatusMessages = {
  MEDIA_ABORTED: "media_aborted",
  PERMISSION_DENIED: "permission_denied",
  NO_MEDIA_FOUND: "no_specified_media_found",
  MEDIA_IN_USE: "media_in_use",
  INVALID_MEDIA_CONSTRAINTS: "invalid_media_constraints",
  NO_CONSTRAINTS: "no_constraints",
  RECORDER_ERROR: "recorder_error",
  IDLE: "idle",
  ACQUIRING_MEDIA: "acquiring_media",
  DELAYED_START: "delayed_start",
  RECORDING: "recording",
  STOPPING: "stopping",
  STOPPED: "stopped",
  PAUSED: "paused",
};

// Define recorder errors as constants
const RecorderErrors = {
  ABORT_ERROR: "media_aborted",
  NOT_ALLOWED_ERROR: "permission_denied",
  NOT_FOUND_ERROR: "no_specified_media_found",
  NOT_READABLE_ERROR: "media_in_use",
  OVERCONSTRAINED_ERROR: "invalid_media_constraints",
  TYPE_ERROR: "no_constraints",
  NONE: "",
  NO_RECORDER: "recorder_error",
};

export const useWebRTCRecorder = ({
  video = true,
  audio = true,
  timeSlice = null, // timeSlice is optional
  mimeType = null,
  facingMode = "user",
  stopStreamsOnStop = true,
  onDataAvailable = null,
}) => {
  const [status, setStatus] = useState(StatusMessages.IDLE);
  const [mediaBlobUrl, setMediaBlobUrl] = useState(null);
  const [previewStream, setPreviewStream] = useState(null);
  const [error, setError] = useState(RecorderErrors.NONE);
  const mediaRecorderRef = useRef(null);
  const mediaChunks = useRef([]);
  const mediaStreamRef = useRef(null);
  // const [chunkIndex, setChunkIndex] = useState(0);
  // const [totalChunks, setTotalChunks] = useState(0);
  
  const chunkQueue = useRef([]);
  const isUploading = useRef(false);
  const MIN_CHUNK_SIZE = 3 * 1024 * 1024; // X MB in bytes

  const { mediaFormat, quality, checkSupportedMediaFormats } =
    useOptimalMediaFormat();

  // Function to get user media (WebRTC stream)
  const getMediaStream = useCallback(async () => {
    setStatus(StatusMessages.ACQUIRING_MEDIA);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: video ? video : false,
        audio: audio,
      });
      setPreviewStream(stream);
      mediaStreamRef.current = stream;
      // setStatus(StatusMessages.IDLE);
    } catch (err) {
      handleError(err);
    }
  }, [video, audio, facingMode]);

  // Handle errors from accessing media devices
  const handleError = (err) => {
    switch (err.name) {
      case "AbortError":
        setError(RecorderErrors.ABORT_ERROR);
        setStatus(StatusMessages.MEDIA_ABORTED);
        break;
      case "NotAllowedError":
        setError(RecorderErrors.NOT_ALLOWED_ERROR);
        setStatus(StatusMessages.PERMISSION_DENIED);
        break;
      case "NotFoundError":
        setError(RecorderErrors.NOT_FOUND_ERROR);
        setStatus(StatusMessages.NO_MEDIA_FOUND);
        break;
      case "NotReadableError":
        setError(RecorderErrors.NOT_READABLE_ERROR);
        setStatus(StatusMessages.MEDIA_IN_USE);
        break;
      case "OverconstrainedError":
        setError(RecorderErrors.OVERCONSTRAINED_ERROR);
        setStatus(StatusMessages.INVALID_MEDIA_CONSTRAINTS);
        break;
      case "TypeError":
        setError(RecorderErrors.TYPE_ERROR);
        setStatus(StatusMessages.NO_CONSTRAINTS);
        break;
      default:
        setError(RecorderErrors.NO_RECORDER);
        setStatus(StatusMessages.RECORDER_ERROR);
    }
  };

  // Start recording with or without time slice
  const startRecording = useCallback(async () => {
    if (!mediaStreamRef.current) {
      // Re-fetch the media stream if not available
      await getMediaStream();
    }

    try {
      setStatus(StatusMessages.RECORDING);
      // const options = { mediaFormat };
      const options = { };
      console.log("options",options);
      
      mediaRecorderRef.current = new MediaRecorder(
        mediaStreamRef.current,
        options
      );

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          mediaChunks.current.push(event.data); // Save chunk
          if (onDataAvailable && timeSlice) {
            let tot = getLocalStorage("totChunk");
            if(isEmpty(tot)){
              tot = 0;
            }else{
              tot = parseInt(tot);
            }
            chunkQueue.current.push({ chunk: event.data, index: tot });
            setLocalStorage("totChunk", parseInt(tot) + parseInt(1));
            uploadNextChunk();
          }
        }
      };

      mediaRecorderRef.current.onstop = () => {
        // When timeSlice is not passed, return the full video on stop
        console.log("mediaChunks.current[0]?.type)",mediaChunks.current[0]?.type);
        const blob = new Blob(mediaChunks.current, { type :mediaChunks.current[0]?.type});
        const url = URL.createObjectURL(blob);
        setMediaBlobUrl(url);
        mediaChunks.current = []; // Clear the chunks after saving
      };

      if (timeSlice) {
        mediaRecorderRef.current.start(timeSlice); // Start recording with time slices
      } else {
        mediaRecorderRef.current.start(); // Start recording normally (full video on stop)
      }
    } catch (err) {
      handleError(err);
    }
  }, [timeSlice, onDataAvailable]);

  // Stop recording
  const stopRecording = useCallback(() => {
    console.log("stopRecording", mediaRecorderRef, "status", status);
    if (mediaRecorderRef.current && status === StatusMessages.RECORDING) {
      mediaRecorderRef.current.stop();
      setStatus(StatusMessages.STOPPING);

      if (stopStreamsOnStop && mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop()); // Stop the tracks
        mediaStreamRef.current = null; // Clear the stream reference
      }

      setStatus(StatusMessages.STOPPED);
    }
  }, [status, stopStreamsOnStop]);

  // Clear the media blob URL
  const clearBlobUrl = useCallback(() => {
    if (mediaBlobUrl) {
      URL.revokeObjectURL(mediaBlobUrl);
      setMediaBlobUrl(null);
    }
  }, [mediaBlobUrl]);

  const stopAll = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setStatus(StatusMessages.STOPPING);

      if (stopStreamsOnStop && mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop()); // Stop the tracks
        mediaStreamRef.current = null; // Clear the stream reference
      }
      setStatus(StatusMessages.STOPPED);
    }
  };

  const uploadNextChunk = useCallback(async () => {
    if (chunkQueue.current.length > 0 && !isUploading.current) {
      isUploading.current = true;
      const { chunk, index } = chunkQueue.current[0];
      
      try {
        await onDataAvailable(chunk);
        chunkQueue.current.shift();
      } catch (error) {
        console.error("Chunk upload failed:", error);
      } finally {
        isUploading.current = false;
        uploadNextChunk(); // Try to upload next chunk
      }
    }
  }, [onDataAvailable]);

  // Request user media on mount
  useEffect(() => {
    // getMediaStream();
    return () => {
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    checkSupportedMediaFormats();
  }, [startRecording, stopRecording]);

  return {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewStream,
    clearBlobUrl,
    error,
    mediaFormat,
    getMediaStream,
    stopAll,
  };
};
