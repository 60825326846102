const Style = (theme) => ({
  divider__root: {
    margin: 0,
    textAlign: 'left',
    width: 'calc(100% )',
    '&::before': {
      content: 'none',
      width: '0 !important',
    },
  },
});
export default Style;
