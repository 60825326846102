import React from 'react';

import { Stack, Typography } from '@mui/material';

import { DeleteIconCV, MagicWrite } from '../../../../assets/constants';

export default function QuickActions({
  top,
  right,
  left,
  bottom,
  editable,
  deleteable,
}) {
  return (
    <Stack
      position='absolute'
      top={top ? top : '-3rem'}
      direction='row'
      alignItems='center'
      py={1.3}
      px={1.5}
      sx={{
        background: '#fff',
        boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.14)',
        borderRadius: '6px',
      }}
    >
      {editable && (
        <Typography
          sx={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            '& svg': {
              marginRight: '.3rem',
            },
          }}
          variant='title12Bold'
        >
          <MagicWrite /> Magic Write
        </Typography>
      )}
      {deleteable && editable && (
        <Stack
          mx={1.3}
          py={1.4}
          height='100%'
          sx={{ borderLeft: '1px solid #e4e4e4' }}
        />
      )}
      {deleteable && <DeleteIconCV style={{ cursor: 'pointer' }} />}
    </Stack>
  );
}
