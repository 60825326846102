import React from 'react';

import { makeStyles } from '@mui/styles';
import { Dialog, Stack, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import Style from './style';

const Styles = makeStyles((theme) => Style(theme));

export default function FreeAttemptsUsedPopup({ toggle, setToggle }) {
  const classes = Styles();

  return (
    <Dialog
      open={toggle}
      onClose={() => setToggle(false)}
      className={classes.main__dialog__attempts}
    >
      <Stack
        justifyContent='flex-end'
        direction='row'
        sx={{ position: 'absolute', right: '0.5rem', top: '0.5rem' }}
      >
        <CloseRoundedIcon
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setToggle(false);
          }}
        />
      </Stack>
      <Stack
        p={1}
        pt={0}
        height='100%'
        justifyContent='center'
        alignItems='center'
        color='primary.main'
      >
        <Typography pt={0.5} sx={{ fontWeight: '600' }} variant={'title20Bold'}>
          Oh Snap
        </Typography>
        <Typography
          variant='title14Bold'
          sx={{ textAlign: 'center', fontWeight: '400', lineHeight: '22px' }}
          pt={1.2}
          pb={0.5}
          px={1.7}
        >
          You’ve used all your free interview attempts. To continue practicing
          and improving, please purchase more credits for additional attempts
        </Typography>
      </Stack>
    </Dialog>
  );
}
