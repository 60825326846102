import React from 'react';

import { styled } from '@mui/material/styles';
import {
  Stack,
  Switch,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';

export default function SwitchMain({
  label,
  checked,
  onChange,
  disabled,
  islangaugeswitch,
}) {
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName='.Mui-focusVisible'
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 43,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 3,
      color: islangaugeswitch ? '#0aa06a' : '#a1a1a1',
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#0aa06a',
        '& + .MuiSwitch-track': {
          backgroundColor: '#fff',
          opacity: 1,
          border: '1px solid #d3d3d3',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: islangaugeswitch ? '#0aa06a' : '#b12b2b',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 20,
      height: 20,
      boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#fff',
      opacity: 1,
      border: '1px solid #d3d3d3',
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));
  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{
        '& .MuiSwitch-switchBase.Mui-disabled': {
          '& .MuiSwitch-thumb': {
            color: '#a1a1a1 !important',
          },
        },
      }}
    >
      {label ? (
        <Typography pr={1.2} variant='title14Bold' color='primary.main'>
          {label}
        </Typography>
      ) : null}
      <FormGroup>
        <FormControlLabel
          sx={{ m: 0 }}
          control={
            <IOSSwitch
              sx={{ m: 0 }}
              checked={checked}
              onChange={onChange}
              disabled={disabled}
              islangaugeswitch={islangaugeswitch}
            />
          }
        />
      </FormGroup>
    </Stack>
  );
}
