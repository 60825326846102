import React, { useRef, useState, useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import { Editor } from '@tinymce/tinymce-react';
import { ClickAwayListener, Stack, Typography } from '@mui/material';
import Style from './style';

const Styles = makeStyles((theme) => Style(theme));

export const EditableContentBox = (props) => {
    const {content, handleFocusOut, placeholder} = props;

  const classes = Styles();
  const editorRef = useRef(null);

  const [callable, setCallabale] = useState(false);
  const [editorContent, setEditorContent] = useState(null);
  const [contentEditable, setContentEditable] = useState(false);

  useEffect(() => {
    if (!editorContent && content) {
      setEditorContent(content);
    }
  }, [content]);

  const saveChanges = () => {
    if (callable) {
      let newValue = editorRef.current?.getContent().trimStart();
      newValue = newValue.trim().split(/ +/).join(' ');
      setEditorContent(newValue);
      if (newValue != content) {
        handleFocusOut(newValue);
      }

      //   if (title === 'Description:') {
      //     updateJobField(id, 'description', newValue);
      //   } else if (title === 'Responsibilities:') {
      //     updateJobField(id, 'responsibilities', newValue);
      //   }
    }
    setTimeout(() => {
      setContentEditable(false);
    }, 100);
    // setContentEditable(false);
  };

  return (
    <Stack>
      <ClickAwayListener onClickAway={saveChanges}>
        <Stack
          sx={{
            padding: contentEditable ? '0px' : '0px',
            '& p': {
              margin: 0,
            },
          }}
        >
          <Stack
            sx={{
                display: contentEditable ? 'block' : 'none',
              '& .tox-tinymce': {
                borderRadius: '0',
                border: '2px solid #256ef4',
                overflow: 'unset',
                height: '180px !important',
                '&::-webkit-scrollbar': {
                  width: '5px !important',
                  height: '5px !important',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'transparent !important',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#010103 !important',
                  borderRadius: '16px !important',
                },
              },
              '& .tox .tox-editor-container': {
                overflow: 'unset',
              },
              '& .tox-editor-header': {
                position: 'absolute',
                top: '-4rem',
                left: '220px',
                boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.14) !important',
                borderRadius: '6px',
                '& .tox-toolbar__group': {
                  '& .tox-tbtn, & .tox-split-button': {
                    // margin: 0,
                  },
                  '& .tox-split-button__chevron': {
                    display: 'none',
                  },
                  '&:first-of-type': {
                    paddingRight: 0,
                    borderRight: '1px solid #e4e4e4 !important',
                  },
                  '&:nth-child(2)': {
                    paddingLeft: '0px',
                  },
                },
              },
              '& .tox-sidebar-wrap': {
                borderRadius: '13px',
                overflow: 'hidden',
              },

              '& .tox .tiny_class_body': {
                height: '150px',
                overflowY: 'auto',
              },
            }}
          >
            <Editor
              tinymceScriptSrc={'/tinymce/tinymce.min.js'}
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={editorContent}
              init={{
                border: '1px solid red',
                menubar: false,
                branding: false,
                  placeholder: placeholder,
                statusbar: false,
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'anchor',
                  'insertdatetime',
                  'media',
                  'table',
                  'preview',
                ],
                toolbar: 'bold italic | bullist numlist',
                list_indent_on_tab: 'false',
                setup: (editor) => {
                  editor.on('keydown', function (e, evt) {
                    if (
                      e.keyCode === 9 &&
                      (editor.selection.getNode().nodeName === 'ul' ||
                        editor.selection.getNode().nodeName === 'ol')
                    ) {
                      e.preventDefault();
                    }
                  });
                },

                content_style:
                  "@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap'); body{ font-family: 'Outfit', sans-serif !important; font-size: 14px; margin: 0; padding: 8px; line-height:22px; font-weight:300;} p{margin:0;} ol{margin:0; padding:0;} ul{margin:0; padding:0;} li{margin-left:1.2rem;}",
              }}
            />
          </Stack>

          <Typography
            variant='title14Bold'
            className={classes.outlineEditable}
            sx={{
              display: contentEditable ? 'none' : 'block',
              fontWeight: '400',
              lineHeight: '22px',
              '& ul, & ol': {
                listStylePosition: 'outside',
                '& li': {
                  marginLeft: '1.2rem',
                },
              },
            }}
            color='primary.pale'
            dangerouslySetInnerHTML={{__html: editorContent ? editorContent : placeholder}}
            onClick={(e) => {
              setContentEditable(true);
              setCallabale(true);
            }}
          />
        </Stack>
      </ClickAwayListener>
    </Stack>
  );
};
