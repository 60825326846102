import React from 'react';

import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  data: {
    marginTop: 0,
  },
  heading: {
    fontSize: 13,
    fontWeight: '600',
    color: '#010103',
    paddingBottom: 8,
    paddingTop: 10,
  },
  para: {
    fontSize: 10,
    fontWeight: '300',
    color: '#010103',
    paddingBottom: 10,
    lineHeight: 1.5,
  },
});

export default function Summary({ parseHtmlToPdfComponents, cvInfo }) {
  return (
    <View style={styles.data}>
      <Text style={[styles.heading, { paddingTop: 25, paddingBottom: 15 }]}>
        Summary:
      </Text>
      <View style={styles.para}>
        {parseHtmlToPdfComponents(cvInfo?.summary)}
      </View>
    </View>
  );
}
