import React, { useEffect, useState } from 'react';

import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  data: {
    marginTop: 0,
    marginBottom: 5,
    borderTop: '1px solid #acacac',
  },
  heading: {
    fontSize: 13,
    fontWeight: '600',
    color: '#010103',
    paddingBottom: 8,
    paddingTop: 10,
  },
  para: {
    fontSize: 10,
    fontWeight: '300',
    color: '#010103',
    paddingBottom: 7,
    lineHeight: 1.5,
  },
});

export default function Education({ cvInfo }) {
  const formatDate = (date) => {
    if (date?.toLowerCase() === 'present') {
      return 'Present';
    }
    const d = new Date(date);
    let year = d.getFullYear();
    return year;
  };

  return (
    <View style={styles.data}>
      <Text style={[styles.heading, { paddingTop: 25, paddingBottom: 15 }]}>
        Education:
      </Text>
      {cvInfo?.educations?.map((education, i) => {
        return (
          <View style={{ paddingBottom: 16 }} wrap={false}>
            <Text style={styles.para}>
              {education?.degree_start_date
                ? formatDate(education?.degree_start_date)
                : 'Start Date '}
              -
              {education?.degree_end_date
                ? formatDate(education?.degree_end_date)
                : ' End Date'}
            </Text>
            <Text
              style={[styles.para, { fontWeight: '600', color: '#256ef4' }]}
            >
              {education?.degree_institute
                ? education?.degree_institute
                : 'Institution / Place of Education'}
            </Text>
            <Text
              style={[styles.para, { fontWeight: '500', color: '#212121' }]}
            >
              {education?.degree_title
                ? education?.degree_title
                : 'Study Program'}
            </Text>
            <Text style={styles.para}>
              {education?.degree_city
                ? education?.degree_city
                : 'City, Country'}
            </Text>
          </View>
        );
      })}
      ;
    </View>
  );
}
