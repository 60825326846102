import React, { useContext, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { Typography, Stack, Drawer } from '@mui/material';

import Style from './style';
import Listing from './Listing';
import Content from '../SelectQuestionDialog/content';
import RemainingCreditsDrawer from './RemainingCreditsDrawer';
import { CareerCoachInterviewContext } from '../VideoInterview/CareerCoachInterviewContext';
import Payment from '../Payment';

const Styles = makeStyles((theme) => Style(theme));

function InterviewListing() {
  const classes = Styles();
  const [toggleRemCredits, setToggleRemCredits] = useState(false);
  const { paymentDrawer, remainingCredits, setPaymentDrawer } = useContext(
    CareerCoachInterviewContext,
  );
  return (
    <div>
      <Stack px={1.5}>
        <Stack
          width='100%'
          alignItems='center'
          justifyContent='center'
          sx={{
            height: '38px',
            borderRadius: '50px',
            border: '1px solid #010103',
          }}
          onClick={() => {
            if (parseInt(remainingCredits) === 0) {
              setPaymentDrawer(true);
            }
            // parseInt(remainingCredits) && setToggleRemCredits(true);
          }}
        >
          <Typography variant='title14Bold' sx={{ fontWeight: '500' }}>
            <span style={{ paddingTop: '2px' }}>
              {remainingCredits && parseInt(remainingCredits)}
            </span>{' '}
            Interview Credits Remaining{' '}
          </Typography>
        </Stack>
      </Stack>
      <Stack px={1.5}>
        <Content isFromDashboard={true} />
      </Stack>
      <Listing />

      <RemainingCreditsDrawer
        toggleRemCredits={toggleRemCredits}
        setToggleRemCredits={setToggleRemCredits}
      />
      <Payment toggle={paymentDrawer} setPaymentToggle={setPaymentDrawer} />
    </div>
  );
}

export default InterviewListing;
