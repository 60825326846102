import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Stack,
  Checkbox,
  FormGroup,
  Typography,
  FormControlLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import Button from '../../../../common/Button';

import Style from './style';
import {
  CheckboxIcon,
  CheckboxCheckedIcon,
} from '../../../../assets/constants';
import { CareerCoachInterviewContext } from '../../VideoInterview/CareerCoachInterviewContext';
import { useInterviewContest } from '../../../../services/career-coach/useInterviewContest';

const Styles = makeStyles((theme) => Style(theme));

export default function Terms() {
  const classes = Styles();
  const navigate = useNavigate();

  const [termsCheck, setTermsCheck] = useState(false);
  const { displayName, setDisplayNameCheck } = useContext(
    CareerCoachInterviewContext,
  );

  const { addToInterviewContest, isAddingToContest } = useInterviewContest();

  const handleTermsCheck = (event) => {
    setTermsCheck(event.target.checked);
  };
  const handleDisplayNameCheck = (event) => {
    setDisplayNameCheck(event.target.checked);
  };

  const handleContinue = () => {
    const cachedAnsId = localStorage.getItem('answerId');
    addToInterviewContest({
      contest: 'Y',
      answerId: cachedAnsId,
      displayName: displayName ? 'Y' : 'N',
    }).then((resp) => {
      // const url = sessionStorage.getItem('answerPermalink');
      // window.location.href = url;
      // navigate('/career-coach/voting/');
      navigate('/career-coach/voting/submission', {replace: true});
    });
  };

  // const handleContinue = () => {
  // };

  return (
    <Stack pb={23} pt={3} px={2}>
      <Stack
        mb={'45px'}
        alignItems='flex-start'
        sx={{ width: '100%', cursor: 'pointer' }}
      >
        <Typography
          onClick={() => navigate('/career-coach/evaluation')}
          variant='title14Bold'
          sx={{
            width: 'max-content',
            display: 'flex',
            alignItems: 'center',
            fontWeight: '600',
          }}
        >
          <ChevronLeftIcon sx={{ marginRight: 0.5, marginLeft: '-7px' }} />
          Back
        </Typography>
      </Stack>
      <Stack pr={1}>
        <Typography
          pb={'36px'}
          variant='title22Bold'
          sx={{ fontWeight: '600' }}
        >
          Terms of Participation - Best Interviews Competition
        </Typography>
        <Typography
          variant='title14Bold'
          sx={{ fontWeight: '400', lineHeight: '22px' }}
        >
          <b> Consent to Public Sharing:</b>
          <br />
          By submitting your video interview, you consent to sharing it publicly
          for voting in the Best Interviews competition.
        </Typography>
        <Typography
          pt={'16px'}
          variant='title14Bold'
          sx={{ fontWeight: '400', lineHeight: '22px' }}
        >
          <b> Use of Video by Interview Guru: </b>
          <br />
          You agree that Interview Guru and Rozee may use your video for
          instructive purposes and marketing activities across their platforms
          and channels.
        </Typography>
        <Typography
          pt={'16px'}
          variant='title14Bold'
          sx={{ fontWeight: '400', lineHeight: '22px' }}
        >
          <b> Content Authenticity:</b>
          <br />
          You affirm that the interview does not contain any false information,
          misleading content, or material that violates the laws of Pakistan.
        </Typography>
        <Typography
          pt={'16px'}
          variant='title14Bold'
          sx={{ fontWeight: '400', lineHeight: '22px' }}
        >
          <b> Compliance with Laws: </b>
          <br />
          Your video must adhere to all applicable laws and regulations in
          Pakistan. Any content that is unlawful or infringes on the rights of
          others is strictly prohibited.
        </Typography>
        <Typography
          pt={'16px'}
          variant='title14Bold'
          sx={{ fontWeight: '400', lineHeight: '22px' }}
        >
          <b> User Responsibility: </b>
          <br />
          You are fully responsible for the content of your video interview,
          including ensuring its accuracy and compliance with these terms.
        </Typography>
        <Typography
          pt={'16px'}
          variant='title14Bold'
          sx={{ fontWeight: '400', lineHeight: '22px' }}
        >
          <b> Waiver of Liability: </b>
          <br />
          By using this service, you waive any and all liability of Interview
          Guru and Rozee for any claims, damages, or losses arising out of your
          participation in the competition or usage of the service.
        </Typography>
      </Stack>
      <Stack
        p={2}
        sx={{
          background: '#fff',
          position: 'fixed',
          bottom: '0',
          left: '0',
          width: '100%',
          boxShadow: '0px -5px 24px 0px rgba(0, 0, 0, 0.30)',
        }}
      >
        <Stack>
          <FormGroup className={classes.checkbox__label}>
            <FormControlLabel
              style={{
                marginBottom: '0.5rem',

                '& .MuiCheckbox-root': {
                  marginTop: '0.15rem !important',
                },
              }}
              control={
                <Checkbox
                  checked={termsCheck}
                  onChange={handleTermsCheck}
                  icon={
                    <CheckboxIcon
                      sx={{
                        color: '#010103',
                        height: '17px',
                        width: '17px',
                      }}
                    />
                  }
                  checkedIcon={<CheckboxCheckedIcon />}
                />
              }
              label='By participating, I acknowledge and agree to these terms'
            />
            <FormControlLabel
              style={{
                marginTop: '0.25rem',
                '& .MuiCheckbox-root': {
                  marginTop: '0 !important',
                },
              }}
              control={
                <Checkbox
                  checked={displayName}
                  onChange={handleDisplayNameCheck}
                  icon={
                    <CheckboxIcon
                      sx={{
                        color: '#010103',
                        height: '17px',
                        width: '17px',
                      }}
                    />
                  }
                  checkedIcon={<CheckboxCheckedIcon />}
                />
              }
              label='Display my name when showing this video'
            />
          </FormGroup>
        </Stack>
        <Button
          color='primary'
          title='Continue'
          disabled={!termsCheck}
          variant='contained'
          onClick={handleContinue}
          width='100%'
          borderRadius='11px'
          fontSize='14px'
          height='56px'
          fontWeight='400'
          backgroundColor={
            !termsCheck ? '#808080 !important' : '#010103 !important'
          }
          textColor='#fff !important'
        />
      </Stack>
    </Stack>
  );
}
