import {useLocation, Navigate, Outlet} from "react-router-dom";
import {useContext} from "react";
import {AuthContext} from "./AuthContext";

const RequireAuth = ({allowedRoles}) => {
    const {
        user
    } = useContext(AuthContext);

    const location = useLocation();

    return (
        allowedRoles?.includes(user?.userType)
            ? <Outlet/>
            : user?.token //changed from user to accessToken to persist login after refresh
                ? <Navigate to="/unauthorized" state={{from: location}} replace/>
                : <Navigate to="/login" state={{from: location}} replace/>
    );
}

export default RequireAuth;