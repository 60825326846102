import React, { useState } from 'react';

import { Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import AccordionMain from '../AccordionMain';
import TextField from '../../../../../../common/Textfield';

export default function Employment({
  expandedAccordion,
  handleAccordionChange,
}) {
  const [present, setPresent] = useState(false);
  const [experience, setExperience] = useState([]);
  const [fieldErrors, setFieldErrors] = useState('');
  const [fieldVals, setFieldVals] = useState({
    companyName: '',
    jobTitle: '',
    description: '',
    statyDate: '',
    emdDate: '',
  });

  const handleChanges = (e) => {
    var { name, value } = e.target;
    var value = (value = value?.trimStart());
    setFieldVals({
      ...fieldVals,
      [name]: value,
    });
    setFieldErrors('');
  };

  const maxDate = dayjs();

  const addNewExp = () => {
    setExperience([...experience, { id: Date.now() }]);
  };
  return (
    <AccordionMain
      title='Employment'
      name='employment'
      expandedState={expandedAccordion === 'employment'}
      onChange={handleAccordionChange}
    >
      <Typography
        sx={{
          cursor: 'pointer',
          minWidth: '110px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: 'max-content',
        }}
        onClick={addNewExp}
      >
        <AddCircleOutlineOutlinedIcon
          style={{ marginRight: '4px', height: '20px', width: '20px' }}
        />{' '}
        Add New
      </Typography>
      {experience?.map((experience, i) => {
        return (
          <Stack>
            <TextField
              autoFocus
              margin='dense'
              tableInput
              variant='standard'
              helperText={fieldErrors}
              value={fieldVals?.companyName}
              placeholder='Enter Copmany Name'
              onChange={(e) => handleChanges(e)}
            />
            <TextField
              margin='dense'
              tableInput
              variant='standard'
              helperText={fieldErrors}
              value={fieldVals?.jobTitle}
              placeholder='Enter Job Title'
              onChange={(e) => handleChanges(e)}
            />
            <Stack py={1} />
            <TextField
              margin='dense'
              tableInput
              multiline
              maxRows={3}
              variant='standard'
              helperText={fieldErrors}
              value={fieldVals?.description}
              placeholder='Enter Description'
              onChange={(e) => handleChanges(e)}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <Stack
                  direction='row'
                  spacing={2}
                  sx={{
                    '& fieldset': {
                      borderTop: '0 !important',
                      borderLeft: '0 !important',
                      borderRight: '0 !important',
                      borderBottom: '1px solid #c8c8c8!important',
                    },
                    '& .MuiInputBase-root': {
                      '& input': {
                        paddingLeft: 0,
                        paddingRight: 0,
                      },
                    },
                  }}
                >
                  <Stack
                    sx={{
                      '& .MuiFormHelperText-root': {
                        fontStyle: fieldErrors?.startDate ? 'normal' : 'italic',
                        fontSize: fieldErrors?.startDate ? '12px' : '14px',
                        fontWeight: fieldErrors?.startDate ? '400' : '500',
                        color: fieldErrors?.startDate
                          ? '#ec7376 !important'
                          : '#0aa06a !important',
                      },
                    }}
                  >
                    <DatePicker
                      slotProps={{
                        textField: {
                          helperText: fieldErrors?.startDate
                            ? fieldErrors?.startDate
                            : ``,
                        },
                      }}
                      maxDate={maxDate}
                      views={['year', 'month']}
                      value={
                        fieldVals?.startDate && dayjs(fieldVals?.startDate)
                      }
                      onChange={(newValue) => {
                        setFieldVals({
                          ...fieldVals,
                          startDate: newValue,
                        });
                        setFieldErrors('');
                      }}
                    />
                  </Stack>
                  <Stack>
                    <DatePicker
                      views={['year', 'month']}
                      value={
                        present
                          ? 'Present'
                          : fieldVals?.endDate === 'Present' ||
                            fieldVals?.endDate === 'present' ||
                            fieldVals?.endDate === ''
                          ? dayjs(Date.now())
                          : dayjs(fieldVals?.endDate)
                      }
                      disabled={present}
                      onChange={(newValue) => {
                        setFieldVals({
                          ...fieldVals,
                          endDate: newValue,
                        });
                        setFieldErrors('');
                      }}
                      minDate={
                        fieldVals.startDate &&
                        typeof fieldVals.startDate === 'object'
                          ? fieldVals.startDate
                          : null
                      } // Set minDate to start date
                      maxDate={maxDate} // Disable dates after the present date
                      // shouldDisableDate={(day) =>
                      //   // Disable dates before the start date
                      //   isAfter(day, fieldVals.startDate)
                      // }
                      slotProps={{
                        textField: {
                          helperText:
                            fieldVals.endDate &&
                            new Date(fieldVals.endDate) <=
                              new Date(fieldVals.startDate)
                              ? 'End date should be greater than the start date'
                              : fieldErrors?.endDate
                              ? fieldErrors?.endDate
                              : '', // No helper text if end date is after start date or not selected
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              </DemoContainer>
            </LocalizationProvider>
          </Stack>
        );
      })}
    </AccordionMain>
  );
}
