import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/styles';
import { Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import { DeleteIconCV } from '../../../../../assets/constants';
import { updateExperienceInfo } from '../updateTemplateDataApi';
import { deleteCvExperience } from '../deleteTemplateDataApi';
import TextDictionary from '../../../../../common/Dictionary';
import EditableTypography from '../../../../../common/EditableTypography';
import { EditableContentBox } from '../../../../Common/EditableContentBox';
import {useCvBuilderContext} from "../../../../../context/CvBuilderContext/useCvBuilderContext";

export default function Employment({classes, templateData}) {
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  const {
    updateFieldExp,
    formatDate,
    addNewExp,
    experiences,
    handleDeleteExp,
  } = useCvBuilderContext();

  // useEffect(() => {
  //   setExperiences(templateData?.experiences || []);
  // }, [templateData]);

  // const addNewExp = () => {
  //   setExperiences([{ id: Date.now() }, ...experiences]);
  // };

  // const handleDeleteExp = (id) => {
  //   const newExperiences = experiences?.filter(
  //     (experience) => experience?.id !== id,
  //   );
  //   deleteCvExperience(id);
  //   setExperiences(newExperiences);
  // };

  // const replaceExpId = (expId, newId) => {
  //   const newExperiences = [...experiences];
  //   const index = newExperiences.findIndex((obj) => obj.id == expId);
  //   newExperiences[index].id = newId;
  //   setExperiences(newExperiences);
  // };
  //
  // const updateFieldExp = (params) => {
  //   const { name, text, id } = params;
  //   updateExperienceInfo(name, text, id).then((resp) => {
  //     if (resp?.code === '11') {
  //       const expId = resp?.data?.expId;
  //       replaceExpId(id, expId);
  //     }
  //   });
  // };

  return (
    <>
      <Stack
        py={1}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Divider
          classes={{ root: classes.divider__root }}
          sx={{ width: 'calc(100% - 120px) !important' }}
          textAlign='left'
        >
          <Typography variant='title18Bold' sx={{ fontWeight: '600' }}>
            Employment
          </Typography>
        </Divider>
        <Typography
          sx={{
            cursor: 'pointer',
            minWidth: '110px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
          onClick={addNewExp}
        >
          <AddCircleOutlineOutlinedIcon
            style={{ marginRight: '4px', height: '20px', width: '20px' }}
          />{' '}
          Add New
        </Typography>
      </Stack>
      {experiences?.map((experience, i) => {
        return (
          <Stack
            width='100%'
            position='relative'
            direction='column'
            key={experience?.id}
            sx={{
              '&:hover': {
                '& .del__icon': {
                  display: 'flex',
                },
              },
            }}
          >
            <Stack
              width='100%'
              direction='row'
              alignItems='flex-start'
              justifyContent='space-between'
            >
              <Stack width='100%' direction='row' alignItems='flex-start'>
                <Stack
                  direction='row'
                  alignItems='center'
                  sx={{ color: '#3e6af2' }}
                >
                  <EditableTypography
                    padding={1}
                    onBlur={(e) =>
                        updateFieldExp({
                        text: e.target.innerText,
                        name: 'start_date',
                        id: experience?.id,
                      })
                    }
                    width={experience?.start_date ? 'max-content' : '85px'}
                    variant='title14Bold'
                    fontWeight='400'
                    placeholder={
                      TextDictionary.templates.templateplaceholders.employment
                        ._1
                    }
                  >
                    {experience?.start_date
                      ? formatDate(experience?.start_date)
                      : null}
                  </EditableTypography>
                  -{' '}
                  <EditableTypography
                    padding={1}
                    onBlur={(e) =>
                        updateFieldExp({
                        text: e.target.innerText,
                        name: 'end_date',
                        id: experience?.id,
                      })
                    }
                    width='max-content'
                    variant='title14Bold'
                    fontWeight='400'
                    placeholder={
                      TextDictionary.templates.templateplaceholders.employment
                        ._2
                    }
                  >
                    {experience?.end_date
                      ? formatDate(experience?.end_date)
                      : null}
                  </EditableTypography>
                </Stack>
                <Stack width='100%'>
                  <EditableTypography
                    padding={1}
                    onBlur={(e) =>
                        updateFieldExp({
                        text: e.target.innerText,
                        name: 'company_name',
                        id: experience?.id,
                      })
                    }
                    width='max-content'
                    variant='title12Bold'
                    fontWeight='400'
                    placeholder={
                      TextDictionary.templates.templateplaceholders.employment
                        ._3
                    }
                  >
                    {experience?.company_name
                      ? experience?.company_name
                      : 'Company Name'}
                  </EditableTypography>
                  <EditableTypography
                    padding={1}
                    onBlur={(e) =>
                        updateFieldExp({
                        text: e.target.innerText,
                        name: 'job_title',
                        id: experience?.id,
                      })
                    }
                    variant={mediaquery1380 ? 'title16Bold' : 'title18Bold'}
                    fontWeight={mediaquery1380 ? '500' : '700'}
                    width='max-content'
                    placeholder={
                      TextDictionary.templates.templateplaceholders.employment
                        ._4
                    }
                  >
                    {experience?.job_title
                      ? experience?.job_title
                      : 'Senior Software Engineer'}
                  </EditableTypography>

                  <EditableContentBox
                    content={
                      experience?.job_description ??
                      'Enter job description here'
                    }
                    handleFocusOut={(content) => {
                      updateFieldExp({
                        text: content,
                        name: 'job_description',
                        id: experience?.id,
                      });
                    }}
                  />
                  {false && (
                    <EditableTypography
                      padding={1}
                      onBlur={(e) =>
                          updateFieldExp({
                          text: e.target.innerText,
                          name: 'job_description',
                          id: experience?.id,
                        })
                      }
                      dangerouslySetInnerHTML={{
                        __html: experience?.job_description,
                      }}
                      variant='title14Bold'
                      fontWeight='400'
                      whiteSpace='pre-wrap'
                    ></EditableTypography>
                  )}
                </Stack>
              </Stack>
              <Stack
                sx={{
                  fontWeight: '400',
                  minWidth: '130px',
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <EditableTypography
                  padding={1}
                  onBlur={(e) => {
                    updateFieldExp({
                      text: e.target.innerText,
                      name: 'job_city',
                      id: experience?.id,
                    });
                  }}
                  minWidth='max-content'
                  variant='title14Bold'
                  fontWeight='400'
                  placeholder={
                    TextDictionary.templates.templateplaceholders.employment._5
                  }
                >
                  {experience?.job_city ? experience?.job_city : 'Lahore'}
                </EditableTypography>
              </Stack>
              <Stack
                className='del__icon'
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '0',
                  top: '2rem',
                  background: '#fff',
                  borderRadius: '6px',
                  boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.14)',
                  height: '40px',
                  width: '40px',
                  display: 'none',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 10,
                }}
              >
                <DeleteIconCV
                  onClick={() => {
                    handleDeleteExp(experience?.id);
                    // deleteCvExperience(experience?.id);
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        );
      })}
    </>
  );
}
