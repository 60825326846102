import {useState} from "react";
import {headers, defaultHeaders, usefetch} from "../helpers/fetch";

export const useCountCvView = () => {
    const [error, setError] = useState(null);
    const [isCountingCvView, setIsCountingCvView] = useState(null);
    const [response, setResponse] = useState(null);
    const countCvView = async (data) => {
        const {
            permalink,
            token,
            utm
        } = data;
        setIsCountingCvView(true);

        const response = await usefetch('cv/countView', 'post', defaultHeaders(), data);

        if (!response.ok) {
            setError(response.error);
        }

        if (response.ok) {
            setResponse((prevData) => [...prevData, response]);
        }

        setIsCountingCvView(false);
        return response;
    };

    return { countCvView, response, isCountingCvView, error };
};