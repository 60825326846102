import { useEffect, useState } from 'react';

import {
  updateAwardsInfo,
  updateCvInfo,
  updateEducationInfo,
  updateExperienceInfo,
  updateLanguageInfo,
  updateSkillsInfo,
} from '../../components/Seeker/CreateResume/Templates/updateTemplateDataApi';
import {usefetch, headers, defaultHeaders} from '../../helpers/fetch';
import {
  deleteCvAward,
  deleteCvEducation,
  deleteCvExperience,
  deleteCvLanguage,
  deleteCvSkill,
} from '../../components/Seeker/CreateResume/Templates/deleteTemplateDataApi';
import {
  addCvLanguage,
  addCvSkill,
} from '../../components/Seeker/CreateResume/Templates/addTemplateDataApi';
import {useParams, useSearchParams} from 'react-router-dom';
import {useCountCvView} from "../../services/useCountCvView";

export const useCvBuilder = () => {
  const [skill, setSkill] = useState('');
  const [cvInfo, setCVInfo] = useState(null);
  const [awards, setAwards] = useState([]);
  const [language, setLanguage] = useState('');
  const [educations, setEducations] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([...Array(2)]);
  const [languages, setLanguages] = useState([...Array(2)]);
  const [showQuickActions, setShowQuickAction] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [currentTemplate, setCurrentTemplate] = useState('');
  const [searchParams] = useSearchParams();
  const [addNewSkillState, setAddNewSkillState] = useState(false);
  const [addNewLangState, setAddNewLangState] = useState(false);
  const {countCvView} = useCountCvView();


  const isDec = searchParams.get('is_dec');
  const cvId = searchParams.get('cvId');

  const [fieldVals, setFieldVals] = useState({
    name: '',
    tagline: '',
    email: '',
    mobile: '',
    address: '',
    link: '',
    summary: '',
    skill: '',
  });

  const handleTextChange = (e, field) => {
    const newValue = e.target.textContent;
    updateCvInfo(field, newValue, cvId);
    setFieldVals((prevVals) => ({
      ...prevVals,
      [field]: newValue,
    }));
  };

  // const getCv = () => {
  //   usefetch('cv/getCvInfo', 'post', headers(), {
  //     cvId: sessionStorage.getItem('cvId'),
  //   }).then(function (resp) {
  //     setCVInfo(resp?.data?.cvInfo);
  //   });
  // };

  const getCv = (params) => {
    const {cId} = params || {};
    const data = isDec && isDec === 'Y' ? {cvIddec: cvId} : {cvId: cId ?? cvId};
    if(!data?.cvIddec && !data?.cvId) {
      return;
    }
    usefetch('cv/getCvInfo', 'post', headers(), data).then(function (resp) {
      setCVInfo(resp?.data?.cvInfo);
      sessionStorage.setItem('cvId', resp?.data?.cvInfo?.cvIdEnc);
      setActiveTab(resp?.data?.cvInfo?.templateCode);
      setCurrentTemplate(resp?.data?.cvInfo?.templateCode);
    });
  };

  const getPermalinkCv = (cvlink) => {
    usefetch("cv/getPermalinkCv", "post", defaultHeaders(), {
      permalink: cvlink,
    }).then(function (resp) {
      setCVInfo(resp?.data?.cvInfo);
      countCvView({
        permalink: resp?.data?.cvInfo?.permalink,
        utm_source: searchParams.get("utm_source"),
        sentTo: searchParams.get("sentTo"),
        token: localStorage.getItem("token"),
      });
    });
  };

  const handleUpdateCvSkill = (params) => {
    const { skillId, content } = params;
    updateSkillsInfo('skill', content, skillId, cvId);
  };

  const handleUpdateCvLang = (params) => {
    const { langId, content } = params;
    updateLanguageInfo('language', content, langId, cvId);
  };

  const formatDate = (date) => {
    if (date?.toLowerCase() === 'present') {
      return 'Present';
    }
    const d = new Date(date);
    let year = d.getFullYear();
    return year;
  };

  useEffect(() => {
    getCv();
  }, []);

  useEffect(() => {
    if (cvInfo) {
      setFieldVals((prevState) => {
        return {
          ...prevState,
          name: cvInfo?.name ? cvInfo?.name : '',
          tagline: cvInfo?.tagline ? cvInfo?.tagline : '',
          email: cvInfo?.email ? cvInfo?.email : '',
          mobile: cvInfo?.mobile ? cvInfo?.mobile : '',
          address: cvInfo?.address ? cvInfo?.address : '',
          link: cvInfo?.link ? cvInfo?.link : '',
          summary: cvInfo?.summary ? cvInfo?.summary : '',
        };
      });
      // debugger;
      setEducations(cvInfo?.educations || []);
      setAwards(cvInfo?.awards);
      setLanguages(cvInfo?.languages);
      setSkills(cvInfo?.skills);
      setExperiences(cvInfo?.experiences || []);
    }
  }, [cvInfo]);

  // Education

  const addNewEdu = () => {
    setEducations([{ id: Date.now() }, ...educations]);
  };

  const replaceEducationId = (eduId, newId, name, text) => {
    const newEducations = [...educations];
    const index = newEducations.findIndex((obj) => obj.id == eduId);
    newEducations[index].id = newId;
    newEducations[index][name] = text;
    setEducations(newEducations);
  };

  const updateEduField = (params) => {
    const { name, text, id } = params;
    updateEducationInfo(name, text, id, cvId).then((resp) => {
      if (resp?.code === '11') {
        const eduId = resp?.data?.eduId;
        replaceEducationId(id, eduId, name, text);
      }
    });
  };

  const handleDeleteEducation = (id) => {
    const newEducations = educations?.filter(
      (education) => education?.id !== id,
    );
    deleteCvEducation(id, cvId);
    setEducations(newEducations);
  };

  // Awards

  const addNewAward = () => {
    setAwards([{ id: Date.now() }, ...awards]);
  };

  const replaceAwardId = (awardId, newId) => {
    const newAwards = [...awards];
    const index = newAwards.findIndex((obj) => obj.id == awardId);
    newAwards[index].id = newId;
    setAwards(newAwards);
  };

  const updateAwardField = (params) => {
    const { name, text, id } = params;
    updateAwardsInfo(name, text, id, cvId).then((resp) => {
      if (resp?.code === '11') {
        const awardId = resp?.data?.awardId;
        replaceAwardId(id, awardId);
      }
    });
  };

  const handleDeleteAward = (id) => {
    const newAwards = awards?.filter((award) => award?.id !== id);
    deleteCvAward(id, cvId);
    setAwards(newAwards);
  };

  // Language

  const handleDeleteLanguage = (languageId) => {
    setLanguages(languages.filter((obj) => obj.id !== languageId));
    deleteCvLanguage(languageId, cvId);
  };

  const handleChangeLangauge = (e) => {
    var { name, value } = e.target;
    var value = (value = value?.trimStart());
    setLanguage(value);
  };

  const addNewLang = async () => {
    const resp = await addCvLanguage(language, cvId);
    setLanguages([...languages, { id: Date.now(), language: language }]);
    setLanguage('');
    getCv();
  };

  const keyEnterLanguage = (e) => {
    if (e.key === 'Enter') {
      const { name, value } = e.target;
      if (value === '') {
        return;
      } else {
        addNewLang();
      }
    }
  };

  // Skills

  const handleChangeSkill = (e) => {
    var { name, value } = e.target;
    var value = (value = value?.trimStart());
    setSkill(value);
  };

  const addNewSkill = async () => {
    const resp = await addCvSkill(skill, cvId);
    setSkills([...skills, { id: Date.now(), skill: skill }]);
    setSkill('');
    getCv();
  };

  const keyEnterSkill = (e) => {
    if (e.key === 'Enter') {
      const { name, value } = e.target;
      if (value === '') {
        return;
      } else {
        addNewSkill();
      }
    }
  };

  const handleDeleteSkill = (skillId) => {
    setSkills(skills.filter((obj) => obj.id !== skillId));
    deleteCvSkill(skillId, cvId);
  };

  // Employment

  const addNewExp = () => {
    setExperiences([{ id: Date.now() }, ...experiences]);
  };

  const handleDeleteExp = (id) => {
    const newExperiences = experiences?.filter(
      (experience) => experience?.id !== id,
    );
    deleteCvExperience(id, cvId);
    setExperiences(newExperiences);
  };

  const replaceExpId = (expId, newId, name, text) => {
    const newExperiences = [...experiences];
    const index = newExperiences.findIndex((obj) => obj.id == expId);
    newExperiences[index].id = newId;
    newExperiences[index][name] = text;
    // newExperiences[index].id = newId;
    setExperiences(newExperiences);
  };

  const updateFieldExp = (params) => {
    const { name, text, id } = params;
    // setExperiences({});
    updateExperienceInfo(name, text, id, cvId).then((resp) => {
      if (resp?.code === '11') {
        const expId = resp?.data?.expId;
        replaceExpId(id, expId, name, text);
      }
    });
  };

  return {
    getCv,
    getPermalinkCv,
    cvInfo,
    setCVInfo,
    fieldVals,
    setFieldVals,
    activeTab,
    setActiveTab,
    currentTemplate,
    setCurrentTemplate,
    handleTextChange,
    addNewEdu,
    educations,
    setEducations,
    updateEduField,
    formatDate,
    handleDeleteEducation,
    addNewAward,
    awards,
    setAwards,
    updateAwardField,
    handleDeleteAward,
    languages,
    handleDeleteLanguage,
    handleUpdateCvSkill,
    handleUpdateCvLang,
    keyEnterLanguage,
    handleChangeLangauge,
    language,
    setShowQuickAction,
    showQuickActions,
    handleChangeSkill,
    keyEnterSkill,
    handleDeleteSkill,
    skill,
    skills,
    setSkill,
    setSkills,
    addNewExp,
    handleDeleteExp,
    updateFieldExp,
    experiences,
    addNewSkillState,
    setAddNewSkillState,
    addNewLangState,
    setAddNewLangState,
  };
};
