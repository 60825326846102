import {useState} from "react";
import {headers, defaultHeaders, usefetch} from "../helpers/fetch";

export const useGetPackages = () => {
    const [error, setError] = useState(null);
    const [isLoadingPackages, setIsLoadingPackages] = useState(true);
    const [response, setResponse] = useState(null);
    const [packages, setPackages] = useState();
    const [salesTaxPercent, setSalesTaxPercent] = useState('');
    const [topUps, setTopUps] = useState();


    const getPackages = async (params) => {
        setIsLoadingPackages(true);
        const data = {};

        const resp = await usefetch('packages/getPackages', 'POST', localStorage.getItem('token')? headers(): defaultHeaders(), data);

        if (resp?.code === '11') {
            setPackages(prev => resp?.data?.packages);
            setTopUps(prev => resp?.data?.topups);
            setSalesTaxPercent(prev => resp?.data?.sales_tax_percent);
        }

        if (!resp.ok) {
            setError(resp.error);
        }

        if (resp.ok) {
            setResponse((prevData) => [...prevData, resp]);
        }

        setIsLoadingPackages(false);
        return resp;
    };

    return { getPackages, response, packages, salesTaxPercent,setSalesTaxPercent, topUps, isLoadingPackages, error,setIsLoadingPackages };
};