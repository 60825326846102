import React from 'react';

import EmailIcon from '@mui/icons-material/Email';
import { Stack, Typography } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';

import { useTemplateContext } from '../../../../../context/TemplateContext/useTemplateContext';
import EditableTypography from '../../../../../common/EditableTypography';
import { useCvBuilderContext } from '../../../../../context/CvBuilderContext/useCvBuilderContext';
import {
  LocationTemplateIcon,
  WebsiteTemplteIcon,
  CallTemplateIcon,
  EmailTemplateIcon,
} from '../../../../../assets/constants';

export default function PersonalInformation() {
  const { fieldVals, handleTextChange } = useCvBuilderContext();

  return (
    <Stack>
      <EditableTypography
        variant='title36Bold'
        placeholder={'Your name here'}
        lineHeight='38px'
        onBlur={(e, isChanged) => {
          if (isChanged) {
            handleTextChange(e, 'name');
          }
        }}
      >
        {fieldVals?.name}
      </EditableTypography>
      <Stack py={1}>
        <EditableTypography
          variant='title16Bold'
          placeholder={'Your tagline here'}
          fontWeight='400'
          onBlur={(e, isChanged) => {
            if (isChanged) {
              handleTextChange(e, 'tagline');
            }
          }}
        >
          {fieldVals?.tagline}
        </EditableTypography>
      </Stack>
      <Stack
        direction='row'
        spacing={1.2}
        sx={{
          '& .contentEditableWidth': {
            width: 'calc(100% - 40px)',
            '& .MuiTypography-root': {
              minWidth: 'calc(100% - 3px)',
            },
          },
        }}
      >
        <Stack width='50%' direction='column'>
          <Stack
            py={1.5}
            width='100%'
            spacing={1.2}
            direction='row'
            alignItems='center'
          >
            <EmailTemplateIcon />
            <Stack className='contentEditableWidth'>
              <EditableTypography
                width='calc(100% - 40px)'
                minWidth='calc(100% - 40px)'
                variant='title14Bold'
                placeholder={'example@gmail.com'}
                fontWeight='400'
                onBlur={(e, isChanged) => {
                  if (isChanged) {
                    handleTextChange(e, 'email');
                  }
                }}
              >
                {fieldVals?.email}
              </EditableTypography>
            </Stack>
          </Stack>

          <Stack
            pb={1}
            width='100%'
            spacing={1.2}
            direction='row'
            alignItems='center'
          >
            <LocationTemplateIcon />
            <Stack className='contentEditableWidth'>
              <EditableTypography
                width='calc(100% - 40px)'
                minWidth='calc(100% - 40px)'
                variant='title14Bold'
                fontWeight='400'
                placeholder={'Your address here'}
                onBlur={(e, isChanged) => {
                  if (isChanged) {
                    handleTextChange(e, 'address');
                  }
                }}
              >
                {fieldVals?.address}
              </EditableTypography>
            </Stack>
          </Stack>
        </Stack>
        <Stack width='50%' direction='column'>
          <Stack
            py={1.5}
            width='100%'
            spacing={1.2}
            direction='row'
            alignItems='center'
          >
            <CallTemplateIcon />
            <Stack className='contentEditableWidth'>
              <EditableTypography
                width='calc(100% - 40px)'
                minWidth='calc(100% - 40px)'
                variant='title14Bold'
                placeholder={'03000000000'}
                fontWeight='400'
                onBlur={(e, isChanged) => {
                  if (isChanged) {
                    handleTextChange(e, 'mobile');
                  }
                }}
              >
                {fieldVals?.mobile}
              </EditableTypography>
            </Stack>
          </Stack>

          <Stack
            pb={1}
            width='100%'
            spacing={1.2}
            direction='row'
            alignItems='center'
          >
            <WebsiteTemplteIcon />
            <Stack className='contentEditableWidth'>
              <EditableTypography
                width='calc(100% - 40px)'
                minWidth='calc(100% - 40px)'
                variant='title14Bold'
                fontWeight='400'
                onBlur={(e, isChanged) => {
                  if (isChanged) {
                    handleTextChange(e, 'link');
                  }
                }}
                placeholder={'Your portfolio link here'}
              >
                {fieldVals?.link}
              </EditableTypography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
