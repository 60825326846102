import {CvBuilderContext} from './CvBuilderContext';
import {useCvBuilder} from './useCvBuilder';

export const CvBuilderProvider = ({children}) => {
  const values = useCvBuilder();
  return (
      <CvBuilderContext.Provider value={values}>
      {children}
      </CvBuilderContext.Provider>
  );
};
