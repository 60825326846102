import React from 'react';
import AccordionMain from '../AccordionMain';

export default function Awards({ expandedAccordion, handleAccordionChange }) {
  return (
    <AccordionMain
      title='Awards'
      name='awards'
      expandedState={expandedAccordion === 'awards'}
      onChange={handleAccordionChange}
    >
      Awards
    </AccordionMain>
  );
}
