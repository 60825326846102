import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { headers, usefetch } from '../../helpers/fetch';
import { CartContext } from '../../pages/Packages/Cart/CartContext';
import { useGlobalLoader } from '../../App';

export const useSeekerChat = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const ChatRef = useRef(null);

  const [qCount, setQCount] = useState(0);
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [calledFrom, setCalledFrom] = useState('');
  const [loaderTexts, setLoaderTexts] = useState({
    text1: 'Our AI is generating a CV based on your chat response.',
    text2: "Hang in there, we're almost done.",
    text3: '',
  });

  const [currentQAns, setCurrentQAns] = useState([]);
  const [loopCategory, setLoopCategory] = useState(0);
  const [currentQList, setCurrentQList] = useState([]);
  const [currentQType, setCurrentQType] = useState('');
  const [chatSessionId, setChatSessionId] = useState('');
  const [conversations, setConversations] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(0);
  const [currentQustionIndex, setCurrentQustionIndex] = useState(0);
  const [loopQustionIndex, setLoopQustionIndex] = useState(0);
  const { globalLoader, setGlobalLoader } = useGlobalLoader();
  const { checkCredits, setCreditToggle } = useContext(CartContext);

  const getChatQuestions = () => {
    const cvid = sessionStorage.getItem('cvId');
    usefetch('applications/seekerQuestions', 'post', headers(), {
      jid: sessionStorage.getItem('jid') ? sessionStorage.getItem('jid') : '',
      cvId: cvid && cvid !== 'undefined' ? cvid : '',
      type: `${calledFrom},all`,
    }).then((resp) => {
      setAllCategories(resp?.data?.categories);
      if (resp?.data?.cvId) {
        sessionStorage.setItem('cvId', resp?.data?.cvId);
      }
    });
  };

  const reinitChat = () => {
    setChatSessionId('');
    setQCount(0);
    setConversations([]);
    setAllCategories([]);
    getChatQuestions();
  };
  useEffect(() => {
    if (calledFrom) {
      getChatQuestions();
    }
  }, [calledFrom]);

  const getNextCategory = () => {
    let categoryQ = null;

    if (currentCategory) {
      const currentIndex = allCategories.findIndex(
        (item) => item.id === currentCategory,
      );
      if (currentIndex !== -1 && currentIndex < allCategories.length - 1) {
        categoryQ = allCategories[currentIndex + 1];
      }
    } else if (qCount === 0 && allCategories?.length > 0) {
      categoryQ = allCategories[0];
    }

    if (categoryQ) {
      setCurrentCategory(categoryQ?.id);
    } else {
      parseChat();
    }
  };

  const setQuestionList = () => {
    let categoryQ = null;
    if (currentCategory && allCategories?.length > 0) {
      categoryQ = allCategories.find((item) => item.id === currentCategory);
      if (categoryQ?.questions.length > 0) {
        setCurrentQList(() => categoryQ?.questions);
        setCurrentQustionIndex(categoryQ?.questions[0]?.id);
      } else {
        getNextCategory();
      }
    }
  };
  useEffect(() => {
    getNextCategory();
  }, [allCategories]);

  useEffect(() => {
    setQuestionList();
  }, [currentCategory]);

  const getNextQIndex = () => {
    const currentIndex = currentQList.findIndex(
      (item) => item.id === currentQustionIndex,
    );
    if (currentIndex !== -1 && currentIndex < currentQList.length - 1) {
      return currentQList[currentIndex + 1]?.id;
    } else {
      getNextCategory();
    }
  };

  const setNextQuestion = () => {
    let cQ = null;

    if (currentQustionIndex) {
      cQ = currentQList.find((item) => item.id === currentQustionIndex);
      setCurrentQType(() => cQ?.type);
      setConversations((prevConversation) => [
        ...prevConversation,
        {
          user: false,
          message: cQ?.question,
          type: cQ?.type,
          options: cQ?.answers,
        },
      ]);
      scrollRef?.current?.scrollIntoView({ behaviour: 'smooth' });
      console.log('Loadied');
      setQCount((prevCount) => prevCount + 1);
      setCurrentQAns(() => cQ?.answers);
      setTimeout(() => {
        if (ChatRef?.current) {
          ChatRef?.current?.focus();
        }
      }, 1000);
    }
  };

  useEffect(() => {
    setNextQuestion();
  }, [currentQustionIndex]);

  useEffect(() => {
    if (currentQType === 'info') {
      setLoading(true);
      setTimeout(() => {
        const ind = getNextQIndex();
        console.log('ind', ind);
        if (ind) {
          setCurrentQustionIndex(() => ind);
        }

        setLoading(false);
      }, 2000);
    }
  }, [currentQType]);

  const focusChat = () => {
    if (ChatRef.current) {
      ChatRef.current.focus();
    }
  };

  const handleEnter = (e, isButton, seletedAns = '') => {
    if (e.key === 'Enter' || isButton === 'Y') {
      let currQuestion = currentQList.find(
        (item) => item.id == currentQustionIndex,
      );
      var userAns =
        currQuestion?.type === 'radio' && seletedAns !== ''
          ? seletedAns
          : answer;

      let qType = currQuestion?.type;
      //   let typeAnswers = currQuestion?.answers;
      let ansAction = currQuestion?.actions;
      if (qType !== 'radio') {
        setConversations((prevConversation) => [
          ...prevConversation,
          {
            user: true,
            message: userAns,
          },
        ]);
        scrollRef?.current?.scrollIntoView({ behaviour: 'smooth' });
      }

      if (
        userAns == '' ||
        (qType === 'radio' && ansAction[userAns?.toLowerCase()] == undefined)
      ) {
        setConversations((prevConversation) => [
          ...prevConversation,
          {
            user: false,
            message: "Sorry I didn't get that, Please provide answer again",
          },
        ]);
        scrollRef?.current?.scrollIntoView({ behaviour: 'smooth' });
        if (ChatRef.current) {
          ChatRef.current.focus();
        }
        setAnswer('');
        return false;
      }

      handleSaveChat(
        currQuestion?.id,
        currQuestion?.question,
        userAns,
        currQuestion?.question_order,
      );
      console.log(currQuestion?.loop_to, userAns);
      // if (qType === "radio") {

      // if (
      //   qCount == 1 &&
      //   (Number.isInteger(Number(ansAction[userAns?.toLowerCase()])) ||
      //   userAns == "yes")
      // ) {
      //   // createChildCv();
      // }
      if (
        qType === 'radio' &&
        ansAction[userAns?.toLowerCase()] == 'navigate_resume'
      ) {
        navigate(`/cv-edit?cvId=${sessionStorage.getItem('cvId')}`, {
          state: { applyJob: 'Y' },
        });
      } else if (
        qType === 'radio' &&
        ansAction[userAns?.toLowerCase()] == 'mcq_test'
      ) {
        parseChat();
      } else if (
        qType === 'radio' &&
        ansAction[userAns?.toLowerCase()] == 'navigate_cv'
      ) {
        parseChat();
      } else if (
        qType === 'radio' &&
        currentCategory != ansAction[userAns?.toLowerCase()]
      ) {
        setCurrentCategory(() => ansAction[userAns?.toLowerCase()]);
      } else if (currQuestion?.loop_to && userAns != '') {
        setCurrentQustionIndex(() => currQuestion?.loop_to);
      } else {
        setCurrentQustionIndex(() => getNextQIndex());
      }
      focusChat();
      setAnswer('');
    }
  };

  const createChildCv = () => {
    usefetch('cv/createChildCv', 'post', headers(), {
      chatSessionId: chatSessionId,
      cvId: sessionStorage.getItem('cvId'),
    }).then((resp) => {
      sessionStorage.setItem('cvId', resp?.data?.cvId);
      setChatSessionId(resp?.data?.chatSessionId);
    });
  };

  const parseChat = () => {
    if (!chatSessionId) return false;
    setLoading(true);
    setGlobalLoader(true);

    usefetch('cv/parseChat', 'post', headers(), {
      chatSessionId: chatSessionId,
      cvId: sessionStorage.getItem('cvId'),
      jid: sessionStorage.getItem('jid'),
    }).then((resp) => {
      setLoading(false);
      setGlobalLoader(false);
      reinitChat();

      // setLoader(false);
      if (resp.code == '11') {
        sessionStorage.setItem('cvId', resp?.data?.cvId);
        navigate(`/cv-edit?cvId=${sessionStorage.getItem('cvId')}`, {
          state: { applyJob: 'Y' },
        });
      }
    });
  };

  const handleSaveChat = (questionId, question, answer, questionOrder) => {
    usefetch('cv/saveChatAnswers', 'post', headers(), {
      question: question,
      questionId: questionId,
      questionOrder: questionOrder,
      answer: answer,
      chatSessionId: chatSessionId,
      cvId: sessionStorage.getItem('cvId'),
      jid: sessionStorage.getItem('jid'),
    }).then((resp) => {
      //   console.log(resp);
      setChatSessionId(resp?.data?.chatSessionId);
    });
  };

  const handleChange = (e) => {
    setAnswer(e.target.value);
  };

  return {
    id,
    scrollRef,
    qCount,
    answer,
    loading,
    loader,
    loaderTexts,
    currentQAns,
    loopCategory,
    currentQList,
    currentQType,
    chatSessionId,
    conversations,
    allCategories,
    currentCategory,
    currentQustionIndex,
    ChatRef,
    setQCount,
    setAnswer,
    setLoading,
    setCurrentQAns,
    setLoopCategory,
    setCurrentQList,
    setCurrentQType,
    setChatSessionId,
    setConversations,
    setAllCategories,
    setCurrentCategory,
    setCurrentQustionIndex,
    handleEnter,
    handleChange,
    focusChat,
    setCalledFrom,
  };
};
