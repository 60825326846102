import { Outlet } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext';
import Loader from '../common/Loader';

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);

  const { user, refreshUserData } = useContext(AuthContext);

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refreshUserData();
      } catch (err) {
          console.log(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    // Avoids unwanted call to verifyRefreshToken
    !user?.token ? verifyRefreshToken() : setIsLoading(false);
    return () => (isMounted = false);
  }, []);

  return <>{isLoading ? <Loader /> : <Outlet />}</>;
};

export default PersistLogin;
