import React, { useState } from 'react';
import { Stack } from '@mui/material';
import SwitchMain from '../../common/Switch';
import TextDictionary from "../../common/Dictionary";

export default function ShowHideSalary(props) {
    const {toggle, setToggle} = props;

  return (
    <Stack position='absolute' right='0' bottom='-1rem'>
      <SwitchMain
          label={TextDictionary.postJob._1}
        onChange={() => setToggle(!toggle)}
        checked={toggle}
      />
    </Stack>
  );
}
