import React, { useState } from 'react';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Dialog, Stack, Typography, useMediaQuery } from '@mui/material';

import Style from './style';
import Button from '../../../common/Button';
import Textfield from '../../../common/Textfield';
import VerificationSuccess from './VerificationSuccess';
import { CreatePasswordIcon } from '../../../assets/constants';
import { defaultHeaders, headers, usefetch } from '../../../helpers/fetch';

const Styles = makeStyles((theme) => Style(theme));

export default function UpdatePassword({
  email,
  otp,
  toggle,
  setToggle,
  setOtpToggle,
  handleUpdate,
}) {
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  const [fieldErrors, setFieldErrors] = useState({
    password_one: '',
    password_two: '',
  });
  const [successPopup, setSuccessPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [fieldVals, setFieldVals] = useState({
    password_one: '',
    password_two: '',
  });

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setFieldVals({
      ...fieldVals,
      [name]: value,
    });

    console.log(fieldVals);
    setFieldErrors('');
  };

  const handleEnter = (e, isButton) => {
    if (e?.key === 'Enter' || isButton === 'Y') {
      if (!fieldVals.password_one) {
        setFieldErrors({
          ...fieldErrors,
          password_one: 'Password is required',
        });
        return;
      } else if (fieldVals.password_one.length < 8) {
        setFieldErrors({
          ...fieldErrors,
          password_one: 'Password must be at least 8 characters long.',
        });
        return;
      } else if (!/(?=.*[a-zA-Z])(?=.*\d)/.test(fieldVals.password_one)) {
        setFieldErrors({
          ...fieldErrors,
          password_one: 'Password must contain both letters and numbers.',
        });
        return;
      }

      if (!fieldVals.password_one) {
        setFieldErrors({
          ...fieldErrors,
          password_one: 'Password is required',
        });
        return;
      } else if (!fieldVals.password_two) {
        setFieldErrors({
          ...fieldErrors,
          password_two: 'Confirm password is required',
        });
        return;
      } else if (fieldVals.password_one !== fieldVals.password_two) {
        setFieldErrors({
          ...fieldErrors,
          password_two: 'Passwords should be same.',
        });
        return;
      } else {
        usefetch('user/updateSeekerPassword', 'post', defaultHeaders(), {
          otp: otp,
          email: email,
          password:
            fieldVals.password_one === fieldVals.password_two
              ? fieldVals.password_one
              : '',
        }).then(function (resp) {
          if (resp?.code === '11') {
            localStorage.setItem('token', resp?.data?.sessionId);
            localStorage.setItem('userName', resp?.data?.userName);
            setSuccessPopup(true);
            // setToggle(false);
            // setOtpToggle(false);
          } else {
            setFieldErrors({
              ...fieldErrors,
              password_two: resp?.message,
            });
          }
        });
      }
    }
  };

  const SuccessPopup = (
    <VerificationSuccess
      toggle={successPopup}
      setToggle={setSuccessPopup}
      setOtpToggle={setOtpToggle}
      setPasswordToggle={setToggle}
      handleUpdate={handleUpdate}
    />
  );

  return (
    <Dialog
      open={toggle}
      onClose={setToggle}
      className={clsx(
        classes.main__dialog__verification,
        classes.set__password__dialog,
      )}
    >
      <Stack
        justifyContent='flex-end'
        direction='row'
        position='absolute'
        right='.5rem'
        top='0.5rem'
      >
        <CloseRoundedIcon
          style={{ cursor: 'pointer' }}
          onClick={() => setToggle(false)}
        />
      </Stack>
      <Stack
        p={1.8}
        px={2.5}
        height='100%'
        justifyContent='center'
        alignItems='center'
        color='primary.main'
      >
        <CreatePasswordIcon />
        <Typography
          variant={'title20Bold'}
          sx={{ fontWeight: '600' }}
          pt={1}
          pb={0.7}
        >
          Create Password
        </Typography>
        <Typography
          variant={'title14Bold'}
          sx={{ textAlign: 'center', fontWeight: '400', lineHeight: '22px' }}
          pb={2.5}
        >
          Create your new password to complete the process.
        </Typography>

        <Stack
          width='100%'
          sx={{
            '& .label__main': {
              fontSize: '18px',
            },
            '& .textfield__global': {
              // marginTop: '0',
              '& .MuiInputBase-root': {
                height: '45px',
                borderRadius: '12px',
                border: 'none',
                '& input': {
                  fontSize: '12px',
                  padding: '.2rem 1rem',
                },
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #d3d3d3 !important', // Default border color
                },
                '&:hover fieldset': {
                  border: '1px solid #010103 !important', // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid #010103 !important', // Border color on focus
                },
              },
              '& .MuiFormHelperText-root': {
                fontSize: '12px !important',
                fontWeight: '400 !important',
              },
            },
          }}
        >
          <Textfield
            placeholder='Enter Password'
            margin='dense'
            autoFocus
            variant='outlined'
            name='password_one'
            value={fieldVals?.password_one}
            onChange={(e) => {
              handleChanges(e);
            }}
            onEnter={handleEnter}
            //   disabled={loading}
            type={showPasswordOne ? 'text' : 'password'}
            helperText={fieldErrors?.password_one}
            endAdornment={
              showPasswordOne ? (
                <VisibilityOffIcon
                  style={{
                    cursor: 'pointer',
                    color: '#010103',
                    fontSize: '16px',
                  }}
                  onClick={() => setShowPasswordOne(false)}
                />
              ) : (
                <VisibilityIcon
                  style={{
                    cursor: 'pointer',
                    color: '#010103',
                    fontSize: '16px',
                  }}
                  onClick={() => setShowPasswordOne(true)}
                />
              )
            }
          />
          <Textfield
            placeholder='Confirm Password'
            margin='dense'
            variant='outlined'
            name='password_two'
            value={fieldVals?.password_two}
            onChange={(e) => {
              handleChanges(e);
            }}
            onEnter={handleEnter}
            //   disabled={loading}
            type={showPassword ? 'text' : 'password'}
            helperText={fieldErrors?.password_two}
            endAdornment={
              showPassword ? (
                <VisibilityOffIcon
                  style={{
                    cursor: 'pointer',
                    color: '#010103',
                    fontSize: '16px',
                  }}
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <VisibilityIcon
                  style={{
                    cursor: 'pointer',
                    color: '#010103',
                    fontSize: '16px',
                  }}
                  onClick={() => setShowPassword(true)}
                />
              )
            }
          />
        </Stack>
        <Button
          color={'primary'}
          title='Confirm'
          variant='contained'
          width='100%'
          height='48px'
          disabled={!fieldVals.password_one || !fieldVals.password_two}
          borderRadius='11px'
          fontSize={mediaquery1380 ? '14px' : '16px'}
          fontWeight='500'
          onClick={(e) => handleEnter(e, 'Y')}
        />
      </Stack>

      {SuccessPopup}
    </Dialog>
  );
}
