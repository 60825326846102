import React from 'react';

import { Divider, Stack, Typography } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import { useTemplateContext } from '../../../../../context/TemplateContext/useTemplateContext';
import { DeleteIconCV } from '../../../../../assets/constants';
import EditableTypography from '../../../../../common/EditableTypography';
import { useCvBuilderContext } from '../../../../../context/CvBuilderContext/useCvBuilderContext';

export default function Education({ publicView }) {
  const {
    formatDate,
    addNewEdu,
    educations,
    updateEduField,
    handleDeleteEducation,
  } = useCvBuilderContext();

  return (
    <Stack py={3}>
      <Stack
        py={1}
        spacing={1.2}
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
      >
        <Typography variant='title18Bold' sx={{ fontWeight: '600' }}>
          Education
        </Typography>

        <Typography
          sx={{
            cursor: 'pointer',
          }}
          onClick={addNewEdu}
        >
          <AddCircleOutlineOutlinedIcon
            style={{
              marginRight: '4px',
              height: '20px',
              width: '20px',
              display: publicView && 'none',
            }}
          />{' '}
        </Typography>
      </Stack>
      <Divider sx={{ backgroundColor: '#d3d3d3' }} my={2} />
      {educations?.map((education, i) => {
        return (
          <Stack
            position='relative'
            direction='row'
            key={education?.id}
            alignItems='flex-start'
            justifyContent='space-between'
            sx={{
              '&:hover': {
                '& .del__icon': {
                  display: 'flex',
                },
              },
            }}
          >
            <Stack alignItems='flex-start'>
              <Stack
                direction='row'
                alignItems='center'
                sx={{ color: '#3e6af2' }}
              >
                <EditableTypography
                  p={1}
                  onBlur={(e) =>
                    updateEduField({
                      name: 'degree_start_date',
                      text: e.target.innerText,
                      id: education?.id,
                    })
                  }
                  placeholder={'2019'}
                  contentEditable
                  variant='title17Bold'
                  sx={{ fontWeight: '400' }}
                >
                  {education?.degree_start_date}
                </EditableTypography>
                -{' '}
                <EditableTypography
                  p={1}
                  onBlur={(e) =>
                    updateEduField({
                      name: 'degree_end_date',
                      text: e.target.innerText,
                      id: education?.id,
                    })
                  }
                  placeholder={'Present'}
                  contentEditable
                  variant='title17Bold'
                  sx={{ fontWeight: '400' }}
                >
                  {education?.degree_end_date}
                </EditableTypography>
                -{' '}
                <EditableTypography
                  p={1}
                  onBlur={(e) =>
                    updateEduField({
                      name: 'degree_city',
                      text: e.target.innerText,
                      id: education?.id,
                    })
                  }
                  placeholder={'City, Country'}
                  contentEditable
                  variant='title14Bold'
                  sx={{
                    fontWeight: '400',
                  }}
                >
                  {education?.degree_city}
                </EditableTypography>
              </Stack>
              <Stack>
                <EditableTypography
                  p={1}
                  onBlur={(e) =>
                    updateEduField({
                      name: 'degree_institute',
                      text: e.target.innerText,
                      id: education?.id,
                    })
                  }
                  contentEditable
                  placeholder={'Institution / Place of Education'}
                  variant='title16Bold'
                  sx={{ fontWeight: '700' }}
                >
                  {education?.degree_institute}
                </EditableTypography>
                <EditableTypography
                  p={1}
                  onBlur={(e, isChanged) => {
                    if (isChanged) {
                      updateEduField({
                        name: 'degree_title',
                        text: e.target.innerText,
                        id: education?.id,
                      });
                    }
                  }}
                  contentEditable
                  variant='title14Bold'
                  sx={{ fontWeight: '400' }}
                  placeholder={'Study Program'}
                >
                  {education?.degree_title}
                </EditableTypography>
              </Stack>
            </Stack>

            <Stack
              className='del__icon'
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                right: '0',
                top: '2rem',
                background: '#fff',
                borderRadius: '6px',
                boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.14)',
                height: '40px',
                width: '40px',
                display: 'none',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 10,
              }}
            >
              <DeleteIconCV
                onClick={() => {
                  handleDeleteEducation(education?.id);
                }}
              />
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}
