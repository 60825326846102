import React, { useEffect, useReducer, useRef, useState } from 'react';

import { Typography } from '@mui/material';

export default function EditableTypography({
  variant,
  fontWeight,
  onBlur,
  width,
  children,
  padding,
  paddingX,
  paddingY,
  minWidth,
  color,
  onFocus,
  whiteSpace,
  dangerouslySetInnerHTML,
  placeholder,
}) {
  const [placeholderWidth, setPlaceholderWidth] = useState(10);
  const placeholderRef = useRef(null);
  const editableContentRef = useRef(null);

  const [, forceRerender] = useReducer((x) => x + 1, 0);

  let initialContent = editableContentRef?.current?.innerText;

  // useEffect(() => {
  //     if (editableContentRef?.current) {
  //         initialContent = editableContentRef?.current?.innerText
  //     }
  // }, []);

  const [myPlaceholder, setMyPlaceholder] = useState(placeholder);
  const [myChildren, setMyChildren] = useState(children);

  useEffect(() => {
    setMyChildren(children);
  }, [children]);

  useEffect(() => {
    if (placeholderRef.current) {
      setPlaceholderWidth(placeholderRef.current.offsetWidth + 16);
    }
  }, [placeholderRef]);

  // useEffect(() => {
  //     const tId = setTimeout(() => {
  //         forceRerender();
  //     }, 1000);
  //
  //     return () => clearTimeout(tId);
  // }, [myChildren]);

  return (
    <>
      <Typography
        ref={placeholderRef}
        variant={variant}
        sx={{
          position: 'absolute',
          visibility: 'hidden',
          whiteSpace: 'nowrap',
          fontWeight: fontWeight,
        }}
      >
        {placeholder}
      </Typography>
      <Typography
        ref={editableContentRef}
        p={padding}
        px={paddingX}
        py={paddingY}
        width={width}
        contentEditable
        suppressContentEditableWarning={true}
        variant={variant}
        sx={{
          fontWeight: fontWeight,
          minWidth:
            initialContent == placeholder ||
            children == placeholder ||
            (typeof children === 'string' && children?.trim()?.length < 1)
              ? placeholderWidth
              : 'max-content',
          color: myChildren ? color : '#818181ce',
          whiteSpace: whiteSpace,
          outlineOffset: '3px',
          borderRadius: '0px',
          '&:hover': {
            // outline: '2px solid #999999',
          },
          '&:focus': {
            outline: '2px solid #256ef4',
          },
          position: 'relative',

          '&:empty:before': {
            content: `"${placeholder}"`,
            opacity: 0.5,
            color: '#8099ac',
            position: 'absolute',
            top: '50%',
            left: '8px',
            transform: 'translateY(-50%)',
            pointerEvents: 'none',
            width: 'max-content',
          },
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            editableContentRef?.current?.blur();
          }
          // if (myPlaceholder) {
          //     setMyPlaceholder(null);
          // }
        }}
        onBlur={(e) => {
          const changedText = editableContentRef?.current?.innerText;
          if (typeof onBlur === 'function') {
            onBlur(e, initialContent !== changedText);
          }
          if (!editableContentRef?.current?.innerText) {
            setMyPlaceholder(placeholder);
          } else {
            setMyChildren(changedText);
            setMyPlaceholder(null);
          }
        }}
        onFocus={(e) => {
          if (typeof onFocus === 'function') {
            onFocus(e);
          }
          initialContent = editableContentRef?.current?.innerText;
          if (initialContent == placeholder || children == placeholder) {
            // const wi = placeholderRef.current.offsetWidth + 16;
            // setPlaceholderWidth(wi);
            setMyPlaceholder('');
            setMyChildren(null);
            // forceRerender();
            // editableContentRef.current.style.minWidth = wi;
          }
        }}
        // dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      >
        {myChildren ?? myPlaceholder}
      </Typography>
    </>
  );
}
