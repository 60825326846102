import React from 'react';

import { Stack, Typography, Divider } from '@mui/material';

import ChartGraph from './Chart';
import Avatars from '../../../common/Avatar';

export default function ApplicantDetails(props) {
    const {application} = props;

  return (
    <Stack className='accordion__detail__inner' color='primary.main'>
      <Stack pb={1} direction='row' justifyContent='space-between'>
        <Typography variant='title16Bold'>Senior Software Engineer</Typography>
        <Typography variant='title12Bold' sx={{ fontWeight: '400' }}>
            {application?.cities?.map((city, i) => {
               if(application?.cities?.length - 1 === i) {
                   return `${city?.city}`
               }
                return `${city?.city} | `
            })}
        </Typography>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='title12Bold' sx={{ fontWeight: '400' }}>
          Total Applicants ({application?.totalApps}) - Total Qualified ({application?.totalQualified})
        </Typography>
        <Typography variant='title12Bold' sx={{ fontWeight: '400' }}>
            {application?.matchScore}
          {/*High | Median*/}
        </Typography>
      </Stack>
      <ChartGraph application={application} />
      <Divider sx={{ my: 3 }} />
      <Stack
        sx={{
          '& .MuiAvatar-root': {
            height: '36px',
            width: '36px',
            fontSize: '15px',
          },
        }}
        direction='row'
        alignItems='center'
      >
        <Avatars userName={application?.name} />
        <Stack pl={1.2}>
          <Typography variant='title12Bold' sx={{ fontWeight: '500' }}>
              {application?.name}
          </Typography>
          <Typography variant='title12Bold' sx={{ fontWeight: '400' }}>
              {application?.jobTitle}
          </Typography>
        </Stack>
      </Stack>
      <Stack pt={1.4} direction='row' flexWrap='wrap'>
        {application?.skills?.map((skill, i) => {
          return (
            <Stack
              sx={{
                borderRadius: '13px',
                fontSize: '14px',
                fontWeight: '400',
                marginRight: '.5rem',
                marginBottom: '0',
                marginTop: '0.5rem',
                background: '#ededed',
                width: 'max-content',
                padding: '14px 16px 14px 16px',
              }}
              key={i++}
            >
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: '400',
                }}
                variant='title12Bold'
                color='primary.main'
              >
                  {skill?.skillName}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}
