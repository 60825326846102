import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { Drawer, Stack, Typography } from '@mui/material';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import Style from './style';
import Button from '../../../../common/Button';
import Loader from '../../../../common/Loader';
import { useGetInterviewPermalink } from '../../../../services/career-coach/useGetInterviewPermalink';

const Styles = makeStyles((theme) => Style(theme));

export default function QualifyDrawer({ toggle, setToggle }) {
  const classes = Styles();
  const navigate = useNavigate();
  const { getInterviewPermalink, isGettingPermalink } =
    useGetInterviewPermalink();
  const [detailsDrawer, setDetailsDrawer] = useState(false);

  return isGettingPermalink ? (
    <Loader textWithLoader text1={'Loading...'} />
  ) : (
    <>
      <Drawer
        anchor='bottom'
        open={toggle}
        onClose={() => setToggle(false)}
        className={classes.qualify__drawer}
        sx={{
          '--Drawer-transitionDuration': toggle ? '1s' : '0.1s',
        }}
      >
        <Stack alignItems='center' px={1.5}>
          <Typography pt={3} pb={1} variant='title36Bold'>
            Congrats!
          </Typography>
          <Typography
            pb={0.2}
            variant='title28Bold'
            sx={{ fontWeight: '700', color: '#0aa06a' }}
          >
            Aapne kamaal kar diya!
          </Typography>
          <Typography
            px={1}
            variant='title17Bold'
            sx={{ fontWeight: '500', textAlign: 'center' }}
          >
            Apka interview <br />
            <span
              style={{
                fontWeight: '500',
                color: '#0aa06a',
                lineHeight: '22px',
                fontSize: '20px',
              }}
            >
              {' '}
              Best Interviews Competition
            </span>{' '}
            <br />
            mein qualify karta hai.
          </Typography>
          <Typography
            pt={1.5}
            pb={0.7}
            variant='title25Bold'
            sx={{ color: '#0aa06a' }}
          >
            Rs. 100,000
          </Typography>
          <Typography
            pb={2}
            variant='title17Bold'
            sx={{ fontWeight: '500', textAlign: 'center', lineHeight: '30px' }}
          >
            tak jeetain
            <HelpRoundedIcon
              onClick={() => setDetailsDrawer(true)}
              sx={{ ml: 0.4, height: '18px', width: '18px' }}
            />
          </Typography>
          <Button
            color='primary'
            title='ENTER COMPETITION'
            variant='contained'
            onClick={() => {
              const cachedAnsId = localStorage.getItem('answerId');
              // getInterviewPermalink({ id: cachedAnsId }).then((resp) => {
              //   if (resp.code == '11') {
              //     sessionStorage.setItem('answerPermalink', resp?.permalink);
              //
              //   }
              // });

              navigate('/career-coach/voting/terms');
              setToggle(false);
            }}
            width='100%'
            borderRadius='11px'
            fontSize='14px'
            height='56px'
            fontWeight='400'
            backgroundColor='#1045ff !important'
            textColor='#fff !important'
          />
          <Typography
            my={2}
            variant='title14Bold'
            onClick={() => {
              setToggle(false);
            }}
            sx={{ fontWeight: '400', width: 'max-content' }}
          >
            No Thanks
          </Typography>
        </Stack>
      </Drawer>
      <Drawer
        anchor='bottom'
        open={detailsDrawer}
        onClose={() => setDetailsDrawer(false)}
        className={classes.qualify__drawer}
        sx={{
          '--Drawer-transitionDuration': detailsDrawer ? '1s' : '0.1s',
        }}
      >
        <Stack pt={3} alignItems='left' px={1.5}>
          <Typography variant='title36Bold' sx={{ fontWeight: '700' }}>
            Zabardast jawab, <br /> zabardast prize!
          </Typography>
          <Typography
            pt={2}
            pb={3}
            variant='title17Bold'
            sx={{ fontWeight: '500', lineHeight: '25px', textAlign: 'left' }}
          >
            Behtareen interview jawab{' '}
            <span style={{ color: '#0aa06a', fontWeight: '700' }}>
              {' '}
              Rs. 100,000
            </span>{' '}
            tak jeet sakte hain. Apna interview submit karne se doosre
            professionals aur students aapke behtareen jawab se seekh sakte
            hain. Har koi aapke interview ke liye vote kar sakta hai. Hum aapko
            ek link share kareinge jisse aap apne doston ko invite kar sakte
            hain ke wo bhi is video ke liye vote karein.
          </Typography>
        </Stack>
      </Drawer>
    </>
  );
}
