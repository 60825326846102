import React, { useEffect, useState } from 'react';

import { Divider, Stack, Typography } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import { DeleteIconCV } from '../../../../../assets/constants';
import {
  updateAwardsInfo,
  updateEducationInfo,
} from '../updateTemplateDataApi';
import {
  deleteCvEducation,
  deleteCvExperience,
} from '../deleteTemplateDataApi';
import EditableTypography from '../../../../../common/EditableTypography';
import { EditableContentBox } from '../../../../Common/EditableContentBox';
import {useCvBuilder} from "../../../../../context/CvBuilderContext/useCvBuilder";
import {useCvBuilderContext} from "../../../../../context/CvBuilderContext/useCvBuilderContext";

export default function Education({classes, templateData}) {
  // const {educations, setEducations} = useCvBuilderContext();
  const {
    formatDate,
    addNewEdu,
    educations,
    setEducations,
    updateEduField,
    handleDeleteEducation,
  } = useCvBuilderContext();

  // const handleDeleteEducation = (id) => {
  //   deleteCvEducation(id);
  //   setEducations(educations?.filter((edu) => edu?.id != id));
  // };

  // useEffect(() => {
  //   setEducations(templateData?.educations || []);
  // }, [templateData]);

  // const addNewEdu = () => {
  //   setEducations([{ id: Date.now() }, ...educations]);
  // };

  // const handleDelete = (id) => {
  //   const newEducations = educations?.filter(
  //     (education) => education?.id !== id,
  //   );
  //   deleteCvEducation(id);
  //   setEducations(newEducations);
  // };

  // const replaceEducationId = (eduId, newId) => {
  //   const newEducations = [...educations];
  //   const index = newEducations.findIndex((obj) => obj.id == eduId);
  //   newEducations[index].id = newId;
  //   setEducations(newEducations);
  // };
  //
  // const updateEduField = (params) => {
  //   const { name, text, id } = params;
  //   updateEducationInfo(name, text, id).then((resp) => {
  //     if (resp?.code === '11') {
  //       const eduId = resp?.data?.eduId;
  //       replaceEducationId(id, eduId);
  //     }
  //   });
  // };

  return (
    <>
      <Stack
        py={1}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Divider
          classes={{ root: classes.divider__root }}
          sx={{ width: 'calc(100% - 120px) !important' }}
          textAlign='left'
        >
          <Typography variant='title18Bold' sx={{ fontWeight: '600' }}>
            Education
          </Typography>
        </Divider>
        <Typography
          sx={{
            cursor: 'pointer',
            minWidth: '110px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
          onClick={addNewEdu}
        >
          <AddCircleOutlineOutlinedIcon
            style={{ marginRight: '4px', height: '20px', width: '20px' }}
          />{' '}
          Add New
        </Typography>
      </Stack>
      {educations?.map((education, i) => (
        <Stack
          width='100%'
          position='relative'
          direction='column'
          key={education?.id}
          sx={{
            '&:hover': {
              '& .del__icon': {
                display: 'flex',
              },
            },
          }}
        >
          <Stack
            width='100%'
            direction='row'
            alignItems='flex-start'
            justifyContent='space-between'
          >
            <Stack width='100%' direction='row' alignItems='flex-start'>
              <Stack
                direction='row'
                alignItems='center'
                sx={{ color: '#3e6af2' }}
              >
                <EditableTypography
                  padding={1}
                  onBlur={(e) =>
                      updateEduField({
                      name: 'degree_start_date',
                      text: e.target.innerText,
                      id: education?.id,
                    })
                  }
                  variant='title17Bold'
                  fontWeight='400'
                >
                  {education?.degree_start_date
                    ? formatDate(education?.degree_start_date)
                    : '2019'}
                </EditableTypography>
                -{' '}
                <EditableTypography
                  padding={1}
                  onBlur={(e) =>
                      updateEduField({
                      name: 'degree_end_date',
                      text: e.target.innerText,
                      id: education?.id,
                    })
                  }
                  variant='title17Bold'
                  fontWeight='400'
                >
                  {education?.degree_end_date
                    ? formatDate(education?.degree_end_date)
                    : 'Present'}
                </EditableTypography>
              </Stack>
              <Stack width='100%'>
                <EditableTypography
                  padding={1}
                  onBlur={(e) =>
                      updateEduField({
                      name: 'degree_institute',
                      text: e.target.innerText,
                      id: education?.id,
                    })
                  }
                  variant='title12Bold'
                  fontWeight='400'
                >
                  {education?.degree_institute
                    ? education?.degree_institute
                    : 'Institution / Place of Education'}
                </EditableTypography>
                <EditableTypography
                  padding={1}
                  onBlur={(e) =>
                      updateEduField({
                      name: 'degree_title',
                      text: e.target.innerText,
                      id: education?.id,
                    })
                  }
                  variant='title18Bold'
                  fontWeight='700'
                >
                  {education?.degree_title
                    ? education?.degree_title
                    : 'Study Program'}
                </EditableTypography>

                <EditableContentBox
                  content={
                    education?.degree_details ?? 'Enter education details here'
                  }
                  handleFocusOut={(content) => {
                    updateEduField({
                      text: content,
                      name: 'degree_details',
                      id: education?.id,
                    });
                  }}
                />
                {false && (
                  <EditableTypography
                    padding={1}
                    onBlur={(e) =>
                        updateEduField({
                        name: 'degree_details',
                        text: e.target.innerText,
                        id: education?.id,
                      })
                    }
                    variant='title14Bold'
                    fontWeight='400'
                  >
                    {education?.degree_details
                      ? education?.degree_details
                      : `Course/Thesis/Project`}
                  </EditableTypography>
                )}
              </Stack>
            </Stack>
            <Stack
              sx={{
                fontWeight: '400',
                minWidth: '130px',
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <EditableTypography
                padding={1}
                onBlur={(e) =>
                    updateEduField({
                    name: 'degree_city',
                    text: e.target.innerText,
                    id: education?.id,
                  })
                }
                variant='title14Bold'
                fontWeight='400'
              >
                {education?.degree_city
                  ? education?.degree_city
                  : 'City, Country'}
              </EditableTypography>
            </Stack>
            <Stack
              className='del__icon'
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                right: '0',
                top: '2rem',
                background: '#fff',
                borderRadius: '6px',
                boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.14)',
                height: '40px',
                width: '40px',
                display: 'none',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 10,
              }}
            >
              <DeleteIconCV
                onClick={() => {
                  // handleDelete(education?.id);
                  handleDeleteEducation(education?.id);
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      ))}
    </>
  );
}
