import React from 'react';
import { useNavigate } from 'react-router-dom';

import { makeStyles, useTheme } from '@mui/styles';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { Dialog, Stack, Typography, useMediaQuery } from '@mui/material';

import Style from '../style';
import Button from '../../../../common/Button';
import {
  CameraIonAcessBlack,
  CameraIonAcessGreen,
  LockIconAccess,
  MicIconAccess,
  ReloadIconAccess,
  TestTaken,
} from '../../../../assets/constants';

const Styles = makeStyles((theme) => Style(theme));

export default function Permission({ toggle, setToggle }) {
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const requestPermission = async () => {
    try {
      // Request permission to access camera and microphone again
      await navigator.mediaDevices.getUserMedia({ video:  {facingMode:'user'}, audio: true });
      setToggle(true);

    } catch (error) {
      setToggle(false);
    }
  };
  const StepTile = ({ icon, icon2, text }) => {
    return (
      <Stack
        width='100%'
        direction='row'
        alignItems='center'
        px={1.2}
        py={1.8}
        sx={{
          backgroundColor: icon2 ? '#fff' : '#f3f3f3',
          borderRadius: '8px',
          border: icon2 ? '1px solid #0aa06a' : '1px solid #f3f3f3',
          boxShadow: icon2 ? '0px 2px 34px 0px rgba(0,0,0,0.15)' : 'none',
        }}
      >
        <Stack direction='row' spacnig={1.2} pr={1.2}>
          {icon} {icon2}
        </Stack>
        <Typography
          variant='title12Bold'
          sx={{
            fontWeight: '400',
            width: icon2 ? 'calc(100% - 40px)' : 'calc(100% - 20px)',
          }}
        >
          {text}
        </Typography>
      </Stack>
    );
  };

  return (
    <Dialog open={toggle} className={classes.permission__dialog}>
      <Stack
        p={2}
        pt={1}
        height='100%'
        justifyContent='center'
        alignItems='center'
        color='primary.main'
      >
        <DoNotDisturbIcon
          style={{
            width: '70px',
            height: '70px',
          }}
        />
        <Typography pt={2} sx={{ fontWeight: '600' }} variant={'title20Bold'}>
          Access Blocked
        </Typography>
        <Typography
          variant='title14Bold'
          sx={{ textAlign: 'center', fontWeight: '400' }}
          pt={1}
        >
          To enable camera and microphone permissions, please follow these
          steps:
        </Typography>
        <Stack spacing={1.2} py={3.8} width='100%'>
          <StepTile
            icon={<LockIconAccess />}
            text='Click the lock icon next to te URL in your browser’s address bar.'
          />
          <StepTile
            icon={<CameraIonAcessBlack />}
            text='Find the camera and microphone permissions
          settings.'
          />
          <StepTile
            text='Set both to ‘Allow’.'
            icon={<CameraIonAcessGreen />}
            icon2={<MicIconAccess style={{ marginLeft: '.6rem' }} />}
          />
          <StepTile
            icon={<ReloadIconAccess />}
            text='Click on Allow Access or reload the page.'
          />
        </Stack>
        <Stack
          direction='row'
          space={2}
          width='100%'
          justifyContent='center'
          alignItems='center'
        >
          <Button
            width='100%'
            title='Allow Access'
            color={'primary'}
            variant='contained'
            fontSize={'12px'}
            onClick={requestPermission}
            borderRadius='11px'
            height={'44px'}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
}
