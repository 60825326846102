import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Divider,
  Stack,
  Typography,
  IconButton,
  TextField,
} from '@mui/material';
import { updateLanguageInfo } from '../updateTemplateDataApi';
import { deleteCvLanguage } from '../deleteTemplateDataApi';
import { addCvLanguage } from '../addTemplateDataApi';
import EditableTypography from '../../../../../common/EditableTypography';
import {useCvBuilderContext} from "../../../../../context/CvBuilderContext/useCvBuilderContext";

export default function Language({ classes, templateData, getCv }) {
  const {
    language,
    languages,
    handleDeleteLanguage,
    keyEnterLanguage,
    handleUpdateCvLang,
    handleChangeLangauge,
  } = useCvBuilderContext();

  // const [language, setLanguage] = useState('');
  // const [languages, setLanguages] = useState([...Array(2)]);
  //
  // useEffect(() => {
  //   setLanguages(templateData?.languages);
  // }, [templateData]);

  // const handleChangeLangauge = (e) => {
  //   var { name, value } = e.target;
  //   var value = (value = value?.trimStart());
  //   setLanguage(value);
  // };

  // const addNewLang = async () => {
  //   const resp = await addCvLanguage(language);
  //   setLanguages([...languages, { id: Date.now(), language: language }]);
  //   setLanguage('');
  //   getCv();
  // };

  // const handleDeleteLanguage = (languageId) => {
  //   setLanguages(languages.filter((obj) => obj.id !== languageId));
  //   deleteCvLanguage(languageId);
  // };

  // const keyEnterLanguage = (e) => {
  //   if (e.key === 'Enter') {
  //     const { name, value } = e.target;
  //     if (value === '') {
  //       return;
  //     } else {
  //       addNewLang();
  //     }
  //   }
  // };

  return (
    <>
      <Stack py={1}>
        <Divider classes={{ root: classes.divider__root }} textAlign='left'>
          <Typography variant='title18Bold' sx={{ fontWeight: '600' }}>
            Language
          </Typography>
        </Divider>
      </Stack>
      <Stack
        direction='row'
        flexWrap='wrap'
        alignItems='center'
        spacing={4}
        pl={1.5}
      >
        {languages?.map((k, i) => {
          return (
            <Stack key={k?.id} alignItems='center' direction='row'>
              <IconButton
                sx={{ p: 0 }}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                size='small'
              >
                <CloseIcon
                  onClick={() => handleDeleteLanguage(k?.id)}
                  sx={{ fontSize: '15px' }}
                />
              </IconButton>
              <EditableTypography
                paddingX={1}
                paddingY={0.6}
                width='max-content'
                variant='title14Bold'
                fontWeight='400'
                color='#212121'
                onBlur={(e) =>
                    handleUpdateCvLang({content: e.target.innerText, langId: k?.id})
                }
              >
                {k?.language}
              </EditableTypography>
            </Stack>
          );
        })}
        <Stack alignItems='center' direction='row'>
          <IconButton
            sx={{ p: 0 }}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            size='small'
          >
            <AddIcon sx={{ fontSize: '15px' }} />
          </IconButton>
          <Stack
            sx={{
              '& .MuiInputBase-root': {
                '& input': {
                  padding: '0.35rem .5rem',
                  fontSize: '14px',
                  fontWeight: '400',
                },
              },
            }}
          >
            <TextField
              value={language}
              onChange={handleChangeLangauge}
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              onKeyDown={keyEnterLanguage}
              placeholder='Add new language'
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
