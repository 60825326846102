import React, { useContext, useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { useTheme } from '@emotion/react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import {
  Drawer,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

import Button from '../../../common/Button';
import SwitchMain from '../../../common/Switch';
import { CareerCoachInterviewContext } from '../VideoInterview/CareerCoachInterviewContext';

import Style from './style';
import { isEmpty } from '../../../helpers/common';

const Styles = makeStyles((theme) => Style(theme));

export default function Content({ setCategoryToggle, isFromDashboard }) {
  const classes = Styles();
  const theme = useTheme();

  const [tempQType, setTempQType] = useState(0); // Temporary state for dropdown selection
  // const [types, setTypes] = useState([
  //   { type: 'Adaptability', name: 'Adaptability' },
  //   { type: 'Attention to Detail', name: 'Attention to Detail' },
  //   { type: 'Accountability', name: 'Accountability' },
  //   { type: 'Budget Management', name: 'Budget Management' },
  //   { type: 'Building Relationships', name: 'Building Relationships' },
  //   {
  //     type: 'Cross-Cultural Communication',
  //     name: 'Cross-Cultural Communication',
  //   },
  //   { type: 'Communication', name: 'Communication' },
  //   { type: 'Creativity and Innovation', name: 'Creativity and Innovation' },
  //   { type: 'Collaboration', name: 'Collaboration' },
  //   { type: 'Conflict Resolution', name: 'Conflict Resolution' },
  //   { type: 'Continuous Learning', name: 'Continuous Learning' },
  //   { type: 'Customer Focus', name: 'Customer Focus' },
  //   { type: 'Conflict Avoidance', name: 'Conflict Avoidance' },
  //   { type: 'Decision-Making', name: 'Decision-Making' },
  //   { type: 'Crisis Management', name: 'Crisis Management' },
  //   {
  //     type: 'Decision-Making Under Uncertainty',
  //     name: 'Decision-Making Under Uncertainty',
  //   },
  //   { type: 'Dependability', name: 'Dependability' },
  //   { type: 'Delegation', name: 'Delegation' },
  //   {
  //     type: 'Ethical Judgment and Integrity',
  //     name: 'Ethical Judgment and Integrity',
  //   },
  //   { type: 'Emotional Intelligence', name: 'Emotional Intelligence' },
  //   { type: 'Ethical Leadership', name: 'Ethical Leadership' },
  //   { type: 'Goal Orientation', name: 'Goal Orientation' },
  //   { type: 'Innovation Management', name: 'Innovation Management' },
  //   { type: 'Influencing Others', name: 'Influencing Others' },
  //   { type: 'Initiative', name: 'Initiative' },
  //   { type: 'Leadership', name: 'Leadership' },
  //   { type: 'Organizational Awareness', name: 'Organizational Awareness' },
  //   { type: 'Problem-Solving', name: 'Problem-Solving' },
  //   { type: 'Patience', name: 'Patience' },
  //   { type: 'Process Improvement', name: 'Process Improvement' },
  //   { type: 'Project Management', name: 'Project Management' },
  //   { type: 'Resilience', name: 'Resilience' },
  //   { type: 'Resourcefulness', name: 'Resourcefulness' },
  //   { type: 'Service Orientation', name: 'Service Orientation' },
  //   { type: 'Self-Motivation', name: 'Self-Motivation' },
  //   { type: 'Stakeholder Management', name: 'Stakeholder Management' },
  //   { type: 'Stress Management', name: 'Stress Management' },
  //   { type: 'Strategic Thinking', name: 'Strategic Thinking' },
  //   { type: 'Teamwork', name: 'Teamwork' },
  //   { type: 'Time Management', name: 'Time Management' },
  //   { type: 'Negotiation Skills', name: 'Negotiation Skills' },
  //   { type: 'Cultural Awareness', name: 'Cultural Awareness' },
  //   { type: 'Mentoring and Coaching', name: 'Mentoring and Coaching' },
  //   { type: 'Negotiation', name: 'Negotiation' },
  //   { type: 'Operational Efficiency', name: 'Operational Efficiency' },
  //   { type: 'Visionary Leadership', name: 'Visionary Leadership' },
  //   { type: 'Work Ethic', name: 'Work Ethic' },
  //   { type: 'Work-Life Balance', name: 'Work-Life Balance' },
  // ]);

  const [secondDrawerOpen, setSecondDrawerOpen] = useState(false); // State for second drawer

  const {
    setPaymentDrawer,
    setQType,
    selectedQType,
    setLanguage,
    language,
    setId,
    setToggle,
    setCurrentQuestion,
    interviewTypes,
    getInterviewsTypes,
    creditsUsed,
    setStartInterview,
    remainingCredits,
  } = useContext(CareerCoachInterviewContext);

  const navigate = useNavigate();

  useEffect(() => {
    getInterviewsTypes();
  }, []);

  const handleSelect = () => {
    setSecondDrawerOpen(true); // Open second drawer on select click
  };

  const handleMenuItemClick = (type) => {
    if (parseInt(remainingCredits) === 0 && parseInt(type.is_free) === 2) {
      setPaymentDrawer(true);
    } else {
      setTempQType(type?.name); // Set the selected type to tempQType
      setSecondDrawerOpen(false); // Close second drawer
    }
  };

  const handleContinue = () => {
    if (!isFromDashboard) {
      setCategoryToggle(false);
    }
    if (isFromDashboard) {
      const careerCoachInterview = localStorage.getItem('careerCoachInterview');

      if (!isEmpty(careerCoachInterview)) {
        if (selectedQType !== tempQType) {
          setCurrentQuestion(null);
        }
        setQType(tempQType);
        setId(careerCoachInterview);
        setStartInterview(true);
        setToggle(true);
        navigate(`/career-coach/interview/${careerCoachInterview}`, {
          replace: true,
        });
      }
    }
  };

  const handleSelectLangauge = (type) => {
    setLanguage(type);
  };

  return (
    <>
      <Stack
        py={isFromDashboard ? 0 : 3}
        px={isFromDashboard ? 0 : 2}
        direction='column'
        height={isFromDashboard ? 'max-content' : '100%'}
        width='100%'
        justifyContent='flex-start'
        alignItems='center'
        color='primary.main'
      >
        <Typography
          pt={3}
          variant='title30Bold'
          sx={{ fontWeight: '700', lineHeight: '32px' }}
        >
          Choose what type of interview you would like to practice
        </Typography>
        <FormControl
          sx={{ mt: '27px', width: '100%' }}
          className={classes.dropdown__main}
          onClick={handleSelect}
        >
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={tempQType}
            sx={{
              '& .MuiPopover-paper.MuiMenu-paper': {
                boxShadow: 'none',
              },
            }}
            disabled
            IconComponent={() => <KeyboardArrowDownIcon />}
          >
            <MenuItem value={0} style={{ display: 'none' }}>
              Select an interview
            </MenuItem>
            {interviewTypes?.map((type, tIndex) => {
              return (
                <MenuItem
                  key={tIndex} // Add key to prevent warning
                  sx={{
                    borderRadius: '6px',
                    fontSize: '14px',
                    fontWeight: '400',
                  }}
                  value={type?.name}
                >
                  {type?.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <Stack my={'20px'} width='100%' direction='row' alignItems='center'>
          <Typography pr={1} variant='title12Bold' sx={{ fontWeight: '500' }}>
            English
          </Typography>
          <SwitchMain
            islangaugeswitch='true'
            onChange={(e) =>
              handleSelectLangauge(e?.target?.checked ? 'roman' : 'english')
            }
            checked={language === 'roman'}
          />
          <Typography pl={1} variant='title12Bold' sx={{ fontWeight: '500' }}>
            Urdu
          </Typography>
        </Stack>

        <Stack width='100%' className={classes.choose__btn}>
          <Button
            disabled={tempQType === 0}
            variant='contained'
            title='Continue'
            onClick={handleContinue} // Handle continue click
          />
        </Stack>
      </Stack>

      {/* Second Drawer for Menu Items */}
      <Drawer
        anchor='bottom'
        open={secondDrawerOpen}
        onClose={() => setSecondDrawerOpen(false)}
        className={classes.select__option__drawer}
      >
        <Stack
          pt={3}
          px={2}
          direction='column'
          height='100%'
          width='100%'
          justifyContent='flex-start'
          alignItems='center'
          color='primary.main'
        >
          <Stack
            width='100%'
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            pb={2}
          >
            <Typography variant='title16Bold' sx={{ fontWeight: '700' }}>
              Behavioral Questions
            </Typography>
            <KeyboardArrowUpIcon onClick={() => setSecondDrawerOpen(false)} />
          </Stack>
          <Stack
            px={0}
            width='100%'
            sx={{
              height: 'calc(100% - 30px)',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '5px',
                height: '5px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#e4e4e400',
                boxShadow: 'none',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#e4e4e4',
                borderRadius: '16px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#010103',
                borderRadius: '16px',
              },
            }}
          >
            {interviewTypes?.map((type, tIndex) => (
              <Stack
                key={tIndex}
                sx={{
                  borderRadius: '6px',
                  fontSize: '18px',
                  fontWeight: type?.is_free === '1' ? '600' : '400',
                  marginBottom: '0px',
                  minHeight: 'max-content',
                  paddingLeft: '0',
                  paddingBottom: '16px',
                  paddingTop: '0',
                  color:
                    parseInt(remainingCredits) === 0 &&
                    parseInt(type.is_free) === 2
                      ? 'rgba(1, 1, 3, 0.3)'
                      : type?.is_free === '1'
                      ? '#256EF4'
                      : '#010103',
                }}
                onClick={() => handleMenuItemClick(type)}
              >
                {type.name}
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
}
