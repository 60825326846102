import React, { useState, useEffect } from 'react';

import clsx from 'clsx';
import { useTheme, makeStyles } from '@mui/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  Stack,
  Accordion,
  Typography,
  Pagination,
  useMediaQuery,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import Style from './style';
import ApplicantDetails from './ApplicantDetails';
import { headers, usefetch } from '../../../helpers/fetch';
import { convertServerDateFormate } from '../../../helpers/dateHelper';
import ListingLoader from '../../../common/Loader/ListingLoader';
import { EyeIcon } from '../../../assets/constants';

const Styles = makeStyles((theme) => Style(theme));

export default function MyApplications() {
  const theme = useTheme();
  const classes = Styles();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [applications, setApplications] = useState([]);

  const rowsPerPage = 5;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getJobApplications = async (start) => {
    // const resp = await usefetch(
    //   'applications/userApplications',
    //   'post',
    //   headers(),
    //   {
    //     start: start * rowsPerPage,
    //     limit: rowsPerPage,
    //   },
    // );
    setLoading(true);

    usefetch('applications/userApplications', 'post', headers(), {
      start: start * rowsPerPage,
      limit: rowsPerPage,
    }).then(function (resp) {
      if (resp?.code === '11') {
        setLoading(false);
        setApplications(resp?.data?.appliedJobs);
        setCount(resp?.data?.appliedCount);
      }
    });
  };

  const onDataPageChange = (event, page) => {
    setPage(page);
    getJobApplications(page - 1);
  };

  useEffect(() => {
    if (window.location.pathname === "/dashboard/my-applications") {
      getJobApplications();
    }
  }, []);

  return (
    <>
      <Stack
        width='100%'
        sx={{
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
          },
          '&::-webkit-scrollbar-button': {
            height: '90px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#e4e4e400',
            boxShadow: 'none',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#e4e4e4',
            borderRadius: '16px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#010103',
            borderRadius: '16px',
          },
        }}
      >
        <Typography
          pb={1.5}
          variant={mediaquery1380 ? 'title24Bold' : 'title34Bold'}
          sx={{ fontWeight: '700', position: 'sticky', left: '0' }}
        >
          My Applications
        </Typography>
        <Typography
          pb={2}
          variant={mediaquery1380 ? 'title14Bold' : 'title18Bold'}
          sx={{ fontWeight: '400', position: 'sticky', left: '0' }}
        >
          You have applied for the jobs below through RozeeGpt.
        </Typography>
        <Stack pt={3} className={classes.main__applicant__table}>
          <Stack direction='row' className={classes.table__header}>
            <Typography
              variant={mediaquery1380 ? 'title16Bold' : 'title18Bold'}
              sx={{ fontWeight: '600' }}
              className={clsx(classes.divided__width, classes.heading)}
            >
              Job Title
            </Typography>
            <Typography
              variant={mediaquery1380 ? 'title16Bold' : 'title18Bold'}
              sx={{ fontWeight: '600' }}
              className={clsx(classes.divided__width, classes.heading)}
            >
              Company
            </Typography>
            <Typography
              variant={mediaquery1380 ? 'title16Bold' : 'title18Bold'}
              sx={{ fontWeight: '600' }}
              className={clsx(classes.divided__width__last, classes.heading)}
            >
              Date
            </Typography>
          </Stack>
          {loading ? (
            <ListingLoader
              count={[...Array(5)]}
              lengthRow={[...Array(3)]}
              hideLastDot
            />
          ) : (
            applications?.map((application, i) => {
              return (
                <Accordion
                  key={application + i}
                  className={classes.accordion}
                  expanded={expanded === `panel${i + 1}`}
                  onChange={handleChange(`panel${i + 1}`)}
                >
                  <AccordionSummary
                    className={classes.accordion__summary}
                    id={`panel${i++}-header`}
                    expandIcon={
                      <KeyboardArrowDownIcon
                        sx={{ color: '#010103', height: '19px', width: '19px' }}
                      />
                    }
                  >
                    <Stack color='primary.main' width='100%' direction='row'>
                      <Stack className={classes.divided__width}>
                        <Stack direction='row' alignItems='center'>
                          <EyeIcon
                            style={{
                              marginLeft: '1rem',
                              marginRight: '0.5rem',
                            }}
                          />
                          <Stack>
                            <Typography
                              variant={
                                mediaquery1380 ? 'title14Bold' : 'title16Bold'
                              }
                              sx={{ fontWeight: '400' }}
                              pl={1}
                              pb={0.3}
                              className={clsx(classes.content)}
                            >
                              {application?.job_title}
                            </Typography>
                            <Typography
                              pl={1}
                              variant='title10Bold'
                              className={clsx(classes.content)}
                              color='error.main'
                              sx={{ fontWeight: '500' }}
                            >
                              Pending Test
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Typography
                        variant={mediaquery1380 ? 'title14Bold' : 'title16Bold'}
                        sx={{ fontWeight: '400' }}
                        className={clsx(
                          classes.divided__width,
                          classes.content,
                        )}
                      >
                        {application?.company_name}
                      </Typography>
                      <Typography
                        variant={mediaquery1380 ? 'title14Bold' : 'title16Bold'}
                        sx={{ fontWeight: '400' }}
                        className={clsx(
                          classes.divided__width__last,
                          classes.content,
                        )}
                      >
                        {convertServerDateFormate(application?.applied_at)}
                      </Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordion__details}>
                    <ApplicantDetails application={application} />
                  </AccordionDetails>
                </Accordion>
              );
            })
          )}
        </Stack>
        {/*{Math.ceil(count / rowsPerPage) > 1 && (*/}
      </Stack>
      {Math.ceil(count / rowsPerPage) > 1 && (
        <Stack
          py={2}
          direction='row'
          justifyContent={smMediaquery ? 'center' : 'flex-end'}
          alignItems='flex-end'
        >
          <Pagination
            count={Math.ceil(count / rowsPerPage)}
            showFirstButton
            showLastButton
            page={page}
            siblingCount={0}
            onChange={onDataPageChange}
            sx={{ position: 'relative', right: '1rem' }}
          />
        </Stack>
      )}
    </>
  );
}
