const Style = (theme) => ({
  main__dialog__success: {
    '& .MuiDialog-paper': {
      height: '240px !important',
      width: '350px !important',
      padding: '0.5rem',
      margin: '0 !important',
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        margin: '10px',
      },
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
});
export default Style;
