import React, { useEffect } from 'react';

import { Divider, Stack, Typography } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import { useTemplateContext } from '../../../../../context/TemplateContext/useTemplateContext';
import { DeleteIconCV } from '../../../../../assets/constants';
import EditableTypography from '../../../../../common/EditableTypography';
import { useCvBuilderContext } from '../../../../../context/CvBuilderContext/useCvBuilderContext';

export default function Employment({ publicView }) {
  const {
    updateFieldExp,
    formatDate,
    addNewExp,
    experiences,
    handleDeleteExp,
  } = useCvBuilderContext();

  return (
    <Stack pt={2}>
      <Stack
        py={1}
        spacing={1.2}
        direction='row'
        alignItems='center'
        justifyContent='flex-start'
      >
        <Typography variant='title18Bold' sx={{ fontWeight: '600' }}>
          Employment
        </Typography>

        <Typography
          sx={{
            cursor: 'pointer',
          }}
          onClick={addNewExp}
        >
          <AddCircleOutlineOutlinedIcon
            style={{
              marginRight: '4px',
              height: '20px',
              width: '20px',
              display: publicView && 'none',
            }}
          />{' '}
        </Typography>
      </Stack>

      {experiences?.map((experience, i) => {
        return (
          <Stack
            pb={3}
            position='relative'
            key={experience?.id}
            direction='row'
            alignItems='flex-start'
            justifyContent='space-between'
            sx={{
              '&:hover': {
                '& .del__icon': {
                  display: 'flex',
                },
              },
            }}
          >
            <Stack alignItems='flex-start'>
              <Stack pb={1} direction='row' alignItems='center'>
                <EditableTypography
                  py={1}
                  onBlur={(e) =>
                    updateFieldExp({
                      name: 'start_date',
                      text: e.target.innerText,
                      id: experience?.id,
                    })
                  }
                  contentEditable
                  placeholder={'2019'}
                  variant='title17Bold'
                  fontWeight='400'
                >
                  {experience?.start_date}
                </EditableTypography>
                &nbsp;-&nbsp;
                <EditableTypography
                  py={1}
                  onBlur={(e) =>
                    updateFieldExp({
                      name: 'end_date',
                      text: e.target.innerText,
                      id: experience?.id,
                    })
                  }
                  contentEditable
                  placeholder={'Present'}
                  variant='title17Bold'
                  fontWeight='400'
                >
                  {experience?.end_date}
                </EditableTypography>
              </Stack>
              <Stack>
                <EditableTypography
                  p={1}
                  onBlur={(e) => {
                    updateFieldExp({
                      name: 'company_name',
                      text: e.target.innerText,
                      id: experience?.id,
                    });
                  }}
                  placeholder={'Company Name'}
                  variant='title16Bold'
                  fontWeight='700'
                  color='#3e6af2'
                >
                  {experience?.company_name}
                </EditableTypography>
                <Stack pt={1}>
                  <EditableTypography
                    p={1}
                    onBlur={(e) =>
                      updateFieldExp({
                        name: 'job_title',
                        text: e.target.innerText,
                        id: experience?.id,
                      })
                    }
                    contentEditable
                    placeholder='Senior Software Engineer'
                    variant='title14Bold'
                    fontWeight='400'
                  >
                    {experience?.job_title}
                  </EditableTypography>
                </Stack>
                <Stack py={1}>
                  <EditableTypography
                    p={1}
                    onBlur={(e) =>
                      updateFieldExp({
                        name: 'job_city',
                        text: e.target.innerText,
                        id: experience?.id,
                      })
                    }
                    contentEditable
                    placeholder={'Lahore'}
                    variant='title14Bold'
                    fontWeight='400'
                  >
                    {experience?.job_city}
                  </EditableTypography>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              className='del__icon'
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                right: '0',
                top: '2rem',
                background: '#fff',
                borderRadius: '6px',
                boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.14)',
                height: '40px',
                width: '40px',
                display: 'none',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 10,
              }}
            >
              <DeleteIconCV
                onClick={() => {
                  handleDeleteExp(experience?.id);
                }}
              />
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}
