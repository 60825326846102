import React from 'react';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import { TextField, Typography, useMediaQuery } from '@mui/material';
import { NumericFormat } from 'react-number-format';

import Style from './style';

const Styles = makeStyles((theme) => Style(theme));

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      // prefix='$'
    />
  );
});

export default function Textfield({
  name,
  type,
  label,
  value,
  margin,
  height,
  shrink,
  onBlur,
  variant,
  maxRows,
  onEnter,
  onFocus,
  inputRef,
  endProps,
  onChange,
  disabled,
  autoFocus,
  inputMode,
  multiline,
  showField,
  maxLength,
  tableInput,
  startProps,
  helperChip,
  helperText,
  placeholder,
  noHelperText,
  endAdornment,
  specificParam,
  defaultValue,
  darkModeField,
  inputComponent,
  textCapitalize,
  startAdornment,
  startAdornText,
  defineMaxRows,
  defineMinRows,
  isFromPostNewJob,
  autoComplete = 'off',
}) {
  const containerRef = React.useRef(null);
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaQuery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  return (
    <div
      className='d-flex flex-column control-width'
      style={{ height: 'max-content' }}
    >
      {label ? (
        <Typography
          htmlFor={name}
          variant={
            smMediaquery
              ? 'title16Bold'
              : isFromPostNewJob
              ? 'title20Bold'
              : mediaQuery1380
              ? 'title20Bold'
              : 'mainInput'
          }
          color={darkModeField ? 'primary.lightMain' : 'primary.main'}
          className={clsx('label__main', showField === 0 && 'label__animation')}
          sx={{ whiteSpace: 'nowrap', fontWeight: '500' }}
        >
          {label}
        </Typography>
      ) : null}
      <TextField
        {...specificParam}
        autoComplete={autoComplete}
        name={name}
        type={type}
        hiddenLabel
        {...startProps}
        {...endProps}
        size='small'
        value={value}
        inputMode={inputMode}
        onFocus={onFocus}
        disabled={disabled}
        onKeyDown={onEnter}
        margin={margin}
        variant={variant}
        rows={maxRows}
        maxRows={defineMaxRows}
        minRows={defineMinRows}
        onBlur={onBlur}
        autoFocus={autoFocus}
        multiline={multiline}
        placeholder={placeholder}
        defaultValue={defaultValue}
        error={helperText?.length > 0}
        helperText={helperText ? helperText : noHelperText ? null : ' '}
        inputRef={inputRef}
        sx={{
          border: 'none',
          '& fieldset': { border: helperText ? '1px solid #ff3636' : 'none' },
        }}
        className={clsx(
          'textfield__global',
          classes.style__main,
          height && classes.custom__height,
          tableInput && classes.table__input,
          helperText && classes.error__field,
          multiline && classes.multiline__input,
          darkModeField && classes.darkModeField,
          isFromPostNewJob && classes.post__formField,
          startAdornText && classes.style__main__startAdorn,
        )}
        onChange={onChange}
        InputProps={{
          // disableUnderline: true,
          inputComponent: inputComponent && NumericFormatCustom,
          shrink: shrink,
          endAdornment: endAdornment,
          startAdornment: startAdornment,
        }}
        inputProps={{
          style: { textTransform: textCapitalize && 'capitalize' },
          maxLength: maxLength,
          sx: {
            '&::placeholder': {
              color: !darkModeField && '#d6d6d6',
              opacity: !darkModeField && 1,
            },
          },
        }}
        // bellow 3 line code for FormHelperTextProps added by asif
        // FormHelperTextProps={{
        //   className: 'helper__text',  // Setting className for helperText here
        // }}
      />
      {helperChip && (
        <Typography
          color='primary.medium'
          varinat='title14Bold'
          sx={{ fontWeight: '600', lineHeight: '20px' }}
        >
          {helperChip}
        </Typography>
      )}
    </div>
  );
}
