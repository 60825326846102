import React, { useContext } from 'react';
import { Grow, Snackbar } from '@mui/material';

export default function SnackbarCustom({
  response,
  handleClose,
  autoHideDuration,
  verticalPosition,
}) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: verticalPosition ? verticalPosition : 'top',
        horizontal: 'center',
      }}
      sx={{
        '& .MuiSnackbarContent-root': {
          background: '#010103',
          color: '#fff',
        },
      }}
      TransitionComponent={Grow}
      autoHideDuration={autoHideDuration ? autoHideDuration : 3000}
      open={response?.length > 0}
      message={response}
      onClose={handleClose}
    />
  );
}
