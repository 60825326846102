import React, { useContext } from 'react';

import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Drawer, Stack, Divider } from '@mui/material';

import Style from './style';
import { CareerCoachInterviewContext } from '../VideoInterview/CareerCoachInterviewContext';

const Styles = makeStyles((theme) => Style(theme));

export default function RemainingCreditsDrawer({
  toggleRemCredits,
  setToggleRemCredits,
}) {
  const classes = Styles();
  const { remainingCredits } = useContext(CareerCoachInterviewContext);
  return (
    <Drawer
      anchor='bottom'
      open={toggleRemCredits}
      onClose={() => setToggleRemCredits(false)}
      className={classes.question__dialog}
      sx={{
        '--Drawer-transitionDuration': toggleRemCredits ? '1s' : '0.1s',
      }}
    >
      {/*  <CloseIcon
        sx={{
          position: 'absolute',
          right: '0.5rem',
          top: '0.5rem',
          color: '#010103',
          '& .MuiMenuItem-root': {
            paddingLeft: '20px',
          },
        }}
        onClick={() => setToggleRemCredits(false)}
      /> */}
      <Stack
        m={1.5}
        sx={{
          borderRadius: '14px',
          border: '1px solid #d3d3d3',
          padding: '20px',
        }}
      >
        <Typography variant='title18Bold' sx={{ fontWeight: '700' }}>
          Remaining Credits ({parseInt(remainingCredits)})
        </Typography>
        <Typography
          pt={1}
          pb={2}
          variant='title14Bold'
          sx={{ fontWeight: '500', color: '#de1135' }}
        >
          Left: 4 days
        </Typography>
        <Stack
          sx={{
            borderRadius: '14px',
            border: '1px solid #d3d3d3',
            padding: '20px',
          }}
        >
          <Typography variant='title14Bold' sx={{ fontWeight: '400' }}>
            Buy: Aug 22. 2024
          </Typography>

          <Divider sx={{ my: '14px', backgroundColor: '#d3d3d3' }} />
          <Typography variant='title14Bold' sx={{ fontWeight: '400' }}>
            Expiry: Sep 22. 2024
          </Typography>
        </Stack>
      </Stack>
    </Drawer>
  );
}
