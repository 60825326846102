import React, { useState } from 'react';

import { Divider, Stack, Typography, ClickAwayListener } from '@mui/material';

import QuickActions from '../QuickActions';
import EditableTypography from '../../../../../common/EditableTypography';
import { EditableContentBox } from '../../../../Common/EditableContentBox';
import {useCvBuilderContext} from "../../../../../context/CvBuilderContext/useCvBuilderContext";

export default function Summary({classes}) {
    const {fieldVals, handleTextChange} = useCvBuilderContext();
  const [showQuickActions, setShowQuickAction] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setShowQuickAction(false)}>
      <Stack width='100%' position='relative'>
        <Stack py={1}>
          <Divider classes={{ root: classes.divider__root }} textAlign='left'>
            <Typography variant='title18Bold' sx={{ fontWeight: '600' }}>
              Summary
            </Typography>
          </Divider>
        </Stack>
        <EditableContentBox
          content={fieldVals?.summary}
          handleFocusOut={(content) => {
            handleTextChange({ target: { textContent: content } }, 'summary');
          }}
        />

        {showQuickActions === 'summary' && (
          <QuickActions top='-4rem' deleteable />
        )}
      </Stack>
    </ClickAwayListener>
  );
}
