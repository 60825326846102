const Style = (theme) => ({
  main__dialog__matchedCandidate: {
    '& .MuiDialog-paper': {
      width: '500px !important',
      height: '300px',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px',
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  file__upload__btn: {
    height: '38px',
    borderRadius: '8px',
    width: '141px',
    fontSize: '14px',
    textTransform: 'capitalize',
    border: '1px solid #010103 !important',
  },
  delete__btn: {
    height: '38px',
    borderRadius: '8px',
    width: '86px',
    fontSize: '14px',
    textTransform: 'capitalize',
    background: '#fff !important',
    border: '1px solid #010103 !important',
  },
  main__btn__cancel: {
    height: '52px',
    width: '157px',
    fontSize: '16px',
    borderRadius: '10px',
    textTransform: 'capitalize',
    background: '#fff !important',
    border: '1px solid #010103 !important',
  },
  main__btn__save: {
    height: '52px',
    width: '157px',
    fontSize: '16px',
    borderRadius: '10px',
    textTransform: 'capitalize',
    border: '1px solid #010103 !important',
  },
});
export default Style;
