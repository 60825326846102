const Style = (theme) => ({
  payment__dialog: {
    zIndex: '1500 !important',
    '& .MuiDrawer-paper': {
      width: '100% !important',
      height: '100vh',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '0px',
      background:
        'linear-gradient(180deg, rgba(1, 1, 3, 0.87) -34.59%, rgba(0,0,0,0.87) 100%)',
    },
    '& .MuiModal-backdrop': {
      backgroundColor:
        'linear-gradient(180deg, rgba(1, 1, 3, 0.87) -34.59%, #000 100%)',
      backdropFilter: 'blur(4px)',
    },
  },
  upgrade__premium: {
    zIndex: '1600 !important',
    '& .MuiDialog-paper': {
      width: '710px !important',
      maxWidth: '710px !important',
      height: '340px',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px',
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        margin: '10px',
      },
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  upgrade__premium__sm: {
    display: 'flex',
    alignItems: 'flex-end',
    zIndex: '1600 !important',
    '& .MuiDrawer-paper': {
      width: '100% !important',
      height: 'max-content',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px 10px 0 0',
      boxShadow: 'none',
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  main__dialog__success__custom: {
    '& .MuiDialog-paper': {
      width: '360px !important',
      height: '280px',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px',
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        margin: '10px',
      },
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  btn__main__payment: {
    [theme.breakpoints.down('md')]: {
      BorderTop: '1px solid #d3d3d3',
      position: 'fixed',
      bottom: '0',
      width: '100%',
      left: '0',
      bottom: '0',
    },
  },
});
export default Style;
