import React, { useState, useEffect, useRef, useContext } from "react";

import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Box,
  Stack,
  useMediaQuery,
} from "@mui/material";

import Style from "./style";
import TemplateLoader from "../../../components/Seeker/TemplateLoader";
import {useCvBuilderContext} from "../../../context/CvBuilderContext/useCvBuilderContext";
import {useParams} from "react-router-dom";

const Styles = makeStyles((theme) => Style(theme));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 0,
            height: "100%",
            paddingLeft: props.smMediaquery ? 0 : ".5rem",
          }}
        >
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


export default function CvPublicView() {
  const classes = Styles();
  const theme = useTheme();

  const {getPermalinkCv, cvInfo} = useCvBuilderContext();

  const {cvlink} = useParams();

  const getCv = () => {
    getPermalinkCv(cvlink);
  }

  useEffect(() => {
    getCv();
  }, []);




  return (
    <Stack direction="row">
      <Stack
        sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
        className={classes.templates}
      >
        <TemplateLoader
            getCv={getCv}
            templateId={cvInfo?.templateCode}
            templateData={cvInfo}
        />
          {/*{allTabs[activeTab].component}*/}
      </Stack>
    </Stack>
  );
}
