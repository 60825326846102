import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Divider,
  IconButton,
  Stack,
  Typography,
  TextField,
  ClickAwayListener,
} from '@mui/material';

import QuickActions from '../QuickActions';
import { useTemplateContext } from '../../../../../context/TemplateContext/useTemplateContext';
import { EditableContentBox } from '../../../../Common/EditableContentBox';
import { useCvBuilderContext } from '../../../../../context/CvBuilderContext/useCvBuilderContext';

export default function Summary() {
  const { fieldVals, handleTextChange, setShowQuickAction, showQuickActions } =
    useCvBuilderContext();

  return (
    <Stack py={3}>
      <ClickAwayListener onClickAway={() => setShowQuickAction(false)}>
        <Stack width='100%' position='relative'>
          <Stack
            py={1}
            spacing={1.2}
            direction='row'
            alignItems='center'
            justifyContent='flex-start'
          >
            <Typography variant='title18Bold' sx={{ fontWeight: '600' }}>
              Summary
            </Typography>
          </Stack>

          <EditableContentBox
              placeholder={'Tell us about yourself'}
              content={fieldVals?.summary}
            handleFocusOut={(content) => {
              handleTextChange({ target: { textContent: content } }, 'summary');
            }}
          />

          {false && (
            <Typography
              p={1}
              contentEditable
              variant='title14Bold'
              sx={{
                fontWeight: '400',
              }}
              onFocus={() => {
                setShowQuickAction('link');
              }}
              onBlur={(e) => handleTextChange(e, 'summary')}
            >
              {fieldVals?.summary}
            </Typography>
          )}

          {showQuickActions === 'summary' && (
            <QuickActions top='-4rem' deleteable />
          )}
        </Stack>
      </ClickAwayListener>
    </Stack>
  );
}
