const Style = (theme) => ({
  question__dialog: {
    display: 'flex',
    alignItems: 'flex-end',
    '& .MuiDrawer-paper': {
      width: '100% !important',
      height: 'max-content',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px 10px 0 0',
      boxShadow: 'none',
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  main__dialog__attempts: {
    '& .MuiDialog-paper': {
      width: '341px !important',
      height: '170px',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px',
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        margin: '10px',
      },
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
});

export default Style;
