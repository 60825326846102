import React, { useState } from 'react';
import {
  Stack,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import clsx from 'clsx';
import { useTheme, makeStyles } from '@mui/styles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Style from './style';

const Styles = makeStyles((theme) => Style(theme));

export default function AccordionMain({
  name,
  onChange,
  expandedAccordion,
  children,
  title,
}) {
  const classes = Styles();

  return (
    <Accordion
      className={classes.accordion}
      expanded={expandedAccordion}
      onChange={onChange(name)}
      sx={{
        '& .MuiAccordionSummary-root.Mui-expanded': {
          minHeight: '48px',
        },
      }}
    >
      <AccordionSummary
        className={classes.accordion__summary}
        id={`panel${name++}-header`}
        expandIcon={
          <ExpandMoreIcon
            sx={{ color: '#010103', height: '19px', width: '19px' }}
          />
        }
      >
        <Stack color='primary.main' width='100%' direction='row'>
          <Typography className={clsx(classes.divided__width, classes.content)}>
            {title}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails className={classes.accordion__details}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
