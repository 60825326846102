import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import {
  Stack,
  Typography,
  Dialog,
  Grow,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import clsx from "clsx";
import { makeStyles, useTheme } from "@mui/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Loader from "../../../common/Loader";

import Style from "../../Signin/style.js";
import Button from "../../../common/Button";
import Textfield from "../../../common/Textfield";
import { LogoWhite } from "../../../assets/constants";
import { defaultHeaders, usefetch } from "../../../helpers/fetch";
import { passwordValidation } from "../../../helpers/formValidation";

const Styles = makeStyles((theme) => Style(theme));

export default function AccessAccount() {
  const classes = Styles();
  const location = useLocation();

  const navigate = useNavigate();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down("md"));

  const [fieldErrors, setFieldErrors] = useState("");
  const [currentField, setCurrentField] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isExistedUser, setIsExistedUser] = useState(true);
  const [userId, setUserId] = useState('');


  const [fieldVals, setFieldVals] = useState({
    passwordOne: "",
    passwordTwo: "",
  });
  const acceptAccount = async (id) => {
    usefetch("CompanyAccess/accept", "post", defaultHeaders(), {
      id: id,
    }).then(function (resp) {
      if (resp?.code === "11") {
        setResetSuccess(true);
        setIsExistedUser(true);
      } else if (resp?.code === "22") {
        setResetSuccess(false);
        setIsExistedUser(false);
        setUserId(resp?.user_id);
      } else {
        setFieldErrors(resp?.message);
      }
      setIsLoading(false);
    });
  };
  useEffect(() => {
    const queryParam = new URLSearchParams(location.search);
    const id = queryParam.get("aa");
    if (id) {
      acceptAccount(id);
    }
  }, []);

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setFieldVals({
      ...fieldVals,
      [name]: value,
    });
    setFieldErrors("");
  };

  const handleEnter = (e, isButton) => {
    if (e.key === "Enter" || isButton === "Y") {
      if (currentField === 2) {
        if (fieldVals.passwordOne !== fieldVals.passwordTwo) {
          setFieldErrors("Passwords should be same.");
          return;
        }
        setIsLoading(true);
        usefetch("user/resetPassword", "post", defaultHeaders(), {
          user_id: userId,
          password:
            fieldVals.passwordOne === fieldVals.passwordTwo
              ? fieldVals.passwordOne
              : "",
        }).then(function (resp) {
          if (resp?.code === "11") {
          
            setResetSuccess(true);
            setIsExistedUser(false);
          } else {
            setFieldErrors(resp?.message);
          }
        });
        setIsLoading(false);
      }

      if (currentField !== 2) {
        const errors = passwordValidation(fieldVals.passwordOne);
        setFieldErrors(errors);
        if (errors) {
          return;
        }
        setCurrentField(currentField + 1);
      }
    }
  };

  const handleLogin = (e) => {
    if(window.localStorage.getItem('token')){
      navigate("/dashboard");
    }
    navigate("/login");
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Dialog
          open={true}
          onClose={() => {
            handleLogin()
          }}
          className={clsx(
            classes.main__dialog__forgotPassword,
            resetSuccess && classes.congratulation__screen
          )}
        >
          
          <Stack
            sx={{ position: "relative", height: "100%" }}
            className={classes.dialog__content__forgotPassword}
          >
            <Stack justifyContent="flex-end" direction="row" pt={2} pr={2}>
              <CloseRoundedIcon
                style={{ cursor: "pointer", color: "#fff" }}
                onClick={() => {
                  handleLogin()
                }}
              />
            </Stack>
            <Stack
              sx={{ width: "100%", height: "100%" }}
              direction="column"
              alignItems="center"
              justifyContent="flex-start"
              px={smMediaquery ? 3 : 6}
            >
              <LogoWhite
                style={{ width: smMediaquery ? "125px" : "max-content" }}
              />

              {!resetSuccess && (
                <Stack
                  alignItems={smMediaquery ? "flex-start" : "center"}
                  pb={3}
                  width="100%"
                >
                  <Typography
                    variant={smMediaquery ? "title24Bold" : "title30Bold"}
                    color="primary.lightMain"
                    pt={4}
                    pb={smMediaquery ? 2 : 1}
                  >
                    Reset Password
                  </Typography>
                  <Typography
                    variant={smMediaquery ? "title14Bold" : "title22Bold"}
                    sx={{ fontWeight: "400" }}
                    color={smMediaquery ? "#aaa" : "primary.lightMain"}
                    pb={smMediaquery ? 5 : 3.7}
                  >
                    Please enter your new password below
                  </Typography>

                  {currentField === 1 && (
                    <Stack width="100%">
                      <Textfield
                        placeholder="New password"
                        margin="dense"
                        label="Password"
                        variant="outlined"
                        darkModeField
                        name="passwordOne"
                        type={showPassword ? "text" : "password"}
                        helperText={fieldErrors}
                        autoFocus
                        // value={fieldVals?.passwordOne}
                        onEnter={(e) => handleEnter(e, "N")}
                        onChange={(e) => handleChanges(e)}
                        endAdornment={
                          showPassword ? (
                            <VisibilityOffIcon
                              sx={{ cursor: "pointer", color: "#fff" }}
                              onClick={() => setShowPassword(false)}
                            />
                          ) : (
                            <VisibilityIcon
                              sx={{ cursor: "pointer", color: "#fff" }}
                              onClick={() => setShowPassword(true)}
                            />
                          )
                        }
                      />
                      <Stack py={4} />
                      <Stack
                        sx={{
                          position: smMediaquery ? "relative" : "absolute",
                          left: "0",
                          width: "100%",
                          bottom: "2rem",
                        }}
                        alignItems="center"
                      >
                        <Button
                          title="Next"
                          color="secondary"
                          width={smMediaquery ? "100%" : "84%"}
                          height="59px"
                          variant="contained"
                          borderRadius="22px"
                          onClick={(e) => handleEnter(e, "Y")}
                        />
                      </Stack>
                    </Stack>
                  )}
                  {currentField === 2 && (
                    <Stack width="100%">
                      <Textfield
                        placeholder="Confirm password"
                        margin="dense"
                        label="Confirm Password"
                        variant="outlined"
                        darkModeField
                        name="passwordTwo"
                        autoFocus
                        type={showPassword ? "text" : "password"}
                        helperText={fieldErrors}
                        // value={fieldVals?.passwordTwo}
                        onEnter={(e) => handleEnter(e, "N")}
                        onChange={(e) => handleChanges(e)}
                        endAdornment={
                          showPassword ? (
                            <VisibilityOffIcon
                              sx={{ cursor: "pointer", color: "#fff" }}
                              onClick={() => setShowPassword(false)}
                            />
                          ) : (
                            <VisibilityIcon
                              sx={{ cursor: "pointer", color: "#fff" }}
                              onClick={() => setShowPassword(true)}
                            />
                          )
                        }
                      />
                      <Stack py={4} />
                      <Stack
                        sx={{
                          position: smMediaquery ? "relative" : "absolute",
                          left: "0",
                          width: "100%",
                          bottom: "2rem",
                        }}
                        alignItems="center"
                      >
                        <Button
                          title="Confirm Password"
                          color="secondary"
                          width={smMediaquery ? "100%" : "84%"}
                          height="59px"
                          variant="contained"
                          borderRadius="22px"
                          onClick={(e) => handleEnter(e, "Y")}
                        />
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              )}

              {resetSuccess && (
                <Stack alignItems="center">
                  <Typography
                    variant={smMediaquery ? "title28Bold" : "title30Bold"}
                    color="primary.lightMain"
                    pt={7}
                    pb={1}
                  >
                    Congratulations!
                  </Typography>
                  <Typography
                    variant={smMediaquery ? "title18Bold" : "title22Bold"}
                    sx={{ fontWeight: "400" }}
                    color="primary.lightMain"
                    pb={5.7}
                  >
                    {!isExistedUser && 'Your password has been updated successfully.'} 
                     Login to check shared Account access.
                  </Typography>
                  <Button
                    title="Login"
                    color="secondary"
                    width="100%"
                    height="59px"
                    variant="contained"
                    borderRadius="22px"
                    onClick={handleLogin}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        </Dialog>
      )}
    </>
  );
}
