const Style = (theme) => ({
  drawer__chat__with__cv: {
    width: '555px',
    '& .MuiDrawer-paper': {
      width: '555px',
      [theme.breakpoints.down('md')]: {
        width: '100vw',
      },
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  dots__width: {
    width: '55px',
    height: '36px',
    paddingTop: '1rem',
    '& .dot': {
      '&:nth-child(1)': {
        background: '#010103',
      },
      '&:nth-child(2)': {
        background: '#d6d6d6',
      },
      '&:nth-child(3)': {
        background: '#f6f6f6',
      },
    },
  },
  avatars__main__grid: {
    '& .MuiAvatar-root': {
      border: '0',
      fontSize: '14px',
      fontWeight: '700',
      height: '40px',
      width: '40px',
    },

    '&:nth-child(4n + 1)': {
      '& .MuiAvatar-root': {
        backgroundColor: '#67739c',
        color: '#fff',
      },
    },
    '&:nth-child(4n + 2)': {
      '& .MuiAvatar-root': {
        backgroundColor: '#cce2e0',
        color: '#000',
      },
    },
    '&:nth-child(4n)': {
      '& .MuiAvatar-root': {
        backgroundColor: '#fff',
        color: '#000',
      },
    },
  },
  checkbox__label: {
    padding: '0 !important',
    borderRadius: '6px',
    '& label': {
      margin: '0',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
      fontWeight: '500',
      color: '#010103',
      paddingTop: '1px',
      paddingRight: '9px',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
      },
    },
    '&:hover': {
      background: '#f7f7f7',
    },
  },
});
export default Style;
