import React, { useState, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Stack, IconButton, Typography, TextField } from '@mui/material';

import AccordionMain from '../AccordionMain';

export default function Skills({
  templateData,
  expandedAccordion,
  handleAccordionChange,
}) {
  const [skill, setSkill] = useState('');
  const [skills, setSkills] = useState([...Array(2)]);

  useEffect(() => {
    setSkills(templateData?.skills);
  }, [templateData]);

  const handleChange = (e) => {
    var { name, value } = e.target;
    var value = (value = value?.trimStart());
    setSkill(value);
  };

  const addNewSkill = async () => {
    setSkills([...skills, { id: Date.now(), skill: skill }]);
    setSkill('');
  };

  const keyEnter = (e) => {
    if (e.key === 'Enter') {
      const { name, value } = e.target;
      if (value === '') {
        return;
      } else {
        addNewSkill();
      }
    }
  };

  const handleDeleteSkill = (skillId) => {
    setSkills(skills.filter((obj) => obj.id !== skillId));
    // deleteCvSkill(skillId);
  };

  return (
    <AccordionMain
      title='Skills'
      name='skills'
      expandedState={expandedAccordion === 'skills'}
      onChange={handleAccordionChange}
    >
      <Stack pl={1.5}>
        {skills?.map((k, i) => {
          return (
            <Stack alignItems='center' direction='row'>
              <IconButton
                sx={{ p: 0 }}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                size='small'
                onClick={() => handleDeleteSkill(k?.id)}
              >
                <CloseIcon sx={{ fontSize: '15px' }} />
              </IconButton>
              <Typography
                px={1}
                py={0.6}
                width='max-content'
                contentEditable
                variant='title14Bold'
                sx={{ fontWeight: '400', color: '#212121' }}
                // onBlur={(e) =>
                //   // updateSkillsInfo('skill', e.target.innerText, k?.id)
                // }
              >
                {k?.skill}
              </Typography>
            </Stack>
          );
        })}
        <Stack alignItems='center' direction='row'>
          <IconButton
            sx={{ p: 0 }}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            size='small'
          >
            <AddIcon sx={{ fontSize: '15px' }} />
          </IconButton>
          <Stack
            sx={{
              '& .MuiInputBase-root': {
                '& input': {
                  padding: '0.35rem .5rem',
                  fontSize: '14px',
                  fontWeight: '400',
                },
              },
            }}
          >
            <TextField
              value={skill}
              onChange={handleChange}
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              onKeyDown={keyEnter}
              placeholder='Add new skill'
            />
          </Stack>
        </Stack>
      </Stack>
    </AccordionMain>
  );
}
