import React from 'react';
import { useNavigate } from 'react-router-dom';

import { makeStyles, useTheme } from '@mui/styles';
import ReplayIcon from '@mui/icons-material/Replay';
import { Drawer, Stack, Typography, useMediaQuery } from '@mui/material';

import Style from '../style';
import Button from '../../../../common/Button';
import {
  CameraIonAcessBlack,
  CameraIonAcessGreen,
  IOSPermissionIcon,
  LockIconAccess,
  MicIconAccess,
  ReloadIconAccess,
  TestTaken,
} from '../../../../assets/constants';

const Styles = makeStyles((theme) => Style(theme));

export default function IOSPermission({ toggle, setToggle }) {
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const requestPermission = async () => {
    try {
      // Request permission to access camera and microphone again
      await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'user' },
        audio: true,
      });
      setToggle(true);
    } catch (error) {
      setToggle(false);
    }
  };
  const StepTile = ({ icon, icon2, text }) => {
    return (
      <Stack
        width='100%'
        direction='row'
        alignItems='center'
        px={1.2}
        py={1.8}
        sx={{
          minHeight: '51px',
          backgroundColor: '#EAEAEA',
          borderRadius: '15px',
        }}
      >
        <Typography
          variant='title14Bold'
          sx={{
            textAlign: 'center',
            fontWeight: '400',
            width: 'calc(100%)',
            '& .color': {
              color: '#E8B029',
            },
          }}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </Stack>
    );
  };

  return (
    <Drawer
      anchor='bottom'
      open={toggle}
      onClose={() => setToggle(false)}
      className={classes.ios__permission__dialog}
      sx={{
        '--Drawer-transitionDuration': toggle ? '1s' : '0.1s',
      }}
    >
      <Stack
        p={2}
        pt={3}
        height='100%'
        justifyContent='center'
        alignItems='center'
        color='primary.main'
      >
        <IOSPermissionIcon
          style={{
            width: '70px',
            height: '70px',
          }}
        />
        <Typography pt={3} sx={{ fontWeight: '700' }} variant={'title22Bold'}>
          Permissions Request
        </Typography>

        <Stack spacing={1.2} py={3.25} width='100%'>
          <StepTile
            icon={<LockIconAccess />}
            text='Tap the <span class="color"><b>“AA”</b></span> icon in the address bar <br/> <span class="color"><b>(at the top left corner).</b></span>'
          />
          <StepTile
            icon={<CameraIonAcessBlack />}
            text='Select <b>Website Settings.</b>'
          />
          <StepTile
            text='Under <b>Camera,</b> select Allow to grant access.'
            icon={<CameraIonAcessGreen />}
            icon2={<MicIconAccess style={{ marginLeft: '.6rem' }} />}
          />
          <StepTile
            icon={<ReloadIconAccess />}
            text='Under <b>Microphone,</b> select Allow to grant access.'
          />
          <Stack
            width='100%'
            direction='row'
            alignItems='center'
            px={1.2}
            py={1.8}
            sx={{
              minHeight: '51px',
              backgroundColor: '#EAEAEA',
              borderRadius: '15px',
            }}
          >
            <Typography
              variant='title14Bold'
              sx={{
                textAlign: 'center',
                fontWeight: '400',
                width: 'calc(100%)',
                '& b': {
                  color: '#E8B029',
                },
              }}
            >
              After adjusting these settings, <br /> tap the reload icon
              <ReplayIcon
                style={{
                  height: '14px',
                  width: '14px',
                  margin: '0 2px',
                  transform: 'rotate(0deg) scaleX(-1)',
                }}
              />
              in the address bar.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
}
