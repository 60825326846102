import { useState, useEffect } from 'react';

export const useOptimalMediaFormat = () => {
    const [mediaFormat, setMediaFormat] = useState("");
    const [quality, setQuality] = useState("low"); // Default to low quality
  
    const checkSupportedMediaFormats = () => {
      const formats = [
        {
          format: "Ogg Theora",
          mimeType: "video/ogg; codecs=theora",
          quality: "low",
        },
        {
          format: "WebM VP8",
          mimeType: "video/webm; codecs=vp8",
          quality: "low",
        },
        {
          format: "WebM VP9",
          mimeType: "video/webm; codecs=vp9",
          quality: "medium",
        },
        {
          format: "MP4 H.264 Baseline",
          mimeType: 'video/mp4; codecs="avc1.42E01E"',
          quality: "medium",
        },
        {
          format: "MP4 H.264 High",
          mimeType: 'video/mp4; codecs="avc1.640028"',
          quality: "high",
        },
        {
          format: "MKV H.264",
          mimeType: "video/x-matroska; codecs=avc1",
          quality: "high",
        },
        {
          format: "MP4 HEVC H.265",
          mimeType: "video/mp4; codecs=hvc1",
          quality: "high",
        },
      ];
  
      // Iterate over formats from low to high, select the first supported one
      for (let { format, mimeType, quality } of formats) {
        if (MediaRecorder.isTypeSupported(mimeType)) {
          // console.log(`${format}: Supported`);
          setMediaFormat(mimeType); // Set the first supported format
          setQuality(quality); // Set the quality level based on format
          break; // Stop after finding the first supported format
        }
      }
    };
    useEffect(()=>{
      checkSupportedMediaFormats();
    },[mediaFormat])

    return { checkSupportedMediaFormats,mediaFormat, quality };
  };