import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  ClickAwayListener,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

import Context from '../../store/Context';
import Avatars from '../Avatar';
import { LogoBlack, SigninIcon } from '../../assets/constants';
import {deleteAllCookies} from "../../helpers/CookieHelper";
import {AuthContext} from "../../Auth/AuthContext";

export default function AvatarMenu() {
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  let sessionToken = localStorage.getItem('token');
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [globalState, globalDispatch] = useContext(Context);
  const {user, logout} = useContext(AuthContext);

  const handleSignIn = () => {
    navigate('/');
  };

  const handleSignOut = () => {
      // localStorage.clear();
      // deleteAllCookies();
      // globalDispatch({
      //   token: '',
      // });
      // navigate('/');
      // window.location.reload();
      logout();
  };

  const goToDashboard = () => {
    navigate('/dashboard');
  }

  const menuItems = [
    {
      label: 'Dashboard',
      onClick: goToDashboard,
    },
    {
      label: 'Logout',
      onClick: handleSignOut,
    },
  ];

    return (
    <Stack position='relative'>
      <Stack
        direction='row'
        alignItems='center'
        color='primary.main'
        onClick={() => setShowDropdown(!showDropdown)}
        sx={{
          '& .MuiAvatar-root': {
            height: smMediaquery ? '40px' : '54px',
            width: smMediaquery ? '40px' : '54px',
            fontSize: smMediaquery && '17px',
          },
        }}
      >
        <Avatars userName={user?.full_name}
                 src={user?.image}
        />
        <ArrowDropDownIcon />
        {false && (
          <Typography
            textTransform='capitalize'
            variant='title20Bold'
            sx={{ fontWeight: '700', userSelect: 'none' }}
            pl={1.2}
          >
            {localStorage.getItem('userName')}
          </Typography>
        )}
      </Stack>
      {showDropdown && (
        <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
          <Stack
            position='absolute'
            sx={{
              width: 'max-content',
              right: '0',
              top: '3.8rem',
              background: '#fff',
              boxShadow: '0px 0px 36px 0px rgba(0,0,0,0.10)',
              borderRadius: '12px',
              zIndex: '99',
              overflow: 'hidden',
              padding: '8px',
              minWidth: '150px',
            }}
          >
            <Typography
              variant='title16Bold'
              sx={{ fontWeight: '400', padding: '.7rem 1rem' }}
            >
              {user?.full_name}
            </Typography>
            {menuItems?.map((menuItem, i) => (
                <MenuItem
                key={i}
                sx={{
                  padding: '.7rem 1rem',
                  dislay: 'flex',
                  alignItems: 'center',
                  fontSize: '16px',
                  fontWeight: '400',
                  borderRadius: '6px',
                  '&:hover': {
                    background: '#f7f7f7',
                  },
                }}
                name='selectedEmployee'
                onClick={menuItem?.onClick}
              >
                {menuItem?.label}
              </MenuItem>
              )
            )}
          </Stack>
        </ClickAwayListener>
      )}
    </Stack>
  );
}
