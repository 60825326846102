import React, { useEffect, useState } from 'react';

import { makeStyles, useTheme } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Divider,
  Drawer,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

import Style from './style';
import Awards from './Components/Awards';
import Skills from './Components/Skills';
import Summary from './Components/Summary';
import Education from './Components/Education';
import Languages from './Components/Languages';
import Employment from './Components/Employment';
import Button from '../../../../../common/Button';
import PersonalInformation from './Components/PersonalInformation';
import { EditIconTable, EyeIcon } from '../../../../../assets/constants';

const Styles = makeStyles((theme) => Style(theme));

export default function EditTemplate({ toggle, setToggle }) {
  const classes = Styles();
  const theme = useTheme();
  // useEffect(() => {
  //   console.log(job);
  // }, [toggle]);

  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));
  const handleClose = () => {
    // navigate(`/job/app/${id}`);
    setToggle(false);
  };

  const [expandedAccordion, setExpandedAccordion] = useState(null);

  const handleAccordionChange = (accordionName) => {
    setExpandedAccordion((prevExpanded) =>
      prevExpanded === accordionName ? null : accordionName,
    );
  };

  return (
    <Drawer
      anchor='left'
      open={toggle}
      onClose={() => {
        handleClose();
      }}
      className={classes.drawer__job__details}
      sx={{
        '--Drawer-transitionDuration': toggle ? '1s' : '0.2s',
        '& .MuiDrawer-paper': {
          overflowY: 'auto',
        },
      }}
    >
      <Stack width='100vw'>
        <Stack
          p={3}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            '& .edit__icon': {
              height: '18px',
              width: '18px',
              '& path': {
                fill: '#010103',
              },
            },
          }}
        >
          <Stack direction='row' alignItems='center' spacing={0.5}>
            <ArrowBackIcon
              className={classes.close__icon}
              onClick={handleClose}
            />
            <Typography
              variant='title16Bold'
              contentEditable
              suppressContentEditableWarning={true}
            >
              Name Editable
            </Typography>
            <EditIconTable className='edit__icon' />
          </Stack>
          <EyeIcon />
        </Stack>
        <Divider />
        <Stack pt={3.5} pb={2}>
          <Stack
            px={3}
            sx={{
              width: '100%',
              maxWidth: '815px',
              '& .label__main': {
                fontSize: '18px',
                fontWeight: '400',
                [theme.breakpoints.down('md')]: {
                  fontSize: '16px',
                },
              },
              '& .textfield__global': {
                margin: 0,
                '& .MuiInputBase-root': {
                  height: '52px',
                  borderRadius: '0px',
                  border: 'none !Important',
                  borderBottom: '1px solid #c8c8c8 !important',
                  '& input': {
                    fontSize: '18px',
                    fontWeight: '400',
                    paddingLeft: '0',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '16px',
                    },
                  },
                  '&::before, &::after': {
                    display: 'none',
                  },
                },
                '& .MuiInputBase-multiline': {
                  height: 'max-content',
                  '&::before, &::after': {
                    display: 'none',
                  },
                },
                '& .MuiFormHelperText-root': {
                  display: 'none',
                },
                '& fieldset': {
                  borderBottom: '1px solid #c8c8c8 !important',
                },
              },
            }}
            className={classes.template__view}
          >
            <PersonalInformation
              handleAccordionChange={handleAccordionChange}
              expandedAccordion={expandedAccordion}
            />
            <Summary
              handleAccordionChange={handleAccordionChange}
              expandedAccordion={expandedAccordion}
            />
            <Employment
              handleAccordionChange={handleAccordionChange}
              expandedAccordion={expandedAccordion}
            />
            <Education
              handleAccordionChange={handleAccordionChange}
              expandedAccordion={expandedAccordion}
            />
            <Skills
              handleAccordionChange={handleAccordionChange}
              expandedAccordion={expandedAccordion}
            />
            <Awards
              handleAccordionChange={handleAccordionChange}
              expandedAccordion={expandedAccordion}
            />
            <Languages
              handleAccordionChange={handleAccordionChange}
              expandedAccordion={expandedAccordion}
            />
          </Stack>
          <Stack
            py={2}
            px={3}
            sx={{
              backgroundColor: '#fff',
              boxShadow: '0px -3px 0px 0px rgba(0,0,0,0.07)',
            }}
          >
            <Button
              title='Save'
              color='primary'
              width='194px'
              borderRadius='8px'
              height={smMediaquery ? '47px' : '59px'}
              variant='contained'
              fontSize={smMediaquery && '14px'}
              // onClick={handleUpdate}
            />
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
}
