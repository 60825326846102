import React, { useState } from 'react';

import { Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Style from './style';
import Skills from './Skills';
import Awards from './Awards';
import Summary from './Summary';
import Language from './Language';
import Education from './Education';
import Employment from './Employment';
import { updateCvInfo } from '../updateTemplateDataApi';
import PersonalInformation from './PersonalInformation';
import {useCvBuilder} from "../../../../../context/CvBuilderContext/useCvBuilder";
import {useCvBuilderContext} from "../../../../../context/CvBuilderContext/useCvBuilderContext";

const Styles = makeStyles((theme) => Style(theme));

export default function Template1({ templateData, getCv }) {
  const classes = Styles();

    const {fieldVals, setFieldVals, handleTextChange} = useCvBuilderContext();


    const formatDate = (date) => {
    if (date?.toLowerCase() === 'present') {
      return 'Present';
    }
    const d = new Date(date);
    let year = d.getFullYear();
    return year;
  };

    // const handleTextChange = (e, field) => {
    //   const newValue = e.target.textContent;
    //   updateCvInfo(field, newValue);
    //   setFieldVals((prevVals) => ({
    //     ...prevVals,
    //     [field]: newValue,
    //   }));
    // };

  return (
    <Stack px={6}>
      <PersonalInformation
        handleTextChange={handleTextChange}
        fieldVals={fieldVals}
        classes={classes}
        setFieldVals={setFieldVals}
        templateData={templateData}
        formatDate={formatDate}
      />

      {/* Summary Stack */}
      <Summary
        handleTextChange={handleTextChange}
        fieldVals={fieldVals}
        classes={classes}
      />

      {/* Employment Stack */}

      <Employment
        handleTextChange={handleTextChange}
        fieldVals={fieldVals}
        classes={classes}
        setFieldVals={setFieldVals}
        templateData={templateData}
        formatDate={formatDate}
      />

      {/* Education Stack */}

      <Education
        handleTextChange={handleTextChange}
        fieldVals={fieldVals}
        classes={classes}
        setFieldVals={setFieldVals}
        templateData={templateData}
        formatDate={formatDate}
      />

      {/* Skills Stack */}

      <Skills
        handleTextChange={handleTextChange}
        fieldVals={fieldVals}
        classes={classes}
        setFieldVals={setFieldVals}
        templateData={templateData}
        formatDate={formatDate}
        getCv={getCv}
      />

      {/* Awards Stack */}

      <Awards
        handleTextChange={handleTextChange}
        fieldVals={fieldVals}
        classes={classes}
        setFieldVals={setFieldVals}
        templateData={templateData}
        formatDate={formatDate}
      />

      {/* Language Stack */}

      <Language
        handleTextChange={handleTextChange}
        fieldVals={fieldVals}
        classes={classes}
        setFieldVals={setFieldVals}
        templateData={templateData}
        formatDate={formatDate}
        getCv={getCv}
      />
    </Stack>
  );
}
