import {useState} from "react";
import {headers, usefetch} from "../../../helpers/fetch";
import {HTTP_METHOD} from "../../../helpers/constants";

export const useGetInterviews = () => {
    const [error, setError] = useState(null);
    const [isLoadingInterviews, setIsLoadingInterviews] = useState(null);
    const [response, setResponse] = useState(null);
    const [interviews, setInterviews] = useState([]);
    const [answerTestCode, setAnswerTestCode] = useState([]);
    const [interviewsCount, setInterviewsCount] = useState([]);

    const endpoint = 'career/getInterviews';

    const getInterviews = async (data) => {
        setIsLoadingInterviews(true);

        const response = await usefetch(endpoint, HTTP_METHOD.POST, headers(), data);

        if (!response.ok) {
            setError(response.error);
        }

        if (response.ok) {
            setResponse((prevData) => [...prevData, response]);
        }

        setIsLoadingInterviews(false);
        setInterviews(response?.data?.answers);
        setAnswerTestCode(response?.data?.testCode);
        setInterviewsCount(response?.data?.totCount);
        return response;
    };

    return {  getInterviews, response,  interviews, answerTestCode, isLoadingInterviews, interviewsCount, error };
};