const Style = (theme) => ({
  main__signin__container: {
    height: '100vh',
    minHeight: '600px',
    overflowY: 'hidden',
    [theme.breakpoints.down('md')]: {
      minHeight: 'fill-available',
    },
  },
  content__container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column !important',
    backgroundColor: '#010103',
    paddingLeft: '5rem',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  main__dialog__forgotPassword: {
    '& .MuiDialog-paper': {
      minWidth: '41rem !important',
      height: '32rem',
      minHeight: '32rem',
      position: 'relative',
      borderRadius: '10px',
      backgroundColor: '#23293d',
      boxShadow: 'none',
      [theme.breakpoints.down('md')]: {
        minWidth: '100vw !important',
        height: '100vh',
        minHeight: '100vh',
        margin: 0,
        '& .control-width': {
          width: '100%',
        },
      },
    },
    '& .MuiModal-backdrop': {
      backgroundColor: '#23293d',
    },
  },
  dialog__content__forgotPassword: {
    backgroundColor: '#23293d',
  },
  signin__video__container: {
    '& video': {
      height: '100% !important',
      width: '100% !important',
      objectFit: 'cover',
    },
  },
  info__icon: {
    '& svg': {
      '& path': {
        fill: '#8398de',
      },
    },
  },
  congratulation__screen: {
    '& .MuiDialog-paper': {
      height: '23rem',
      minHeight: '23rem',
      [theme.breakpoints.down('md')]: {
        minWidth: '100vw !important',
        height: '100vh',
        minHeight: '100vh',
      },
    },
  },
});

export default Style;
