const Style = (theme) => ({
  drawer__main: {
    width: '456px',

    '& .MuiDrawer-paper': {
      width: '456px',

      [theme.breakpoints.down('md')]: {
        width: '100vw',
      },
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
  tabs__main: {
    backgroundColor: '#f3f3f3',
    boxShadow: 'none',
    padding: '6px',
    zIndex: '1',
    width: '100%',
    position: 'relative',
    borderRadius: '100px',
    minHeight: '30px !important',
    '& .MuiTabScrollButton-root': {
      color: 'rgba(109, 125, 139, 1)',
      borderBottom: '3px solid #CCD2E3',
      opacity: '1 !important',
    },
    '& .MuiTabScrollButton-root.Mui-disabled': {
      color: 'rgba(109, 125, 139, .3)',
    },
    '& .MuiTabs-scroller': {
      width: '100%',
      '& .MuiTabs-flexContainer': {
        justifyContent: 'flex-start',
        width: '100%',
        '& .MuiTab-root': {
          minHeight: '30px',
          width: '50%',
          maxWidth: 'max-content',
          minWidth: '50%',
          textTransform: 'capitalize',
          color: 'var(--color-primary) !important',
          opacity: '1',
          borderBottom: '0',
          fontSize: '16px',
          fontWeight: '500',
          padding: '.8rem 1.4rem',
          margin: '0',
          borderRadius: '100px',

          [theme.breakpoints.down('1380')]: {
            fontSize: '14px',
            fontWeight: '400',
          },
          [theme.breakpoints.down('md')]: {
            fontSize: '12px',
            fontWeight: '600',
          },
        },
        '& .Mui-selected': {
          color: 'var(--color-primary) !important',
          zIndex: 10,
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 'max-content',
    },
  },
  indicator: {
    bottom: '0px',
    backgroundColor: '#fff !important',
    height: '100% !important',
    borderRadius: '100px',
    zIndex: 1,
  },
});
export default Style;
