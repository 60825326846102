import React, { useContext, useEffect, useState } from "react";
import { getLocalStorage, setLocalStorage } from "../../helpers/common";
import { headers, usefetch } from "../../helpers/fetch";
import { useLocation, useNavigate } from "react-router-dom";
import { CartContext } from "../Packages/Cart/CartContext";
export default function CallBack() {
  const {
   
    getRemainingCredits,
    setPaymentRes,
    setPaymentToggle,
    setCartCount,
    setCart,
    setOrderAmount,
    setOrderId,
  } = useContext(CartContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [paymentData, setPaymentData] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramsObject = {};
    queryParams.forEach((value, key) => {
      paramsObject[key] = value;
    });

    setPaymentData(paramsObject);

    const OrderId = getLocalStorage("OrderId");
    const OrderPayment = getLocalStorage("OrderAmount");
    if (Object.keys(paramsObject).length > 2) {
      usefetch("packages/varifyCallBack", "post", headers(), {
        callback: paramsObject,
        OrderId: OrderId,
        OrderPayment: OrderPayment,
      }).then(function (resp) {
        if (resp?.code == "11" && resp?.data?.transaction_id != undefined) {
          setPaymentRes({
            code: "11",
            message: "You have successfully made the payment.",
            heading: "Congratulations!",
          });
          setPaymentToggle(true);
        } else {
          setPaymentRes({
            code: resp?.data?.errCode,
            message: "You have canceled the payment",
            heading: "Transaction not completed",
          });
          setPaymentToggle(true);
        }
        setCartCount(null);
        setOrderAmount(0);
        setOrderId(null);
        setCart([]);
        getRemainingCredits();
        // Redirect to dashboard
        navigate("/dashboard");
      });
    }
  }, [location.search]);

  return null;
}
