import React, { useState, useRef, useEffect } from 'react';

import { useTheme } from '@mui/styles';
import { Stack, Typography, useMediaQuery } from '@mui/material';

import Textfield from '../../../common/Textfield';

export default function Experience({
  refMain,
  loading,
  fieldErrors,
  classes,
  TypograhyMain,
  fieldVals,
  setFieldVals,
  keyEnter,
  handleChanges,
  SpecificEndAdornment,
}) {
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  const [textWidth, setTextWidth] = useState(0);
  const spanRef = useRef(null);

  useEffect(() => {
    if (spanRef.current) {
      setTextWidth(spanRef.current.offsetWidth);
    }
  }, [fieldVals?.experience]);

  return (
    <>
      <TypograhyMain titleText="What's the minimum <br/> experience required <br />  for this position?" />
      <Stack className='field__stack' position='relative'>
        <Textfield
          placeholder='5 Years'
          margin='dense'
          variant='outlined'
          name='experience'
          inputRef={refMain}
          autoFocus
          onEnter={keyEnter}
          value={fieldVals?.experience}
          onChange={(e) => {
            handleChanges(e);
          }}
          maxLength='30'
          helperText={fieldErrors}
          disabled={loading}
          endAdornment={
            <SpecificEndAdornment
              nameSpecific='experience'
              valueSpecific={fieldVals?.experience}
            />
          }
        />
        <span
          ref={spanRef}
          style={{
            position: 'absolute',
            visibility: 'hidden',
            whiteSpace: 'pre',
          }}
        >
          {fieldVals?.experience}
        </span>
        {fieldVals?.experience && false && (
          <Stack
            position='absolute'
            top={smMediaquery ? '3.55rem' : mediaquery1380 ? '3.75rem' : '5rem'}
            left={`calc(2rem + ${textWidth}px)`}
          >
            <Typography
              variant={
                smMediaquery
                  ? 'title16Bold'
                  : mediaquery1380
                  ? 'title20Bold'
                  : 'title22Bold'
              }
              sx={{ fontWeight: '500' }}
            >
              Years
            </Typography>
          </Stack>
        )}
      </Stack>
    </>
  );
}
