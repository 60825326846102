import React, { useState } from 'react';

import {
  Menu,
  Stack,
  TextField,
  Typography,
  IconButton,
  useMediaQuery,
  ClickAwayListener,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/styles';

import Button from '../../../common/Button';
import {
  ShareIcon,
  ShareEmailIcon,
  CopyLinkIconShare,
} from '../../../assets/constants';
import { copyToClipboard } from '../../../helpers/ClipboardHelper';
import { useShareCv } from '../../../services/useShareCv';
import { emailValidation } from '../../../helpers/formValidation';
import { useSnackbar } from '../../../App';

export default function ShareDropdown(props) {
  const { i, classes, cv } = props;
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));

  //   View Dropdown State
  const [anchorEl, setAnchorEl] = useState(null);
  const [linkCopied, setLinkCopy] = useState(false);
  const [viewDropdown, setViewDropdown] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const { setSnackbarMessage } = useSnackbar();

  const open = Boolean(anchorEl);
  const { shareCv, isSharingCv } = useShareCv();

  const handleClose = () => {
    setAnchorEl(null);
    setViewDropdown(false);
  };

  const handleSendEmail = async () => {
    const emailErr = emailValidation(email);

    if (emailErr) {
      setEmailError(emailErr);
      return false;
    }

    await shareCv({ cvId: cv?.id, email: email }).then((resp) => {
      setSnackbarMessage(resp?.message);
    });
    setEmail('');
    setViewDropdown(false);
  };

  return (
    <Stack position='relative'>
      <IconButton
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setViewDropdown(i);
        }}
        className={classes.share__view__icon}
      >
        <ShareIcon />
      </IconButton>
      {viewDropdown === i && (
        <Menu
          id={`basic-menu${i}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          menulistprop={{
            'aria-labelledby': `basic-button${i}`,
          }}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Stack p={1} width={smMediaquery ? '100%' : '300px'}>
              <Typography
                pb={1.9}
                variant='title16Bold'
                sx={{ fontWeight: '600' }}
              >
                Share
              </Typography>
              <Stack direction='row'>
                <Stack
                  className={classes.active__tab}
                  direction='row'
                  py={1.2}
                  px={2}
                >
                  <ShareEmailIcon />
                  <Typography
                    pl={1.2}
                    variant='title12Bold'
                    sx={{ fontWeight: '500' }}
                  >
                    Email
                  </Typography>
                </Stack>
                <ClickAwayListener onClickAway={() => setLinkCopy(false)}>
                  <Stack
                    onClick={() => {
                      copyToClipboard(cv?.copylink);
                      setLinkCopy(true);
                    }}
                    className={classes.active__tab}
                    direction='row'
                    py={1.2}
                    px={2}
                    sx={{
                      background: '#fff !important',
                    }}
                  >
                    <CopyLinkIconShare />
                    <Typography
                      pl={1.2}
                      variant='title12Bold'
                      sx={{ fontWeight: '500' }}
                    >
                      {linkCopied ? 'Copied' : 'Copy Link'}
                    </Typography>
                  </Stack>
                </ClickAwayListener>
              </Stack>
              <Stack
                mt={1.5}
                p={smMediaquery ? 0 : 1.7}
                className={classes.shared__box}
              >
                <Typography
                  variant='title14Bold'
                  sx={{
                    fontWeight: '600',
                    display: { md: 'block', sm: 'none', xs: 'none' },
                  }}
                >
                  Add Email
                </Typography>
                <Stack
                  className={classes.email__textfield}
                  variant='title14Bold'
                >
                  <TextField
                    className='text__main'
                    variant='outlined'
                    placeholder='example@gmail.com'
                    onChange={(e) => {
                      setEmailError('');
                      setEmail(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        handleSendEmail();
                      }
                    }}
                    value={email}
                    helperText={emailError}
                    InputProps={{ disableUnderline: false }}
                  />
                  <Stack py={0.5} />
                  <Button
                    color='primary'
                    title='Send'
                    variant='contained'
                    width='100%'
                    height='40px'
                    fontSize='12px'
                    fontWeight='500'
                    borderRadius='8px'
                    disabled={email?.length < 1}
                    onClick={handleSendEmail}
                    endIcon={
                      isSharingCv ? (
                        <CircularProgress
                          style={{
                            height: '22px',
                            width: '22px',
                            color: '#010103',
                            boxShadow: 'inset 0 0 1px 2px #e8e8e8',
                            backgroundColor: 'transparent',
                            borderRadius: '50%',
                          }}
                        />
                      ) : null
                    }
                  />
                </Stack>
              </Stack>
            </Stack>
          </ClickAwayListener>
        </Menu>
      )}
    </Stack>
  );
}
