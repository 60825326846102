import React, { useState } from 'react';

import { makeStyles, useTheme } from '@mui/styles';
import { Stack, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';

import Style from './style';
import Template1 from '../Templates/Template1';
import PreviewPageTemp from '../Mobile/PreviewPage/PreviewPage';
import TemplateImg from '../../../../assets/images/template-1.png';
import TemplateImg2 from '../../../../assets/images/template-2.png';
import { updateCvTemplate } from '../Templates/updateTemplateDataApi';
import { useCvBuilderContext } from '../../../../context/CvBuilderContext/useCvBuilderContext';

const Styles = makeStyles((theme) => Style(theme));

export default function ResumeTabs({
  templates,
  allTabs,
  activeTab,
  handleChange,
  a11yProps,
  setCurrentTemplate,
  templateData,
}) {
  const classes = Styles();

  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const { getCv } = useCvBuilderContext();

  const [previewTempMob, setPreviewTempMob] = useState();

  const PreviewPage = previewTempMob && (
    <PreviewPageTemp
      templates={templates}
      toggle={previewTempMob}
      setToggle={setPreviewTempMob}
    />
  );
  // debugger;
  return (
    <Stack direction='row' pt={1}>
      <Tabs
        orientation='horizontal'
        // variant='scrollable'
        className={classes.tabs__bar}
        value={activeTab}
        onChange={(e, value) => {
          handleChange(e, value);
          updateCvTemplate({ templateCode: value }).then((resp) => {
            getCv();
          });
        }}
        aria-label='basic tabs example'
        classes={{
          indicator: classes.indicator,
        }}
      >
        {templates?.map((template, i) => {
          return (
            <Tab
              onClick={(e) => {
                e.stopPropagation();
                smMediaquery
                  ? setPreviewTempMob(template?.template_code)
                  : setCurrentTemplate(template?.template_code);
              }}
              label={
                <img
                  width='100%'
                  src={
                    template?.template_code === 'k2n5h2nj3'
                      ? TemplateImg
                      : TemplateImg2
                  }
                  alt=''
                />
              }
              key={i}
              // icon={tab.icon}
              value={template?.template_code}
              iconPosition='start'
              {...a11yProps(i++)}
            />
          );
        })}
        {/*{allTabs?.map(*/}
        {/*  (tab, key) =>*/}
        {/*    tab.title && (*/}
        {/*      */}
        {/*    ),*/}
        {/*)}*/}
        {PreviewPage}
      </Tabs>
    </Stack>
  );
}
