import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { makeStyles, useTheme } from '@mui/styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Dialog, Stack, Typography, useMediaQuery } from '@mui/material';

import Style from './style';
import Button from '../../../common/Button';
import { JobPostedTick } from '../../../assets/constants';
import { useCart } from '../../Packages/Cart/useCart';
import { CartContext } from '../../Packages/Cart/CartContext';

const Styles = makeStyles((theme) => Style(theme));

export default function Popup() {
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));
  const navigate = useNavigate();
  const { creditToggle, setCreditToggle } = useContext(CartContext);

  const handleUpgradeClick = () => {
    setCreditToggle(() => false);
    setTimeout(() => {
      navigate('/packages');
    }, 500);
  };

  return (
    <Dialog
      open={creditToggle}
      onClose={setCreditToggle}
      className={classes.main__dialog__success}
    >
      <Stack justifyContent='flex-end' direction='row'>
        <CloseRoundedIcon
          style={{ cursor: 'pointer' }}
          onClick={() => setCreditToggle(() => false)}
        />
      </Stack>
      <Stack
        p={2}
        pt={0}
        height='100%'
        justifyContent='center'
        alignItems='center'
        color='primary.main'
      >
        <Typography
          sx={{ fontWeight: '600' }}
          variant={mediaquery1380 ? 'title20Bold' : 'title26Bold'}
        >
          Out of Credits
        </Typography>
        <Typography
          variant={mediaquery1380 ? 'title14Bold' : 'title16Bold'}
          sx={{ textAlign: 'center', fontWeight: '400' }}
          pt={2.2}
          pb={3.2}
        >
          You have run out of credits. To continue using our services, please
          upgrade your account to a paid version.
        </Typography>
        <Stack
          direction='row'
          space={2}
          width='100%'
          justifyContent='center'
          alignItems='center'
        >
          <Button
            width='100%'
            title='Upgrade Account'
            color={'primary'}
            variant='contained'
            fontSize={mediaquery1380 ? '12px' : '14px'}
            onClick={handleUpgradeClick}
            height={mediaquery1380 ? '44px' : '50px'}
            borderRadius='11px'
          />
        </Stack>
      </Stack>
    </Dialog>
  );
}
