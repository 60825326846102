export const SCORE_TEXT = {
  HIGHLY_RELEVANT: 'Highly Relevant',
  MEDIUM_RELEVANT: 'Somewhat Relevant',
  NOT_RELEVANT: 'Not Relevant',
};

export const USER_TYPES = {
  EMPLOYER: 'employer',
  SEEKER: 'seeker',
  RECRUITER: 'recruiter',
};

export const ANSWER_SCORE_TEXT = {
  HIGHLY_RELEVANT: 'HIGHLY RELEVANT',
  MODERATE: 'MODERATE',
  NOT_MATCHING: 'NOT MATCHING',
};

export const ANSWER_SCORE_TEXT_COLOR = {
  HIGHLY_RELEVANT: '#0aa06a',
  MODERATE: '#256ef4',
  NOT_MATCHING: '#ff3636',
};

export const SCORE_TEXT_COLOR = {
  HIGHLY_RELEVANT: '#0aa06a',
  MEDIUM_RELEVANT: '#256ef4',
  NOT_RELEVANT: '#ff3636',
};

export const VIDEO_INTERVIEW_STATUS = {
  COMPLETED: 'completed',
};

export const MCQ_TEST_STATUS = {
  PENDING: 'pending',
  STARTED: 'started',
  COMPLETED: 'completed',
};

export const CODING_TEST_STATUS = {
  PENDING: 'pending',
  STARTED: 'started',
  COMPLETED: 'completed',
};

export const EMPLOYER_STATUS = {
  ALL: 'all',
  ACTIVE: 'active',
  PENDING: 'pending',
  SHORTLISTED: 'shortlisted',
  REJECTED: 'rejected',
  FAILED: 'failed',
  TEST: 'test',
  TEST_ASSIGNED: 'testAssigned',
  SUGGESTED: 'suggested',
  TEST_ATTEMPTED: 'testAttempted',
  VIDEO_INTERVIEW_ATTEMPTED: 'interviewAttempted',
  CODING_TEST_ATTEMPTED: 'codingAttempted',
};

export const PACKAGE__TYPE = {
  FREE: 'Y',
  PREMIUM: 'Y',
};

export const MATCH_FILTER_HIGH_MATCH = '1';
export const MATCH_FILTER_MEDIUM_MATCH = '2';
export const MATCH_FILTER_LOW_MATCH = '3';
export const MATCH_FILTER_ALL = '4';
export const MATCH_FILTER_UNMATCHED = '5';
export const MATCH_FILTER_RECENT = '6';
export const MATCH_FILTER_MCQ_TEST = '7';
export const MATCH_FILTER_VIDEO_INTERVIEW = '8';
export const MATCH_FILTER_CODING_TEST = '9';
export const MATCH_FILTER_BEST_OVERALL = '10';

export const MATCH_FILTERS = {
  [MATCH_FILTER_HIGH_MATCH]: 'High Match',
  [MATCH_FILTER_ALL]: 'All Candidates',
  [MATCH_FILTER_MEDIUM_MATCH]: 'Medium Match',
  [MATCH_FILTER_LOW_MATCH]: 'Low Match',
  [MATCH_FILTER_UNMATCHED]: 'Unmatched',
  [MATCH_FILTER_RECENT]: 'Recent',
  [MATCH_FILTER_MCQ_TEST]: 'MCQ Test',
  [MATCH_FILTER_VIDEO_INTERVIEW]: 'Video Interview',
  [MATCH_FILTER_CODING_TEST]: 'Coding Test',
  [MATCH_FILTER_BEST_OVERALL]: 'Best Overall',
};

export const FILTER_BY = {
  ALL: 'all',
  UPLOADED: 'uploaded',
  DIRECT: 'direct',
};

export const EDIT_TEST = {
  MCQ: 'mcq',
  VIDEO: 'video',
  CODING: 'coding',
};

export const JOB_TYPE = {
  ONSITE: 'onsite',
  REMOTE: 'remote',
  HYBRID: 'hybrid',
};

export const JOB_TYPES_LABELS = {
  [JOB_TYPE.ONSITE]: 'On-Site',
  [JOB_TYPE.REMOTE]: 'Remote',
  [JOB_TYPE.HYBRID]: 'Hybrid',
};


export const HTTP_METHOD = {
  POST: 'POST',
  GET: 'GET',
}

export const APP_ENV = {
  SAILFISH: 'sailfish',
  BETA: 'beta',
  BETA_QA: 'betaqa',
  DEMO: 'demo',
  DEMO_QA: 'demoqa',
  LIVE: 'live',
}

export const ONE_BYTE = 1048576;
