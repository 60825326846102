import React from 'react';
import AccordionMain from '../AccordionMain';

export default function Langauges({
  expandedAccordion,
  handleAccordionChange,
}) {
  return (
    <AccordionMain
      title='Langauges'
      name='language'
      expandedState={expandedAccordion === 'language'}
      onChange={handleAccordionChange}
    >
      Langauges
    </AccordionMain>
  );
}
