import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Divider,
  Stack,
  Typography,
  IconButton,
  TextField,
} from '@mui/material';
import {
  updateEducationInfo,
  updateSkillsInfo,
} from '../updateTemplateDataApi';
import { deleteCvLanguage, deleteCvSkill } from '../deleteTemplateDataApi';
import { addCvLanguage, addCvSkill } from '../addTemplateDataApi';
import EditableTypography from '../../../../../common/EditableTypography';
import {useCvBuilderContext} from "../../../../../context/CvBuilderContext/useCvBuilderContext";

export default function Skills({ classes, templateData, getCv }) {
  const {skill, skills, handleUpdateCvSkill, setSkill, setSkills, handleDeleteSkill, keyEnterSkill, handleChangeSkill} =
      useCvBuilderContext();
  // const [skill, setSkill] = useState('');
  // const [skills, setSkills] = useState([...Array(2)]);

  // useEffect(() => {
  //   setSkills(templateData?.skills);
  // }, [templateData]);

  // const handleChangeSkill = (e) => {
  //   var { name, value } = e.target;
  //   var value = (value = value?.trimStart());
  //   setSkill(value);
  // };
  //
  // const addNewSkill = async () => {
  //   const resp = await addCvSkill(skill);
  //   setSkills([...skills, { id: Date.now(), skill: skill }]);
  //   setSkill('');
  //   getCv();
  // };

  // const keyEnterSkill = (e) => {
  //   if (e.key === 'Enter') {
  //     const { name, value } = e.target;
  //     if (value === '') {
  //       return;
  //     } else {
  //       addNewSkill();
  //     }
  //   }
  // };

  // const handleDeleteSkill = (skillId) => {
  //   setSkills(skills.filter((obj) => obj.id !== skillId));
  //   deleteCvSkill(skillId);
  // };

  return (
    <>
      <Stack py={1}>
        <Divider classes={{ root: classes.divider__root }} textAlign='left'>
          <Typography variant='title18Bold' sx={{ fontWeight: '600' }}>
            Skills
          </Typography>
        </Divider>
      </Stack>
      <Stack pl={1.5}>
        {skills?.map((k, i) => {
          return (
            <Stack key={k?.id} alignItems='center' direction='row'>
              <IconButton
                sx={{ p: 0 }}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                size='small'
                onClick={() => handleDeleteSkill(k?.id)}
              >
                <CloseIcon sx={{ fontSize: '15px' }} />
              </IconButton>
              <EditableTypography
                paddingX={1}
                paddingY={0.6}
                width='max-content'
                variant='title14Bold'
                fontWeight='400'
                color='#212121'
                onBlur={(e) =>
                    handleUpdateCvSkill({content: e.target.innerText, skillId: k?.id})
                }
              >
                {k?.skill}
              </EditableTypography>
            </Stack>
          );
        })}
        <Stack alignItems='center' direction='row'>
          <IconButton
            sx={{ p: 0 }}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            size='small'
          >
            <AddIcon sx={{ fontSize: '15px' }} />
          </IconButton>
          <Stack
            sx={{
              '& .MuiInputBase-root': {
                '& input': {
                  padding: '0.35rem .5rem',
                  fontSize: '14px',
                  fontWeight: '400',
                },
              },
            }}
          >
            <TextField
              value={skill}
              onChange={handleChangeSkill}
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              onKeyDown={keyEnterSkill}
              placeholder='Add new skill'
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
