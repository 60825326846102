import React, { useState, useEffect } from 'react';
import { makeStyles, styled, useTheme } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Stack,
  Typography,
  useMediaQuery,
  Grow,
  Snackbar,
} from '@mui/material';

import Style from './style';
import Button from '../../../common/Button';
import Avatars from '../../../common/Avatar';
import Textfield from '../../../common/Textfield';
import { headers, usefetch } from '../../../helpers/fetch';
import background from '../../../assets/images/gpt-banner.png';
import { useAuthContext } from '../../../Auth/useAuthContext';

const Styles = makeStyles((theme) => Style(theme));

export default function ProfielSetting({
  toggle,
  setToggle,
  messageOne,
  isFromSeeker,
}) {
  const theme = useTheme();
  const classes = Styles();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  const [userInfo, setUserInfo] = useState([]);
  const [fieldErrors, setFieldErrors] = useState('');
  const [respMessage, setRespMessage] = useState('');
  const {user} = useAuthContext();
  const [showPassword, setShowPassword] = useState({
    old_password: false,
    password_one: false,
    password_two: false,
  });
  const [file, setFile] = useState();

  const [fieldVals, setFieldVals] = useState({
    full_name: '',
    mobile_number: '',
    email_address: '',
    old_password: '',
    password_one: '',
    password_two: '',
  });

  const handleChanges = (e) => {
    var { name, value } = e.target;
    value = value?.trimStart();
    setFieldVals({
      ...fieldVals,
      [name]: value,
    });
    setFieldErrors('');
  };


  const handleUpdate = () => {
    usefetch('user/updatepassword', 'post', headers(), {
      oldPassword: fieldVals?.old_password,
      newPassword: fieldVals?.password_two,
    }).then((resp) => {
      if (resp?.code === '00') {
        setRespMessage(resp?.message);
      }
      if (resp?.code === '11') {
        setRespMessage(resp?.message);
        setFieldVals({
          ...fieldVals,
          old_password: '',
          password_one: '',
          password_two: '',
        });
      }
    });
  };

  

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const toggleShowPassword = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  return (
    <Stack
      pl={smMediaquery ? 3 : isFromSeeker ? 0 : 4}
      pr={smMediaquery ? 3 : 0}
      maxWidth={smMediaquery ? '100%' : mediaquery1380 ? '517px' : '565px'}
    >
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiSnackbarContent-root': {
            background: '#010103',
            color: '#fff',
          },
        }}
        TransitionComponent={Grow}
        autoHideDuration={4000}
        open={respMessage?.length}
        message={respMessage}
        onClose={() => setRespMessage('')}
      />
      {false && (
        <Stack color='primary.main'>
          <Typography
            variant={
              isFromSeeker
                ? 'title34Bold'
                : isFromSeeker && mediaquery1380
                ? 'title24Bold'
                : 'h3'
            }
            pb={4}
          >
            Profile Setting
          </Typography>
          <Typography variant='title26Bold' pb={1.5}>
            General Setting
          </Typography>
          <Typography pb={1} variant='title16Bold' sx={{ fontWeight: '400' }}>
            This information will be displayed publicly so be careful what you
            share.
          </Typography>
        </Stack>
      )}
      {false && (
        <Stack
          py={2}
          spacing={2}
          direction='row'
          alignItems='center'
          sx={{
            '& .MuiAvatar-root': {
              height: false ? '40px' : '130px',
              width: false ? '40px' : '130px',
              fontSize: false ? '17px' : '44px',
              fontWeight: false ? '600' : '700',
              background: '#fff',
              color: '#010103',
              border: '1px solid #010103',
            },
          }}
        >
          <Avatars src={background} userName={user?.full_name} />

          <Button
            className={classes.file__upload__btn}
            component='label'
            variant='contained'
          >
            Upload file
            <VisuallyHiddenInput type='file' />
          </Button>

          <Button
            className={classes.delete__btn}
            variant='outlined'
            // onClick={() => handleSave()}
          >
            Delete
          </Button>
        </Stack>
      )}

      {false && (
        <Stack
          sx={{
            width: '100%',
            maxWidth: '815px',
            '& .label__main': {
              fontSize: '18px',
              fontWeight: '400',
              [theme.breakpoints.down('md')]: {
                fontSize: '16px',
              },
            },
            '& .textfield__global': {
              '& .MuiInputBase-root': {
                height: '64px',
                borderRadius: '15px',
                borderColor: '#c8c8c8 !important',

                '& input': {
                  fontSize: '18px',
                  fontWeight: '400',
                  [theme.breakpoints.down('md')]: {
                    fontSize: '16px',
                  },
                },
              },
            },
          }}
        >
          <Textfield
            autoFocus
            maxLength={50}
            margin='dense'
            name='full_name'
            isFromPostNewJob
            variant='outlined'
            //   onEnter={keyEnter}
            helperText={fieldErrors}
            value={fieldVals?.full_name}
            placeholder='Enter Full Name'
            defaultValue={userInfo?.full_name}
            onChange={(e) => handleChanges(e)}
            label='Name'
          />
          <Textfield
            maxLength={50}
            margin='dense'
            name='mobile_number'
            isFromPostNewJob
            variant='outlined'
            //   onEnter={keyEnter}
            helperText={fieldErrors}
            value={fieldVals?.mobile_number}
            placeholder='Enter Mobile Number'
            onChange={(e) => handleChanges(e)}
            label='Mobile Number'
          />

          <Textfield
            maxLength={50}
            margin='dense'
            name='email_address'
            isFromPostNewJob
            variant='outlined'
            //   onEnter={keyEnter}
            disabled={true}
            helperText={fieldErrors}
            value={fieldVals?.email_address}
            placeholder='Enter Email Address'
            onChange={(e) => handleChanges(e)}
            label='Email'
          />
        </Stack>
      )}

      <Stack color='primary.main'>
        <Typography
          variant={mediaquery1380 ? 'title20Bold' : 'title24Bold'}
          pb={1.5}
          sx={{ fontWeight: isFromSeeker ? 700 : 600 }}
        >
          Change Password
        </Typography>
        <Typography
          pb={5}
          variant={mediaquery1380 ? 'title14Bold' : 'title16Bold'}
          sx={{ fontWeight: '400' }}
        >
          This will be used to log into your account and complete high severity
          action.
        </Typography>
      </Stack>
      <Stack
        sx={{
          width: '100%',
          maxWidth: '815px',
          '& .label__main': {
            fontSize: '14px',
            fontWeight: '400',
            [theme.breakpoints.down('md')]: {
              fontSize: '16px',
            },
          },
          '& .textfield__global': {
            '& .MuiInputBase-root': {
              height: '58px',
              borderRadius: '15px',
              borderColor: '#c8c8c8 !important',
              [theme.breakpoints.down('md')]: {
                height: '56px',
              },
              '& input': {
                fontSize: '14px',
                fontWeight: '400',
                [theme.breakpoints.down('md')]: {
                  fontSize: '16px',
                },
              },
            },
          },
        }}
      >
        <Textfield
          maxLength={50}
          margin='dense'
          name='old_password'
          isFromPostNewJob
          variant='outlined'
          helperText={fieldErrors}
          value={fieldVals?.old_password}
          placeholder='Old Password'
          onChange={(e) => handleChanges(e)}
          label='Old Password'
          type={showPassword.old_password ? 'text' : 'password'}
          endAdornment={
            showPassword.old_password ? (
              <VisibilityOffIcon
                style={{ cursor: 'pointer', color: '#010103' }}
                onClick={() => toggleShowPassword('old_password')}
              />
            ) : (
              <VisibilityIcon
                style={{ cursor: 'pointer', color: '#010103' }}
                onClick={() => toggleShowPassword('old_password')}
              />
            )
          }
        />

        <Textfield
          maxLength={50}
          margin='dense'
          name='password_one'
          isFromPostNewJob
          variant='outlined'
          helperText={fieldErrors}
          value={fieldVals?.password_one}
          placeholder='Enter Password'
          onChange={(e) => handleChanges(e)}
          label='New Password'
          type={showPassword.password_one ? 'text' : 'password'}
          endAdornment={
            showPassword.password_one ? (
              <VisibilityOffIcon
                style={{ cursor: 'pointer', color: '#010103' }}
                onClick={() => toggleShowPassword('password_one')}
              />
            ) : (
              <VisibilityIcon
                style={{ cursor: 'pointer', color: '#010103' }}
                onClick={() => toggleShowPassword('password_one')}
              />
            )
          }
        />
        <Textfield
          maxLength={50}
          margin='dense'
          name='password_two'
          isFromPostNewJob
          variant='outlined'
          helperText={fieldErrors}
          value={fieldVals?.password_two}
          placeholder='Enter Password'
          onChange={(e) => handleChanges(e)}
          label='Confirm Password'
          type={showPassword.password_two ? 'text' : 'password'}
          endAdornment={
            showPassword.password_two ? (
              <VisibilityOffIcon
                style={{ cursor: 'pointer', color: '#010103' }}
                onClick={() => toggleShowPassword('password_two')}
              />
            ) : (
              <VisibilityIcon
                style={{ cursor: 'pointer', color: '#010103' }}
                onClick={() => toggleShowPassword('password_two')}
              />
            )
          }
        />
      </Stack>
      <Stack pt={6} pb={5} direction='row'>
        <Button
          title='Save Change'
          variant='contained'
          height={'57px'}
          width={'184px'}
          borderRadius='11px'
          fontSize={mediaquery1380 ? '14px' : '16px'}
          fontWeight='600'
          onClick={() => handleUpdate()}
        />
      </Stack>
    </Stack>
  );
}
