import React, { useState } from 'react';

import { useTheme } from '@mui/styles';
import {
  Stack,
  Menu,
  Typography,
  IconButton,
  Divider,
  useMediaQuery,
} from '@mui/material';

import { EyeIcon } from '../../../assets/constants';
import {convertServerDateFormate, formatDateToLongDateTimeString} from "../../../helpers/dateHelper";

export default function CVViewDropdown({cv,  i, classes }) {
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));

  //   View Dropdown State
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewDropdown, setViewDropdown] = useState(false);

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
    console.log('works', anchorEl);
  };

  return (
    <Stack position='relative'>
      <IconButton
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setViewDropdown(i);
        }}
        className={classes.share__view__icon}
      >
        <EyeIcon />
      </IconButton>
      {viewDropdown === i && (
        <Menu
          id={`basic-menu${i}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          menulistprop={{
            'aria-labelledby': `basic-button${i}`,
          }}
        >
          <Stack p={1.5} width={smMediaquery ? '100%' : '461px'}>
            {smMediaquery ? (
              <Typography
                pt={0.5}
                variant='title20Bold'
                sx={{ fontWeight: '600' }}
              >
                CV Views
              </Typography>
            ) : null}
            <Typography
              pt={0.5}
              mb={1.7}
              variant={smMediaquery ? 'title14Bold' : 'title16Bold'}
              sx={{ fontWeight: smMediaquery ? '400' : '600' }}
            >
              Your CV has been viewed by {cv?.viewsCount} members.
            </Typography>
            <Stack
              sx={{
                maxHeight: '15.4rem',
                overflowY: 'auto',
              }}
            >
              {smMediaquery ? <Divider sx={{ mb: 1.9 }} /> : null}
              {cv?.views?.map((k, i) => {
                return (
                  <Stack
                    key={i++}
                    sx={{
                      '&:last-child': {
                        '& .MuiDivider-root': {
                          display: 'none',
                        },
                      },
                    }}
                  >
                    <Typography
                      pb={0.7}
                      variant='title14Bold'
                      sx={{ fontWeight: '500' }}
                    >
                        {k?.name}
                    </Typography>
                      {k?.company_name &&  (
                          <Typography
                              variant={smMediaquery ? 'title10Bold' : 'title12Bold'}
                              sx={{ fontWeight: '400' }}
                          >
                              {k?.company_name}
                          </Typography>
                      )}

                    <Typography
                      pt={0.5}
                      variant={smMediaquery ? 'title10Bold' : 'title12Bold'}
                      sx={{ fontWeight: '400' }}
                    >
                        {/*August 3, 2023 5:02PM*/}
                        {formatDateToLongDateTimeString(k?.viewAt)}
                    </Typography>
                    <Divider sx={{ my: 1.9 }} />
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </Menu>
      )}
    </Stack>
  );
}
