import React, { useState, useEffect } from 'react';

import { useTheme } from '@mui/styles';
import { Stack, Typography, useMediaQuery } from '@mui/material';

import { defaultHeaders, usefetch } from '../../../helpers/fetch';

export default function Timer({ remainingTime, testCode, setTestCompleted }) {
  // const initialTime = remainingTime;
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const mediaquery1380 = useMediaQuery(theme.breakpoints.down('1380'));

  const [time, setTime] = useState(remainingTime);

  useEffect(() => {
    // const storedTime = localStorage.getItem('countdownTimer');
    // if (storedTime) {
    //   setTime(parseInt(storedTime, 10));
    // }
    setTime(remainingTime);
  }, [remainingTime]);

  useEffect(() => {
    localStorage.setItem('countdownTimer', time?.toString());
    if (time === 0) {
      handleSubmitTest();
    }
  }, [time]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime((prevTime) => Math.max(prevTime - 1, 0));
    }, 1000);

    const intervalId2 = setInterval(() => {
      let updatedTime = localStorage.getItem('countdownTimer');
      usefetch('testBuilder/saveTimer', 'post', defaultHeaders(), {
        testCode: testCode,
        time: updatedTime,
      });
    }, 5000);
    return () => {
      clearInterval(intervalId);
      clearInterval(intervalId2);
    };
  }, []);

  const handleSubmitTest = () => {
    let data = {
      testCode: testCode,
    };
    return usefetch(
      'testBuilder/completeTest',
      'post',
      defaultHeaders(),
      data,
    ).then(function (resp) {
      if (resp?.code === '11') {
        setTestCompleted(true);
      }
      return resp;
    });
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     // setTime((prevTime) => Math.max(prevTime - 1, 0));
  //     usefetch('testBuilder/saveTimer', 'post', defaultHeaders(), {
  //       testCode: testCode,
  //       time: time
  //     }).then(function (resp) {
  //       console.log('time saved');
  //     });
  //   }, 5000);
  //   return () => clearInterval(intervalId);
  // }, []);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formatNumber = (num) => (num < 10 ? `0${num}` : num);

    return `${formatNumber(hours)}:${formatNumber(minutes)}:${formatNumber(
      remainingSeconds,
    )}`;
  };
  return (
    <Stack
      sx={{
        width: smMediaquery
          ? 'max-content'
          : mediaquery1380
          ? '180px'
          : '210px',
      }}
    >
      <Typography
        sx={{ display: 'flex', alignItems: 'center', fontWeight: '600' }}
        variant={smMediaquery ? 'title26Bold' : mediaquery1380 ? 'h4' : 'h3'}
      >
        {formatTime(time)}
      </Typography>
    </Stack>
  );
}
