const Style = (theme) => ({
  accordion: {
    margin: '0 !important',
    boxShadow: 'none !important',
    '&:before': {
      display: 'none !important',
    },
    '&:not(:last-child)': {
      borderBottom: '1px solid #e4e4e4',
    },
  },
  accordion__summary: {
    flexDirection: 'row-reverse',
    padding: '0.7rem 1rem !important',
    paddingLeft: '0 !important',
    height: '65px !important',
  },
  divided__width: {
    width: 'calc((100% - 100px) / 2)',
  },
  table__header: {
    padding: '1rem',
    background: '#f4f4f4',
  },
  heading: {
    fontSize: '18px',
    fontWeight: '600',
  },
  content: {
    fontSize: '14px',
    fontWeight: '400',
  },
  accordion__details: {
    paddingTop: '0 !important',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    width: 'calc(100vw - 777px)',
    position: 'sticky',
    left: '14px',
    '& .accordion__detail__inner': {
      padding: '1rem',
      background: '#fff',
      boxShadow: '0px 0px 14px 0px rgba(0,0,0,0.15)',
    },
  },
  divided__width__last: {
    width: '100px',
  },
  main__applicant__table: {
    minWidth: '800px',
  },
});
export default Style;
