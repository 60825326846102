import React from 'react';

import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  data: {
    marginTop: 0,
    marginBottom: 5,
  },
  heading: {
    fontSize: 13,
    fontWeight: '600',
    color: '#010103',
    paddingBottom: 8,
    paddingTop: 10,
  },
  para: {
    fontSize: 10,
    fontWeight: '300',
    color: '#010103',
    paddingBottom: 7,
    lineHeight: 1.5,
  },
});

export default function Employment({ parseHtmlToPdfComponents, cvInfo }) {
  const formatDate = (date) => {
    if (date?.toLowerCase() === 'present') {
      return 'Present';
    }
    const d = new Date(date);
    let year = d.getFullYear();
    return year;
  };
  console.log('check employment', cvInfo?.experiences);

  return (
    <View style={styles.data}>
      <Text style={[styles.heading, { paddingTop: 25, paddingBottom: 15 }]}>
        Employment:
      </Text>
      {cvInfo?.experiences?.map((experience, i) => {
        return (
          <View style={{ paddingBottom: 16 }} wrap={false}>
            <Text style={styles.para}>
              {experience?.start_date
                ? formatDate(experience?.start_date)
                : 'Start Date '}
              -
              {experience?.end_date
                ? formatDate(experience?.end_date)
                : ' End Date'}
            </Text>
            <Text
              style={[styles.para, { fontWeight: '600', color: '#256ef4' }]}
            >
              {experience?.company_name
                ? experience?.company_name
                : 'Company Name'}
            </Text>
            <Text
              style={[styles.para, { fontWeight: '500', color: '#212121' }]}
            >
              {experience?.job_title ? experience?.job_title : 'Job Title'}
            </Text>
            <Text style={styles.para}>
              {experience?.job_city ? experience?.job_city : 'City, Country'}
            </Text>
            <View style={styles.para}>
              {parseHtmlToPdfComponents(experience?.job_description)}
            </View>
          </View>
        );
      })}
      ;
    </View>
  );
}
