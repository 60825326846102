import React, { useEffect } from 'react';

import { Stack, Typography, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import SendIcon from '@mui/icons-material/Send';
import { makeStyles, useTheme } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import Style from './style';
import Textfield from '../../../common/Textfield';
import SelectOption from './components/SelectOption';
import { AIWhiteLogoBlackBg } from '../../../assets/constants';
import { useSeekerChat } from '../../../context/SeekerChatContext/useSeekerChat';
import { useSeekerChatContext } from '../../../context/SeekerChatContext/useSeekerChatContext';

const Styles = makeStyles((theme) => Style(theme));
export default function ChatContent({
  handleClose,
  setToggle,
  disableClose,
  seekerId,
  calledFrom,
}) {
  const classes = Styles();
  const theme = useTheme();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));

  const {
    scrollRef,
    answer,
    loading,
    currentQAns,
    currentQType,
    conversations,
    ChatRef,
    setAnswer,
    handleEnter,
    handleChange,
    setCalledFrom,
  } = useSeekerChatContext();

  useEffect(() => {
    setCalledFrom(calledFrom);
  }, [calledFrom]);

  console.log(currentQType);
  return (
    <Stack height='100%'>
      <Stack
        px={3}
        py={2.5}
        width='100%'
        direction='row'
        alignItems='center'
        sx={{ borderBottom: smMediaquery ? 'none' : '1px solid #e4e4e4' }}
        justifyContent={smMediaquery ? 'flex-start' : 'space-between'}
      >
        {smMediaquery && !disableClose && (
          <Stack sx={{ cursor: 'pointer' }} onClick={handleClose}>
            <Typography
              variant='title16Bold'
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: '400',
              }}
            >
              <ArrowBackIcon sx={{ marginRight: 1.2 }} />
              Back
            </Typography>
          </Stack>
        )}

        <Typography
          pl={smMediaquery && 3}
          variant='title16Bold'
          sx={{ fontWeight: '500' }}
          color='primary.main'
        >
          Just a few more questions
        </Typography>

        {smMediaquery ? (
          false
        ) : disableClose ? null : (
          <CloseRoundedIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
        )}
      </Stack>
      <Stack
        p={3}
        pt={smMediaquery ? 0 : 3}
        sx={{
          height: loading ? 'calc(100% - 224px)' : 'calc(100% - 131px)',
          overflowY: 'auto',
        }}
      >
        {conversations.map((conversation, i) => {
          return (
            <Stack key={i++}>
              {/* User Stack */}
              {conversation.user && (
                <Stack width='100%' alignItems='flex-end' my={1}>
                  <Stack
                    p={1.5}
                    px={2}
                    maxWidth='306px'
                    sx={{
                      background: '#ecedf2',
                      color: '#2b2b2b',
                      borderRadius: '15px 15px 0 15px',
                      position: 'relative',
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: '400', lineHeight: '22px' }}
                      variant='title14Bold'
                    >
                      {conversation.message}
                    </Typography>
                  </Stack>
                </Stack>
              )}
              {/* AI Stack */}
              {conversation.user === false && (
                <>
                  <Stack
                    my={1}
                    spacing={1.2}
                    direction='row'
                    width='100%'
                    alignItems='flex-end'
                  >
                    <AIWhiteLogoBlackBg />
                    <Stack
                      p={1.5}
                      px={2}
                      maxWidth='calc(100% - 56px)'
                      sx={{
                        background: '#010103',
                        color: '#fff',
                        borderRadius: '15px 15px 15px 0',
                        position: 'relative',
                      }}
                    >
                      <Typography
                        sx={{ fontWeight: '400', lineHeight: '22px' }}
                        variant='title14Bold'
                      >
                        {conversation.message}
                      </Typography>
                    </Stack>
                  </Stack>
                  {conversation?.type === 'radio' && (
                    <SelectOption
                      options={conversation?.options}
                      onClick={(option) => setAnswer(option)}
                      onEnter={handleEnter}
                    />
                  )}
                </>
              )}
            </Stack>
          );
        })}

        <Stack py={10} ref={scrollRef} />
      </Stack>

      <Stack>
        {loading && (
          <Stack
            px={3}
            my={2}
            spacing={1.3}
            direction='row'
            width='100%'
            alignItems='center'
          >
            <AIWhiteLogoBlackBg />
            <Stack
              maxWidth='326px'
              sx={{
                color: '#010103',
              }}
            >
              <Typography>
                <div className={clsx('chat-bubble', classes.dots__width)}>
                  <span className='dot'></span>
                  <span className='dot'></span>
                  <span className='dot'></span>
                </div>
              </Typography>
            </Stack>
          </Stack>
        )}

        <Stack
          p={3}
          pb={0}
          sx={{
            // borderTop: '1px solid #e4e4e4',
            '& .MuiInputBase-root': {
              height: '46px !important',
              borderColor: '#ffffff00 !important',
              borderRadius: '10px !important',
              backgroundColor: '#f3f3f3 !important',
              '& input': {
                color: '#010103',
                fontSize: '14px !important',
                padding: '0.6rem 1.25rem !important',
                '&::placeholder': {
                  color: '#7a7a7a',
                  opacity: 1,
                },
              },
            },
          }}
        >
          {/* {(currentQType === 'radio' && true) && (
				
				<FormControl component={'fieldset'} name="input" >
					<RadioGroup
					   value={answer}
					   disabled={loading}
					   onClick={(e)=> {handleChangeRadio(e)}}
					 >
					{currentQAns.map((ans,index)=>(
						
						<FormControlLabel
							key={index}
							value={ans}
							control={<Radio />}
							label={ans}
						/>
						
					))}
				 	</RadioGroup>
				</FormControl>

			)} */}
          {(currentQType !== 'radio' || true) && (
            <Textfield
              tableInput
              ref={ChatRef}
              placeholder='Type your query'
              name='query'
              value={answer}
              disabled={
                loading || currentQType === 'radio' || currentQType === 'info'
              }
              autoComplete='off'
              onEnter={handleEnter}
              onChange={handleChange}
              endAdornment={<SendIcon onClick={(e) => handleEnter(e, 'Y')} />}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
