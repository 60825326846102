import React, { useContext, useEffect, useRef, useState } from 'react';

import {useLocation, useNavigate } from 'react-router';
import { makeStyles, useTheme } from '@mui/styles';
import { Stack, useMediaQuery } from '@mui/material';

import Style from './style';
import AvatarMenu from '../../../common/AvatarMenu';
import MyCvs from '../../../components/Seeker/MyCvs';
import { AuthContext } from '../../../Auth/AuthContext';
import InterviewListing from '../../CareerCoach/InterviewListing';
import MyApplications from '../../../components/Seeker/MyApplications';
import ProfielSetting from '../../../components/Dashboard/ProfileSetting';
import ChatContent from '../../../components/Seeker/ChatForCV/ChatContent';
import { useSeekerChatContext } from '../../../context/SeekerChatContext/useSeekerChatContext';
import {
  JobListingIcon,
  MyCvIcon,
  ProfileSettingIcon,
} from '../../../assets/constants';
import Sidebar from './Sidebar';
import {getLocalStorage, isEmpty, setLocalStorage} from "../../../helpers/common";
import {CareerCoachInterviewContext} from "../VideoInterview/CareerCoachInterviewContext";
import { headers, usefetch } from '../../../helpers/fetch';
import SuccessPopup from '../Payment/SuccessPopup';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Styles = makeStyles((theme) => Style(theme));

export default function CareerCoachDashboard() {
  const classes = Styles();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const smMediaquery = useMediaQuery(theme.breakpoints.down('md'));
  const { user, updateProfileImage } = useContext(AuthContext);

  const [activeTab, setActiveTab] = useState(0);
  const [currentTemplate, setCurrentTemplate] = useState('');
  const [userInfo, setUserInfo] = useState([]);
  const { focusChat } = useSeekerChatContext();

  useEffect(() => {
    console.log(window.location.pathname);
    if (window.location.pathname === '/career-coach/dashboard') {
      setActiveTab(0);
    }
  }, []);

  const {
    setToggle,
    setStartInterview,
    setCurrentQuestion,
    setId,
    setQType,
    setSimpleLoader,
    setCountdown,
    setRerecord,
    setPaymentRes,
    setOrderId,
    setOrderAmount,
    setPaymenttSuccessToggle,
    paymentSuccessToggle
} = useContext(CareerCoachInterviewContext);

useEffect(() => {
  const queryParams = new URLSearchParams(location.search);
  const paramsObject = {};
  queryParams.forEach((value, key) => {
    paramsObject[key] = value;
  });


  const OrderId = getLocalStorage('OrderId');
  const OrderPayment = getLocalStorage('OrderAmount');
  if (Object.keys(paramsObject).length > 2) {
    usefetch('packages/varifyCallBack', 'post', headers(), {
      callback: paramsObject,
      OrderId: OrderId,
      OrderPayment: OrderPayment,
    }).then(function (resp) {
      if (resp?.code == '11' && resp?.data?.transaction_id != undefined) {
        setPaymentRes({
          code: '11',
          message: 'You have successfully made the payment.',
          heading: 'Congratulations!',
        });
        
      } else {
        setPaymentRes({
          code: resp?.data?.errCode,
          message: 'You have canceled the payment',
          heading: 'Transaction not completed',
        });
       
      }
      setPaymenttSuccessToggle(true);
      setOrderAmount(0);
      setOrderId(null);
      setLocalStorage('OrderId', '');
      setLocalStorage('OrderAmount', '');
      const pathname = { location };
      navigate(pathname);
    });
  }
}, [location.search]);
  useEffect(() => {
    const careerCoachInterview = localStorage.getItem('careerCoachInterview');

    if (!isEmpty(careerCoachInterview)) {
      let viQues = sessionStorage.getItem('viQues');
      if(!isEmpty(viQues)) {
        localStorage.removeItem('answerId');
        const parseViQues = JSON.parse(viQues);
        setCurrentQuestion(parseViQues);
        setQType(parseViQues?.type);
        setCountdown(parseViQues?.countdown);
        setRerecord(parseViQues?.reRecord);
        setId(careerCoachInterview);
        setStartInterview(true);
        setToggle(true);
        sessionStorage.removeItem('viQues');
        setSimpleLoader(true);
        navigate(`/career-coach/interview/${careerCoachInterview}`, {
          replace: true,
        });
        setTimeout(() => {
          setSimpleLoader(false);
        }, 1000);
      }
    }

  }, []);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    console.log('new', newValue);

    if (newValue === 0) {
      navigate(`/career-coach/dashboard`);
    }
  };

  const allTabs = [
    {
      title: 'Dashboard',
      component: <InterviewListing />,
    },
  ];

  const SuccessPopupPayment = paymentSuccessToggle && (
    <SuccessPopup toggle={paymentSuccessToggle} setToggle={setPaymenttSuccessToggle} />
  );

  return (
    <>
      <Stack direction='row' className={classes.content__height}>
        <Sidebar
          allTabs={allTabs}
          activeTab={activeTab}
          userInfo={user}
          a11yProps={a11yProps}
          handleChange={handleChange}
          handleChatFocus={focusChat}
        />

        <Stack height='100%' className={classes.tab__details} direction='row'>
          <Stack
            pt={smMediaquery ? 3 : 3}
            pb={smMediaquery ? 3 : 8}
            px={smMediaquery ? 1 : 4}
            className={classes.content__bar}
            sx={{
              backgroundColor:
                activeTab === 3 && smMediaquery ? '#f3f3f3' : '#f3f3f3',
            }}
          >
            <Stack
              sx={{ display: smMediaquery ? 'none' : 'flex' }}
              alignItems='flex-end'
              pb={2}
            >
              <AvatarMenu />
            </Stack>
            {allTabs[activeTab].component}
          </Stack>
          <Stack
            height='100%'
            sx={{ display: { md: 'block', sm: 'none', xs: 'none' } }}
            className={classes.cv__side}
          ></Stack>
        </Stack>
        {SuccessPopupPayment}
      </Stack>
    </>
  );
}
