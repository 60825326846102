import {useEffect} from 'react';

export const useDisableUserActions = () => {
    useEffect(() => {
        const handleContextMenu = (event) => {
            event.preventDefault();
        };

        const handleKeyDown = (event) => {
            // Disable F12, Ctrl+Shift+I, Ctrl+Shift+J, Ctrl+U
            if (
                event.keyCode === 123 || // F12
                (event.ctrlKey && event.shiftKey && event.keyCode === 73) || // Ctrl+Shift+I
                (event.ctrlKey && event.shiftKey && event.keyCode === 74) || // Ctrl+Shift+J
                (event.ctrlKey && event.keyCode === 85) // Ctrl+U
            ) {
                event.preventDefault();
            }
        };

        // const handleDevToolsOpen = () => {
        //     const threshold = 160;
        //     const widthThreshold = window.outerWidth - window.innerWidth > threshold;
        //     const heightThreshold = window.outerHeight - window.innerHeight > threshold;
        //     if (widthThreshold || heightThreshold) {
        //         alert('Developer tools are open. Please close them.');
        //         window.location.href = 'about:blank'; // Optional: Redirect to a blank page
        //     }
        // };

        const handleCutCopyPaste = (event) => {
            event.preventDefault();
        };

        const handleSelectStart = (event) => {
            event.preventDefault();
        };

        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('keydown', handleKeyDown);
        // window.addEventListener('resize', handleDevToolsOpen);
        document.addEventListener('cut', handleCutCopyPaste);
        document.addEventListener('copy', handleCutCopyPaste);
        document.addEventListener('paste', handleCutCopyPaste);
        document.addEventListener('selectstart', handleSelectStart);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
            document.removeEventListener('keydown', handleKeyDown);
            // window.removeEventListener('resize', handleDevToolsOpen);
            document.removeEventListener('cut', handleCutCopyPaste);
            document.removeEventListener('copy', handleCutCopyPaste);
            document.removeEventListener('paste', handleCutCopyPaste);
            document.removeEventListener('selectstart', handleSelectStart);
        };
    }, []);
};