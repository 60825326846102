import { CareerCoachInterviewContext } from './CareerCoachInterviewContext';
import { useCareerCoachInterview } from './useCareerCoachInterview';

export const CareerCoachInterviewProvider = (props) => {
  const { children } = props;
  let values = useCareerCoachInterview();

  return (
    <CareerCoachInterviewContext.Provider value={values}>
      {children}
    </CareerCoachInterviewContext.Provider>
  );
};
