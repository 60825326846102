import React, { useState } from 'react';

import ReactPlayer from 'react-player';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Stack, Drawer, IconButton } from '@mui/material';

import Style from './style';
import { PlayIconTestResult } from '../../../assets/constants';

const Styles = makeStyles((theme) => Style(theme));

export default function VideoPlayerDrawer({ url, toggle, setToggle }) {
  const classes = Styles();
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <Drawer
      anchor='bottom'
      open={toggle}
      onClose={() => setToggle(false)}
      className={classes.video__dialog}
      sx={{
        '--Drawer-transitionDuration': toggle ? '1s' : '0.1s',
      }}
    >
      <IconButton
        onClick={() => setToggle(false)}
        sx={{
          backgroundColor: '#fff',
          position: 'absolute',
          right: '0.5rem',
          top: '0.5rem',
          zIndex: '9',
          boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
        }}
      >
        <CloseIcon
          sx={{
            color: '#010103',
          }}
        />
      </IconButton>
      <Stack
        width='100%'
        sx={{
          height: 'calc(100%)',
          '& video, & react-player_preview': {
            borderRadius: '0px',
            width: '100%',
            objectFit: 'cover',
          },
        }}
      >
        <ReactPlayer
          url={url}
          width='100%'
          height='100%'
          controls
          playsinline={true}
          id='myvideo'
          playing={url && toggle}
          paused={isPlaying === url ? false : true}
          playIcon={<PlayIconTestResult />}
          // onReady={handleVideoReady}
          // light={<PlayCircleIcon />}|
          // onClickPreview={() => handlePlay(videoData?.videoUrl)}
        />
      </Stack>
    </Drawer>
  );
}
