const Style = (theme) => ({
  share__view__icon: {
    height: '35px',
    width: '35px',
    border: '1px solid #d6d6d6 !important',
  },
  template__view: {
    border: '2px solid #d6d6d6 !important',
    borderRadius: '12px',
    height: '208px',
    width: '157px',
    overflow: 'hidden',
    pointerEvents: 'none !important',
    '& > .MuiStack-root': {
      transform: 'scale(0.244)',
      transformOrigin: 'top left',
      height: '34rem',
      padding: '0 !important',
      pointerEvents: 'none !important',
      minWidth: '603px',
    },
  },
  more__horiz__icon: {
    top: '.5rem',
    right: '.5rem',
    zIndex: '10',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    color: '#fff',
    height: '20px',
    cursor: 'pointer',
    borderRadius: '5px',
    background: 'rgba(0,0,0,0.7)',
  },
  name__editable: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: '600',
    alignItems: 'center',
    '& .edit__icon': {
      '& path': {
        fill: '#010103',
      },
    },
    '& .text__main': {
      width: 'calc(100% - 22px)',
      padding: '0',
      '& .MuiInputBase-root': {
        '& input': {
          padding: '0 !important',
          fontSize: '14px',
          fontWeight: '600',
        },
      },
    },
  },
  active__tab: {
    padding: '11px 22px',
    background: '#f4f4f4',
    borderRadius: '6px',
    userSelect: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  shared__box: {
    background: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 1px 40px 0px rgba(0,0,0,0.1)',
    [theme.breakpoints.down('md')]: {
      borderRadius: 'unset',
      boxShadow: 'unset',
    },
  },
  email__textfield: {
    '& .text__main': {
      height: '45px',
      marginTop: '.5rem',
      marginBottom: '1rem',
      '& .MuiInputBase-root': {
        borderRadius: '12px !important',
        height: '45px',
        '& input': {
          fontSize: '14px',
          fontWeight: '400',
          borderRadius: '12px !important',
          borderWidth: '1px !important',
          padding: '10.5px 14px !important',
        },
        '& fieldset': {
          borderWidth: '1px !important',
        },
      },
      '& .MuiFormHelperText-root': {
        color: '#ff3636',
      },
    },
  },
});
export default Style;
