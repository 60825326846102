import {SeekerChatContext} from './SeekerChatContext';
import {useSeekerChat} from './useSeekerChat';

export const SeekerChatProvider = ({children}) => {
  const values = useSeekerChat();
  return (
      <SeekerChatContext.Provider value={values}>
      {children}
      </SeekerChatContext.Provider>
  );
};
