import {useState} from "react";
import {headers, defaultHeaders, usefetch} from "../helpers/fetch";

export const useCompleteVideoInterview = () => {
    const [error, setError] = useState(null);
    const [isCompletingInterview, setIsCompletingInterview] = useState(null);
    const [response, setResponse] = useState(null);
    const [isCompleted, setIsCompleted] = useState(false);

    const completeVideoInterview = async (params) => {
        setIsCompletingInterview(true);
        const data = {
            testCode: params?.testCode,
        };

        const resp = await usefetch('videoInterview/completeVideoInterview', 'POST', defaultHeaders(), data);

        if (resp?.code === '11') {
            setIsCompleted(true);
        }

        if (!resp.ok) {
            setError(resp.error);
        }

        if (resp.ok) {
            setResponse((prevData) => [...prevData, resp]);
        }

        setIsCompletingInterview(false);
        return resp;
    };

    return { completeVideoInterview, response, isCompletingInterview, error };
};