import {Helmet} from 'react-helmet';

const JobOgTags = (props) => {
    const { job } = props;
  return (
      <Helmet>
         <meta property='og:title' content='This is OG Tag Title' />
         <meta property='og:description' content='This is OG Tag Description' />
      </Helmet>
  );
}

export default JobOgTags;