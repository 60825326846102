// NavigationHandler.js
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "./helpers/common";

const NavigationHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [routeStack, setRouteStack] = useState([]);

  useEffect(() => {
    // Add a new history entry so that back button triggers popstate
    window.history.pushState(null, null, window.location.href);

    const handlePopState = (event) => {
      event?.preventDefault();
      handleBackButton(event);
    };
    // Ensure that routeStack exists in localStorage
    let routeStack = getLocalStorage("routeStack") || [];

    // Add the current route to the stack
    const noBackRoutes = ["/interview/"]; // Routes where back button is disabled
    const isInterviewRoute = location.pathname.startsWith("/career-coach/interview/");
    if (!noBackRoutes.includes(location.pathname) && !isInterviewRoute && routeStack[routeStack.length - 1] !== location.pathname) {
      routeStack = [...routeStack, location.pathname];
      setLocalStorage("routeStack", routeStack);
    }

    // Function to handle back button and route behavior
    const handleBackButton = (event) => {
      let routeStack = getLocalStorage("routeStack");
      if (routeStack.length > 1) {
        // Navigate to the previous route from the stack
        const previousRoute = routeStack[routeStack.length - 2];
        console.log(previousRoute);
        routeStack = routeStack.slice(0, -1);
        setLocalStorage("routeStack", routeStack); // Remove the last route from stack
        navigate(previousRoute); // Navigate to the previous route
      } else {
        // If no more routes in the stack, fallback to browser default behavior
        navigate(-1);
      }
      if (noBackRoutes.includes(location.pathname) || isInterviewRoute) {
        window.location.reload();
      }
    };
    // Your custom function to handle back/forward navigation
    const handleNavigation = () => {
      console.log("Back or forward button pressed");
      // Add your custom logic here
    };

    // Add the event listener for popstate
    window.addEventListener("popstate", handlePopState);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]); // Empty dependency array ensures this runs once on mount
};

export default NavigationHandler;
