import React, { memo, useEffect, useState } from 'react';
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
  // PDFViewer,
  Font,
} from '@react-pdf/renderer';

import Skills from './Skills';
import Summary from './Summary';
import Language from './Language';
import Education from './Education';
import Employment from './Employment';
import Information from './Information';
import { usefetch, headers } from '../../../../helpers/fetch';
import Logo from '../../../../assets/images/rozeegpt-logo.png';

import OutfitThin from '../../Fonts/Outfit/static/Outfit-Light.ttf';
import OutfitRegular from '../../Fonts/Outfit/static/Outfit-Regular.ttf';
import OutfitMedium from '../../Fonts/Outfit/static/Outfit-Medium.ttf';
import OutfitSemiBold from '../../Fonts/Outfit/static/Outfit-SemiBold.ttf';

Font.register({
  family: 'Outfit',
  fonts: [
    {
      src: OutfitThin,
      fontWeight: '300',
    },
    {
      src: OutfitRegular,
      fontWeight: '400',
    },
    {
      src: OutfitMedium,
      fontWeight: '500',
    },
    {
      src: OutfitSemiBold,
      fontWeight: '600',
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingLeft: 25,
    paddingRight: 25,
    paddingBottom: 80,
    backgroundColor: '#fff',
    fontFamily: 'Outfit',
    color: '#29264A',
    letterSpacing: '.6px',
  },
  title__sm__footer: {
    fontSize: 7.5,
    fontWeight: '400',
  },
  headerContainer: {
    marginBottom: 5,
  },
  image: {
    width: '80px',
  },
  footer: {
    position: 'absolute',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    bottom: 8,
    left: 0,
    right: 0,
    borderTop: '1px solid #8A9EAF',
    paddingTop: 8,
    marginTop: 10,
    marginLeft: 25,
    marginRight: 25,
    marginBottom: 10,
  },
  align__center: {
    alignItems: 'center',
  },
  paddingRight: {
    paddingRight: 25,
  },
  para: {
    fontSize: 11,
    fontWeight: '300',
    color: '#010103',
    paddingBottom: 0,
    lineHeight: 1.5,
  },
});

const parseHtmlToPdfComponents = (htmlString) => {
  // Parse the HTML string using DOMParser
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Function to convert DOM nodes to React-PDF components
  const convertNodeToPdfComponents = (node) => {
    switch (node.nodeName.toLowerCase()) {
      case 'p':
        return (
          <Text style={styles.para}>
            {Array.from(node.childNodes).map((childNode) =>
              convertNodeToPdfComponents(childNode),
            )}
          </Text>
        );
      case 'strong':
        return (
          <Text style={[styles.para, { fontWeight: '500' }]}>
            {Array.from(node.childNodes).map((childNode) =>
              convertNodeToPdfComponents(childNode),
            )}
          </Text>
        );
      case 'b':
        return (
          <Text style={[styles.para, { fontWeight: '500' }]}>
            {Array.from(node.childNodes).map((childNode) =>
              convertNodeToPdfComponents(childNode),
            )}
          </Text>
        );
      case 'div':
        return (
          <Text style={styles.para}>
            {Array.from(node.childNodes).map((childNode) =>
              convertNodeToPdfComponents(childNode),
            )}
          </Text>
        );
      case 'ul':
      case 'ol': {
        return (
          <View style={[styles.para, { marginLeft: 10 }]}>
            {Array.from(node.childNodes).map((childNode) =>
              convertNodeToPdfComponents(childNode),
            )}
          </View>
        );
      }
      case 'li': {
        const parentChildren = Array.from(node.parentNode.childNodes);
        const visibleListItems = parentChildren.filter(
          (childNode) => childNode.nodeName.toLowerCase() === 'li',
        );

        const listItemNumber = visibleListItems.indexOf(node) + 1;
        return (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text
              style={{
                lineHeight: 1.5,
                fontSize:
                  node.parentNode.nodeName.toLowerCase() === 'ul' ? 14 : 11,
                fontWeight:
                  node.parentNode.nodeName.toLowerCase() === 'ul'
                    ? '600'
                    : '400',
              }}
            >
              {node.parentNode.nodeName.toLowerCase() === 'ul'
                ? '•'
                : `${listItemNumber}.`}{' '}
              &nbsp;&nbsp;
            </Text>

            <Text>
              {Array.from(node.childNodes).map((childNode) =>
                convertNodeToPdfComponents(childNode),
              )}
            </Text>
          </View>
        );
      }
      case '#text': // Handle text nodes
        return <Text>{node.textContent}</Text>;
      default:
        // For other HTML tags, treat child nodes recursively
        return Array.from(node.childNodes).map((childNode) =>
          convertNodeToPdfComponents(childNode),
        );
    }
  };

  // Convert the root nodes (child nodes of the body) to React-PDF components
  return Array.from(doc.body.childNodes).map((childNode) =>
    convertNodeToPdfComponents(childNode),
  );
};

const Template1Pdf = ({ cvInfo }) => {
  return (
    <>
      {/* <PDFViewer> */}
      <Document>
        <Page size='A4' style={styles.body} wrap>
          {/* Header */}
          <View style={styles.paddingRight}>
            <Information cvInfo={cvInfo} />
            <Summary
              cvInfo={cvInfo}
              parseHtmlToPdfComponents={parseHtmlToPdfComponents}
            />
            {cvInfo?.experiences?.length > 0 && (
              <Employment
                cvInfo={cvInfo}
                parseHtmlToPdfComponents={parseHtmlToPdfComponents}
              />
            )}
            {cvInfo?.educations?.length > 0 && <Education cvInfo={cvInfo} />}
            <Skills cvInfo={cvInfo} />
            <Language cvInfo={cvInfo} />
          </View>
          {/* Footer */}
          <View style={styles.footer} fixed>
            <Text style={styles.title__sm__footer}>
              {false && Date.now('MM/DD/YYYY')}
            </Text>

            <View style={styles.align__center}>
              <Image src={Logo || 'data:,'} style={styles.image} />
            </View>
            <Text
              style={styles.title__sm__footer}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
          </View>
        </Page>
      </Document>
      {/*   </PDFViewer>  */}
    </>
  );
};

export default memo(Template1Pdf);
