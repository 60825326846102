import {useState} from "react";
import {headers, defaultHeaders, usefetch} from "../helpers/fetch";

export const useGetOrderHistory = () => {
    const [isLoadingHistory, setIsLoadingHistory] = useState(true);
    const [orderHistory, setOrderHistory] = useState([]);


    const getOrderHistory = async (params) => {
        setIsLoadingHistory(true);
        const data = {};

        const resp = await usefetch('packages/purchaseHistory', 'POST', headers(), data);

        if (resp?.code === '11') {
            setOrderHistory(prev => resp?.data?.orders);
        }

        setIsLoadingHistory(false);
        return resp;
    };

    return { getOrderHistory, orderHistory, isLoadingHistory };
};