import {headers, usefetch} from "../../../../helpers/fetch";

export const addCvLanguage = async (language, cvId) => {
    return await usefetch(
        'cv/addCvLanguage',
        'post',
        headers(),
        {
            cvId: cvId,
            language: language,
        },
    );
}

export const addCvSkill = async (skill, cvId) => {
    return await usefetch(
        'cv/addCvSkill',
        'post',
        headers(),
        {
            cvId: cvId,
            skill: skill,
        },
    );
}