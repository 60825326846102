const Style = (theme) => ({
  main__dialog__success: {
    '& .MuiDialog-paper': {
      width: '365px !important',
      height: '240px',
      position: 'relative',
      padding: '0.5rem',
      borderRadius: '10px',
      [theme.breakpoints.down('1380')]: {
        width: '339px !important',
        height: '221px',
      },
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        margin: '10px',
      },
    },
    '& .MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      backdropFilter: 'blur(4px)',
    },
  },
});
export default Style;
